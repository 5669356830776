import classNames from 'classnames';
import { getInitials, stringToColor, getTextColorClass } from '@client/shared/utilities';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface AvatarProps {
  name?: string;
  className?: string;
  rounded?: boolean;
  imagePath?: string;
  randomBgColor?: boolean;
  width?: number
}

export const Avatar = ({ name, className, rounded = true, imagePath, randomBgColor = true, width = 100 }: AvatarProps) => {
  const backgroundColor = randomBgColor ? stringToColor(name ?? 'Default') : undefined;

  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center overflow-hidden flex-none',
        className,
        !rounded ? 'rounded-md' : 'rounded-full',
        { 'bg-gray-500': !randomBgColor },
      )}
      style={{ backgroundColor }}
    >
      {imagePath ? (
        <LazyLoadImage
          src={`${imagePath}`}
          alt={name}
          aria-hidden={true}
          className="w-auto h-full object-cover"
          width={width}
        />
      ) : (
        <span
          className={classNames(
            'text-lg font-medium leading-none',
            backgroundColor ? getTextColorClass(backgroundColor) : 'text-white',
          )}
        >
          {getInitials(name)}
        </span>
      )}
    </span>
  );
};
