import { useEffect, useId, useRef } from 'react';
import { useAutocomplete } from '../hooks';
import classNames from 'classnames';
import { isEmpty } from '@client/shared/utilities';
import React from 'react';
import { FormHelperText } from './FormHelperText';

interface AutoCompleteProps {
  data: string[];
  value: string;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  maxLength?: number;
  name?: string;
  autoComplete?: string;
  showValidation?: boolean;
  isValidationValid?: boolean;
  icon?: React.ReactNode;
  placeHolder?: string;
  label?: string;
  helperText?: string;
  onEnterKeyPress?: (value: string) => void;
  onClick?: (value: string) => void;
  onChange: (selected: string) => void;
  onFocus?: (focus: boolean) => void
}

export const AutoComplete = ({
  data,
  value,
  className,
  disabled,
  inputClassName,
  maxLength,
  autoComplete,
  showValidation,
  isValidationValid,
  icon,
  placeHolder,
  name,
  label,
  helperText,
  onEnterKeyPress,
  onClick,
  onChange,
  onFocus
}: AutoCompleteProps) => {
  const inputId = useId();
  const [isFocused, setIsFocused] = React.useState(false);
  const inputSearchRef = useRef<HTMLInputElement | null>(null);

  const handleFocusChange = (focus: boolean) => {
    setIsFocused(focus);
    if (onFocus) {
      onFocus(focus)
    }
  };

  useEffect(() => {
    if (inputSearchRef.current) {
      inputSearchRef.current.focus();
    }
  }, []);

  const { suggestions, activeSuggestion, handleChange, handleKeyDown, handleClick } = useAutocomplete(
    data,
    onChange,
    inputSearchRef.current
  );

  const handleOnClick = (value: string) => {
    handleClick(value);

    if (onClick) onClick(value);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const selectedSuggestion = handleKeyDown(event) ?? event.currentTarget.value;

    if (onEnterKeyPress && event.code === 'Enter' && selectedSuggestion) onEnterKeyPress(selectedSuggestion);
  };

  return (
    <div className={className}>
      <div
        className={classNames('w-full relative px-3 flex flex-row bg-white', {
          'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
            showValidation && isValidationValid != null && !isValidationValid,
          'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
        })}
      >
        {icon && (
          <div className="h-14 flex items-center">
            <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
          </div>
        )}
        <div
          className={classNames('relative flex-grow', {
            'ml-2': icon,
            'mx-1': !icon,
          })}
        >
          <input
            id={inputId}
            className={classNames(
              'fake-mt block pt-5 w-full text-lg appearance-none focus:outline-none bg-transparent font-medium transition-opacity',
              {
                'text-gray-800': !disabled,
                'text-gray-500 cursor-not-allowed': disabled,
                'opacity-0': placeHolder && isEmpty(value) && !isFocused,
              },
              inputClassName
            )}
            value={value}
            type="text"
            placeholder={placeHolder}
            disabled={disabled}
            autoComplete={autoComplete}
            name={name}
            maxLength={maxLength}
            onChange={handleChange}
            onFocus={() => handleFocusChange(true)}
            onBlur={() => handleFocusChange(false)}
            onKeyDown={handleOnKeyDown}
            ref={inputSearchRef}
          />
          {label && (
            <label
              htmlFor={inputId}
              className={classNames(
                'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate pr-4 text-left',
                {
                  'pt-3 mt-[3px]': isEmpty(value) && !isFocused,
                  'pt-5 -mt-px': !isEmpty(value) || isFocused,
                }
              )}
            >
              {label}
            </label>
          )}
        </div>
        <div
          className={classNames('absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-300 transition-opacity', {
            'opacity-0': !isFocused,
          })}
        />
      </div>

      <div className="w-full bg-white mb-5 origin-top-right border border-t-0 border-gray-200 divide-y divide-gray-100 rounded-bl-lg rounded-br-lg shadow-lg outline-none max-h-72 overflow-y-auto">
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            className={classNames(
              'select-none relative p-3 text-lg',
              { 'cursor-pointer text-gray-900': activeSuggestion },
              { 'cursor-pointer text-blue-900 bg-sky-100': index === activeSuggestion - 1 }
            )}
            onClick={() => handleOnClick(suggestion)}
          >
            <span className="block truncate font-medium">{suggestion}</span>
          </div>
        ))}
      </div>

      {helperText && (
        <div className="w-full bg-white">
          <FormHelperText text={helperText} error={!isValidationValid} />
        </div>
      )}
    </div>
  );
};
