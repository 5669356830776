import {
  BaseSelectOption,
  ComboSelect,
  ComboSelectOption,
  FormField,
  LoadingIndicator
} from '@client/shared/toolkit';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useLoadedPortfolioId } from '@client/portfolio/store';
import { ClusterItemTreeReadModel, RequirementReadModel, useApiGetClusterItemsTreeQuery } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useUi } from '@client/shared/store';
import { getClusterItemIdsForUser, getClusterItemUsers, isReporterInCluster } from '@client/portfolio/shared';

const getClusterListItems = (children: ClusterItemTreeReadModel[], userId?: string, parentId?: string) => {
  const userClusterItemIds = userId ? getClusterItemIdsForUser(userId) : [];
  const items: ComboSelectOption[] = [];
  if (children.length) {
    children.forEach((clusterItem) => {
      let children = clusterItem.children.length
        ? getClusterListItems(clusterItem.children, userId, clusterItem.id)
        : undefined;

      if (userId && children) {
        children = children?.filter((child) => userClusterItemIds.includes(child.value));
      }

      if (
        (!parentId && children && children.length) ||
        (parentId && userClusterItemIds.includes(clusterItem.id)) ||
        (!parentId && userClusterItemIds.includes(clusterItem.id))
      ) {
        items.push({
          label: clusterItem.name,
          value: clusterItem.id,
          options: children,
        });
      }
    });
  }
  return items;
};

export const ClusterItemSelect = ({
  formRef,
  requirement,
  onChange,
  disabled = false,
  clusterChanged = false,
}: {
  formRef?: RefObject<HTMLFormElement> | null;
  requirement?: RequirementReadModel | null;
  onChange?: (selected: string | null) => void;
  disabled?: boolean;
  clusterChanged?: boolean;
}) => {
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();
  const ui = useUi();

  const { data: clusterResponse, isFetching } = useApiGetClusterItemsTreeQuery(
    {
      portfolioId: loadedPortfolioId ?? '',
    },
    {
      skip: !loadedPortfolioId,
    },
  );

  const [reportedById, setReportedById] = useState<string | null | undefined>(requirement?.reportedById);
  const [reportedByDeputyId, setReportedByDeputyId] = useState<string | null | undefined>(
    requirement?.reportedByDeputyId,
  );
  const [clusterItemId, setClusterItemId] = useState<string | null | undefined>(requirement?.clusterItem.id);

  useEffect(() => {
    setReportedById(requirement?.reportedById);
    setReportedByDeputyId(requirement?.reportedByDeputyId);
    setClusterItemId(requirement?.clusterItem.id);
  }, [requirement]);

  const clusterOptions: ComboSelectOption[] = useMemo(() => {
    let options: ComboSelectOption[] = [];
    if (clusterResponse?.clusterItemsTree) {
      if (clusterResponse.clusterItemsTree.children) {
        options = getClusterListItems(clusterResponse.clusterItemsTree.children, ui.appUser.userId);
      }
    }
    return options;
  }, [clusterResponse?.clusterItemsTree, ui.appUser.userId]);

  const resetReporter = useCallback(
    (clusterItem?: string | null) => {
      return requirement?.reportedById ? !isReporterInCluster(requirement.reportedById, clusterItem) : true;
    },
    [requirement?.reportedById],
  );

  const reporterOptions = useMemo(() => {
    const options: BaseSelectOption[] = [];
    if (requirement?.clusterItem.id) {
      // find all the teams with users that are allowed to see the cluster item and are in the same team as the reporter
      const users =
        clusterItemId && clusterItemId !== requirement.clusterItem.id
          ? getClusterItemUsers(clusterItemId)
          : getClusterItemUsers(requirement.clusterItem.id, requirement.reportedById);
      users.forEach((team) => {
        const teamUsers: BaseSelectOption[] = [];
        team.users.forEach((teamUser) => {
          if (teamUser.id !== reportedByDeputyId) {
            teamUsers.push({
              label: teamUser.name,
              value: teamUser.id,
            });
          }
        });
        options.push({
          label: team.name,
          value: team.name,
          disabled: true,
          options: teamUsers,
        });
      });
    }
    return options;
  }, [requirement?.clusterItem.id, clusterItemId, requirement?.reportedById, reportedByDeputyId]);

  const reporterDeputyOptions = useMemo(() => {
    const options: BaseSelectOption[] = [];
    if (requirement?.clusterItem.id) {
      // find all the teams with users that are allowed to see the cluster item and are in the same team as the reporter
      const users =
        reportedById && clusterItemId && clusterItemId !== requirement.clusterItem.id
          ? getClusterItemUsers(clusterItemId, reportedById)
          : getClusterItemUsers(requirement.clusterItem.id, requirement.reportedById);
      users.forEach((team) => {
        const userOptions: BaseSelectOption[] = [];
        team.users.forEach((teamUser) => {
          // reported him / herself cannot be deputy
          if (teamUser.id !== (reportedById ?? requirement.reportedById)) {
            userOptions.push({
              label: teamUser.name,
              value: teamUser.id,
            });
          }
        });
        options.push({
          label: team.name,
          value: team.name,
          disabled: true,
          options: userOptions,
        });
      });
    }
    return options;
  }, [requirement?.clusterItem.id, clusterItemId, reportedById, requirement?.reportedById]);

  return (
    <div className="relative">
      {isFetching && (
        <LoadingIndicator
          mode="inline"
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10"
          svgClassName="w-8"
        />
      )}
      <FormField name="clusterItemId">
        {(control) => (
          <ComboSelect
            nullable={false}
            label={t('portfolio.projecting.createWizard.cluster')}
            options={clusterOptions}
            {...control}
            onChange={(value) => {
              if (onChange) {
                setClusterItemId(value);
                // unset if new cluster does not contain the reporter
                if (requirement && resetReporter(value)) {
                  // unset the values
                  formRef?.current?.setValue('reportedById', '');
                  formRef?.current?.setValue('reportedByDeputyId', '');
                  setReportedById('');
                } else if (requirement) { // reset the values
                  formRef?.current?.setValue('reportedById', requirement.reportedById);
                  formRef?.current?.setValue('reportedByDeputyId', requirement.reportedByDeputyId);
                  setReportedById(requirement.reportedById);
                }
                onChange(value);
              }
              control.onChange(value);
            }}
            helperTextClassName="bg-transparent"
            disabled={isFetching || disabled}
          />
        )}
      </FormField>
      {clusterChanged && resetReporter(clusterItemId) && (
        <>
          <FormField name="reportedById">
            {(control) => (
              <ComboSelect
                nullable={false}
                label={t('portfolio.projecting.releaseForReviewWizard.reportedBy')}
                options={reporterOptions}
                {...control}
                onChange={(val) => {
                  setReportedById(val);
                  control.onChange(val);
                }}
                helperTextClassName="bg-transparent"
                disabled={!clusterChanged}
              />
            )}
          </FormField>
          <FormField name="reportedByDeputyId">
            {(control) => (
              <ComboSelect
                nullable={false}
                label={t('portfolio.projecting.submitNotificationWizard.deputy')}
                options={reporterDeputyOptions}
                {...control}
                onChange={(val) => {
                  setReportedByDeputyId(val);
                  control.onChange(val);
                }}
                helperTextClassName="bg-transparent"
                disabled={!reportedById}
              />
            )}
          </FormField>
        </>
      )}
    </div>
  );
};
