import { useApiProjectGetUserDefinedFieldsDefinitionQuery } from '@client/shared/api';
import { SelectedCustomIcon, UnselectedCustomIcon } from '@client/shared/toolkit';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';

interface UserDefinedFieldDropDownData {
  id: string | null;
  name: string;
}

interface UserDefinedFieldSelectSelectProps {
  className?: string;
  projectId: string | undefined | null;
  selectedUserDefinedFieldId: string | null;
  onChange: (selectedUserDefinedFieldId: string | null) => void;
}

export const UserDefinedFieldSelectSelect = ({
  className,
  projectId,
  selectedUserDefinedFieldId,
  onChange,
}: UserDefinedFieldSelectSelectProps) => {
  const { t } = useTranslation();

  const { data } = useApiProjectGetUserDefinedFieldsDefinitionQuery(
    {
      projectId: projectId ?? ''
    },
    { skip: !projectId },
  );

  const userDefinedFieldDefintions : UserDefinedFieldDropDownData[] = useMemo(() => {
    const options = data?.userDefinedFieldsDefinition
      .filter(x => x.elementType === 'Cost' && x.calculateElementType === 'Element')
      .map((field) => { return {
        name: field.name,
        id: field.id
      }}) ?? [];
    
    const emptyOption : UserDefinedFieldDropDownData = {
      name : t('common.none'),
      id : null
    };
    
    return [emptyOption].concat(options);
  }, [data, t])

  const selectedUserDefinedField = userDefinedFieldDefintions.find((x) => x.id === selectedUserDefinedFieldId);

  const handleSelectUserDefinedField = (id: string | null) => {
    onChange(id);
  };

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  return (
    <Listbox
      as="div"
      className={classNames(
        'w-full h-12 flex items-center text-sm font-medium text-gray-600 bg-gray-400 bg-opacity-10 rounded-full hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        className
      )}
    >
      <div ref={setTargetElement} className="w-full px-6">
        <ListboxButton className="flex w-full">
          <div className="flex-grow truncate">
            <div className="text-left text-xs text-gray-600 -mb-1 font-normal">{t('common.userDefinedField')}</div>
            <div className="text-left font-bold pr-3 text-[15px] truncate">
              {selectedUserDefinedField?.name ?? '-'}
            </div>
          </div>
          <ChevronDownIcon className="w-5 h-5 my-auto text-gray-600 flex-shrink-0" />
        </ListboxButton>
      </div>
      <ListboxOptions
        portal
        ref={setPopperElement}
        style={{ ...styles.popper }}
        {...attributes.popper}
        className="absolute z-50 right-0 mt-4 w-96 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div className="divide-y divide-gray-100">
          {userDefinedFieldDefintions.map((userDefinedFieldDefinition) => (
            <ListboxOption key={userDefinedFieldDefinition.id} value={userDefinedFieldDefinition.id}>
              {({ focus }) => (
                <div
                  onClick={() => handleSelectUserDefinedField(userDefinedFieldDefinition.id)}
                  className={classNames('text-gray-900 group flex rounded-none w-full py-3 px-4 text-sm', {
                    'bg-gray-100': focus,
                  })}
                >
                  <div className="grow text-left w-7/12 pr-3 my-auto text-[15px] font-bold">
                    {userDefinedFieldDefinition.name}
                  </div>
                  <div className="w-12 text-right my-auto">
                    {selectedUserDefinedField?.id === userDefinedFieldDefinition.id ? (
                      <SelectedCustomIcon className="w-5 h-5 ml-auto" />
                    ) : (
                      <UnselectedCustomIcon className="w-5 h-5 ml-auto" />
                    )}
                  </div>
                </div>
              )}
            </ListboxOption>
          ))}
        </div>
      </ListboxOptions>
    </Listbox>
  );
};
