import {useTranslation} from "react-i18next";
import React, { ReactElement, useMemo } from "react";
import {
  ArchiveIcon,
  CopyToClipboardIcon,
  ExportIcon,
  MoveNodeUpIcon,
  MultiProjectIcon,
  ReportFileDottedIcon,
  RoundedCloseIcon,
  TrashCanCustomIcon
} from "@client/shared/toolkit";
import { useValidateTenantPermission } from '@client/shared/permissions';
import cn from 'classnames';
import { settings, useUi } from '@client/shared/store';
import { OpenModal } from '@client/project/edit';

type FloatingMenuProps = {
  selectedItemsCount: number;
  clearSelection: () => void;
  onClickMultiProject: () => void;
  onClickReporting: (direction: 'project' | 'multiProject') => void;
  openModal: (type: OpenModal) => void;
  isMultiProjectSelected?: boolean;
};
export const FloatingMenu = (props: FloatingMenuProps) => {
  const { t } = useTranslation()
  const {
    openModal,
    selectedItemsCount,
    onClickMultiProject,
    onClickReporting,
    isMultiProjectSelected = false
  } = props

  const canCreateProject = useValidateTenantPermission(['TENANT_PROJECT_CREATE'])
  const canDeleteProject = useValidateTenantPermission(['TENANT_PROJECT_DELETE'])

  const ui = useUi();
  const user = ui.appUser;

  const canViewReporting = (user.tenant?.tenantId === '3239f945-4fce-4ebb-931f-89700f4c7631') || settings.mainDomain === 'probis.qa' || settings.mainDomain === 'probis.test' || settings.mainDomain === 'probis.dev'

  const menuOptions = useMemo(() => {
    return [
      {
        label: t('project.Duplicate'),
        icon: <CopyToClipboardIcon />,
        show: selectedItemsCount === 1 && !isMultiProjectSelected && canCreateProject,
        onClick: () => openModal('Copy'),
        disabled: !canCreateProject
      },
      {
        label: t('project.Export'),
        icon: <ExportIcon />,
        show: true,
        disabled: true
      },
      {
        label: t('project.Archive'),
        icon: <ArchiveIcon />,
        show: true,
        disabled: true
      },
      {
        label: t('project.Delete'),
        icon: <TrashCanCustomIcon />,
        show: selectedItemsCount === 1 && canDeleteProject,
        onClick: () => openModal('Delete'),
        iconClassName: 'w-4 pb-1'
      },
      {
        label: t('project.Reporting'),
        icon: <ReportFileDottedIcon />,
        onClick: () => onClickReporting(isMultiProjectSelected ? 'multiProject' : 'project'),
        show: selectedItemsCount === 1,
        disabled:  isMultiProjectSelected ? (!canViewReporting) : false
      },
      {
        label: t('project.Linking'),
        icon: <MoveNodeUpIcon />,
        show: !isMultiProjectSelected,
        onClick: () => openModal('Move'),
      },
      {
        label: t('project.Multiproject'),
        icon: <MultiProjectIcon />,
        onClick: onClickMultiProject,
        show: !isMultiProjectSelected,
        disabled: !canCreateProject
      },
    ];
  }, [t, selectedItemsCount, isMultiProjectSelected, canCreateProject, openModal, canDeleteProject, onClickMultiProject, onClickReporting, canViewReporting])

  const shownMenuOptions = useMemo(() => {
    return menuOptions.filter((option) => option.show)
  }, [menuOptions])

  return (
    <div className="floating-projects-menu rounded-lg flex bg-white flex-nowrap overflow-hidden">
      <div className="w-12 bg-sky-800 flex justify-center items-center text-white rounded-l border border-sky-800">
        {props.selectedItemsCount}
      </div>
      <div className="flex justify-between gap-x-7 border-y flex-nowrap pl-7">
        <div className="w-28 border-r-2 flex justify-center items-center">
          <div className="h-full flex flex-col flex-grow font-bold justify-center">
            {t('app.projectFloatingMenuSelectedElementsLabel', { count: props.selectedItemsCount })}
          </div>
        </div>
        {shownMenuOptions.map((m) => (
          <button
            key={m.label}
            className={cn('flex flex-col justify-center items-center py-1 px-1 pt-2 text-inherit', {
              'text-slate-300 cursor-not-allowed': m.disabled,
              'cursor-pointer': !m.disabled
            })}
            onClick={m.onClick}
            disabled={m.disabled}
          >
            {React.cloneElement(m.icon as ReactElement, { className: m.iconClassName ? m.iconClassName : 'w-5 pb-1'})}
            <span className="text-xs">{m.label}</span>
          </button>
        ))}
        <button className="w-10 flex items-center justify-center cursor-pointer border-r rounded-r-lg border-l-2" onClick={props.clearSelection}>
          <RoundedCloseIcon className="w-6" />
        </button>
      </div>
    </div>
  );
};
