export const TOGGLED_EXPANDED_IDS = 'expanded_ids'
export const toggleExpandedIds = (expandedIds: string[] | {
  project: string;
  ids: string[];
}[] | null, type: string) => {
  const currentExpandedIds = localStorage.getItem(TOGGLED_EXPANDED_IDS)
  const parsedStorage = currentExpandedIds ? JSON.parse(currentExpandedIds) : null
  const newStorage = {
    Taxonomy: type === 'Taxonomy' ? expandedIds : parsedStorage?.Taxonomy,
    Calculate: {
      Cost: type === 'Cost' ? expandedIds : parsedStorage?.Calculate?.Cost,
      Risk: type === 'Risk' ? expandedIds : parsedStorage?.Calculate?.Risk,
      Earning: type === 'Earning' ? expandedIds : parsedStorage?.Calculate?.Earning,
      Financing: type === 'Financing' ? expandedIds : parsedStorage?.Calculate?.Financing,
    },
    Company: type === 'Company' ? expandedIds : parsedStorage?.Company,
    Reporting: {
      Detail: type === 'Detail' ? expandedIds : parsedStorage?.Reporting?.Detail,
      Budget: type === 'Budget' ? expandedIds : parsedStorage?.Reporting?.Budget,
      Comparison: type === 'Comparison' ? expandedIds : parsedStorage?.Reporting?.Comparison,
      Forecast: type === 'Forecast' ? expandedIds : parsedStorage?.Reporting?.Forecast,
      CostBreakdown: type === 'CostBreakdown' ? expandedIds : parsedStorage?.Reporting?.CostBreakdown,
    },
  }
  localStorage.setItem(TOGGLED_EXPANDED_IDS, JSON.stringify(newStorage))
};
