import {
  CompanyReadModel,
  PersonGender,
  useApiPostCreatePrivatePersonMutation,
  useApiPostUpdatePrivatePersonMutation,
} from '@client/shared/api';
import {
  Button,
  Form,
  FormField,
  MuseumIcon,
  BankAccountIcon,
  SlideOver,
  SlideOverTitle,
  TextInput,
  UserIcon,
  AddressFormFields,
  ContactDetailsFormFields,
  SwiftCodeFormField,
  IbanFormField, ContactFormFields, CreditDataFormFields, LoadingIndicator
} from '@client/shared/toolkit';
import { isValidSWIFT, isValidIBAN, safeMutation } from '@client/shared/utilities';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';

interface PrivatePersonDetailsSlideOverProps {
  isAddMode: boolean;
  company?: CompanyReadModel | undefined;
  onClose: () => void;
}

const PrivatePersonFormValidationSchema = yup.object({
  gender: yup.mixed<PersonGender>().oneOf(['M', 'F', 'D']).required('validation.required'),
  title: yup.string().optional(),
  firstName: yup.string().required('validation.required'),
  lastName: yup.string().required('validation.required'),
  function: yup.string().optional(),
  email: yup.string().optional().email('validation.email'),
  phone: yup.string().optional(),
  mobile: yup.string().optional(),
  web: yup.string().optional(),

  //type: yup.string().required('validation.required'),
  street: yup.string().optional(),
  number: yup.string().optional(),
  supplement: yup.string().optional(),
  postalCode: yup.string().optional(),
  city: yup.string().optional(),
  country: yup.string().optional(),

  bank: yup.string().optional(),
  owner: yup.string().optional(),
  iban: yup.string().optional().nullable()
    .when(['bank', 'owner', 'swift'], {
      is: (bank: string | null | undefined, owner: string | null | undefined, swift: string | null | undefined) => (bank || owner || swift),
      then: (schema) => schema.required('validation.ibanRequiredForBankAccount'),
      otherwise: (schema) => schema.optional().nullable()
    }),
  swift: yup.string().optional(),

  creditorId: yup.string().optional(),
  debitorId: yup.string().optional(),
  spvId: yup.string().optional(),
})

type PrivatePersonValidationValues = InferType<typeof PrivatePersonFormValidationSchema>;

export const PrivatePersonDetailsSlideOver = ({ isAddMode, company, onClose }: PrivatePersonDetailsSlideOverProps) => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const [doCreate, { isLoading: isCreating }] = useApiPostCreatePrivatePersonMutation();
  const [doUpdate, { isLoading: isUpdating }] = useApiPostUpdatePrivatePersonMutation();

  const defaultValues = useMemo(() => {
    const person = company?.persons[0];
    const branch = company?.branches[0];
    const account = branch?.bankAccounts[0];
    return {
      gender: person?.gender ?? 'D',
      title: person?.title ?? '',
      firstName: person?.firstName ?? '',
      lastName: person?.lastName ?? '',
      function: person?.function ?? '',
      email: person?.email ?? '',
      phone: person?.phone ?? '',
      mobile: person?.mobile ?? '',
      web: person?.web ?? '',

      street: branch?.address?.street ?? '',
      number: branch?.address?.number ?? '',
      supplement: branch?.address?.supplement ?? '',
      postalCode: branch?.address?.postalCode ?? '',
      city: branch?.address?.city ?? '',
      country: branch?.address?.country ?? '',

      bank: account?.bankName ?? '',
      owner: account?.accountOwner ?? '',
      iban: account?.iban ?? '',
      swift: account?.swift ?? '',

      creditorId: branch?.creditorId ?? '',
      debitorId: branch?.debitorId ?? '',
      spvId: branch?.spvId ?? ''
    }
  }, [company?.persons, company?.branches])

  const handleSubmit = async (data: PrivatePersonValidationValues) => {
    if (formRef.current) {
      let valid = true
      const values = formRef.current.getValues();
      if (values.iban && !isValidIBAN(values.iban)) {
        valid = false;
      }
      if (values.swift && !isValidSWIFT(values.swift)) {
        valid = false;
      }
      if (valid) {
        const body = {
          person: {
            gender: data.gender,
            title: data.title,
            firstName: data.firstName,
            lastName: data.lastName,
            function: data.function,
            email: data.email,
            phone: data.phone,
            mobile: data.mobile,
            web: data.web,
          },
          address: {
            supplement: data.supplement,
            street: data.street,
            number: data.number,
            postalCode: data.postalCode,
            city: data.city,
            country: data.country,
          },
          account: data.iban ? {
            bankName: data.bank,
            swift: data.swift,
            accountOwner: data.owner,
            iban: data.iban,
          } : null,
          creditorId: data.creditorId,
          debitorId: data.debitorId,
          spvId: data.spvId,
        };
        if (isAddMode) {
          try {
            await safeMutation(
              doCreate,
              {
                body: body,
              },
              isCreating,
            );
            onClose();
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            await safeMutation(
              doUpdate,
              {
                personId: company?.id ?? '',
                body: body,
              },
              isUpdating,
            );
            onClose();
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  };

  return (
    <Form<PrivatePersonValidationValues>
      onSubmit={handleSubmit}
      validationSchema={PrivatePersonFormValidationSchema}
      defaultValues={defaultValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      {(isCreating || isUpdating) && (
        <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} mode="overlay" />
      )}
      <SlideOver.Header
        additionalContentBefore={<UserIcon className="w-[60px] h-[60px] mb-2" />}
        title={isAddMode ? t('app.companiesAddPrivatePerson') : company?.name ?? ''}
        subTitle={isAddMode ? '' : t('app.masterDataPartnerPerson')}
        backgroundClassName="bg-slate-800"
        onClose={onClose}
        truncateTitle={false}
      />
      <SlideOver.Content className="p-8">
        <ContactFormFields />
        <ContactDetailsFormFields />
        <CreditDataFormFields />
        <AddressFormFields />
        <SlideOverTitle title={t('app.companiesBankAccount')} />
        <FormField name="owner">
          {(control) => <TextInput icon={<BankAccountIcon />} label={t('app.companiesBankAccountOwner')} {...control} />}
        </FormField>
        <FormField name="bank">{(control) => <TextInput icon={<MuseumIcon />} label={t('app.companiesBank')} {...control} />}</FormField>
        <SwiftCodeFormField />
        <IbanFormField />
      </SlideOver.Content>
      <SlideOver.Controls>
        <div className="w-full flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit={true}>
            {t('common.save')}
          </Button>
        </div>
      </SlideOver.Controls>
    </Form>
  );
};
