import { useApiPostCreateUserAvatarMutation } from '@client/shared/api';
import { Button, FileInput, LoadingIndicator, SlideOver, SlideOverOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const UploadAvatarSlideOver = ({ onClose }: SlideOverOnCloseProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [file, setFile] = useState<File | null>(null);
  const [postAvatar, { isLoading }] = useApiPostCreateUserAvatarMutation();

  const handleFileChange = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    }
  };

  const handleSubmit = async () => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        await safeMutation(
          postAvatar,
          {
            body: formData as unknown as { file: Blob },
          },
          isLoading
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator mode="overlay-window" text={t('common.uploading')} />}
      <SlideOver.Header
        title={t('app.editUploadImage')}
        subTitle={''}
        backgroundClassName="bg-blue-900"
        onClose={onClose}
      />
      <SlideOver.Content
        className="p-8"
        onKeyEnter={() => {
          submitRef.current?.focus()
          submitRef.current?.click()
        }}
      >
        <FileInput
          className="bg-white"
          acceptedFileTypes={['image/*']}
          multiple={false}
          onChange={(files) => handleFileChange(files)}
        />
      </SlideOver.Content>

      <SlideOver.Controls>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} innerRef={submitRef} disabled={!file}>
          {t('common.save')}
        </Button>
      </SlideOver.Controls>
    </>
  )
};
