import { createContext, PropsWithChildren, RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { FormRefHandle } from '@client/shared/toolkit';
import { InvoiceCreateFormValidationValues } from './InvoiceCreateFormValidationValues';

export type InvoiceEditContextType = {
  isEditMode: boolean;
  setIsEditMode: (editMode: boolean) => void;
  unsavedData: string[];
  setUnsavedData: (unsavedData: string[]) => void;
  submitForm: () => void;
  formRef: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>> | null;
  isValid: boolean;
  setIsValid: (hasError: boolean) => void;
  updateUnsavedData: (formField: string, isOriginalValue: boolean) => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  isUpdated: boolean;
  setIsUpdated: (isUpdated: boolean) => void;
};
export const InvoiceEditContext = createContext<InvoiceEditContextType>({
  isEditMode: false,
  setIsEditMode: () => {
    //
  },
  unsavedData: [],
  setUnsavedData: () => {
    //
  },
  submitForm: () => {
    //
  },
  formRef: null,
  isValid: true,
  setIsValid: () => {
    //
  },
  updateUnsavedData: () => {
    //
  },
  isSaving: false,
  setIsSaving: () => {
    //
  },
  isUpdated: false,
  setIsUpdated: () => {
    //
  },
});

export const InvoiceEditContextProvider = ({ children }: PropsWithChildren) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [unsavedData, setUnsavedData] = useState<string[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const formRef = useRef<FormRefHandle<InvoiceCreateFormValidationValues>>(null);
  const submitForm = async () => {
    if (formRef.current) {
      setIsUpdated(false);
      setIsSaving(true);
      formRef.current.submitForm();
      await formRef.current.validateForm();
      const { isValid } = formRef.current.getState();
      setIsValid(isValid);
      if (!isValid) {
        setIsSaving(false);
      }
    }
  };

  const updateUnsavedData = useCallback(
    (formField: string, isOriginalValue: boolean) => {
      const currentUnsavedData = [...unsavedData];
      const foundIndex = currentUnsavedData.indexOf(formField);

      if (foundIndex >= 0) {
        if (isOriginalValue) {
          currentUnsavedData.splice(foundIndex, 1);
        }
      } else {
        if (!isOriginalValue) {
          currentUnsavedData.push(formField);
        }
      }
      setUnsavedData(currentUnsavedData);
    },
    [unsavedData],
  );

  const value = useMemo(
    () => ({
      isEditMode,
      setIsEditMode,
      unsavedData,
      setUnsavedData,
      submitForm,
      formRef,
      isValid,
      setIsValid,
      updateUnsavedData,
      isSaving,
      setIsSaving,
      isUpdated,
      setIsUpdated,
    }),
    [isEditMode, unsavedData, isValid, updateUnsavedData, isSaving, isUpdated],
  );

  return <InvoiceEditContext.Provider value={value}>{children}</InvoiceEditContext.Provider>;
};
