import {
  ElementTimelineReadModel,
  useApiGetCostElementQuery,
  useApiPostUpdateCostElementMutation,
} from '@client/shared/api';
import React, { useEffect, useMemo, useState } from 'react';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { TimeLineElementsProps } from '../index';
import { TimeLineMoneyDistributionModal } from '@client/project/shared'
import { LoadingIndicator } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { timelineReadModelToPayload } from '@client/project/shared';

export interface EditTimeLineMoneyDistributionModalProps {
  onClose: () => void;
  onSave: () => void;
  selectedElement: TimeLineElementsProps;
  costGroupId?: string
  riskGroupId?: string
  disabled: boolean
  selectedDate?: string
}

export const EditCostsTimeLineMoneyDistributionModal = (props: EditTimeLineMoneyDistributionModalProps) => {
  const { onClose, onSave, selectedElement, costGroupId, disabled, selectedDate } = props;
  const { t } = useTranslation()
  const variantId = useLoadedVariantId();
  const projectId = useLoadedProjectId();
  const [putElement, { isLoading: isUpdating }] = useApiPostUpdateCostElementMutation();

  const {
    data: dataCostElement,
    isLoading,
    isFetching,
  } = useApiGetCostElementQuery(
    {
      id: selectedElement.elementId ?? '',
      projectId: projectId ?? 'unset',
      calculationModelId: variantId ?? 'unset',
    },
    { skip: false }
  );
  const [timeline, setTimeline] = useState<ElementTimelineReadModel | null>(null);

  useEffect(() => {
    if (dataCostElement?.readModel.deliveryPhase) {
      setTimeline(dataCostElement?.readModel.deliveryPhase);
    }
  }, [dataCostElement?.readModel.deliveryPhase]);

  const isReadOnly = useMemo(() => {
    return disabled || (dataCostElement?.readModel.readOnly ?? true)
  }, [dataCostElement?.readModel.readOnly, disabled])

  const totalValue = useMemo(() => {
    return dataCostElement?.readModel.calculatedValue
  }, [dataCostElement?.readModel.calculatedValue])

  const handleUpdate = async (timelineUpdated: ElementTimelineReadModel | null) => {
    if (selectedElement.elementId && dataCostElement) {
      const amountType = dataCostElement.readModel.amount?.type ?? 'Static';
      const unitType = dataCostElement.readModel.unitPrice?.type ?? 'Static';

      try {
        await safeMutation(
          putElement,
          {
            id: selectedElement.elementId,
            projectId: projectId ?? 'unset',
            calculationModelId: variantId ?? 'unset',
            body: {
              isGroup: dataCostElement.readModel.isGroup,
              catalogElementId: dataCostElement.readModel.catalogElementElement?.id ?? null,
              amount: {
                type: amountType ?? 'Static',
                staticFactor:
                  amountType === 'Static' || amountType === 'Formula'
                    ? {
                      value: amountType === 'Formula' ? 0 : dataCostElement.readModel?.amount?.staticValue?.value ?? 0,
                      unit: dataCostElement.readModel?.amount.staticValue?.unit ?? '',
                    }
                    : null,
                formula: amountType === 'Formula' ? {
                  expression: dataCostElement.readModel.amount.formulaValue?.expression ?? '',
                  expressionParameterPayloads:
                    dataCostElement.readModel.amount.formulaValue?.expressionParameters?.map((p) => ({
                      position: p.position,
                      taxonomyElementId: p?.taxonomyItem?.id,
                      costCatalogElementId: p?.costCatalogElement?.id,
                      plotId: p?.plot?.id,
                    })) ?? [],
                  catalogElementId: selectedElement.elementId,
                  elementType: 'CostElement',
                } : null,
              },
              unitPrice: {
                type: unitType ?? 'Static',
                staticFactor:
                  unitType === 'Static'
                    ? {
                      value: dataCostElement.readModel?.unitPrice.staticValue?.value ?? 0,
                      unit: dataCostElement.readModel?.unitPrice.staticValue?.unit ?? '€',
                    }
                    : null,
                formula: dataCostElement.readModel?.unitPrice.type === 'Formula' ? {
                  expression: dataCostElement.readModel.unitPrice.formulaValue?.expression ?? '',
                  expressionParameterPayloads:
                    dataCostElement.readModel.unitPrice.formulaValue?.expressionParameters?.map((p) => ({
                      position: p.position,
                      taxonomyElementId: p?.taxonomyItem?.id,
                      costCatalogElementId: p?.costCatalogElement?.id,
                      plotId: p?.plot?.id,
                    })) ?? [],
                  catalogElementId: selectedElement.elementId,
                  elementType: 'CostElement',
                } : null,
              },
              deliveryPhasePayload: timelineReadModelToPayload(timelineUpdated),
              description: dataCostElement.readModel.description ?? '',
              statusId: dataCostElement?.readModel.statusElement?.id,
              vatId: dataCostElement?.readModel.vatElement?.id,
              constructionPhaseId: dataCostElement?.readModel.constructionPhaseElement?.id,
            },
          },
          isUpdating
        );
        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      {(isLoading || isFetching || isUpdating) && <LoadingIndicator mode={'overlay'} />}
      <TimeLineMoneyDistributionModal
        disabled={isReadOnly}
        timeline={timeline}
        variantId={variantId}
        totalValue={totalValue}
        costGroupId={costGroupId}
        disabledDistributionTypes={!(selectedElement.type === 'group') || !selectedElement.hasChildren ? ['Effective'] : []}
        onClose={() => {
          setTimeline(null);
          onClose();
        }}
        onChange={(timeline) => setTimeline(timeline)}
        onClear={() => setTimeline(null)}
        onSave={(timelineUpdated) => {
          handleUpdate(timelineUpdated).then(() => {
            onSave();
            setTimeline(null)
          })
        }}
        saveButtonText={t('common.save')}
        selectedDate={selectedDate}
      />
    </>
  );
};
