import {
  useDocumentTitle,
  TitleBar
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { RequirementsContainer } from '../components';
import { useLoadedPortfolio } from '@client/portfolio/store';

export const ProjectingRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('portfolio.menu.projecting') });

  const { data } = useLoadedPortfolio();

  return (
    <>
      <TitleBar
        title={data?.portfolio.name ?? t('portfolio.menu.projecting')}
        subTitle={t('portfolio.projecting.routeSubTitle')}
      />
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="px-4 py-8 md:px-8 flex flex-col flex-grow overflow-y-auto">
          <RequirementsContainer />
        </div>
      </div>
    </>
  );
};
