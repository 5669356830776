import { WidgetTable, WidgetTableRow } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useProjectDashboardData } from '../../hooks';
import { useMemo } from 'react';
import { formatCurrency } from '@client/shared/utilities';
import { useGetCurrency } from '@client/project/store';

export const DashboardWidgetAssets = () => {
  const { t } = useTranslation();
  const { data: projectData, isFetching } = useProjectDashboardData();
  const currentCurrency = useGetCurrency();
  const formatCurrencyOptions = useMemo(() => {
    return { maxDigits: 2, minDigits: 2, currency: currentCurrency };
  }, [currentCurrency]);

  const tableDataRows: WidgetTableRow[] = useMemo(() => {
    const rows: WidgetTableRow[] = [];
    if (projectData?.project?.calculationModel?.customDataTable) {
      projectData?.project?.calculationModel?.customDataTable.forEach((customData) => {
        rows.push({
          cols: [
            customData.name,
            customData.valuePlanned !== null ? formatCurrency(customData.valuePlanned, formatCurrencyOptions) : '-',
            customData.valueIs !== null ? formatCurrency(customData.valueIs, formatCurrencyOptions) : '-',
          ],
        });
      });
    }
    return rows;
  }, [projectData?.project?.calculationModel?.customDataTable, formatCurrencyOptions]);

  return (
    <WidgetTable
      isLoading={isFetching}
      rows={tableDataRows}
      tableTitles={[
        t('project.dashboard.widget.assets.table.title.item'),
        t('project.dashboard.widget.assets.table.title.plan'),
        t('project.dashboard.widget.assets.table.title.isPaid'),
      ]}
      gridCols="grid-cols-4"
    />
  );
};
