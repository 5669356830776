import { useNavigate, useParams } from 'react-router-dom';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import React, { useEffect, useState } from 'react';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useTranslation } from 'react-i18next';
import { RequirementSlideOver } from './RequirementSlideOver';
import { RequirementReadModel, useApiGetClusterItemRequirementQuery } from '@client/shared/api';
import { useLoadedPortfolioId } from '@client/portfolio/store';

export const RequirementView = () => {
  const { requirementId, clusterItemId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const loadedPortfolioId = useLoadedPortfolioId();

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false);
  const [isChildSlideOverIsOpen, setIsChildSlideOverOpen] = useState(false);
  const [requirement, setRequirement] = useState<RequirementReadModel | null>(null);
  const [clusterItemChanged, setClusterItemChanged] = useState(false);

  const { data: loadedRequirement, isFetching, isError } = useApiGetClusterItemRequirementQuery({
    clusterItemId: clusterItemId ?? '',
    requirementId: requirementId ?? '',
    portfolioId: loadedPortfolioId ?? ''
  }, {
    // if clusterItemChanged then we navigate to the correct view, so don't fetch it
    skip: !requirementId || !loadedPortfolioId || !clusterItemId || clusterItemChanged
  })

  if (!requirementId || !clusterItemId) {
    navigate(ROUTES_CONFIG.PORTFOLIO_PROJECTING.name);
  }

  useEffect(() => {
    if (typeof loadedRequirement !== 'undefined' && loadedRequirement?.requirement) {
      setRequirement(loadedRequirement.requirement);
      if (!requirement) {
        setIsOpenSlideOver(true);
      }
    }
    if (isError) {
      navigate(ROUTES_CONFIG.PORTFOLIO_PROJECTING.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedRequirement, isError]);

  useEffect(() => {
    if (clusterItemId) {
      setClusterItemChanged(false);
    }
  }, [clusterItemId]);

  return (
    <>
      {!isOpenSlideOver && isFetching && (
        <LoadingIndicator text={t('portfolio.projecting.fetchingRequirementsLoadingIndicator')} mode="overlay" />
      )}
      <SlideOver
        isOpen={isOpenSlideOver}
        onClose={() => setIsOpenSlideOver(false)}
        variant="x-large"
        confirmBeforeClose={false}
        preventClickOutsideClose={isChildSlideOverIsOpen}
        onAfterLeave={() => {
          navigate(ROUTES_CONFIG.PORTFOLIO_PROJECTING.name);
        }}
      >
        {isOpenSlideOver && isFetching && (
          <LoadingIndicator text={t('portfolio.projecting.fetchingRequirementsLoadingIndicator')} mode="overlay" />
        )}
        {requirement && (
          <RequirementSlideOver
            requirementId={requirement.id}
            selectedRequirement={requirement}
            onClose={() => {
              setIsOpenSlideOver(false)
            }}
            setIsChildSlideOverOpen={setIsChildSlideOverOpen}
            setClusterItemChanged={setClusterItemChanged}
          />
        )}
      </SlideOver>
    </>
  );
};
