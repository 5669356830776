import { VatDistribution } from '@client/shared/api';
import { NumberInput, MonthPicker, ContextMenu, ContextMenuItem, TrashIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

interface VatReturnModalRowProps {
  vatReturnDistribution: VatDistribution;
  onChange?: (value: VatDistribution) => void;
  onItemDeleted: () => void;
}

export const VatReturnModalRow = ({ vatReturnDistribution, onChange, onItemDeleted }: VatReturnModalRowProps) => {
  const { t } = useTranslation();

  const handleOnChange = (value: number | null | undefined) => {
    if (onChange) onChange({ ...vatReturnDistribution, amount: value ?? 0 });
  };

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.delete'),
      subtitle: t('common.deleteElement'),
      icon: <TrashIcon />,
      onClick: () => onItemDeleted(),
    },
  ];

  return (
    <div className="flex flex-row items-center bg-white divide-x">
      <div className="w-5/12">
        <MonthPicker
          label={t('projectCalculate.vatReturnDate')}
          value={new Date(vatReturnDistribution.date)}
          onChange={(date) => undefined}
        />
      </div>
      <div className="w-7/12">
        <NumberInput
          label={t('projectCalculate.vatReturnAmount')}
          value={vatReturnDistribution.amount}
          onChange={handleOnChange}
          disabled={!onChange}
        />
      </div>
      <div className="w-fit text-gray-400 hover:text-gray-600 px-1">
        <ContextMenu items={contextItems} />
      </div>
    </div>
  );
};
