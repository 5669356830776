import cn from 'classnames';
import { BooleanInput, Button, ClearFiltersIcon, ConversionIcon, LoadingIndicator, SearchInput } from '../index';
import { Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import { UserDefinedFieldDefinitionListItemReadModel, SearchFilterType } from '@client/shared/api';

export interface FilterOption {
  label?: string;
  fieldType?: string;
  listItems?: UserDefinedFieldDefinitionListItemReadModel[] | null;
  disabled?: boolean;
  type: SearchFilterType | string;
  id: string;
}

export type FilterGroup = {
  options: FilterOption[];
  visible: boolean;
  title: string;
};

export interface SearchFilterProps {
  className?: string;
  handleSearch: (value: string, filters: FilterOption[]) => void;
  updateSearchValue: (value: string) => void;
  searchValue: string;
  filterGroups?: FilterGroup[];
  showFilterSearch?: boolean;
  isApplyDisabled?: boolean;
  searchClassName?: string;
  searchBgColor?: string;
  searchDisabled?: boolean;
  toggledFilters?: string[];
  isLoading?: boolean;
}
export const SearchFilter = (props: SearchFilterProps) => {
  const {
    className,
    handleSearch,
    updateSearchValue,
    searchValue,
    filterGroups = [],
    showFilterSearch = true,
    isApplyDisabled = false,
    searchClassName = 'w-full h-full text-slate-500 px-6 py-2',
    searchBgColor = 'bg-gray-400 bg-opacity-10',
    searchDisabled = false,
    toggledFilters = [],
    isLoading = false,
  } = props;

  const { t } = useTranslation();

  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(toggledFilters);
  const [filterSearch, setFilterSearch] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  useEffect(() => {
    if (!selectedFilters.length && toggledFilters.length && selectedFilters !== toggledFilters) {
      setSelectedFilters(toggledFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggledFilters]);

  // detect closing popover
  useEffect(() => {
    if (!popperElement) {
      if (showPopover) {
        setFilterSearch('');
      }
      setShowPopover(false);
    } else {
      setShowPopover(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const visibleFilterGroups = useMemo(() => {
    const visibleFilters: FilterGroup[] = [];
    filterGroups.forEach((group) => {
      if (group.visible) {
        const groupCopy = { ...group };
        let filteredOptions = [...group.options];
        if (filterSearch) {
          filteredOptions = filteredOptions.filter((option) => {
            return option.label?.toLowerCase().includes(filterSearch.toLowerCase());
          });
        }
        if (filteredOptions.length) {
          groupCopy.options = filteredOptions;
          visibleFilters.push(groupCopy);
        }
      }
    });
    return visibleFilters;
  }, [filterGroups, filterSearch]);

  const isFilterOptionFound = useMemo(() => {
    return visibleFilterGroups.find((group) => group.options.length);
  }, [visibleFilterGroups]);

  const getSearchFilters = useCallback(
    (filters: string[]) => {
      const searchFilters: FilterOption[] = [];
      if (filters.length) {
        filterGroups.forEach((group) => {
          group.options.forEach((option) => {
            if (filters.includes(option.id)) {
              searchFilters.push({
                label: '',
                type: option.type,
                id: option.id,
              });
            }
            option.listItems?.forEach((listItem) => {
              if (filters.includes(listItem.listItemId)) {
                searchFilters.push({
                  label: '',
                  type: option.type,
                  id: listItem.listItemId,
                });
              }
            });
          });
        });
      }
      return searchFilters;
    },
    [filterGroups],
  );

  const resetAll = () => {
    setSelectedFilters([]);
    setExpandedIds([]);
    updateSearchValue('');
    handleSearch('', []);
  };

  const addOrRemoveExpandedId = (id: string) => {
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter((i) => i !== id));
    } else {
      setExpandedIds([...expandedIds, id]);
    }
  };

  const prepareSeachValue = (currentSearchValue: string, value: string) => {
    const updatedSearchValue = currentSearchValue
      .replace(`, ${value}`, '')
      .replace(`${value}, `, '')
      .replace(value, '')
      .trim();

    return updatedSearchValue;
  };

  const toggleSelectedFilter = useCallback(
    (ids: string[], search?: string) => {
      const newSelectedFilters = [...selectedFilters];

      ids.forEach((id) => {
        const existingIdx = selectedFilters.indexOf(id);
        if (existingIdx >= 0) {
          newSelectedFilters.splice(existingIdx, 1);
        } else {
          newSelectedFilters.push(id);
        }
      });

      setSelectedFilters(newSelectedFilters);
      handleSearch(search ?? searchValue, getSearchFilters(newSelectedFilters));
    },
    [selectedFilters, getSearchFilters, handleSearch, searchValue],
  );

  const toggleListItem = (listItem: UserDefinedFieldDefinitionListItemReadModel, filterId: string) => {
    let updatedSearchValue = '';
    if (!selectedFilters.includes(listItem.listItemId)) {
      updatedSearchValue = searchValue === '' ? listItem.label : `${searchValue}, ${listItem.label}`;
    } else {
      updatedSearchValue = prepareSeachValue(searchValue, listItem.label);
    }
    const ids = [listItem.listItemId];
    if (!selectedFilters.includes(filterId)) {
      ids.push(filterId);
    }
    updateSearchValue(updatedSearchValue);
    toggleSelectedFilter(ids, updatedSearchValue);
  };

  return (
    <div
      className={cn(
        'text-sm font-medium text-gray-400',
        'bg-opacity-10 rounded-full overflow-hidden bg-white gap-1',
        'flex justify-center',
        className,
      )}
    >
      <SearchInput
        value={searchValue}
        handleSearch={(val) => handleSearch(val, getSearchFilters(selectedFilters))}
        variant="transparent"
        className={searchClassName}
        bgColor={searchBgColor}
      />
      {(visibleFilterGroups.length > 0 || filterSearch !== '') && (
        <Popover>
          <>
            <PopoverButton
              ref={setTargetElement}
              className={cn('h-full rounded-none pl-4 pr-6 py-2 focus:outline-none', searchBgColor, {
                'bg-sky-200 bg-opacity-100': selectedFilters.length > 0,
              })}
            >
              {isLoading ? (
                <LoadingIndicator className="w-5" svgClassName="h-5 w-5" />
              ) : (
                <>
                  {selectedFilters.length > 0 ? (
                    <ClearFiltersIcon className="h-5 w-5 text-gray-600" />
                  ) : (
                    <ConversionIcon className="h-5 w-5 text-gray-400" />
                  )}
                </>
              )}
            </PopoverButton>

            <PopoverPanel
              as="div"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-20 w-96 flex items-center justify-center bg-gray-100 border border-gray-200 shadow-lg outline-none border-t-0 rounded-lg"
            >
              {({ close }) => (
                <div className="w-full flex flex-col">
                  {showFilterSearch && (
                    <div className="w-full h-full border-b-2 shadow-lg z-10 flex px-6 pt-4 gap-1">
                      <div className="flex flex-col items-stretch w-auto flex-grow">
                        <SearchInput
                          handleSearch={(value) => setFilterSearch(value)}
                          variant="transparent"
                          className="h-full text-gray-400 bg-white pl-4"
                          disabled={searchDisabled}
                        />
                      </div>
                      <div className="flex flex-col items-stretch w-auto">
                        <Button
                          className="h-full bg-white rounded-none"
                          onClick={() => {
                            resetAll();
                          }}
                          variant="custom"
                        >
                          {selectedFilters.length > 0 ? (
                            <ClearFiltersIcon className="h-5 w-5" />
                          ) : (
                            <ConversionIcon className="h-5 w-5" />
                          )}
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="w-full flex flex-col gap-0.5 h-fit-content px-6 pt-4 max-h-[400px] overflow-y-auto">
                    {visibleFilterGroups.map((filterGroup, i) => {
                      return (
                        <Disclosure as="div" defaultOpen className="border-b-2" key={`filter-group-${i}`}>
                          {({ open }) => (
                            <>
                              <DisclosureButton as="div" className="w-full my-2">
                                <button className="text-left flex justify-between w-full items-center font-semibold text-[15px] leading-tight text-slate-500">
                                  {filterGroup.title}
                                  <ChevronDownIcon
                                    className={cn(
                                      'w-5 transform transition-transform font-bold',
                                      open ? '-rotate-180 duration-300' : '',
                                    )}
                                  />
                                </button>
                              </DisclosureButton>
                              <DisclosurePanel>
                                {filterGroup.options.map((filter) => {
                                  return (
                                    <div className="bg-white pl-2" key={`filter-${i}-${filter.id}`}>
                                      <div className="w-full flex items-center">
                                        {filter.fieldType === 'List' &&
                                          filter.listItems &&
                                          filter.listItems.length > 0 && (
                                            <span
                                              className="cursor-pointer"
                                              onClick={() => addOrRemoveExpandedId(filter.id)}
                                            >
                                              {expandedIds.includes(filter.id) ? (
                                                <ChevronDownIcon className="w-4 h-4" />
                                              ) : (
                                                <ChevronRightIcon className="w-4 h-4" />
                                              )}
                                            </span>
                                          )}
                                        <div className="w-full">
                                          <BooleanInput
                                            label={filter.label ?? ''}
                                            value={selectedFilters.includes(filter.id)}
                                            onChange={(selected) => {
                                              const ids = [filter.id];
                                              if (!selected) {
                                                let updatedSearchValue = searchValue;
                                                filter.listItems?.forEach((listItem) => {
                                                  if (selectedFilters.includes(listItem.listItemId)) {
                                                    updatedSearchValue = prepareSeachValue(
                                                      updatedSearchValue,
                                                      listItem.label,
                                                    );
                                                    ids.push(listItem.listItemId);
                                                  }
                                                });
                                                updateSearchValue(updatedSearchValue);
                                              }
                                              toggleSelectedFilter(ids);
                                            }}
                                            variant="checkbox"
                                            disabled={filter.disabled}
                                          />
                                        </div>
                                      </div>
                                      <div className="pl-4">
                                        {expandedIds.includes(filter.id) &&
                                          filter.listItems?.map((listItem) => {
                                            return (
                                              <BooleanInput
                                                key={`listItem-${i}-${listItem.listItemId}`}
                                                label={listItem.label}
                                                value={selectedFilters.includes(listItem.listItemId)}
                                                onChange={() => {
                                                  toggleListItem(listItem, filter.id);
                                                }}
                                                variant="checkbox"
                                              />
                                            );
                                          })}
                                      </div>
                                    </div>
                                  );
                                })}
                              </DisclosurePanel>
                            </>
                          )}
                        </Disclosure>
                      );
                    })}
                    {!isFilterOptionFound && (
                      <span className="font-semibold text-[15px] leading-tight text-slate-500">
                        {t('projectCalculate.noFiltersFound')}
                      </span>
                    )}
                    <div className="text-base bg-gray-100 bottom-0 flex gap-2 py-4 sticky w-full justify-end">
                      <Button
                        hasPadding={false}
                        className="bg-gray-300 text-white py-1.5 px-5"
                        variant="custom"
                        onClick={() => {
                          resetAll();
                          close();
                        }}
                      >
                        {t('common.cancel')}
                      </Button>
                      <Button
                        hasPadding={false}
                        className="py-1.5 px-5"
                        variant="primary"
                        onClick={() => {
                          handleSearch(searchValue, getSearchFilters(selectedFilters));
                          close();
                        }}
                        disabled={isApplyDisabled}
                      >
                        {t('common.apply')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </PopoverPanel>
          </>
        </Popover>
      )}
    </div>
  );
};
