import { apiBase as api } from './apiBase';
import {
  ApiGetAiEvalDocumentFileApiArg,
  ApiGetAiEvalDocumentFileApiResponse,
  ApiGetJobRouterPdfApiArg,
  ApiGetJobRouterPdfApiResponse
} from './apiGenerated';
export const pdfsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    apiGetAiEvalDocumentPdfFile: build.query<ApiGetAiEvalDocumentFileApiResponse, ApiGetAiEvalDocumentFileApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/projects/${queryArg.projectId}/aievaldocument/${queryArg.documentId}/file`,
          method: 'GET',
          responseHandler: async (response) => {
            if (response.ok) {
              const contentDisposition = response.headers?.get('content-disposition')
              let fileName = 'invoice_document.pdf';
              if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                  fileName = matches[1].replace(/['"]/g, '');
                }
              }
              const blobFile = await response.blob();
              const file = new File([blobFile], fileName, { type: blobFile.type })
              return URL.createObjectURL(file)
            } else {
              return response.statusText;
            }
          },
          cache: 'no-cache'
        }
      },
    }),
    apiGetJobRouterPdfFile: build.query<ApiGetJobRouterPdfApiResponse, ApiGetJobRouterPdfApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/projects/${queryArg.projectId}/calculation-models/${queryArg.calculationModelId}/job_router/${queryArg.invoiceId}`,
          method: 'GET',
          responseHandler: async (response) => {
            if (response.ok) {
              const contentDisposition = response.headers?.get('content-disposition')
              let fileName = 'invoice_document.pdf';
              if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                  fileName = matches[1].replace(/['"]/g, '');
                }
              }
              const blobFile = await response.blob();
              const file = new File([blobFile], fileName, { type: blobFile.type })
              return URL.createObjectURL(file)
            } else {
              return null; // response.statusText;
            }
          },
          cache: 'no-cache'
        }
      },
    })
  }),
  overrideExisting: false,
})

export const {
  useApiGetAiEvalDocumentPdfFileQuery,
  useApiGetJobRouterPdfFileQuery
} = pdfsSlice
