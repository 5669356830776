import { useMemo } from 'react';
import {
  CalculationCommitmentDto,
  CalculationContractDto,
  CalculationInvoicingPartyDto,
  ContractBudgetErrors,
  CostCatalogElementDto,
  CostElementDto,
  TimelineErrors,
} from '@client/shared/api';
import {
  CostsFilterOption,
  filterElementsByCostsFilter,
  filterElementsById,
} from '../utils';

export type DecoratedElement<T> = {
  element: T;
  children: DecoratedElement<T>[];
  level: number;
  categoryId: string;
  parent?: DecoratedElement<T>;
  disableClick?: boolean;
  match?: boolean
};

export type CostCatalogElement = {
  key: string;
  group?: CostCatalogElementDto;
  showError: boolean;
  costElements?: CostElement[];
  commitments?: CalculationCommitmentDto[]; 
  contracts?: CalculationContractDto[];
  invoicingParties?: CalculationInvoicingPartyDto[];  
  timelineErrors: TimelineErrors[];
  contractBudgetErrors: ContractBudgetErrors[];
  type: 'group' | 'element' | 'summary';
};

export type CostElement = {
  key: string;
  catalogElement: DecoratedElement<CostCatalogElement>;
  costElement?: CostElementDto;
  referenceCostElement?: CostElementDto
  showReference?: boolean
  showError: boolean;
  commitments?: CalculationCommitmentDto[]; 
  contracts?: CalculationContractDto[];
  invoicingParties?: CalculationInvoicingPartyDto[];
  timelineErrors: TimelineErrors[];
  contractBudgetErrors: ContractBudgetErrors[];
}

const unassignedGroupId = 'unassigned';

/**
 * Converts CostCatalogElementDto[] to DecoratedCostElement[]
 */
function decorateElements(
  elements?: CostCatalogElementDto[],
  level = 0,
  parent?: DecoratedElement<CostCatalogElement>
): DecoratedElement<CostCatalogElement>[] {
  if (elements == null || elements.length === 0) return [];

  return elements.map((element, i) => {

    const decorated: DecoratedElement<CostCatalogElement> = {
      element: {
        key: element.id,
        group: element ?? undefined,
        showError: element.modelValues.calculationState === 'Overflow' || element.timelineErrors.length > 0,
        type: 'group',
        timelineErrors: element.timelineErrors,
        contractBudgetErrors: element.contractBudgetErrors,
        costElements: [],
        commitments: element.commitments,
        contracts: element.contracts,
        invoicingParties: element.invoicingParties
      },
      categoryId: element.id,
      level: level,
      parent,
      children: [],
      disableClick: element.id === unassignedGroupId,
    };

    const costElements = element.costElements.map((ce, h) => {
      const decoratedCostElement: CostElement = {
          key:
            (ce.modelCostElement?.elementId
              ? ce.modelCostElement?.elementId
              : ce.referenceCostElement?.elementId) ?? i.toString(),
          // TODO if reference cost element exists, we need a different solution in the FE to show the user that an old version is shown
          costElement: ce.modelCostElement || undefined,
          referenceCostElement: ce.referenceCostElement || undefined,
          showReference: !ce.modelCostElement,
          showError: (ce.modelCostElement?.timelineErrors.length ?? 0) > 0
          || (ce.modelCostElement?.contractBudgetErrors.length ?? 0) > 0 ,
          timelineErrors: ce.modelCostElement?.timelineErrors ?? [],
          contractBudgetErrors: ce?.modelCostElement?.contractBudgetErrors ?? [],
          commitments: ce.commitments,
          contracts: ce.contracts,
          invoicingParties: ce.invoicingParties,
          catalogElement: decorated
      }

      return decoratedCostElement;
    })

    const children = element.children ?? [];

    decorated.element.costElements?.push(...costElements);
    decorated.children = decorateElements(children, level + 1, decorated);
    return decorated;
  });
}

export const useCosts = (
  costs: CostCatalogElementDto[],
  costsFilters?: CostsFilterOption[],
  searchValue?: string,
  searchResults?: string[] | null,
) => {
  //const { t } = useTranslation();

  return useMemo(() => {
    // take the elements from the server (or empty array)
    //let allElements: CostCatalogElementDto[] = costs;

    // keep only elements with group
    // const elements = allElements.filter(
    //   (e) =>
    //     e.group ||
    //     e.costElement?.modelCostElement?.costCatalogElementId ||
    //     e.costElement?.referenceCostElement?.costCatalogElementId
    // );

    // // extract unassigned elements
    // const unassigned = allElements.filter(
    //   (e) =>
    //     e.costElement &&
    //     (!e.costElement?.modelCostElement?.costCatalogElementId ||
    //       !e.costElement?.referenceCostElement?.costCatalogElementId)
    // );

    // group unassigned elements into mocked group
    // if (unassigned.length > 0) {
    //   // backend does not group unassigned cost elements so grouping has to be done on frontend
    //   const mockedGeneralCostsGroup: CostElementGroupDto = {
    //     datedPayments: [],
    //     orderNumber: 0,
    //     modelValues: {
    //       calculationState: 'JustTotal',
    //       total: unassigned.reduce((total, element) => {
    //         const elementSum = element.costElement?.modelCostElement?.totalValue || 0
    //         return total + elementSum;
    //       }, 0),
    //     },
    //     hasParent: false,
    //     children: unassigned,
    //     groupId: unassignedGroupId,
    //     timelineElement: undefined,
    //     timelineErrors: [],
    //     contractBudgetErrors: [],
    //     description: t('projectCalculate.calculateCostsElementGroupUnassigned'),
    //     contracts: [],
    //     hasFormula: false,
    //   };

    //   allElements = [{ group: mockedGeneralCostsGroup }, ...elements];
    //};

    // decorate all elements while preserving all items and structure
    let filteredElements = decorateElements(costs);

    // filter out elements // This filter is not working...
    // const filteredElements = filterElementsByCodes<CostElement>(decoratedElements, filteredCodes);

    /* if (searchValue) {
      filteredElements = filterElementsBySearchValue(filteredElements, searchValue);
    } */
    if (searchValue && searchResults) {
      filteredElements = filterElementsById(filteredElements, searchResults);
    }

    if (costsFilters?.length) {
      filteredElements = filterElementsByCostsFilter(filteredElements, costsFilters);
    }

    return filteredElements;
  }, [costs, costsFilters, searchValue, searchResults]);
};
