import {
  DatevClientMasterDataCard,
  DatevClientSlideOverMappingTab,
  DatevClientUpdateModal,
} from '@client/project/shared';
import { DatevConnectionResponse } from '@client/shared/api';
import { DecoratedCard, PencilIcon } from '@client/shared/toolkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface EditProjectDatevProps {
  data: DatevConnectionResponse | undefined;
  projectId: string;
}

export const EditProjectDatev = ({ data, projectId }: EditProjectDatevProps) => {
  const { t } = useTranslation();

  const [isUpdateClientModalOpen, setIsUpdateClientModalOpen] = useState(false);

  return (
    <div className="flex flex-col gap-5">
      <DecoratedCard>
        <DecoratedCard.Header
          icon={<PencilIcon />}
          showActionButton
          onActionClick={() => setIsUpdateClientModalOpen(true)}
        >
          {t('projectSetting.datevClient')}
        </DecoratedCard.Header>
        <DecoratedCard.Content className="p-5">
          {!data?.clientDetail ? (
            t('app.settingsApiDatevNoClientSelected')
          ) : (
            <DatevClientMasterDataCard clientData={data?.clientDetail} />
          )}
        </DecoratedCard.Content>
      </DecoratedCard>
      {data && (
        <DecoratedCard>
          <DecoratedCard.Header>{t('projectSetting.datevConnections')}</DecoratedCard.Header>
          <DecoratedCard.Content className="min-h-10">
            <DatevClientSlideOverMappingTab connectionData={data} projectId={projectId} />
          </DecoratedCard.Content>
        </DecoratedCard>
      )}
      <DatevClientUpdateModal
        projectId={projectId}
        datevClientId={data?.clientDetail?.id}
        isOpen={isUpdateClientModalOpen}
        onClose={() => setIsUpdateClientModalOpen(false)}
      />
    </div>
  );
};
