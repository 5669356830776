import { WidgetTable, WidgetTableRow } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { formatDate } from '@client/shared/utilities';
import { useProjectDashboardData } from '../../hooks';

export const DashboardWidgetMaBVRate = () => {
  const { t } = useTranslation();
  const { data: projectData, isFetching } = useProjectDashboardData();

  const mbvRateMilestones = useMemo(() => {
    const milestonesData: WidgetTableRow[] = [];
    if (projectData?.project?.calculationModel?.milestones) {
      projectData?.project?.calculationModel?.milestones?.forEach((milestone) => {
        if (milestone.name.toLowerCase().includes('MaBV Rate'.toLowerCase())) {
          const splitted = milestone.name.split(' ');
          let value = '-';
          let name = milestone.name;
          if (splitted[3]) {
            value = splitted[3] ? splitted[3] : '-';
            const nameArray = splitted.slice(0, -1);
            name = nameArray.join(' ');
          }
          milestonesData.push({
            cols: [name, formatDate(milestone.date), value],
          });
        }
      });
    }
    return milestonesData;
  }, [projectData?.project?.calculationModel?.milestones]);
  return (
    <WidgetTable
      isLoading={isFetching}
      title={t('project.dashboard.widget.mabv.title')}
      rows={mbvRateMilestones}
      tableTitles={[
        t('project.dashboard.widget.mabv.table.title.rate'),
        t('project.dashboard.widget.mabv.table.title.month'),
        t('project.dashboard.widget.mabv.table.title.valueInPercentage'),
      ]}
      noRowsMessage={t('project.dashboard.widget.mabv.noItemsMessage')}
    />
  );
};
