import {
  AddButton,
  BaseSelect,
  BaseSelectOption,
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  Modal,
  NumberInput,
  ListCustomIcon,
  SlideOverTitle,
  TextInput,
} from '@client/shared/toolkit';
import {
  ElementUserDefinedFieldDefinitionReadModel,
  ElementUserDefinedFieldReadModel,
  UserDefinedFieldCalculateElementType,
  UserDefinedFieldElementType,
  UserDefinedFieldPayload,
  useApiProjectGetUserDefinedFieldsDefinitionByElementQuery,
} from '@client/shared/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { checkIfFieldIsNotValid, getCustomFieldTypeIcon } from '../../utils';
import { useTranslation } from 'react-i18next';
import { SelectUserDefinedFieldsModal } from './SelectUserDefinedFieldsModal';
import cn from 'classnames';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useLoadedProjectId } from '@client/project/store';

interface InlineEditUserDefinedFieldsProps {
  onAddClick?: () => void;
  type: UserDefinedFieldElementType;
  calculateElementType?: UserDefinedFieldCalculateElementType;
  elementId?: string;
  setUpdatePayload?: (payload: UserDefinedFieldPayload[] | undefined) => void;
  isSubmitted?: boolean;
  updateIsValid?: (valid: boolean) => void;
  className?: string;
  allowChangeMode?: boolean;
  setIsEditMode?: (isEditMode: boolean) => void;
  setUnsavedData: (unsavedData: boolean) => void;
  canEdit: boolean
}

export const InlineEditUserDefinedFields = (props: InlineEditUserDefinedFieldsProps) => {
  const {
    elementId,
    onAddClick,
    type,
    calculateElementType,
    setUpdatePayload,
    isSubmitted = false,
    updateIsValid,
    className,
    allowChangeMode = true,
    setIsEditMode,
    setUnsavedData,
    canEdit
  } = props;

  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const [isOpenSelectCustomFieldsModal, setIsOpenSelectCustomFieldsModal] = useState(false);

  const [fields, setFields] = useState<ElementUserDefinedFieldDefinitionReadModel[]>([]);
  const [originalFields, setOriginalFields] = useState<ElementUserDefinedFieldDefinitionReadModel[]>([]);
  const [changedFields, setChangedFields] = useState<string[]>([]);

  const { data: userDefinedFieldsResponse, isFetching: isLoadingUserDefinedFields } = useApiProjectGetUserDefinedFieldsDefinitionByElementQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      elementId: elementId,
      elementType: type,
      calculateElementType: calculateElementType
    },
    { skip: !elementId || !loadedProjectId }
  );
  const checkAndSetFields = useCallback(
    (changedFields: ElementUserDefinedFieldDefinitionReadModel[]) => {
      let someNotValid = false;
      changedFields.forEach((item) => {
        const itemIsNotValid = checkIfFieldIsNotValid(item);
        if (itemIsNotValid) {
          someNotValid = true;
        }
      });
      if (updateIsValid) {
        updateIsValid(!someNotValid);
      }
      // create a new update payload
      if (setUpdatePayload) {
        // create a new update payload
        const udfPayload: UserDefinedFieldPayload[] = [];
        changedFields.forEach((item) => {
          if (item.userDefinedField) {
            // user has entered a value already
            udfPayload.push({
              userDefinedFieldDefinitionId: item.id,
              text: item.fieldType === 'Text' ? item.userDefinedField.text : null,
              number: item.fieldType === 'Number' ? item.userDefinedField.number : null,
              listSelectedItemId: item.fieldType === 'List' ? item.userDefinedField.listSelectedItem?.listItemId : null,
              markedVisible: item.isVisible,
            });
          } else if (
            item.isRequired ||
            item.defaultText ||
            typeof item.defaultNumber !== 'undefined' ||
            item.defaultListItem?.listItemId
          ) {
            // or it's mandatory or there is a default value
            udfPayload.push({
              userDefinedFieldDefinitionId: item.id,
              text: item.fieldType === 'Text' ? item.defaultText : null,
              number: item.fieldType === 'Number' ? item.defaultNumber : null,
              listSelectedItemId: item.fieldType === 'List' ? item.defaultListItem?.listItemId : null,
              markedVisible: item.isVisible,
            });
          }
        });
        setUpdatePayload(udfPayload);
      }
      setFields(changedFields);
    },
    [updateIsValid, setUpdatePayload],
  );

  useEffect(() => {
    let udfs: ElementUserDefinedFieldDefinitionReadModel[] = [];
    if (userDefinedFieldsResponse?.userDefinedFieldsDefinition && !isLoadingUserDefinedFields) {
      udfs = userDefinedFieldsResponse.userDefinedFieldsDefinition;
      checkAndSetFields(udfs);
      setOriginalFields(udfs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefinedFieldsResponse]);

  const handleOnChange = useCallback(
    (val: string | number | string[] | null, field: ElementUserDefinedFieldDefinitionReadModel) => {
      if (fields != null) {
        const foundIndex = fields.indexOf(field);
        if (foundIndex >= 0) {
          const updatedFields = [...fields];
          const fieldToUpdate = { ...updatedFields[foundIndex] } as ElementUserDefinedFieldDefinitionReadModel;
          let userDefinedField: ElementUserDefinedFieldReadModel | null;

          // update udf value if it already exists
          if (fieldToUpdate.userDefinedField) {
            userDefinedField = { ...fieldToUpdate.userDefinedField };
          } else {
            // otherwise create new one
            userDefinedField = {
              definition: fieldToUpdate,
              id: 'none',
              isMarkedVisible: false,
            };
          }

          let changedValue = null;
          if (userDefinedField) {
            switch (field.fieldType) {
              case 'Text':
                userDefinedField.text = val?.toString() ?? null;
                changedValue = val?.toString() ?? null;
                break;
              case 'Number':
                userDefinedField.number = typeof val !== 'undefined' && val !== null ? (val as number) : null;
                changedValue = typeof val !== 'undefined' && val !== null ? (val as number) : null;
                break;
              case 'List': {
                const item = field.listItems?.find((item) => item.listItemId === val);
                userDefinedField.listSelectedItem = val ? { listItemId: val.toString(), label: item?.label ?? '' } : null;
                changedValue = item?.label ?? null;
                break;
              }
            }
            fieldToUpdate.userDefinedField = userDefinedField;
            updatedFields[foundIndex] = fieldToUpdate;

            // store the new fields
            checkAndSetFields(updatedFields);
          }

          const copy = [...changedFields];
          const changedFieldIndex = changedFields.indexOf(field.id);
          if (getUserDefinedFieldValueChanged(field, changedValue)) {
            if (changedFieldIndex < 0) {
              copy.push(field.id);
            }
          } else if (changedFieldIndex >= 0) {
            copy.splice(changedFieldIndex, 1);
          }
          setChangedFields(copy);
          setUnsavedData(copy.length > 0);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, setUpdatePayload],
  );

  const getUserDefinedFieldValue = (field: ElementUserDefinedFieldDefinitionReadModel) => {
    switch (field.fieldType) {
      case 'Text':
        return field.userDefinedField?.text ?? (field.isRequired ? field.defaultText : '') ?? '';
      case 'Number':
        return field.userDefinedField?.number ?? (field.isRequired ? field.defaultNumber : null) ?? null;
      case 'List':
        return field.userDefinedField?.listSelectedItem?.label ?? (field.isRequired ? field.defaultListItem?.label : '') ?? '';
      default:
        return null;
    }
    return null;
  };

  const getUserDefinedFieldValueChanged = (field: ElementUserDefinedFieldDefinitionReadModel, changedValue: string | number | null) => {
    const foundField = originalFields.find((item) => item.id === field.userDefinedField?.definition.id);
    if (changedValue && foundField) {
      switch (field.fieldType) {
        case 'Text':
          return foundField.userDefinedField?.text !== changedValue;
        case 'Number':
          return foundField.userDefinedField?.number !== changedValue;
        case 'List':
          return foundField.userDefinedField?.listSelectedItem?.label !== changedValue;
        default:
          return null;
      }
    } else if ((!!changedValue && !foundField) || (!changedValue && !!foundField)) return true;

    return false;
  }

  const getUserDefinedField = useCallback(
    (field: ElementUserDefinedFieldDefinitionReadModel) => {
      const IconComponent = getCustomFieldTypeIcon(field.fieldType);
      const isValid = !checkIfFieldIsNotValid(field);
      switch (field.fieldType) {
        case 'Text': {
          return (
            <TextInput
              icon={<IconComponent />}
              label={field.name}
              value={field.userDefinedField?.text ?? (field.isRequired ? field.defaultText : '') ?? ''}
              onChange={(val: string) => handleOnChange(val, field)}
              isValidationValid={isValid}
              showValidation={!isValid && isSubmitted}
              helperText={!isValid && isSubmitted ? t('app.settingsUserDefinedFieldsIsRequired') : undefined}
            />
          );
        }
        case 'List': {
          const options: BaseSelectOption[] = [];
          field.listItems?.forEach((listItem) => {
            options.push({
              label: listItem.label,
              value: listItem.listItemId,
            });
          });
          return (
            <BaseSelect
              icon={<IconComponent />}
              label={field.name}
              value={field.userDefinedField?.listSelectedItem?.listItemId ?? (field.isRequired ? field.defaultListItem?.listItemId : '') ?? ''}
              options={options}
              onChange={(val: string) => handleOnChange(val, field)}
              isValidationValid={isValid}
              showValidation={!isValid && isSubmitted}
              helperText={!isValid && isSubmitted ? t('app.settingsUserDefinedFieldsIsRequired') : undefined}
              nullable={!field.isRequired}
            />
          );
        }
        case 'Number': {
          return (
            <NumberInput
              icon={<IconComponent />}
              label={field.name}
              value={typeof (field.userDefinedField?.number) !== 'undefined' ? field.userDefinedField?.number as number : (field.isRequired ? field.defaultNumber : null ?? null)}
              onChange={(val: number | null) => handleOnChange(val, field)}
              isValidationValid={isValid}
              showValidation={!isValid && isSubmitted}
              helperText={!isValid && isSubmitted ? t('app.settingsUserDefinedFieldsIsRequired') : undefined}
            />
          );
        }
        default:
          return '';
      }
    },
    [handleOnChange, isSubmitted, t],
  );

  const visibleFields = useMemo(() => {
    return fields ? fields.filter((field) => field.isVisible) : [];
  }, [fields]);

  return (
    fields &&
    fields.length > 0 && (
      <DocumentViewerFileDataGroup className="relative">
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <DisclosureButton as="div">
                <div className={cn('flex items-center gap-2 cursor-pointer', open ? 'pb-2' : '')}>
                  <ListCustomIcon className="w-6 mt-2 flex-none" />
                  <SlideOverTitle
                    marginTop={false}
                    title={t('app.settingsUserDefinedFields')}
                    className="mt-4 flex-1 flex justify-between hover:text-gray-800 duration-300 transition-colors"
                  >
                    <ChevronDownIcon
                      className={cn(
                        'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5 flex-none',
                        {
                          'rotate-0': open,
                        },
                      )}
                    />
                  </SlideOverTitle>
                </div>
              </DisclosureButton>
              <DisclosurePanel as="div" className="flex flex-col divide-y-2">
                <div className={cn('relative min-h-5', className)}>
                  {fields && fields.length > 0 && canEdit && (
                    <>
                      <div className={cn('-top-4 -right-4 w-full flex justify-end absolute z-10')}>
                        <AddButton
                          onClick={onAddClick ? onAddClick : () => setIsOpenSelectCustomFieldsModal(true)}
                          className="mr-4"
                        />
                      </div>
                      {!onAddClick && (
                        <Modal
                          isOpen={isOpenSelectCustomFieldsModal}
                          onClose={() => setIsOpenSelectCustomFieldsModal(false)}
                          variant="custom"
                          className="w-[360px] h-[480px]"
                        >
                          <SelectUserDefinedFieldsModal
                            onClose={() => setIsOpenSelectCustomFieldsModal(false)}
                            onChange={checkAndSetFields}
                            fields={fields}
                          />
                        </Modal>
                      )}
                    </>
                  )}

                  <div className="divide-y">
                    {visibleFields.map((field, index) => {
                      const changedValue = getUserDefinedFieldValue(field);
                      const valChanged = getUserDefinedFieldValueChanged(field, changedValue)
                      return (
                        <DocumentViewerFileDataInlineEdit
                          key={`edit-custom-field-${field.name}-${index}`}
                          updateEditMode={setIsEditMode}
                          allowChangeMode={allowChangeMode}
                          toggleContent={canEdit ? getUserDefinedField(field) : undefined}
                          marginX={cn(index === 0 ? '-mt-2' : '-mt-px', index === visibleFields.length -1 ? '-mb-[18px]' : '')}
                        >
                          <div className="divide-y-2 py-2">
                            <DocumentViewerFileDataSet label={field.name}>
                              <span className={cn('text-[15px]', valChanged ? 'text-secondary' : '')}>
                                {changedValue || '-'}
                              </span>
                            </DocumentViewerFileDataSet>
                          </div>
                        </DocumentViewerFileDataInlineEdit>
                      )
                    })}
                  </div>
                </div>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </DocumentViewerFileDataGroup>
    )
  );
};
