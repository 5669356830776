import {
  GetInvoiceCalculationSampleResponse,
  CalculationRuleGroupReadModel,
  CalculationRuleReadModel,
  InvoiceCalculationSchemeReadModel,
  InvoiceType,
  MoveDirection,
  SampleInvoiceReadModel,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import {
  ContextMenu,
  ContextMenuItem,
  PencilIcon,
  SlideOverList,
  SlideOverTitle,
  TrashIcon,
  UpwardArrowIcon,
} from '@client/shared/toolkit';
import { getIcsDeductionName, getIcsDeductionTypeLabel, getInvoiceCoverSheetDeductionGroupLabel } from '../../utils';
import { formatNumber, formatPercentage } from '@client/shared/utilities';
import { excludeDeleteDeductionByType } from './InvoiceCoverSheetEditSlideOver';
import { IcsEditExampleData } from './InvoiceCoverSheetExampleInvoice';
import { FormattedCurrency } from '../FormattedCurrency';

type IcsDeductionGroupSectionProps = {
  deductionGroup: CalculationRuleGroupReadModel;
  onAdd: () => void;
  onDelete: () => void;
  onAddItem: () => void;
  onEdit: () => void;
  onDeleteItem: (deduction: CalculationRuleReadModel) => void;
  onEditItem: (deduction: CalculationRuleReadModel) => void;
  onChangeOrderNumber: (deductionGroup: CalculationRuleGroupReadModel, direction: MoveDirection) => void;
  onChangeOrderNumberItem: (deduction: CalculationRuleReadModel, direction: MoveDirection) => void;
  sheet: InvoiceCalculationSchemeReadModel;
  selectedExampleInvoiceType: InvoiceType | null;
  exampleInvoiceData?: GetInvoiceCalculationSampleResponse;
  selectedExampleInvoice?: SampleInvoiceReadModel;
  vatRendered: boolean;
  vatRenderedRuleIndex: number
};
export const IcsDeductionGroupSection = (props: IcsDeductionGroupSectionProps) => {
  const {
    deductionGroup,
    // onAdd,
    onDelete,
    onEdit,
    onAddItem,
    onDeleteItem,
    onEditItem,
    onChangeOrderNumber,
    onChangeOrderNumberItem,
    sheet,
    selectedExampleInvoice,
    vatRendered,
    vatRenderedRuleIndex
  } = props;

  const { t } = useTranslation();

  const groupLabel = useMemo(() => {
    return getInvoiceCoverSheetDeductionGroupLabel(deductionGroup.name);
  }, [deductionGroup.name]);

  const foundGroup = useMemo(() => {
    return selectedExampleInvoice?.calculationRuleGroupResult.find(
      (group) => group.id === deductionGroup.calculationRuleGroupId,
    );
  }, [selectedExampleInvoice?.calculationRuleGroupResult, deductionGroup.calculationRuleGroupId])

  const groupResult = useMemo(() => {
    if (foundGroup) {
      if (vatRendered) {
        return foundGroup.calculationResultNet;
      } else {
        return foundGroup.calculationResultGross;
      }
    }
    return null;
  }, [foundGroup, vatRendered]);

  /**
   * The following deductions cannot be deleted (moved only)
   *
   * Cumulated: VAT, Partial payment, Repayment, Cash discount
   * Not Cumulated: VAT, Cash discount
   * Advanced Payment: VAT
   *
   */
  const listItems = useMemo(() => {
    if (!deductionGroup.calculationRules.length) return [];
    return deductionGroup.calculationRules.map((deduction, ruleIndex) => {
      const contextMenuItems: ContextMenuItem[] = [
        {
          label: t('common.edit'),
          subtitle: t('ics.editDeduction'),
          icon: <PencilIcon />,
          onClick: () => onEditItem(deduction),
        },
      ];

      if (deductionGroup.calculationRules.length > 1) {
        if (ruleIndex > 0) {
          contextMenuItems.push({
            label: t('common.moveUp'),
            icon: <UpwardArrowIcon />,
            subtitle: t('ics.moveDeductionInGroupUp'),
            onClick: () => {
              onChangeOrderNumberItem(deduction, 'Up');
            },
          });
        }
        if (ruleIndex < deductionGroup.calculationRules.length - 1) {
          contextMenuItems.push({
            label: t('common.moveDown'),
            icon: <UpwardArrowIcon className="transform rotate-180" />,
            subtitle: t('ics.moveDeductionInGroupDown'),
            onClick: () => {
              onChangeOrderNumberItem(deduction, 'Down');
            },
          });
        }
      }

      if (!excludeDeleteDeductionByType[sheet.type].includes(deduction.type)) {
        contextMenuItems.push({
          label: t('common.delete'),
          subtitle: t('ics.deleteDeduction'),
          icon: <TrashIcon />,
          onClick: () => onDeleteItem(deduction),
        });
      }

      const foundRule = foundGroup?.calculationRuleResults.find((rule) => rule.id === deduction.calculationRuleId);

      return {
        id: deduction.calculationRuleId,
        leftTop: getIcsDeductionTypeLabel(deduction.type),
        leftCenter: getIcsDeductionName(deduction.name),
        rightTop: t('ics.deductionGroupAmount'),
        rightCenter: <FormattedCurrency amount={deduction.value} options={{ maxDigits: 2, minDigits: 2 }} />,
        onClick: () => onEditItem(deduction),
        contextMenuItems: contextMenuItems,
        borderColor: 'border-l-sky-700',
        children: (
          <div className="w-full h-full flex items-center p-3 self-stretch">
            <div className="break-normal w-1/3 flex-none h-full">
              <div className="flex flex-col justify-center h-full">
                <div className="text-xs text-slate-400">{getIcsDeductionTypeLabel(deduction.type)}</div>
                <div className="h-1/3 text-base text-slate-800 font-bold">{getIcsDeductionName(deduction.name)}</div>
              </div>
            </div>
            <div className="w-2/3 break-normal h-full self-stretch justify-end gap-6 text-right flex pl-6 border-l-2">
              {/* FACTOR */}
              <div className="flex flex-col justify-center h-full w-[50px]">
                {typeof foundRule?.percentage !== 'undefined' && foundRule.percentage !== null && (
                  <>
                    <div className="text-xs text-slate-400">{t('ics.deductionFactor')}</div>
                    <div className="text-sm text-slate-800 font-medium">{formatNumber(foundRule.percentage, { maxDigits : 2})} %</div>
                  </>
                )}
              </div>

              {/* REFERENCE VALUE / VALUE */}
              <div className="flex flex-col justify-center h-full w-[160px]">
                {typeof foundRule?.referenceValue !== 'undefined' && foundRule?.referenceValue !== null && (
                  <>
                    <div className="text-xs text-slate-400">{t('ics.exampleInvoice.referenceValue')}</div>
                    <div className="text-sm text-slate-800 font-medium">
                      <FormattedCurrency amount={foundRule.referenceValue} options={{ maxDigits: 2, minDigits: 2 }} />
                    </div>
                  </>
                )}
                {typeof foundRule?.value !== 'undefined' && foundRule?.value !== null && (
                  <>
                    <div className="text-xs text-slate-400">{t('ics.exampleInvoice.amount')}</div>
                    <div className="text-sm text-slate-800 font-medium">
                      <FormattedCurrency amount={foundRule.value} options={{ maxDigits: 2, minDigits: 2 }} />
                    </div>
                  </>
                )}
              </div>

              {/* VAT */}
              <div className="flex flex-col justify-center h-full w-[50px]">
                {/* VAT FROM RULE */}
                {typeof deduction.vat !== 'undefined' && deduction.vat !== null && (
                  <>
                    <div className="text-xs text-slate-400">{t('ics.deductionTypeVat')}</div>
                    <div className="text-sm text-slate-800 font-medium">{formatPercentage(deduction.vat / 100)}</div>
                  </>
                )}
                {/* VAT FROM INVOICE */}
                {(typeof deduction.vat === 'undefined' || deduction.vat === null) &&
                  typeof selectedExampleInvoice?.vat !== 'undefined' &&
                  selectedExampleInvoice?.vat !== null &&
                  (deduction.type === 'Deduction' ||
                    deduction.type === 'Retention' ||
                    deduction.type === 'CashDiscount' ||
                    deduction.type === 'Discount') && (
                    <>
                      <div className="text-xs text-slate-400">{t('ics.deductionTypeVat')}</div>
                      <div className="text-sm text-slate-800 font-medium">
                        {formatPercentage(selectedExampleInvoice.vat / 100)}
                      </div>
                    </>
                  )}
              </div>

              {/* CALCULATION RESULT NET / GROSS */}
              <div className="flex flex-col justify-center h-full flex-1 min-w-[200px] border-l-2">
                {foundRule &&
                  typeof foundRule?.calculationResultNet !== 'undefined' &&
                  typeof foundRule?.calculationResultGross !== 'undefined' && (
                    <>
                      <div className="text-xs text-slate-400">{t('ics.exampleInvoice.resultNetGross')}</div>
                      <div className="text-sm text-slate-800">
                        <span className={ruleIndex < vatRenderedRuleIndex && !vatRendered ? 'font-bold' : ''}>
                          <FormattedCurrency amount={foundRule.calculationResultNet} options={{ maxDigits: 2, minDigits: 2 }} />
                        </span>
                        &nbsp;/&nbsp;
                        <span className={ruleIndex > vatRenderedRuleIndex || vatRendered ? 'font-bold' : ''}>
                          <FormattedCurrency amount={foundRule.calculationResultGross} options={{ maxDigits: 2, minDigits: 2 }} />
                        </span>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [
    deductionGroup.calculationRules,
    t,
    onEditItem,
    sheet.type,
    onDeleteItem,
    onChangeOrderNumberItem,
    foundGroup,
    selectedExampleInvoice?.vat,
    vatRenderedRuleIndex,
    vatRendered
  ]);

  // the "Payment" group cannot be deleted
  const groupContextMenu = useMemo(() => {
    const menu: ContextMenuItem[] = [];

    if (deductionGroup.name !== 'Sum' && deductionGroup.type !== 'Payment') {
      menu.push({
        label: t('common.edit'),
        subtitle: t('ics.editDeductionGroup'),
        icon: <PencilIcon />,
        onClick: onEdit,
      });
    }

    if (sheet.calculationRuleGroups.length > 1) {
      if (deductionGroup.orderNumber > 1) {
        menu.push({
          label: t('common.moveUp'),
          icon: <UpwardArrowIcon />,
          subtitle: t('ics.moveDeductionInGroupUp'),
          onClick: () => {
            onChangeOrderNumber(deductionGroup, 'Up');
          },
        });
      }
      if (deductionGroup.orderNumber < sheet.calculationRuleGroups.length) {
        menu.push({
          label: t('common.moveDown'),
          icon: <UpwardArrowIcon className="transform rotate-180" />,
          subtitle: t('ics.moveDeductionInGroupDown'),
          onClick: () => {
            onChangeOrderNumber(deductionGroup, 'Down');
          },
        });
      }
    }

    if (deductionGroup.type !== 'Payment') {
      menu.push({
        label: t('common.delete'),
        subtitle: t('ics.deleteDeductionGroup'),
        icon: <TrashIcon />,
        onClick: onDelete,
      });
    }

    return menu;
  }, [t, deductionGroup, onChangeOrderNumber, onDelete, onEdit, sheet.calculationRuleGroups.length]);

  return (
    <>
      <div className="flex justify-between items-end">
        <SlideOverTitle title={groupLabel} />
        {groupContextMenu.length > 0 && <ContextMenu items={groupContextMenu} className="pr-2 mb-2" />}
      </div>

      <SlideOverList clickable items={listItems} noItemsMessage={t('ics.noDeductionsMessage')} onAdd={onAddItem}>
        {groupResult && (
          <IcsEditExampleData
            title={t('ics.exampleInvoice.deductionGroupLabelSum')}
            value={<FormattedCurrency amount={groupResult} options={{ maxDigits: 2, minDigits: 2 }} />}
            variant="large"
            className="pl-5 pr-12 -mt-3"
          />
        )}
      </SlideOverList>
    </>
  );
};
