import { lazy } from 'react';
import { LazyComponent } from '@client/shared/toolkit';
import {
  useApiGetReportInvoiceCoverSheetDataSourceQuery
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useUi } from '@client/shared/store';

export interface IcsViewerProps {
  invoiceId: string;
  reportDataSourceId?: string;
  reportId?: string;
}

const ReportViewerComponent = lazy(() => import('@client/project/lazy/src/components/ReportViewer'));

const LazyIcsViewer = (props: IcsViewerProps) => {
  // TODO
  const { invoiceId, reportDataSourceId = '0e7ed4dd-3030-48af-a670-3b04ffd54e15', reportId = '1326aff7-9964-4475-ab59-949053cef0b5' } = props;

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const userData = useUi();

  const { data: dataSource, isFetching: isFetchingIcsDataSource } = useApiGetReportInvoiceCoverSheetDataSourceQuery(
    {
      dataSourceId: reportDataSourceId,
      invoiceId: invoiceId,
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !reportDataSourceId,
    },
  );

  return (
    <LazyComponent>
      <ReportViewerComponent
        className="ics-report-viewer"
        isLoading={isFetchingIcsDataSource}
        reportId={reportId}
        dataSource={dataSource}
        sessionId={userData?.sessionId}
        smallIcons
        hideToolbar
      />
    </LazyComponent>
  );
};

export default LazyIcsViewer;
