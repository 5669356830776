import React from 'react';
import { ProjectSettingGroup, ProjectTagSetting } from '.';
import { ListCustomIcon, PercentageIcon } from '@client/shared/toolkit';
import { ProjectReadModel } from '@client/shared/api';

interface ProjectSettingElementsProps {
  project?: ProjectReadModel;
  projectId: string;
  readOnly: boolean;
}

export const ProjectSettingElements = ({ project, projectId, readOnly }: ProjectSettingElementsProps) => {
  const statusElements = project?.payload.projectSetting?.statuses;
  const constructionPhases = project?.payload.projectSetting?.constructionPhases;

  const vats = project?.payload.projectSetting?.vats;
  const tags = project?.payload.projectSetting?.tags;

  return (
    <div className="relative h-full overflow-y-auto">
      <ProjectSettingGroup
        projectId={projectId}
        readOnly={readOnly}
        groupType="Status"
        elements={statusElements}
        icon={<ListCustomIcon />}
      />

      <ProjectSettingGroup
        projectId={projectId}
        readOnly={readOnly}
        groupType="Construction Phase"
        elements={constructionPhases}
        icon={<ListCustomIcon />}
      />

      <ProjectSettingGroup
        projectId={projectId}
        readOnly={readOnly}
        groupType="Vat"
        vatElements={vats}
        icon={<PercentageIcon />}
      />

      <ProjectTagSetting projectId={projectId} readOnly={readOnly} tagElements={tags} icon={<PercentageIcon />} />
    </div>
  );
};
