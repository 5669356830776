import { formatNumber, formatPercentage, isCurrencySymbol } from '@client/shared/utilities';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CostElement } from '../hooks';
import { DocumentIcon, Square2StackIcon } from '@heroicons/react/24/outline';
import { getUnitLabel } from '../utils';
import { ElementRowMenu } from './ElementRowMenu';
import {
  ContextMenuItem,
  TransactionIcon,
  MoveGrabberIcon,
  UncheckedCheckbox,
  PencilIcon, TrashIcon
} from '@client/shared/toolkit';
import { useLoadedProjectId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineView } from './Timeline';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import {CalculateColumns} from "./CalculateColumns";
import {
  CalculateInformationColumnContent
} from "./CalculateGroupElementInformationColumn";
import {CalculateElementRowInformationColumn} from "./CalculateElementRowInformationColumn";
import { CostsFinanceDetailsPopover } from './Costs';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn, getUnderBudget } from './CalculateSelectableColumn';

interface CostElementProps {
  item: CostElement;
  selectedVersionId: string;
  onClick: () => void;
  onCodeClick?: () => void;
  isSelected?: boolean;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  onRemove?: () => void;
  column?: Column;
  view: TimeLineView;
  onTakeOver?: () => void
  onMove?: () => void;
  onDistribute?: () => void;
  isReadOnly: boolean;
  searchValue?: string
  onCopy?: () => void
}

export const CostElementRow = ({
  item,
  onClick,
  onRemove,
  optionalColumn,
  obligoColumn,
  view,
  onTakeOver,
  onMove,
  onDistribute,
  isReadOnly,
  searchValue,
  onCopy
}: CostElementProps) => {
  const { t } = useTranslation();
  const {
    costElement,
    showError,
    referenceCostElement,
    showReference,
    catalogElement: {
      level
    }
  } = item

  const loadedProjectId = useLoadedProjectId();
  const canDeleteCosts = useValidateProjectPermission(['COSTS_DELETE'], loadedProjectId ?? '');

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  const groupMenuItemsTimelineView = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];
    if (showReference) {
      return items
    }

    if (costElement?.readOnly) {
      items.push({
        label: t('projectCalculate.rowMenu.viewCostElementTitle'),
        subtitle: t('projectCalculate.rowMenu.viewCostElementSubTitle'),
        onClick: onClick,
        icon: <DocumentIcon />,
      });
    } else {
      items.push({
        label: t('projectCalculate.rowMenu.updateCostElementTitle'),
        subtitle: t('projectCalculate.rowMenu.updateCostElementSubTitle'),
        onClick: onClick,
        icon: <PencilIcon />,
      });
      if (onCopy) {
        items.push({
          label: t('projectCalculate.rowMenu.copyCostElementTitle'),
          subtitle: t('projectCalculate.rowMenu.copyCostElementSubTitle'),
          onClick: onCopy,
          icon: <Square2StackIcon />,
        });
      }
      if (onRemove) {
        items.push({
          isDisabled: !canDeleteCosts,
          label: t('projectCalculate.rowMenu.removeCostElementTitle'),
          subtitle: t('projectCalculate.rowMenu.removeCostElementSubTitle'),
          onClick: onRemove,
          icon: <TrashIcon />,
        });
      }
    }

    return items;
  }, [costElement?.readOnly, t, onClick, onRemove, canDeleteCosts, showReference, onCopy]);

  const groupMenuItemsFinanceView = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];
    if (showReference) {
      return items
    }
    if (!costElement?.readOnly) {
      if (onMove) {
        items.push({
          label: t('projectCalculate.rowMenu.moveElementTitle'),
          subtitle: t('projectCalculate.rowMenu.moveElementSubTitle'),
          onClick: onMove,
          icon: <MoveGrabberIcon />,
        });
      }
      if (onRemove) {
        items.push({
          isDisabled: !canDeleteCosts,
          label: t('projectCalculate.rowMenu.removeCostElementTitle'),
          subtitle: t('projectCalculate.rowMenu.removeCostElementSubTitle'),
          onClick: onRemove,
          icon: <TrashIcon />,
        });
      }
      if (onTakeOver) {
        items.push({
          label: t('projectCalculate.rowMenu.takeoverForecastTitle'),
          subtitle: t('projectCalculate.rowMenu.takeoverForecastSubTitle'),
          onClick: onTakeOver,
          icon: <UncheckedCheckbox />,
        });
      }
      if (onDistribute) {
        items.push({
          label: t('projectCalculate.rowMenu.distributeRestObligoTitle'),
          subtitle: t('projectCalculate.rowMenu.takeoverForecastSubTitle'),
          onClick: onDistribute,
          icon: <TransactionIcon />,
        });
      }
    }
    return items
  }, [costElement?.readOnly, onMove, t, onRemove, canDeleteCosts, onTakeOver, onDistribute, showReference])

  const detailValues = useMemo(() => {
    return {
      // Vorheriges Budget
      previousBudget: showReference ? referenceCostElement?.totalValue : null,
      // Hauptauftrag
      mainContract: costElement?.totalContract,
      // Nachtrag
      totalSupplement: costElement?.totalSupplement,
      // Gesamtauftrag
      totalContract: costElement?.totalContract && costElement.totalSupplement ? costElement?.totalContract + costElement.totalSupplement : costElement?.totalContract,
      // Externe Prognose
      effectiveForecast: costElement?.totalForecast,
      // Aktuelles Budget
      totalBudget: showReference ? null : costElement?.totalValue
    }
  }, [costElement, showReference, referenceCostElement])

  const underBudgetInPercent = useMemo(() => {
    return getUnderBudget(costElement?.totalForecast, costElement?.totalValue)
  }, [costElement?.totalForecast, costElement?.totalValue])

  return (
    <CalculateColumns
      view={view}
      informationColumnContent={(
        <CalculateElementRowInformationColumn
          level={level + 1}
          view={view}
          handleOnClick={showReference ? undefined : handleOnClick}
          contentType="row"
          codeWidth={costElement?.costCatalogCode?.length}
          leftColumn={(
            <CalculateInformationColumnContent
              showCode={showReference ? !!referenceCostElement?.costCatalogCode : !!costElement?.costCatalogCode}
              level={level + 1}
              showError={showError}
              title={showReference ? (referenceCostElement?.title ?? referenceCostElement?.description) : (costElement?.title ?? costElement?.description)}
              contentType="row"
              showExpanded
              searchValue={searchValue}
            >
              {costElement?.vat && <span> ({costElement?.vat}%)</span>}
              {costElement?.hasFormula && <sup className="font-normal italic text-gray-400">&nbsp;fx</sup>}
            </CalculateInformationColumnContent>
          )}
          rightColumn={(
            <>
              <div className="pl-2 hidden md:flex w-2/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  {isCurrencySymbol(costElement?.unit) ? <FormattedCurrency amount={costElement?.amount} /> : formatNumber(costElement?.amount)}
                  {costElement?.unit && !isCurrencySymbol(costElement?.unit) && (
                    <div className="text-xs font-light">{getUnitLabel(costElement?.unit)}</div>
                  )}
                </div>
              </div>

              <div className="hidden w-4/12 md:flex xl:w-5/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  <FormattedCurrency amount={costElement?.unitPrice} />
                  {costElement?.unit && costElement.unit !== '%' && (
                    <div className="text-xs font-light">
                      {t('projectCalculate.earningsElementLabelPer')} {getUnitLabel(costElement?.unit)}
                    </div>
                  )}
                </div>
              </div>

              <div className="w-5/12 text-right flex flex-row justify-end items-center leading-tight">
                <div className="text-sm font-bold">
                  <FormattedCurrency amount={costElement?.totalValue} />
                </div>
              </div>
              {/*
                <ElementRowMenu items={elementMenuItems} />
              */}
            </>
          )}
          menu={
            view === TimeLineView.FINANCE ? (
              <ElementRowMenu
                items={groupMenuItemsFinanceView}
                details={<CostsFinanceDetailsPopover detailValues={detailValues} />}
              />
            ): (!showReference && !isReadOnly && <ElementRowMenu items={groupMenuItemsTimelineView} />)
          }
        />
      )}
      currentColumnContent={(
        <CalculateElementRowFinanceColumn
          level={level + 1}
          handleOnClick={showReference ? undefined : handleOnClick}
          columnType={Column.CURRENT}
          view={view}
        >
          {!showReference && !!costElement?.totalValue && (
            <>
              {underBudgetInPercent ? (
                <div className="flex gap-1 items-center">
                      <span className="text-[9px] text-red-800">
                        {formatPercentage(
                          underBudgetInPercent,
                          { maxDigits: 0 }
                        )}
                      </span>
                  <FormattedCurrency amount={costElement.totalValue} />
                </div>
              ) : (
                <FormattedCurrency amount={costElement.totalValue} />
              )}
            </>
          )}
        </CalculateElementRowFinanceColumn>
      )}
      obligoColumnContent={(
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level + 1}
          handleOnClick={showReference ? undefined : handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={costElement}
          showReference={showReference}
          type="costElement"
        />
      )}
      optionalColumnContent={(
        <CalculateSelectableColumn
          column={optionalColumn}
          level={level + 1}
          handleOnClick={showReference ? undefined : handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={costElement}
          showReference={showReference}
          type="costElement"
        />
      )}
    />
  )
};
