import { useLoadedProjectId } from '@client/project/store';
import {
  ElementSettingReadModel,
  ProjectReadModel,
  useApiPostCreateCostElementTagMutation,
  useApiPostUpdateCostElementTagMutation,
  useApiDeleteCostElementTagMutation,
} from '@client/shared/api';
import { BaseSelectOption, AutoComplete, TagsIcon } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CostTagElementProps {
  project: ProjectReadModel;
  costElementId: string;
  calculationModelId: string;
  setting: ElementSettingReadModel;
  tagElements?: string[];
  disabled?: boolean;
  onChange?: () => void;
  setIsFocused: (focused: boolean) => void
}

export const CostTagElement = ({
  project,
  costElementId,
  calculationModelId,
  tagElements,
  disabled,
  setIsFocused
}: CostTagElementProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const tagList = useMemo(() => {
    return project.payload.projectSetting?.tags;
  }, [project]);

  const tagOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    for (const tag of tagList ?? []) {
      options.push({
        label: `${tag.name}` ?? 'no-tag',
        value: tag.id ?? 'no-id',
      });
    }
    return options;
  }, [tagList]);

  const selectedTags = useMemo(() => {
    return tagOptions.filter((x) => tagElements?.find((c) => c === x.value));
  }, [tagOptions, tagElements]);

  const [tag, setTag] = useState<string | undefined>('');
  const [putTag, { isLoading: isUpdatingTag }] = useApiPostUpdateCostElementTagMutation();
  const [postTag, { isLoading: isCreatingTag }] = useApiPostCreateCostElementTagMutation();
  const [removeTag, { isLoading: isRemovingTag }] = useApiDeleteCostElementTagMutation();

  const handleExistingTag = async (id: string) => {
    if (id && costElementId) {
      try {
        await safeMutation(
          putTag,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: calculationModelId,
            id: costElementId,
            tagId: id,
          },
          isUpdatingTag
        );
        setTag('');
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleNewInput = async (name: string) => {
    if (name && costElementId) {
      try {
        await safeMutation(
          postTag,
          {
            projectId: project.payload.id,
            calculationModelId: calculationModelId,
            id: costElementId,
            body: {
              name: name,
            },
          },
          isCreatingTag
        );
        setTag('');
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleInput = async (value: string) => {
    const existingTag = tagOptions.find((x) => x.label === value);
    existingTag ? await handleExistingTag(existingTag.value) : await handleNewInput(value);
  };

  const handleTagRemove = async (tag: BaseSelectOption) => {
    if (tag) {
      try {
        await safeMutation(
          removeTag,
          {
            projectId: projectId ?? 'unset',
            calculationModelId: calculationModelId,
            id: costElementId,
            tagId: tag.value,
          },
          isRemovingTag
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="mx-8 bg-white">
      <AutoComplete
        value={tag ?? ''}
        label={t('projectSetting.tagElement')}
        data={tagOptions.map((c) => c.label)}
        icon={<TagsIcon />}
        onEnterKeyPress={handleInput}
        onClick={handleInput}
        disabled={disabled}
        onChange={setTag}
        onFocus={setIsFocused}
      />

      <div className="flex flex-row mt-5 flex-wrap">
        {selectedTags &&
          selectedTags.map((element, index) => (
            <span key={index} className="ml-5">
              <span className="inline-flex items-center rounded-full px-2 text-sm text-black bg-gray-300">
                {element.label}
                <div className="ml-2" onClick={() => handleTagRemove(element)}>
                  <XMarkIcon className="w-4 h-4 hover:text-gray-600 transition-color duration-200 cursor-pointer" />
                </div>
              </span>
            </span>
          ))}
      </div>
    </div>
  );
};
