import { TagReadModel, useApiDeleteProjectTagMutation, useApiPostCreateProjectTagMutation } from '@client/shared/api';
import { Form, FormField, FormRefHandle, InputIcon, LoadingIndicator, TagTextInput } from '@client/shared/toolkit';
import React, { ReactElement, ReactNode, useRef, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import {
  ProjectTagSettingFormValidationSchema,
  ProjectTagSettingFormValidationValues,
} from '.';
import { useValidateProjectPermission } from '@client/shared/permissions';

interface ProjectTagSettingProps {
  projectId: string;
  readOnly: boolean;
  tagElements?: TagReadModel[] | null | undefined;
  image?: string;
  icon?: ReactNode
}

export const ProjectTagSetting = ({ projectId, readOnly, tagElements, image, icon }: ProjectTagSettingProps) => {
  const { t } = useTranslation();

  const canDelete = useValidateProjectPermission(['PROJECT_DELETE'], projectId);

  const [postTag, { isLoading: isCreating }] = useApiPostCreateProjectTagMutation();
  const [deleteTag, { isLoading: isDeleting }] = useApiDeleteProjectTagMutation();
  const formRef = useRef<FormRefHandle<ProjectTagSettingFormValidationValues>>();
  const [hasFormSubmitted, setHasFormSubmitted] = useState<boolean>(false);
  const defaultValues = {
    projectId: projectId,
    name: '',
  };

  const removeTag = async (id: string) => {
    try {
      await safeMutation(
        deleteTag,
        {
          projectId: projectId,
          id: id,
        },
        isDeleting
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreate = async (data: ProjectTagSettingFormValidationValues) => {
    try {
      if (hasFormSubmitted) {
        await safeMutation(
          postTag,
          {
            projectId: projectId,
            body: {
              name: data.name ?? '',
            },
          },
          isCreating
        );
        if (formRef?.current) formRef.current.resetForm(defaultValues);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.code === 'Enter' ? setHasFormSubmitted(true) : setHasFormSubmitted(false);
  };

  return (
    <div className="w-full mt-5 flex flex-col px-5">
      {(isCreating || isDeleting) && (
        <LoadingIndicator text={t('project.updatingProjectLoadingIndicator')} mode="overlay-window" />
      )}

      <div className="flex bg-gray-200 py-2 px-2 font-bold">
        {image && <InputIcon src={`${image}`} className="w-4 mr-2" />}
        {icon && React.cloneElement(icon as ReactElement, { className: 'w-4 mr-2'})}
        {t('projectSetting.tagElement')}
      </div>

      <div className="flex w-3/12 mt-2">
        <Form<ProjectTagSettingFormValidationValues>
          onSubmit={handleCreate}
          validationSchema={ProjectTagSettingFormValidationSchema}
          defaultValues={defaultValues}
          ref={formRef}
        >
          <FormField name="name">
            {(control) => (
              <TagTextInput
                disabled={readOnly}
                placeHolder={t('projectSetting.tagPlaceHolder')}
                onEnterKeyPress={handleEnterKeyPress}
                {...control}
              />
            )}
          </FormField>
        </Form>
      </div>

      <div className="flex flex-row mt-5 flex-wrap">
        {tagElements &&
          tagElements?.map((element) => (
            <span className="ml-5">
              <span className="inline-flex items-center rounded-full px-2 text-sm text-black bg-gray-300">
                {element.name}
                {canDelete &&
                  <div className="ml-2" onClick={() => removeTag(element.id)}>
                    <XMarkIcon className="w-4 h-4 hover:text-gray-600 transition-color duration-200 cursor-pointer" />
                  </div>
                }
              </span>
            </span>
          ))}
      </div>
    </div>
  );
};
