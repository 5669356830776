import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { VariantList, CalculationModelHistory, CalculationModelNewWizard } from '../components';
import { useTranslation } from 'react-i18next';
import {
  useDocumentTitle,
  FloatingActionButton,
  Modal,
  ContextMenuItem,
  TitleBar,
  VariantNewVersionIcon
} from '@client/shared/toolkit';
import { VariantDropDown } from '@client/project/shared';
import { useLoadedProject } from '@client/project/store';

type TTab = {
  key: string;
  title: string;
  component: () => React.ReactElement;
};

function getTabs(): TTab[] {
  return [
    {
      key: 'list',
      title: 'projectVariants.tabList',
      component: () => <VariantList />,
    },
    {
      key: 'history',
      title: 'projectVariants.tabHistory',
      component: () => <CalculationModelHistory />,
    },
  ];
}

type OpenModal = 'None' | 'CreateVersion' | 'CreateSnapshot';

export const VariantsRoute = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [index, setIndex] = useState(+(searchParams.get('tab') ?? 0));
  const [openModal, setOpenModal] = useState<OpenModal>('None');

  useEffect(() => {
    searchParams.set('tab', index.toString());
    setSearchParams(searchParams, { replace: true });
  }, [index, searchParams, setSearchParams]);

  // we can safely cache the output here
  // we just need to make sure to not initialize it outside the component
  // as settings might be adjusted after the include
  const tabs = useMemo(() => getTabs(), []);

  const { data: loadedProject } = useLoadedProject();

  useDocumentTitle({ title: t('project.menuVariants') });

  const handleCloseModal = () => {
    setOpenModal('None');
  };

  const contextMenuItems: ContextMenuItem[] = [
    {
      label: t('projectVariants.menuCreateVersion'),
      subtitle: t('projectVariants.menuCreateVersionSubtitle'),
      icon: <VariantNewVersionIcon />,
      onClick: () => setOpenModal('CreateVersion'),
    },
    /*
      {
        label: t('projectVariants.menuCreateSnapshot'),
        subtitle: t('projectVariants.menuCreateSnapshotSubtitle'),
        icon: <InputIcon src="/assets/icon-menu-save.svg" />,
        onClick: () => setOpenModal('CreateVersion'),
      },
    */
  ];

  return (
    <>
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('projectVariants.routeTitle')}
        subTitle={t('projectVariants.routeSubTitle')}
      >
        <div className="flex justify-end w-full">
          <VariantDropDown />
        </div>
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <TabGroup
          as="div"
          className="flex-grow p-8 overflow-y-auto flex flex-col"
          defaultIndex={index}
          onChange={(index: number | React.FormEvent<HTMLDivElement>) => setIndex(+index)}
        >
          <div className="mb-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              <TabList className="block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-sky-700 focus:border-sky-700 sm:text-sm rounded-md space-x-4">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.key}
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'border-b-sky-700 text-sky-700'
                          : 'border-transparent text-slate-500 hover:text-slate-700 hover:border-slate-300',
                        'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm'
                      )
                    }
                  >
                    {t(tab.title)}
                  </Tab>
                ))}
              </TabList>
            </nav>
          </div>
          <TabPanels as="div" className="flex-grow flex flex-col">
            <FloatingActionButton menuItems={contextMenuItems} />
            {tabs.map((tab) => (
              <TabPanel key={tab.key} className="flex-grow flex flex-col relative">
                {tab.component()}
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </div>

      <Modal isOpen={openModal === 'CreateVersion'} onClose={handleCloseModal}>
        <CalculationModelNewWizard onClose={handleCloseModal} />
      </Modal>
    </>
  );
};
