import classNames from 'classnames';
import { parseISO } from 'date-fns/fp';
import React, {
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { ContractDto, FinanceTimeline, FinanceTimeLineElement } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { endOfMonth, startOfMonth } from 'date-fns';
import { TimeLineDataContext } from './TimeLineDataContext';
import { TimeLineElementsProps } from './TimeLineBody';
import { FormattedCurrency } from '@client/project/shared';

export const calculateTotalMonths = (startDate: Date, endDate: Date) => {
  const monthDiff = endDate.getMonth() - startDate.getMonth();
  return monthDiff + 1;
};

export const calculateMonthOffset = (timelineStartDate: Date, currentDate: Date) => {
  return currentDate.getMonth() - timelineStartDate.getMonth();
};

export interface TimelineElementFinancePopoverProps extends PropsWithChildren {
  popoverButton: ReactElement
}
export const TimelineElementFinancePopover = (props: TimelineElementFinancePopoverProps) => {
  const { popoverButton, children } = props;
  const {
    setFinanceTargetElement,
    setFinanceElementDetails
  } = useContext(TimeLineDataContext)
  const enterDelay = 250
  const leaveDelay = 150
  const enterTimeout = useRef<NodeJS.Timeout>()
  const leaveTimeout = useRef<NodeJS.Timeout>()
  const handleMouseEnter = useCallback((event: MouseEvent) => {
    leaveTimeout.current && clearTimeout(leaveTimeout.current)
    enterTimeout.current = setTimeout(() => {
      setFinanceElementDetails(children);
      setFinanceTargetElement(event.target as HTMLDivElement);
    }, enterDelay)
  }, [setFinanceElementDetails, children, setFinanceTargetElement])
  const handleMouseLeave = useCallback(() => {
    enterTimeout.current && clearTimeout(enterTimeout.current)
    leaveTimeout.current = setTimeout(() => {
      setFinanceElementDetails('');
      setFinanceTargetElement(null);
    }, leaveDelay)
  }, [setFinanceElementDetails, setFinanceTargetElement])

  return (
    <div
      className="h-full block cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="w-32 h-full flex flex-col justify-center px-4 py-1 text-gray-600 bg-slate-100 hover:bg-slate-200 rounded-full text-sm leading-tight transition-colors duration-300">
        {popoverButton}
      </div>
      {/* (
        <div className="flex flex-col text-sm text-center text-gray-600 p-4" ref={childRef}>
          {children}
        </div>
      ) */}
    </div>
  )
};

interface TimeLineElementFinanceDetailsProps {
  startDate: Date;
  endDate: Date;
  financeTimeline: FinanceTimeline;
  element: FinanceTimeLineElement;
  paymentSumValue?: number
  datedPaymentsSumValue?: number
}
export const TimelineElementFinanceDetails = ({
  endDate,
  startDate,
  financeTimeline,
  element,
  paymentSumValue,
  datedPaymentsSumValue
}: TimeLineElementFinanceDetailsProps) => {
  const { t } = useTranslation();
  return (
    <TimelineElementFinancePopover
      popoverButton={(
        <>
          <div className="flex flex-row justify-center items-center col-span-1 h-1/2">
            {!!element.datedPayments?.paymentSum && (
              <div
                className={classNames('w-0 h-0 border-l-[4px] border-l-transparent border-r-transparent mr-1 ', {
                  'border-t-[6px] border-r-[4px] border-t-red-600':
                    (!!element.plan?.value && element.datedPayments?.paymentSum < element.plan?.value) ||
                    (!element.plan?.value && element.datedPayments?.paymentSum < 0),
                  'border-b-[6px] border-r-[4px] border-b-green-600':
                    (!!element.plan?.value && element.datedPayments?.paymentSum > element.plan?.value) ||
                    (!element.plan?.value && element.datedPayments?.paymentSum > 0),
                })}
              />
            )}

            <div className="text-right font-semibold">
              <FormattedCurrency amount={paymentSumValue ? paymentSumValue : (element.plan?.value ? element.plan?.value : 0)} />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center col-span-1 h-1/2 text-xs">
            {!!element.datedPayments?.paymentSum && (
              <div
                className={classNames('text-center pb-1 ', {
                  'text-red-600':
                    (!!element.plan?.value && element.datedPayments?.paymentSum < element.plan?.value) ||
                    (!element.plan?.value && element.datedPayments?.paymentSum < 0),
                  'text-green-600':
                    (!!element.plan?.value && element.datedPayments?.paymentSum > element.plan?.value) ||
                    (!element.plan?.value && element.datedPayments?.paymentSum > 0),
                })}
              >
                <FormattedCurrency amount={datedPaymentsSumValue ? datedPaymentsSumValue : element.datedPayments?.paymentSum} /> IST
              </div>
            )}

            {!element.datedPayments?.paymentSum && <div className="text-center font-normal text-xs">-</div>}
          </div>
        </>
      )}
    >
      <span>
        <span className="font-semibold">{t('projectCalculate.financeTimeline.startDate')}: </span>
        {startDate.toLocaleDateString()}
      </span>
      <span>
        <span className="font-semibold">{t('projectCalculate.financeTimeline.endDate')}: </span>
        {endDate.toLocaleDateString()}
      </span>
      {!!financeTimeline.totalPlanned && (
        <span>
          <span className="font-semibold">{t('projectCalculate.financeTimeline.totalPlanned')}: </span>
          <FormattedCurrency amount={financeTimeline.totalPlanned} />
        </span>
      )}
      {!!financeTimeline.totalPayment && (
        <span>
          <span className="font-semibold">{t('projectCalculate.financeTimeline.totalPayment')}: </span>
          <FormattedCurrency amount={financeTimeline.totalPayment} />
        </span>
      )}
    </TimelineElementFinancePopover>
  );
};

interface TimeLineElementFinanceProps {
  timeLineStartDate?: Date;
  timeLineEndDate?: Date;
  financeTimeline: FinanceTimeline | null | undefined;
  contract?: ContractDto | null | undefined;
  year?: number
  timeLineElement?: TimeLineElementsProps | null
  group?: string
  idx?: number
}

export const TimeLineElementFinance = ({
  timeLineEndDate,
  timeLineStartDate,
  financeTimeline,
  year,
  timeLineElement,
  group,
  idx
}: TimeLineElementFinanceProps) => {
  const {
    toggledYears,
    setSelectedTimelineElement
  } = useContext(TimeLineDataContext)

  const startDate = useMemo(() => {
    if (financeTimeline?.start) {
      return parseISO(financeTimeline.start.substring(0, 10))
    }
    return undefined
  }, [financeTimeline?.start]);
  const endDate = useMemo(() => {
    if (financeTimeline?.end) {
      return parseISO(financeTimeline.end.substring(0, 10))
    }
    return undefined
  }, [financeTimeline?.end]);

  // the actual elements in the current year
  const currentYearElements = useMemo(() => {
    if (!timeLineStartDate || !timeLineEndDate || !financeTimeline?.elements.length) {
      return []
    }
    return financeTimeline?.elements.filter((elem) => {
      const elemDate = parseISO(elem.date.substring(0, 10))
      return elemDate >= timeLineStartDate && elemDate <= timeLineEndDate
    })
  }, [financeTimeline?.elements, timeLineStartDate, timeLineEndDate])

  const isToggled = useMemo(() => {
    return year ? toggledYears.includes(year) : false
  }, [toggledYears, year])

  // add up all values for if year is toggled
  const paymentSumValue = useMemo(() => {
    let sumUpValues = 0
    currentYearElements.forEach((elem) => {
      if (elem.plan?.value) {
        sumUpValues += elem.plan.value
      }
    })
    return sumUpValues
  }, [currentYearElements])

  const datedPaymentsSumValue = useMemo(() => {
    let sumUpValues = 0
    currentYearElements.forEach((elem) => {
      if(elem.datedPayments?.paymentSum) {
        sumUpValues += elem.datedPayments.paymentSum
      }
    })
    return sumUpValues
  }, [currentYearElements])

  // Position left based on element's month and start month
  const getPosLeft = useCallback((element: FinanceTimeLineElement) => {
    if (!timeLineStartDate || !timeLineEndDate || !startDate || !endDate) {
      return 0
    }
    if (!isToggled) {
      const start = startDate < timeLineStartDate ? startOfMonth(timeLineStartDate) : startOfMonth(startDate)
      const end = endDate > timeLineEndDate ? endOfMonth(timeLineEndDate) : endOfMonth(endDate)
      const totalMonthsInTimeline = calculateTotalMonths(start, end);
      const startMonthOffset = calculateMonthOffset(start, parseISO(element.date));
      const left = (startMonthOffset / totalMonthsInTimeline) * 100
      return `${left}%`
    }
    return 0
  }, [isToggled, startDate, timeLineStartDate, endDate, timeLineEndDate])

  const handleClick = useCallback((element?: FinanceTimeLineElement) => {
    if (timeLineElement && setSelectedTimelineElement && group !== 'financing') {
      setSelectedTimelineElement({ elem: timeLineElement, date: element?.date })
    }
  }, [timeLineElement, setSelectedTimelineElement, group])

  // use memo required, because of context always updating
  return useMemo(() => {
    if (!timeLineStartDate || !timeLineEndDate || !financeTimeline || !financeTimeline.elements.length || !year || !startDate || !endDate) {
      return null;
    }
    return (
      <PositionedTimeLineElementFinance
        timeLineEndDate={timeLineEndDate}
        timeLineStartDate={timeLineStartDate}
        startDate={startDate}
        endDate={endDate}
        isToggled={isToggled}
      >
        {isToggled && currentYearElements.length > 0 && (
          <div className="absolute top-0 left-0 h-full" onClick={() => handleClick()}>
            <TimelineElementFinanceDetails
              {...{ startDate, endDate, timeLineStartDate, timeLineEndDate, financeTimeline }}
              element={currentYearElements[0]}
              paymentSumValue={paymentSumValue}
              datedPaymentsSumValue={datedPaymentsSumValue}
            />
          </div>
        )}
        {!isToggled && currentYearElements.map((element, idx) => (
          <div
            className="absolute top-0 h-full"
            style={{ left: getPosLeft(element)}}
            key={`financeTimelineChildElement-${idx}`}
            onClick={() => handleClick(element)}
          >
            <TimelineElementFinanceDetails
              {...{ startDate, endDate, timeLineStartDate, timeLineEndDate, element, financeTimeline }}
            />
          </div>
        ))}
      </PositionedTimeLineElementFinance>
    );
  }, [timeLineStartDate, timeLineEndDate, financeTimeline, year, startDate, endDate, isToggled, handleClick, currentYearElements, paymentSumValue, datedPaymentsSumValue, getPosLeft])
};

export interface PositionedTimeLineElementFinanceProps extends PropsWithChildren {
  timeLineStartDate: Date;
  timeLineEndDate: Date;
  startDate: Date
  endDate: Date
  isToggled: boolean
  onClick?: () => void
}
export const PositionedTimeLineElementFinance = ({
  timeLineEndDate,
  timeLineStartDate,
  startDate,
  endDate,
  children,
  isToggled,
  onClick
}: PositionedTimeLineElementFinanceProps) => {
  const realStartDate = useMemo(() => {
    let start = startOfMonth(startDate)
    if (start < timeLineStartDate) {
      start = startOfMonth(timeLineStartDate)
    }
    return start
  }, [startDate, timeLineStartDate])

  const realEndDate = useMemo(() => {
    let end = endOfMonth(endDate)
    if (end > timeLineEndDate) {
      end = endOfMonth(timeLineEndDate)
    }
    return end
  }, [endDate, timeLineEndDate])

  const calculatedPosition = useMemo(() => {
    if (isToggled) {
      return undefined
    }
    const totalMonthsInTimeline = calculateTotalMonths(timeLineStartDate, timeLineEndDate);
    const startMonthOffset = calculateMonthOffset(timeLineStartDate, realStartDate);

    const left = (startMonthOffset / totalMonthsInTimeline) * 100; // Left position as a percentage
    const width = (calculateTotalMonths(realStartDate, realEndDate) / totalMonthsInTimeline) * 100; // Width as a percentage

    return { left: `${left}%`, width: `${width}%` };
  }, [isToggled, timeLineStartDate, timeLineEndDate, realStartDate, realEndDate]);

  return (
    <div className={classNames('w-full flex flex-row bg-slate-100 h-[38px] z-30 absolute', {
      'rounded-r-full': endDate <= timeLineEndDate,
      'rounded-l-full': startDate >= timeLineStartDate
    })}
      style={calculatedPosition}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
