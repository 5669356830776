import { ElementReadModel, VatReadModel } from '@client/shared/api';
import { SettingsAddButton, InputIcon, Modal } from '@client/shared/toolkit';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import React, { ReactElement, ReactNode, useState } from 'react';
import { ProjectSettingElementModal, ProjectSettingGroupType } from '.';
import { useTranslation } from 'react-i18next';

interface ProjectSettingGroupProps {
  projectId: string;
  readOnly: boolean;
  groupType: ProjectSettingGroupType;
  elements?: ElementReadModel[] | null | undefined;
  vatElements?: VatReadModel[] | null | undefined;
  image?: string;
  icon?: ReactNode;
}

export const ProjectSettingGroup = ({
  projectId,
  readOnly,
  groupType,
  elements,
  vatElements,
  image,
  icon,
}: ProjectSettingGroupProps) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [selectedElement, setSelectedElement] = useState<ElementReadModel | undefined>();
  const [selectedVatElement, setSelectedVatElement] = useState<VatReadModel | undefined>();

  const getSettingGroupTranslation = (groupType: ProjectSettingGroupType) => {
    switch (groupType) {
      case 'Status':
        return t('projectSetting.statusElement');
      case 'Construction Phase':
        return t('projectSetting.constructionPhaseElement');
      case 'Vat':
        return t('projectSetting.vatElement');
      case 'Tag':
        return t('projectSetting.tagElement');
    }
  };

  const handleAdd = () => {
    setIsAdd(true);
    setIsModalOpen(true);
  };

  const handleEdit = (element: ElementReadModel) => {
    setIsAdd(false);
    setIsModalOpen(true);
    setSelectedElement(element);
  };

  const handleVatEdit = (element: VatReadModel) => {
    setIsAdd(false);
    setIsModalOpen(true);
    setSelectedVatElement(element);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedElement(undefined);
    setSelectedVatElement(undefined);
  };

  return (
    <>
      <div className="w-full mt-5 flex flex-col px-5">
        <div className="flex bg-gray-200 py-2 px-2 font-bold">
          {image && <InputIcon src={`${image}`} className="w-4 mr-2" />}
          {icon && React.cloneElement(icon as ReactElement, { className: 'w-4 mr-2' })}
          {getSettingGroupTranslation(groupType)}
        </div>
        <div className="text-gray-500 bg-white w-full pl-8 ">
          <table className="divide-y divide-gray-200 w-full">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 px-3 w-3/12 text-left text-xs sm:pl-4 md:pl-0 font-semibold">
                  {getSettingGroupTranslation(groupType)}
                </th>
                <th scope="col" className="py-3.5 px-3 w-2/12 text-left text-xs sm:table-cell font-semibold">
                  {t('app.settingsColType')}
                </th>
                <th className="w-7/12"></th>
              </tr>
            </thead>
            <tbody>
              {groupType !== 'Vat' && groupType !== 'Tag' && (
                <>
                  {elements?.map((element, idx) => (
                    <tr key={idx}>
                      <td className="py-4 text-left text-sm text-gray-500 sm:table-cell">{element.name}</td>
                      <td className="py-4 px-3 text-left text-sm text-gray-500 sm:table-cell">{element.elementType}</td>
                      <td className="py-4 px-3 text-right">
                        {!readOnly && (
                          <button onClick={() => handleEdit(element)}>
                            <EllipsisVerticalIcon className="w-6 h-6" />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}

              {groupType === 'Vat' && (
                <>
                  {vatElements?.map((element, idx) => (
                    <tr key={idx}>
                      <td className="py-4 text-left text-sm text-gray-500 sm:table-cell">{element.vat}</td>
                      <td className="py-4 px-3 text-left text-sm text-gray-500 sm:table-cell">{element.elementType}</td>
                      <td className="py-4 px-3 text-right">
                        {!readOnly && (
                          <button onClick={() => handleVatEdit(element)}>
                            <EllipsisVerticalIcon className="w-6 h-6" />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end pt-2 pr-2">{!readOnly && <SettingsAddButton onClick={handleAdd} />}</div>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleClose} variant="custom" className="w-[520px] h-[420px]">
        <ProjectSettingElementModal
          projectId={projectId}
          readOnly={readOnly}
          isAdd={isAdd}
          onClose={handleClose}
          groupType={groupType}
          settingElement={selectedElement}
          vatElement={selectedVatElement}
          getSettingElementGroupTranslation={getSettingGroupTranslation}
        />
      </Modal>
    </>
  );
};
