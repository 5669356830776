import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Location } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

export type ReportingContextType = {
  onDownload: () => void
  sourceCalculationModelId?: string
  setSourceCalculationModelId: (id: string) => void
  targetCalculationModelId?: string
  setTargetCalculationModelId: (id: string) => void
  calculationModelId?: string
  setCalculationModelId: (id: string) => void
};
export const ReportingContext = createContext<ReportingContextType>({
  sourceCalculationModelId: undefined,
  setSourceCalculationModelId: () => {
    //
  },
  targetCalculationModelId: undefined,
  setTargetCalculationModelId: () => {
    //
  },
  calculationModelId: undefined,
  setCalculationModelId: () => {
    //
  },
  onDownload: () => {
    //
  },
});

interface ReportingContextProviderProps extends PropsWithChildren {
  projectId?: string
  activeVariantId?: string
  location: Location
}

export const ReportingContextProvider = ({ projectId, activeVariantId, location, children }: ReportingContextProviderProps) => {
  const [sourceCalculationModelId, setSourceCalculationModelId] = useState<string | undefined>(activeVariantId);
  const [targetCalculationModelId, setTargetCalculationModelId] = useState<string | undefined>(activeVariantId);
  const [calculationModelId, setCalculationModelId] = React.useState<string | undefined>(activeVariantId);

  const onDownload = useCallback(() => {
    const currentPath = location.pathname
    if (projectId && ROUTES_CONFIG.REPORTING_COMPARISON.path.replace(':id', projectId) === currentPath && sourceCalculationModelId && targetCalculationModelId) {
      window.open(
        `/api/projects/${projectId}/calculation-models/${sourceCalculationModelId}/reporting/comparison/${targetCalculationModelId}/excel`,
        '_new'
      );
    } else if (projectId && calculationModelId && ROUTES_CONFIG.REPORTING_FORECAST.path.replace(':id', projectId) === currentPath) {
      window.open(`/api/projects/${projectId}/calculation-models/${calculationModelId}/reporting/forecast/excel`, '_new');
    } else if (projectId && ROUTES_CONFIG.REPORTING_BUDGET.path.replace(':id', projectId) === currentPath && sourceCalculationModelId) {
      window.open(
        `/api/projects/${projectId}/calculation-models/${sourceCalculationModelId}/reporting/budget/excel`,
        '_new'
      );
    }
  }, [location.pathname, projectId, sourceCalculationModelId, targetCalculationModelId, calculationModelId])

  const value = useMemo(
    () => ({
      sourceCalculationModelId,
      setSourceCalculationModelId,
      targetCalculationModelId,
      setTargetCalculationModelId,
      calculationModelId,
      setCalculationModelId,
      onDownload
    }),
    [
      sourceCalculationModelId,
      targetCalculationModelId,
      calculationModelId,
      onDownload
    ],
  );

  return <ReportingContext.Provider value={value}>{children}</ReportingContext.Provider>;
};
