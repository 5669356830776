import {
  ApiDeleteInvoiceCalculationRuleApiArg,
  ApiDeleteInvoiceCalculationRuleApiResponse,
  ApiDeleteInvoiceCalculationRuleGroupApiArg,
  ApiDeleteInvoiceCalculationRuleGroupApiResponse,
  ApiPostCreateInvoiceCalculationRuleApiArg,
  ApiPostCreateInvoiceCalculationRuleApiResponse,
  ApiPostCreateInvoiceCalculationRuleGroupApiArg,
  ApiPostCreateInvoiceCalculationRuleGroupApiResponse,
  ApiPostMoveInvoiceCalculationRuleApiArg,
  ApiPostMoveInvoiceCalculationRuleApiResponse,
  ApiPostMoveInvoiceCalculationRuleGroupApiArg,
  ApiPostMoveInvoiceCalculationRuleGroupApiResponse,
  ApiPostUpdateInvoiceCalculationRuleApiArg,
  ApiPostUpdateInvoiceCalculationRuleApiResponse,
  ApiPostUpdateInvoiceCalculationRuleGroupApiArg,
  ApiPostUpdateInvoiceCalculationRuleGroupApiResponse
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiInvoiceCalculationEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateInvoiceCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceCalculationRuleGroupApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiPostUpdateInvoiceCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostUpdateInvoiceCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateInvoiceCalculationRuleGroupApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiDeleteInvoiceCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiDeleteInvoiceCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteInvoiceCalculationRuleGroupApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiPostCreateInvoiceCalculationRule: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceCalculationRuleApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiPostUpdateInvoiceCalculationRule: {
    invalidatesTags: (
      _result: ApiPostUpdateInvoiceCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateInvoiceCalculationRuleApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiDeleteInvoiceCalculationRule: {
    invalidatesTags: (
      _result: ApiDeleteInvoiceCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteInvoiceCalculationRuleApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiPostMoveInvoiceCalculationRuleGroup: {
    invalidatesTags: (
      _result: ApiPostMoveInvoiceCalculationRuleGroupApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostMoveInvoiceCalculationRuleGroupApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiPostMoveInvoiceCalculationRule: {
    invalidatesTags: (
      _result: ApiPostMoveInvoiceCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostMoveInvoiceCalculationRuleApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
}
