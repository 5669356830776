import {
  ShortCommitmentReadModel,
  ShortContractReadModel,
  useApiDeleteCommitmentMutation,
  useApiPostCreateCommitmentMutation,
  useApiPostUpdateCommitmentMutation,
  useApiGetCommitmentQuery,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';
import {
  Button,
  Form,
  TextInput,
  FormField,
  SlideOver,
  SlideOverOnCloseProps,
  ContractNumberIcon,
  PriceTagIcon,
  LoadingIndicator,
  FormRefHandle,
  ListTitle,
  DecoratedCard, BrokenLinkIcon, AddButton,
} from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import {
  CommitmentAddContractSlideOver,
  CommitmentFormValidationSchema,
  CommitmentFormValidationValues, ContractBudgeting
} from '.';
import classNames from 'classnames';
import { DialogTitle } from '@headlessui/react';
import { useValidateProjectPermission } from '@client/shared/permissions';

interface CommitmentSlideOverProps extends SlideOverOnCloseProps {
  setChildSlideOverIsOpen: (val: boolean) => void;
  shortCommitment?: ShortCommitmentReadModel | null;
  contracts: ShortContractReadModel[] | null;
}

export const CommitmentSlideOver = ({ onClose, shortCommitment, contracts, setChildSlideOverIsOpen }: CommitmentSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const isAddMode = !shortCommitment;

  const formRef = useRef<FormRefHandle<CommitmentFormValidationValues>>();

  const [postCreateCommitment, { isLoading: isCreating }] = useApiPostCreateCommitmentMutation();
  const [postUpdateCommitment, { isLoading: isUpdating }] = useApiPostUpdateCommitmentMutation();
  const [postDeleteCommitment, { isLoading: isDeleting }] = useApiDeleteCommitmentMutation();

  const [isOpenAddContractSlideOver, setIsOpenAddContractSlideOver] = useState(false);
  const [addedContracts, setAddedContracts] = useState<string[]>([]);

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '');

  const { data: commitment, isFetching: isLoadingCommitment } = useApiGetCommitmentQuery(
    {
      commitmentId: shortCommitment?.id ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !shortCommitment?.id,
    },
  );

  const handleSubmit = async (data: CommitmentFormValidationValues) => {
    if (loadedVariantId && loadedProjectId) {
      if (isAddMode) {
        try {
          await safeMutation(
            postCreateCommitment,
            {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                code: data.code,
                name: data.name,
                description: data.description,
                addedContracts: addedContracts,
                budgetAssignments: [],
              },
            },
            isCreating,
          );
          onClose(true);
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          await safeMutation(
            postUpdateCommitment,
            {
              commitmentId: shortCommitment.id,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                code: data.code,
                name: data.name,
                description: data.description,
                addedContracts: addedContracts.filter((c) => !shortCommitment.contracts.map((c) => c.id).includes(c)),
                deletedContracts: shortCommitment.contracts.map((c) => c.id).filter((c) => !addedContracts.includes(c)),
                addedBudgetAssignments: [],
                updatedBudgetAssignments: [],
                deletedBudgetAssignments: [],
              },
            },
            isUpdating,
          );
          onClose(true);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleDelete = async () => {
    if (commitment?.id && loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          postDeleteCommitment,
          {
            commitmentId: commitment.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          },
          isDeleting,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const addContract = () => {
    setChildSlideOverIsOpen(true)
    setIsOpenAddContractSlideOver(true);
  };

  useEffect(() => {
    if (commitment?.contracts) {
      setAddedContracts(commitment.contracts.map((c) => c.id));
    }
  }, [commitment]);

  const defaultFormValues = useMemo(() => {
    return {
      id: commitment?.id ?? '',
      name: commitment?.name ?? '',
      code: commitment?.code ?? '',
      description: commitment?.description ?? '',
    }
  }, [commitment])

  useEffect(() => {
    formRef.current?.resetForm(defaultFormValues)
  }, [defaultFormValues]);

  return (
    <>
      {(isLoadingCommitment || isUpdating || isDeleting) && (<LoadingIndicator text={t('projectContract.updatingCommitmentLoadingIndicator')} mode="overlay-window" />)}
      <DialogTitle className={classNames('w-full relative')}>
        <div className="flex h-[88px] items-center justify-between border-b">
          <div className="w-1/2 sm:w-1/3 flex-tight py-4 px-8">
            <div className="text-2xl leading-tight font-bold truncate">{commitment?.name}</div>
            <div className="whitespace-nowrap text-[13px] leading-tight text-gray-500 pt-1">
              {t('projectContract.commitment')}&nbsp;<span className="font-bold">{commitment?.code}</span>
            </div>
          </div>
        </div>
      </DialogTitle>

      <SlideOver.Content
        className="p-8"
        onKeyEnter={() => {
          submitRef.current?.click();
        }}
      >
        <div className="mb-4">
          <Form<CommitmentFormValidationValues>
            onSubmit={handleSubmit}
            validationSchema={CommitmentFormValidationSchema}
            defaultValues={defaultFormValues}
            className="w-full flex flex-col flex-grow min-h-0 divide-y-2 gap-9"
            ref={formRef}
          >
            <div className="bg-white">
              <div className="divide-gray-100 divide-y">
                <FormField name="code">
                  {(control) => (
                    <TextInput
                      label={t('common.code')}
                      icon={<ContractNumberIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="name">
                  {(control) => (
                    <TextInput
                      label={t('common.name')}
                      icon={<PriceTagIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="description">
                  {(control) => (
                    <TextInput
                      label={t('common.description')}
                      inputType="textarea"
                      className="font-normal"
                      disabled={!canWrite}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
            </div>
            <div className="pt-8 mb-8">
              <DecoratedCard shadowVariant="normal">
                <DecoratedCard.Content>
                  <ListTitle title={t('projectContract.commitmentContracts')} />
                  <div className="flex flex-col mt-2 bg-white divide-gray-100 divide-y px-4 mb-6">
                    {addedContracts.length > 0 ? (
                      addedContracts.map((contractId) => {
                        const contract =
                          contracts?.find((c) => c.id === contractId) ??
                          commitment?.contracts.find((c) => c.id === contractId);
                        return (
                          <div
                            className="flex justify-between items-center h-10 bg-white px-4 text-sm hover:bg-gray-50"
                            key={contractId}
                          >
                            <div>
                              <span className="font-medium mr-2">{contract?.code}</span>
                              <span className="text-gray-500">{contract?.name}</span>
                            </div>
                            <div
                              onClick={() => {
                                setAddedContracts(addedContracts.filter((c) => c !== contractId));
                              }}
                              className="w-6 cursor-pointer hover:opacity-60"
                            >
                              <BrokenLinkIcon className="w-full h-full" />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <span className="text-gray-500">{t('projectContract.noContracts')}</span>
                    )}
                  </div>
                </DecoratedCard.Content>
              </DecoratedCard>
              <div className="flex w-full items-center relative">
                <AddButton onClick={addContract} className="absolute right-3 top-auto" />
                <span className="text-sky-700 hover:text-sky-500 cursor-pointer font-bold" onClick={addContract} />
              </div>
              <ContractBudgeting commitment={commitment} />
              {/*<DecoratedCard shadowVariant="normal" className="mt-10">
                <DecoratedCard.Content>
                  <ListTitle title={t('projectContract.budgeting')} color="bg-green-400" />
                  <BudgetAssignment
                    showTitle={false}
                    budgetAssignments={commitment?.budgetAssignments}
                    id={commitment?.id}
                    disabled={(commitment ? commitment?.canBeBudgeted === false || addedContractsAreBudgeted : false) || !canWrite}
                    canBeBudgeted={commitment ? commitment?.canBeBudgeted && !addedContractsAreBudgeted : true}
                    updateBudgetAssignments={({ added, updated, deleted }) => {
                      setAddedBudgetAssignments(added);
                      setUpdatedBudgetAssignments(updated);
                      setDeletedBudgetAssignments(deleted);
                    }}
                    validBudget={(valid) => setValidBudget(valid)}
                    setIsBudgetAssignmentPopoverOpen={(open) => setIsBudgetAssignmentPopoverOpen(open)}
                    cannotBeBudgetedMessage={
                      <div className="px-6 pb-2">{t('projectContract.commitmentCannotBeBudgetedMessage')}</div>
                    }
                  />
                </DecoratedCard.Content>
              </DecoratedCard>*/}
            </div>
          </Form>
        </div>
      </SlideOver.Content>

      <SlideOver.Controls
        className={classNames('flex justify-end', {
          'justify-between': canWrite && !isAddMode,
        })}
      >
        {canWrite && !isAddMode && (
          <Button onClick={handleDelete} className="mr-2" variant="danger">
            {t('common.delete')}
          </Button>
        )}
        <div>
          <Button onClick={() => onClose(false)} className="mr-2" variant="secondary">
            {t('common.cancel')}
          </Button>
          {canWrite && (
            <Button variant="primary" innerRef={submitRef} onClick={() => formRef.current?.submitForm()}>
              {t('common.save')}
            </Button>
          )}
        </div>
      </SlideOver.Controls>

      <SlideOver
        isOpen={isOpenAddContractSlideOver}
        onClose={() => {
          setChildSlideOverIsOpen(false)
          setIsOpenAddContractSlideOver(false);
        }}
      >
        <CommitmentAddContractSlideOver
          contracts={contracts}
          addedContracts={addedContracts}
          setAddedContracts={setAddedContracts}
          onClose={() => {
            setChildSlideOverIsOpen(false)
            setIsOpenAddContractSlideOver(false);
          }}
        />
      </SlideOver>
    </>
  );
};
