import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DatevAuthState } from './datevAuthSlice';
type RootState = {
  datev: DatevAuthState;
};

export const useDatevAuthState = () => {
  const data = useSelector<RootState, DatevAuthState>((x) => x.datev);
  return useMemo(() => data, [data]);
};

export const useDatevTenantAuthData = () => {
  return useDatevAuthState().datevTenantAuthData;
};

export const useDatevClientId = () => {
  return useDatevAuthState().datevClientId;
};

export const useDatevClientAuthData = () => {
  return useDatevAuthState().datevClientAuthData;
};

export const useDatev = () => {
  return useDatevAuthState().datevClientAuthData;
};
