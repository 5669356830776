import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import { ROUTES_CONFIG, useValidateProjectPermission } from '@client/shared/permissions';
import React, { useEffect, useState } from 'react';
import { EditProjectSlideOver } from './EditProject';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProjectReadModel, useApiGetProjectQuery } from '@client/shared/api';
import { getProjectThumbnailByCoverId } from '@client/project/shared';

export const ProjectSettings = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const { t } = useTranslation()

  const [isOpenSlideOver, setIsOpenSlideOver] = useState(false)
  const [project, setProject] = useState<ProjectReadModel | undefined>(undefined)

  const { data: loadedProject, isFetching, isError } = useApiGetProjectQuery({ projectId: id || '' }, { skip: id === null });

  useEffect(() => {
    if (typeof loadedProject !== 'undefined' && loadedProject !== null) {
      setProject(loadedProject.project);
      if (!project) {
        setIsOpenSlideOver(true)
      }
    }
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECTS.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedProject, isError]);

  const thumbnail = getProjectThumbnailByCoverId(project?.payload.id ?? '', project?.payload.name ?? '', project?.payload.coverPictureId, false);
  const readOnly = !useValidateProjectPermission(['PROJECT_WRITE'], loadedProject?.project?.payload.id ?? '');

  if (!id) {
    navigate(ROUTES_CONFIG.PROJECTS.name);
  }

  return (
    <SlideOver
      isOpen={isOpenSlideOver}
      onClose={() => setIsOpenSlideOver(false)}
      variant="2xl"
      onAfterLeave={() => {
        navigate(ROUTES_CONFIG.PROJECTS.name);
      }}
    >
      {isFetching && (
        <LoadingIndicator text={t('project.projectLoadingIndicator')} mode="overlay" />
      )}
      <EditProjectSlideOver
        thumbnail={thumbnail}
        project={project}
        readOnly={readOnly}
        projectId={id ?? ''}
        onClose={() => setIsOpenSlideOver(false)}
      />
    </SlideOver>
  )
}
