import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import { ContactList, ContactListProps } from '.';
import { SortDownIcon } from '../../../icons';
import React, { PropsWithChildren, ReactElement, ReactNode, useEffect, useRef } from 'react';

const CONTACT_LIST_BG_COLORS = ['text-white bg-slate-600', 'text-white bg-slate-500', 'bg-white'];

const PORTFOLIO_LIST_BG_COLORS = ['text-white bg-[#164870]', 'text-white bg-[#195888]', 'bg-white'];

export const ContactListItem = (props: ContactListProps) => {
  const {
    defaultOpen = true,
    listItems = [],
    level = 0,
    theme = 'contact',
    icon,
    image,
    title,
    children,
    subtitle,
    customColor,
    onClick,
    onTitleClick,
    onToggle,
  } = props;

  const color = customColor
    ? customColor
    : theme === 'portfolio'
      ? PORTFOLIO_LIST_BG_COLORS[level] ?? 'text-white bg-[#164870]'
      : CONTACT_LIST_BG_COLORS[level] ?? 'text-white bg-slate-600';

  // Hacky solution if defaultOpen changes :(
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (buttonRef.current) {
      if (
        (defaultOpen && buttonRef.current.dataset.headlessuiState !== 'open') ||
        (!defaultOpen && buttonRef.current.dataset.headlessuiState === 'open')
      ) {
        buttonRef.current.click();
      }
    }
  }, [defaultOpen]);

  if (listItems.length) {
    return (
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <DisclosureButton as="div" className="flex gap-1 min-h-[64px] w-full items-center" ref={buttonRef}>
              <div
                className="flex gap-1 min-h-[64px] w-full items-center"
                onClick={onToggle ? () => onToggle(!open) : undefined}
              >
                <SortDownIcon
                  className={cn(
                    'text-slate-600 opacity-60 transition-transform duration-100 w-6 h-6 flex-none cursor-pointer',
                    {
                      '-rotate-180': !open,
                    },
                  )}
                />
                <button className={cn('flex items-center w-full gap-4 ml-1.5 min-h-[64px]', color)}>
                  <ContactListItemContent
                    image={image}
                    icon={icon}
                    title={title}
                    subtitle={subtitle}
                    onTitleClick={onTitleClick}
                    level={level}
                  >
                    {children}
                  </ContactListItemContent>
                </button>
              </div>
            </DisclosureButton>
            <DisclosurePanel>
              <ContactList listItems={listItems} theme={theme} level={level + 1} />
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <div className={cn('flex gap-1 min-h-[64px] w-full')}>
      <div className="w-6 flex-none h-full" />
      <div className={cn('flex items-center w-full gap-4 ml-1.5 min-h-[64px]', color)}>
        <ContactListItemContent
          image={image}
          icon={icon}
          title={title}
          subtitle={subtitle}
          onClick={onClick}
          onTitleClick={onTitleClick}
          level={level}
        >
          {children}
        </ContactListItemContent>
      </div>
    </div>
  );
};

export interface ContactListItemContentProps extends PropsWithChildren {
  icon?: ReactNode;
  image?: ReactNode;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  onClick?: () => void;
  onTitleClick?: () => void;
  level?: number
}

export const ContactListItemContent = (props: ContactListItemContentProps) => {
  const {
    icon,
    image,
    title,
    children,
    subtitle,
    onClick,
    onTitleClick,
    level = 0
  } = props;
  return (
    <div className={cn('flex w-full items-center p-2.5 flex-wrap gap-3', onClick ? 'cursor-pointer' : '')}>
      {(icon || title || subtitle) && (
        <div
          className={cn('flex items-center w-full gap-4 flex-1 min-w-[300px] max-w-full overflow-hidden')}
          onClick={onClick}
        >
          {icon && (
            <div className="w-10 h-10 flex items-center justify-center flex-none">
              {React.cloneElement(icon as ReactElement, { className: 'w-8 h-8' })}
            </div>
          )}
          {image && <div className="w-10 h-10 flex-none">{image}</div>}
          {(title || subtitle) && (
            <div className="flex flex-col items-start text-left leading-none">
              {title && (
                <div
                  className={cn('font-bold text-lg', {
                    'cursor-pointer transition-colors duration-300': onTitleClick,
                    'hover:text-gray-300': onTitleClick && level < 2,
                    'hover:text-gray-500': onTitleClick && level >= 2
                  })}
                  onClick={onTitleClick ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onTitleClick();
                  } : undefined}
                >
                  {title}
                </div>
              )}
              {subtitle && <div className="font-light text-[15px]">{subtitle}</div>}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};
