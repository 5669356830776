import classNames from 'classnames';
import React, { useId } from 'react';
import { isEmpty } from '@client/shared/utilities';
import { FormHelperText } from './FormHelperText';

interface TagTextInputProps {
  label?: string;
  placeHolder?: string;
  value: string;
  onChange?: (value: string) => void;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  maxLength?: number;
  name?: string;
  autoComplete?: string;
  showValidation?: boolean;
  isValidationValid?: boolean;
  helperText?: string;
  onEnterKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const TagTextInput = ({
  autoComplete,
  className,
  disabled,
  icon,
  inputClassName,
  isValidationValid,
  label,
  maxLength,
  name,
  onChange,
  placeHolder,
  showValidation,
  value,
  helperText,
  onEnterKeyPress,
}: TagTextInputProps) => {
  const inputId = useId();
  const [isFocused, setIsFocused] = React.useState(false);

  const handleOnChange = (newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleFocusChange = (focus: boolean) => {
    setIsFocused(focus);
  };

  return (
    <div className={className}>
      <div
        className={classNames('w-full relative px-3 flex flex-row bg-white', {
          'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
            showValidation && isValidationValid != null && !isValidationValid,
          'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
        })}
      >
        {icon && (
          <div className="h-14 flex items-center">
            <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
          </div>
        )}
        <div
          className={classNames('relative flex-grow', {
            'ml-2': icon,
            'mx-1': !icon,
          })}
        >
          <input
            id={inputId}
            className={classNames(
              'fake-mt block pt-5 w-full text-lg appearance-none focus:outline-none bg-transparent font-medium transition-opacity',
              {
                'text-gray-800': !disabled,
                'text-gray-500 cursor-not-allowed': disabled,
                'opacity-0': placeHolder && isEmpty(value) && !isFocused,
              },
              inputClassName
            )}
            value={value}
            type="text"
            placeholder={placeHolder}
            disabled={disabled}
            autoComplete={autoComplete}
            name={name}
            maxLength={maxLength}
            onChange={(event) => handleOnChange(event.target.value)}
            onFocus={() => handleFocusChange(true)}
            onBlur={() => handleFocusChange(false)}
            onKeyDown={onEnterKeyPress}
          />
          {label && (
            <label
              htmlFor={inputId}
              className={classNames(
                'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate pr-4 text-left',
                {
                  'pt-3 mt-[3px]': isEmpty(value) && !isFocused,
                  'pt-5 -mt-px': !isEmpty(value) || isFocused,
                }
              )}
            >
              {label}
            </label>
          )}
        </div>
        <div
          className={classNames('absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-300 transition-opacity', {
            'opacity-0': !isFocused,
          })}
        />
      </div>
      {helperText && (
        <div className="w-full bg-white">
          <FormHelperText text={helperText} error={!isValidationValid} />
        </div>
      )}
    </div>
  );
};
