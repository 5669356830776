import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ROUTES_CONFIG,
  useValidateTenantPermission
} from '@client/shared/permissions';
import {
  ContextMenuItem,
  DashboardCard,
  Modal,
  TextHighlighter,
  TrashIcon,
} from '@client/shared/toolkit';
import { PortfolioShortReadModel } from '@client/shared/api';

interface PortfolioOverviewCardProps {
  portfolio: PortfolioShortReadModel;
  searchText: string
}

export const PortfolioOverviewCard = (props: PortfolioOverviewCardProps) => {
  const { portfolio } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TODO
  const thumbnail = '/assets/project-placeholder/project-placeholder-3.jpg'; // getPortfolioThumbnail(portfolio);
  const [openModal, setOpenModal] = useState<'None' | 'Delete' | 'New'>('None');

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.delete'),
      subtitle: t('common.deleteEntity', { entity: t('app.portfolio') }),
      isDisabled: !useValidateTenantPermission(['TENANT_MANAGE']),
      icon: <TrashIcon />,
      onClick: () => setOpenModal('Delete'),
    },
  ]

  const handleCloseModal = () => {
    setOpenModal('None');
  };

  const onProjectCardClick = () => {
    navigate(ROUTES_CONFIG.PORTFOLIO_PROPERTY.path.replace(':id', portfolio.id));
  };

  return (
    <>
      <DashboardCard
        showStack={false}
        thumbnail={thumbnail}
        thumbnailAlt={portfolio.name}
        title={
          props.searchText ? (
            <TextHighlighter
              text={portfolio.name}
              highlighted={props.searchText
                .split(',')
                .filter((x) => x !== '')
                .map((x) => x.trim())}
            />
          ) : (
            portfolio.name
          )
        }
        contextItems={contextItems}
        onClick={onProjectCardClick}
        passDataCy={`portfolio-${portfolio.name}`}
        icons={[]}
      />

      <Modal isOpen={openModal === 'Delete'} onClose={handleCloseModal} />
    </>
  );
};
