import { useEffect, useState } from 'react';

export const useAutocomplete = (
  data: string[],
  onChange: (selected: string) => void,
  inputSearchRef: HTMLInputElement | null
) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');

  useEffect(() => {
    if (inputSearchRef) {
      inputSearchRef.focus();
    }
  }, [inputSearchRef]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value !== '') {
      const filteredSuggestions = data.filter((itemData) => {
        const value = event.target.value.toLowerCase();
        const suggestion = itemData.toLowerCase();

        return value && suggestion.includes(value) && suggestion !== value;
      });

      onChange(event.target.value);
      setSuggestions(filteredSuggestions);
    } else {
      onChange('');
      setSelectedSuggestion('');
      setSuggestions([]);
      setActiveSuggestion(0);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown' && activeSuggestion < suggestions.length) {
      setActiveSuggestion(activeSuggestion + 1);
    } else if (event.key === 'ArrowUp' && activeSuggestion > 1) {
      setActiveSuggestion(activeSuggestion - 1);
    } else if (event.key === 'Enter') {
      onChange(suggestions[activeSuggestion - 1]);
      setSelectedSuggestion(suggestions[activeSuggestion - 1]);
      setSuggestions([]);
      setActiveSuggestion(0);

      return suggestions[activeSuggestion - 1];
    }

    return null;
  };

  const handleClick = (value: string) => {
    onChange(value);
    setSelectedSuggestion(value);
    setSuggestions([]);
    setActiveSuggestion(0);
  };

  return {
    selectedSuggestion,
    suggestions,
    activeSuggestion,
    handleChange,
    handleKeyDown,
    handleClick,
  };
};
