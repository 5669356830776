import {
    useApiPostCreateCostElementDocumentMutation,
    useApiPostCreateRiskElementDocumentMutation,
    useApiPostCreateEarningsElementDocumentMutation,
    useApiPostCreateFinancingElementDocumentMutation,
    DocumentReadModel
} from '@client/shared/api';
import { useState } from 'react';
import { FileInput, Button, SlideOverTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { CalculateElementDocumentRow } from './CalculateElementDocumentRow';
import { safeMutation } from '@client/shared/utilities';
interface CalculateElementDocumentsProps {
  ownerId: string;
  type: DocumentOwnerType;
  documents: DocumentReadModel[];
  canWrite: boolean;
  canDelete: boolean;
}

export type DocumentOwnerType = 'CostElement' | 'EarningsElement' | 'RiskElement' | 'FinancingElement';

export const CalculateElementDocuments = ({ ownerId, type, documents, canWrite, canDelete }: CalculateElementDocumentsProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  const [files, setFiles] = useState<FileList | null>(null);

  const [postCostElementDocuments, { isLoading : isLoadingCostElementDocuments }] = useApiPostCreateCostElementDocumentMutation();
  const [postRiskElementDocuments, { isLoading : isLoadingRiskElementDocuments }] = useApiPostCreateRiskElementDocumentMutation();
  const [postEarningsElementDocuments, { isLoading : isLoadingEarningsElementDocuments }] = useApiPostCreateEarningsElementDocumentMutation();
  const [postFinancingElementDocuments, { isLoading : isLoadingFinancingElementDocuments }] = useApiPostCreateFinancingElementDocumentMutation();

  const handleFileChange = (fileList: FileList | null) => {
    setFiles(fileList);
  };

  const handleSaveDocuments = async () => {
    if (files && files.length > 0) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      switch (type){
        case 'CostElement':
          try {
            await safeMutation(
              postCostElementDocuments,
              {
                projectId: loadedProjectId ?? 'unset',
                calculationModelId: loadedCalculationModelId ?? 'unset',
                costElementId: ownerId,
                body: formData as unknown as { files: Blob[] },
              },
              isLoadingCostElementDocuments
            );

            setFiles(null);
          } catch (e) {
            console.log(e, 'error');
          }
          break;
        case 'RiskElement':
          try {
            await safeMutation(
              postRiskElementDocuments,
              {
                projectId: loadedProjectId ?? 'unset',
                calculationModelId: loadedCalculationModelId ?? 'unset',
                riskElementId: ownerId,
                body: formData as unknown as { files: Blob[] },
              },
              isLoadingRiskElementDocuments
            );

            setFiles(null);
          } catch (e) {
            console.log(e, 'error');
          }
          break;
        case 'EarningsElement':
            try {
              await safeMutation(
                postEarningsElementDocuments,
                {
                  projectId: loadedProjectId ?? 'unset',
                  calculationModelId: loadedCalculationModelId ?? 'unset',
                  earningsElementId: ownerId,
                  body: formData as unknown as { files: Blob[] },
                },
                isLoadingEarningsElementDocuments
              );

              setFiles(null);
            } catch (e) {
              console.log(e, 'error');
            }
            break;
          case 'FinancingElement':
            try {
              await safeMutation(
                postFinancingElementDocuments,
                {
                  projectId: loadedProjectId ?? 'unset',
                  calculationModelId: loadedCalculationModelId ?? 'unset',
                  financingElementId: ownerId,
                  body: formData as unknown as { files: Blob[] },
                },
                isLoadingFinancingElementDocuments
              );

              setFiles(null);
            } catch (e) {
              console.log(e, 'error');
            }
            break;
      }
    }
  };

  return (
    <div>
        <SlideOverTitle title={t('projectCalculate.addElementDocument')} marginTop={false} />
        <div className="bg-white p-3 rounded-sm">
            <FileInput acceptedFileTypes={['*']} multiple={true} selectedFiles={files} onChange={(files) => handleFileChange(files)} />
        </div>

        <div className='flex justify-end mt-4 mb-10'>
            <Button variant="primary" onClick={handleSaveDocuments}>
                {t('common.upload')}
            </Button>
        </div>
        <SlideOverTitle title={t('projectCalculate.elementDocument')} />
        {documents.map((document) => (
            <CalculateElementDocumentRow ownerId={ownerId} type={type} elementDocument={document} key={document.id} canDelete={canDelete}/>
        ))}
        {!documents.length && (
            <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
                {t('projectCalculate.elementDocumentsEmpty')}
            </div>
        )}
    </div>
  );
};
