import { BaseSelect } from '@client/shared/toolkit';
import {useEffect, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {DistributionFrequency, DistributionReadModel, ElementTimelineReadModel} from "@client/shared/api";

interface TimeLineFrequencySelectProps {
  onChange: (distribution: DistributionReadModel | undefined) => void;
  disabled?: boolean;
  disabledTypes?: DistributionFrequency[];
  className?: string
  timeline: ElementTimelineReadModel | null | undefined;
}

export const TimeLineFrequencySelect = (props: TimeLineFrequencySelectProps) => {
  const { t } = useTranslation();

  const {
    className,
    disabled,
    disabledTypes = [],
    onChange,
    timeline
  } = props;

  const [frequency, setFrequency] = useState<string>('Month');
  useEffect(() => {
    setFrequency(timeline?.distribution?.frequency ?? 'Month');
  }, [timeline]);

  const handleChange = (newGrain: DistributionFrequency) => {
    const distribution: DistributionReadModel = {
      id: timeline?.distribution?.id ?? '',
      type: timeline?.distribution?.type ?? 'None',
      frequency: newGrain,
      distributionValues: [],
    }
    onChange(distribution);
  };

  const selectableTypes = useMemo(() => {
    const types: { value: DistributionFrequency; label: string }[] = [
      { value: 'Month', label: t('projectCalculate.DistributionGrainMonthly') },
      { value: 'Week', label: t('projectCalculate.DistributionGrainWeekly') },
      { value: 'QuarterYear', label: t('projectCalculate.DistributionGrainQuarterly') },
      { value: 'HalfYear', label: t('projectCalculate.DistributionGrainHalfYearly') },
      { value: 'None', label: t('projectCalculate.DistributionManual') }
    ];
    if (disabledTypes && disabledTypes.length > 0) {
      return types.filter((type) => !disabledTypes.includes(type.value));
    }
    return types;
  }, [t, disabledTypes]);

  return (
    <BaseSelect
      className={className}
      disabled={disabled}
      options={selectableTypes}
      label={t('projectCalculate.DistributionGrainLabel')}
      value={frequency}
      onChange={(value) => handleChange(value as DistributionFrequency)}
    />
  );
};
