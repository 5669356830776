import { Modal, Button, FileInput, ModalOnCloseProps, LoadingIndicator } from '@client/shared/toolkit';
import {
  TaxonomyReadModel,
  useApiPostUploadTaxonomyFileMutation,
  useApiPostUploadPlotFileMutation,
  TaxonomyItemFileTypeEnum,
  PlotItemFileTypeEnum,
  PlotReadModel,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId } from '@client/project/store';

interface ImageUploadModalProps extends ModalOnCloseProps {
  item?: TaxonomyReadModel | undefined;
  plot?: PlotReadModel | undefined;
}

export const ImageUploadModal = ({ item, plot, onClose }: ImageUploadModalProps) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const [postTaxonomyImage, { isLoading: isPostingTaxonomyImage }] = useApiPostUploadTaxonomyFileMutation();
  const [postPlotImage, { isLoading: isPostingPlotImage }] = useApiPostUploadPlotFileMutation();
  const loadedProjectId = useLoadedProjectId();

  const handleUploadImage = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('FileType', 'Thumbnail');

    if (item && loadedProjectId) {
      try {
        await safeMutation(
          postTaxonomyImage,
          {
            projectId: loadedProjectId,
            calculationModelId: item?.calculationModelId ?? '',
            taxonomyItemId: item?.itemId ?? '',
            body: formData as unknown as { file: Blob; FileType: TaxonomyItemFileTypeEnum },
          },
          isPostingTaxonomyImage
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }

    if (plot && loadedProjectId) {
      try {
        await safeMutation(
          postPlotImage,
          {
            projectId: loadedProjectId,
            calculationModelId: plot?.calculationModelId ?? '',
            plotItemId: plot?.plotId ?? '',
            body: formData as unknown as { file: Blob; FileType: PlotItemFileTypeEnum },
          },
          isPostingPlotImage
        );

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleFileChange = (fileList: FileList | null) => {
    if (fileList) {
      setFile(fileList[0]);
    }
  };

  return (
    <>
      {(isPostingTaxonomyImage || isPostingPlotImage) && (
        <LoadingIndicator text={t('common.uploading')} mode="overlay-window" />
      )}
      <Modal.Header title={t('projectTaxonomy.uploadNewThumbnail')} />
      <Modal.Content>
        <FileInput
          className="bg-white rounded-sm"
          acceptedFileTypes={['image/*']}
          multiple={false}
          onChange={(files) => handleFileChange(files)}
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleUploadImage} disabled={!file}>
          {t('app.projectImageUploadImage')}
        </Button>
      </Modal.Controls>
    </>
  );
};
