import {useState, useRef} from 'react';
import {
  useApiPostCreateProjectGroupMutation,
  ApiPostCreateProjectGroupApiArg
} from '@client/shared/api';
import {
  MultiProjectNewFormValidationValues,
  MultiProjectNewFormValidationSchema
} from './MultiProjectNewFormValidationSchema';
import {
  Wizard,
  Button,
  Form,
  FormField,
  TextInput,
  Modal,
  FormRefHandle,
  FileInput,
  TaskCompletedDottedIcon,
  TagWindowIcon,
  NewProjectWizardIcon,
  WizardSlideHeader,
  WizardSlideContent, wizardHandleOnSlideChangeFormValidation,
  LoadingIndicator,
} from '@client/shared/toolkit';
import {safeMutation} from '@client/shared/utilities';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {useProjects} from '@client/project/store';

interface MultiNewProjectWizardProps {
  onClose: (clearSelection?: boolean) => void;
  selectedProjectIds: string[];
  onCreate: (id: string) => void
}

export const MultiProjectNewWizard = ({onClose, selectedProjectIds, onCreate}: MultiNewProjectWizardProps) => {
  const {t} = useTranslation();

  const {projects, groups} = useProjects();

  let allProjects = projects;
  groups.map((x) => allProjects = allProjects.concat(x.projects))

  let defaultProjectId = projects.length;

  const isExistingProjectId = (id: string): boolean => {
    return allProjects.some((p) => p.projectId === id);
  };

  while (isExistingProjectId(defaultProjectId.toString())) {
    defaultProjectId++;
  }

  const formRef = useRef<FormRefHandle<MultiProjectNewFormValidationValues>>();
  const [currentStep, setCurrentStep] = useState(0);
  const [postMultiProject, options] = useApiPostCreateProjectGroupMutation();
  const handleCloseModal = (arg: boolean) => {
    onClose(arg);
  };

  const handleSubmit = async () => {
    if (formRef.current) {
      const values = formRef.current.getValues();

      try {
        await handleCreateProject(values);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCreateProject = async (data: MultiProjectNewFormValidationValues) => {
    try {
      const formData = new FormData();
      formData.append('Name', data.name);
      formData.append('Description', data.description ?? '');
      if (selectedProjectIds) {
        selectedProjectIds.forEach(item => {
          formData.append('ProjectIds', item);
        });
      }
      if (data.coverImage && data.coverImage[0]) {
        formData.append('CoverImage', data.coverImage[0]);
      }
      const args = {
        body: formData
      } as ApiPostCreateProjectGroupApiArg

      const result = await safeMutation(
        postMultiProject,
        args,
        options.isLoading
      );
      if (result?.id) {
        onCreate(result.id)
      }
      setCurrentStep(currentStep + 1);
    } catch (e) {
      console.log(e);
    }
  };

  const defaultFormValues = {
    projectId: defaultProjectId.toString(),
    name: '',
    description: '',
    externalId: '',
    projectIds: [],
    coverImage: undefined,
  };

  return (
    <Form<MultiProjectNewFormValidationValues>
      validationSchema={MultiProjectNewFormValidationSchema}
      defaultValues={defaultFormValues}
      ref={formRef}
      className="h-full"
    >
      <Wizard
        currentStep={currentStep}
        onSlideChange={(step) =>
          wizardHandleOnSlideChangeFormValidation(step, formRef, setCurrentStep)
        }
        className="w-[740px] h-[560px]"
      >
        {options.isLoading && <LoadingIndicator text={t('app.createMultiProjectLoading') ?? ''} />}
        <Wizard.Slides>
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<NewProjectWizardIcon />}
              title={t('app.wizardNewMultiProject')}
            />
           <WizardSlideContent>
            <FormField name="name">
              {(control) => (
                <TextInput
                  label={t('app.newProjectName')}
                  icon={<TagWindowIcon />}
                  {...control}
                />
              )}
            </FormField>
            <FormField name="description">
              {(control) => (
                <TextInput
                  label={t('app.editProjectDescription')}
                  icon={<TagWindowIcon />}
                  {...control}
                />
              )}
            </FormField>
           </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideHeader
              title={t('app.wizardUploadImage')}
              subTitle={t('app.wizardUploadImageDescription')}
            />
            <WizardSlideContent>
              <FormField name="coverImage">
                {(control) => (
                  <FileInput className="bg-white" acceptedFileTypes={['image/*']} multiple={true} {...control} />
                )}
              </FormField>
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<TaskCompletedDottedIcon />}
              title={t('app.wizardNewProjectComplete')}
              subTitle={t('app.wizardNewProjectCompleteDescription')}
            />
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ count, isFirst, isLast, canGoPrevious, canGoNext, previous, next }) => (
            <Modal.Controls
              className={classNames({
                'bg-slate-100': currentStep === 0,
                'bg-white': currentStep !== 0,
              })}
            >
              {(() => {
                if (isFirst) {
                  return (
                    <Button variant="text" onClick={() => handleCloseModal(false)}>
                      {t('common.cancel')}
                    </Button>
                  );
                } else if (isLast) {
                  return null;
                } else {
                  return (
                    <Button variant="text" disabled={!canGoPrevious} onClick={previous}>
                      {t('app.newProjectBack')}
                    </Button>
                  );
                }
              })()}
              {(() => {
                if (isLast) {
                  return (
                    <Button variant="primary" onClick={() => handleCloseModal(true)}>
                      {t('common.close')}
                    </Button>
                  );
                } else if (currentStep === count - 2) {
                  return (
                    <Button variant="primary" onClick={handleSubmit}>
                      {t('app.newProjectCreateProject')}
                    </Button>
                  );
                } else {
                  return (
                    <Button variant="primary" disabled={!canGoNext} onClick={next}>
                      {t('app.newProjectNext')}
                    </Button>
                  );
                }
              })()}
            </Modal.Controls>
          )}
        </Wizard.Navigation>
      </Wizard>
    </Form>
  );
};
