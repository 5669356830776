import { DashboardCustom, DashboardCustomConfig, DashboardWidgetType } from './DashboardCustom';
import { useMemo } from 'react';
import {
  AgreementDottedIcon,
  BusinessBuildingDottedIcon, CardPaymentDottedIcon, CheckMark2DottedIcon, CityBuildingsDottedIcon,
  CustomerDottedIcon, DistributionTypeCustomIcon, ExchangeDollarDottedIcon,
  ProjectPhaseCustomIcon, ProjectTypeCustomIcon,
  QuickModeOnDottedIcon,
  RoomDottedIcon, USDollarCircleDottedIcon,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';

export const DashboardCarestone = () => {
  const { t } = useTranslation();
  // this config should be editable in future
  const config: DashboardCustomConfig = useMemo(() => {
    return {
      grid: 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-3',
      cols: [
        {
          columns: 'col-span-1 md:col-span-2 xl:col-span-1 columns-1 md:columns-2 xl:columns-1 2xl:columns-2',
          widgets: [
            {
              type: DashboardWidgetType.ProjectCustomFields,
              config: [
                {
                  name: 'Projektphase',
                  icon: <ProjectPhaseCustomIcon />,
                },
              ],
            },
            {
              type: DashboardWidgetType.ProjectCustomFields,
              config: [
                {
                  name: 'Gesellschaft',
                  title: 'Projektgesellschaft',
                  icon: <BusinessBuildingDottedIcon />,
                },
                {
                  name: 'Projektverantwortung (PE)',
                  title: 'Projektverantwortung PE',
                  icon: <CustomerDottedIcon />,
                },
                {
                  name: 'Projektverantwortung (PM)',
                  title: 'Projektverantwortung PM',
                  icon: <CustomerDottedIcon />,
                },
              ],
            },
            {
              type: DashboardWidgetType.ProjectMetaDataAddress,
            },
            {
              type: DashboardWidgetType.Mixed,
              widgets: [
                {
                  type: DashboardWidgetType.ProjectMetaDataGrz,
                },
                {
                  type: DashboardWidgetType.ProjectCustomFields,
                  config: [
                    {
                      name: 'Energiestandard',
                      icon: <QuickModeOnDottedIcon />,
                      size: 'large',
                    },
                    {
                      name: 'Einheiten',
                      icon: <RoomDottedIcon />,
                      size: 'large',
                    },
                  ]
                },
              ]
            },
            {
              type: DashboardWidgetType.ProjectMilestones,
              config: [
                {
                  name: 'Kaufpreiszahlung',
                  title: 'Kaufpreiszahlung am',
                  icon: <CardPaymentDottedIcon />,
                  size: 'large',
                },
                {
                  name: 'Baugenehmigung',
                  title: 'Baugenehmigung seit',
                  icon: <CheckMark2DottedIcon />,
                  size: 'large',
                }
              ],
              widgets: [
                {
                  type: DashboardWidgetType.ProjectCustomFields,
                  config: [
                    {
                      name: 'Beauftragung Bau',
                      icon: <AgreementDottedIcon />,
                      size: 'large',
                    }
                  ]
                }
              ]
            },
            {
              type: DashboardWidgetType.MaBVRate,
            },
          ],
        },
        {
          columns: 'col-span-full xl:col-span-2 2xl:col-span-1',
          widgets: [
            {
              type: DashboardWidgetType.CashFlowChart,
            },
            {
              type: DashboardWidgetType.IsShouldChart,
            },
          ],
        },
        {
          columns: 'md:col-span-2 xl:col-span-1 columns-1 lg:columns-2 xl:columns-1',
          widgets: [
            {
              type: DashboardWidgetType.Assets,
            },
          ],
          children: [
            {
              columns: 'columns-1 md:columns-2 lg:columns-1 2xl:columns-2',
              widgets: [
                {
                  type: DashboardWidgetType.ProjectCustomFields,
                  config: [
                    {
                      name: 'Betreiber',
                      icon: <BusinessBuildingDottedIcon />,
                      size: 'large'
                    }
                  ],
                  widgets: [
                    {
                      type: DashboardWidgetType.ProjectMetaDataCustom,
                      config: [
                        {
                          name: 'Jahresmiete (Soll)',
                          icon: <USDollarCircleDottedIcon />,
                          size: 'large'
                        }
                      ],
                    },
                    {
                      type: DashboardWidgetType.ProjectMetaDataCustom,
                      config: [
                        {
                          name: 'Endkundenrendite',
                          icon: <ExchangeDollarDottedIcon />,
                          size: 'large'
                        }
                      ],
                    },
                  ]
                },
                {
                  type: DashboardWidgetType.ProjectCustomFields,
                  title: t('dashboard.widget.title.more'),
                  icon: <CityBuildingsDottedIcon />,
                  config: [
                    {
                      name: 'Projektart',
                      icon: <ProjectTypeCustomIcon />,
                      variant: 'other',
                      size: 'large',
                      // hideIfEmpty: true
                    },
                    {
                      name: 'Vertriebsart',
                      icon: <DistributionTypeCustomIcon />,
                      variant: 'other',
                      size: 'large',
                    }
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  }, [t]);

  return <DashboardCustom config={config} />;
};
