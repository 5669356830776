import { DecoratedCard, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { formatDate, formatNumber } from '@client/shared/utilities';
import { useGetCurrencySymbol, useLoadedProject } from '@client/project/store';
import { useApiGetBaseReportQuery } from '@client/shared/api';
import { getProjectThumbnailByCoverId } from '@client/project/shared';

export const ReportBase = () => {
  const { t } = useTranslation();
  const currentCurrencySymbol = useGetCurrencySymbol();

  const { data: projectData } = useLoadedProject();
  const { data : report, isFetching } = useApiGetBaseReportQuery(
    {
      projectId: projectData?.project.payload.id ?? '',
    },
    {
      skip: projectData === undefined
    }
  );

  const thumbnail = getProjectThumbnailByCoverId(projectData?.project.payload.id ?? '', projectData?.project.payload.name ?? '', projectData?.project.payload.coverPictureId, false);

  const getColumn = (index : number) => {
    const maxColumns = 4;
    const length = report?.report.columns.length ?? 0;
    const columnDiff = maxColumns - length;
    const listIndex = index - columnDiff - 1;

    return length === 0 || listIndex < 0 ? undefined : report?.report.columns[listIndex];
  }

  const column1 = getColumn(1);
  const column2 = getColumn(2);
  const column3 = getColumn(3);
  const column4 = getColumn(4);

  const milestones = column4?.milestones.map((x) => x.title);

  return (
    <DecoratedCard>
      <DecoratedCard.Header showActionButton={false}>
        <div className="truncate">{t('reporting.reportBaseTitle')}</div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="w-full h-full p-6 bg-white">
        {isFetching && <LoadingIndicator mode="overlay" />}
        <div className="flex flex-row w-full space-x-6">
          <div className="flex-grow">
            <table className="w-full table-fixed text-[15px] font-bold">
              <thead>
                <tr>
                  <th className="text-right px-6 w-auto"></th>
                  <th className="text-right px-6 w-44 break-words">{column1?.title}</th>
                  <th className="text-right px-6 w-44 break-words bg-slate-50">{column2?.title}</th>
                  <th className="text-right px-6 w-44 break-words">{column3?.title}</th>
                  <th className="text-right px-6 w-44 break-words bg-slate-50">{column4?.title}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <td className="text-right px-6 pt-3 pb-6">{column1 ? formatDate(column1?.statusDate) : undefined}</td>
                  <td className="text-right px-6 pt-3 pb-6 bg-slate-50">{column2 ? formatDate(column2?.statusDate) : undefined}</td>
                  <td className="text-right px-6 pt-3 pb-6">{column3 ? formatDate(column3?.statusDate) : undefined}</td>
                  <td className="text-right px-6 pt-3 pb-6 bg-slate-50">{column4 ? formatDate(column4?.statusDate) : undefined}</td>
                </tr>
                <tr className="border-b">
                  <td className="px-6">
                    {t('report.reportBase.totalEarnings')} <span className="font-normal text-gray-800">{`${t('report.reportBase.inMillions')} ${currentCurrencySymbol}`}</span>
                  </td>
                  <td className="text-right py-2 px-6">{column1 ? formatNumber(column1?.totalEarnings, { maxDigits: 1 }) : undefined}</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatNumber(column2?.totalEarnings, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6">{column3 ? formatNumber(column3?.totalEarnings, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatNumber(column4?.totalEarnings, { maxDigits: 1 }) : undefined }</td>
                </tr>
                <tr className="border-b">
                  <td className="px-6">
                    {t('report.reportBase.totalCosts')} <span className="font-normal text-gray-800">{`${t('report.reportBase.inMillions')} ${currentCurrencySymbol}`}</span>
                  </td>
                  <td className="text-right py-2 px-6">{column1 ? formatNumber(column1?.totalCosts, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatNumber(column2?.totalCosts, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6">{column3 ? formatNumber(column3?.totalCosts, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatNumber(column4?.totalCosts, { maxDigits: 1 }) : undefined }</td>
                </tr>
                <tr className="border-b">
                  <td className="px-6">
                    {t('report.reportBase.ResultByForeignCapital')} <span className="font-normal text-gray-800">{`${t('report.reportBase.inMillions')} ${currentCurrencySymbol}`}</span>
                  </td>
                  <td className="text-right py-2 px-6">{column1 ? formatNumber(column1?.absoluteRoi, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatNumber(column2?.absoluteRoi, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6">{column3 ? formatNumber(column3?.absoluteRoi, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatNumber(column4?.absoluteRoi, { maxDigits: 1 }) : undefined }</td>
                </tr>
                <tr className="border-b">
                  <td className="px-6">
                    {t('report.reportBase.ProfitOnCosts')} <span className="font-normal text-gray-800">in %</span>
                  </td>
                  <td className="text-right py-2 px-6">{column1 ? formatNumber(column1?.percentageRoi, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatNumber(column2?.percentageRoi, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6">{column3 ? formatNumber(column3?.percentageRoi, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatNumber(column4?.percentageRoi, { maxDigits: 1 }) : undefined }</td>
                </tr>
                <tr className="border-b">
                  <td className="px-6">
                    {t('report.reportBase.ProfitOnEquity')} <span className="font-normal text-gray-800">in %</span>
                  </td>
                  <td className="text-right py-2 px-6">{column1 ? formatNumber(column1?.percentageIrr, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatNumber(column2?.percentageIrr, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6">{column3 ? formatNumber(column3?.percentageIrr, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatNumber(column4?.percentageIrr, { maxDigits: 1 }) : undefined }</td>
                </tr>

                {/*<tr className="border-b">
                  <td className="px-6">
                    Vermarktungsquote <span className="font-normal text-gray-800">in % der Gesamterlöse</span>
                  </td>
                  <td className="text-right py-2 px-6">{formatNumber(34, { maxDigits: 1 })}</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{formatNumber(34, { maxDigits: 1 })}</td>
                  <td className="text-right py-2 px-6">{formatNumber(34, { maxDigits: 1 })}</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{formatNumber(34, { maxDigits: 1 })}</td>
                </tr>*/}
                <tr>
                  <td />
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                </tr>
                <tr>
                  <td className="text-3xl px-6">{t('report.reportBase.Milestones')}</td>
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                </tr>

                <tr className="border-b">
                  <td className="px-6">{t('report.reportBase.ProjectStart')}</td>
                  <td className="text-right py-2 px-6">{column1 ? formatDate(column1?.projectStart) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatDate(column2?.projectStart) : undefined }</td>
                  <td className="text-right py-2 px-6">{column3 ? formatDate(column3?.projectStart) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatDate(column4?.projectStart) : undefined }</td>
                </tr>

                <tr className="border-b">
                  <td className="px-6">{t('report.reportBase.ProjectDurationInMonths')}</td>
                  <td className="text-right py-2 px-6">{column1 ? formatNumber(column1?.projectDuration, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatNumber(column2?.projectDuration, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6">{column3 ? formatNumber(column3?.projectDuration, { maxDigits: 1 }) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatNumber(column4?.projectDuration, { maxDigits: 1 }) : undefined }</td>
                </tr>

                {milestones?.map((milestone, i) => (
                  <tr className="border-b" key={`report-base-milestone-${i}`}>
                    <td className="px-6">{milestone}</td>
                    <td className="text-right py-2 px-6">{column1 ? formatDate(column1?.milestones.find(x => x.title === milestone)?.date) : undefined }</td>
                    <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatDate(column2?.milestones.find(x => x.title === milestone)?.date) : undefined }</td>
                    <td className="text-right py-2 px-6">{column3 ? formatDate(column3?.milestones.find(x => x.title === milestone)?.date) : undefined }</td>
                    <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatDate(column4?.milestones.find(x => x.title === milestone)?.date) : undefined }</td>
                  </tr>
                ))}

                <tr className="border-b">
                  <td className="px-6">{t('report.reportBase.ProjectEnd')}</td>
                  <td className="text-right py-2 px-6">{column1 ? formatDate(column1?.projectEnd) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatDate(column2?.projectEnd) : undefined }</td>
                  <td className="text-right py-2 px-6">{column3 ? formatDate(column3?.projectEnd) : undefined }</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatDate(column4?.projectEnd) : undefined }</td>
                </tr>

                <tr>
                  <td />
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                  <td />
                  <td className="bg-slate-50">&nbsp;</td>
                </tr>
                <tr className="border-b">
                  <td className="px-6">
                    {t('report.reportBase.TotalRisk')} <span className="font-normal text-gray-800">{`${t('report.reportBase.inMillions')} ${currentCurrencySymbol}`}</span>
                  </td>
                  <td className="text-right py-2 px-6">{column1 ? formatNumber(column1?.totalRisks, { maxDigits: 1 }) : undefined}</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatNumber(column2?.totalRisks, { maxDigits: 1 }) : undefined}</td>
                  <td className="text-right py-2 px-6">{column3 ? formatNumber(column3?.totalRisks, { maxDigits: 1 }) : undefined}</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatNumber(column4?.totalRisks, { maxDigits: 1 }) : undefined}</td>
                </tr>
                <tr className="border-b">
                  <td className="px-6">
                    {t('report.reportBase.TotalEquity')} <span className="font-normal text-gray-800">{`${t('report.reportBase.inMillions')} ${currentCurrencySymbol}`}</span>
                  </td>
                  <td className="text-right py-2 px-6">{column1 ? formatNumber(column1?.totalEquityCapital, { maxDigits: 1 }) : undefined}</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column2 ? formatNumber(column2?.totalEquityCapital, { maxDigits: 1 }) : undefined}</td>
                  <td className="text-right py-2 px-6">{column3 ? formatNumber(column3?.totalEquityCapital, { maxDigits: 1 }) : undefined}</td>
                  <td className="text-right py-2 px-6 bg-slate-50">{column4 ? formatNumber(column4?.totalEquityCapital, { maxDigits: 1 }) : undefined}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex-shrink-0 hidden 2xl:block">
            <div
              className="aspect-video bg-cover bg-center rounded-none w-[552px] h-full bg-white transition-transform duration-300 transform group-hover:scale-105"
              style={{
                backgroundImage: `url(${thumbnail})`,
                clipPath: 'polygon(35% 0, 100% 0, 100% 100%, 0% 100%)'
              }}
            />
          </div>
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};
