import {
  CompanyBranchReadModel,
  useApiPostCreateCompanyBranchMutation,
  useApiPostUpdateCompanyBranchMutation,
  CompanyReadModel,
  useApiGetProjectsQuery,
  CreateCompanyBankAccountPayload,
  UpdateCompanyBankAccountPayload,
  CompanyPersonReadModel,
  useApiRemoveBranchFormCompanyPersonMutation,
  ShortProjectReadModel,
  useApiPostGenerateNextTenantObjectCodeMutation,
} from '@client/shared/api';
import {
  Button,
  CheckBox,
  Form,
  FormField,
  SlideOver,
  TextInput,
  Modal,
  AddButton,
  MuseumIcon,
  BankCardEuroIcon,
  OrganizationIcon,
  SlideOverTitle,
  BooleanInput,
  ChaseBankIcon,
  BankAccountIcon,
  ContactDetailsFormFields,
  AddressFormFields,
  SlideOverList,
  ContextMenuItem,
  PencilIcon,
  SlideOverListItemValueProps,
  UserIcon,
  TrashIcon,
  CreditDataFormFields,
  HintBox, LoadingIndicator,
  FormRefHandle,
  ContractNumberIcon,
} from '@client/shared/toolkit';
import { isValidSWIFT, isValidIBAN, safeMutation, isEmpty } from '@client/shared/utilities';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getSettingsProjectThumbnail } from '../../utils';
import classNames from 'classnames';
import { BranchDeleteModal } from './BranchDeleteModal';
import { ContactDetailsSlideOver } from './ContactDetailsSlideOver';

interface EditPartnerBranchSlideOverProps {
  isAddMode: boolean;
  branch?: CompanyBranchReadModel | undefined;
  company?: CompanyReadModel | undefined;
  onClose: () => void;
}

export const EditPartnerBranchSlideOver = ({
  isAddMode,
  branch,
  company,
  onClose,
}: EditPartnerBranchSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<CompanyBranchFormValidationValues>>();

  const { data, isFetching } = useApiGetProjectsQuery();
  const [postCreateCompany, { isLoading: isCreating }] = useApiPostCreateCompanyBranchMutation();
  const [postUpdateCompany, { isLoading: isUpdating }] = useApiPostUpdateCompanyBranchMutation();
  const [removeBranchFromPerson, { isLoading: isRemoving }] = useApiRemoveBranchFormCompanyPersonMutation();

  const [isProjectsModalOpen, setIsProjectsModalOpen] = useState<boolean>(false);
  const [showProjectThumbnails, setShowProjectThumbnails] = useState<boolean>(false);
  const [addedProjectAssignments, setAddedProjectAssignments] = useState<string[]>([]);
  const [deletedProjectAssignments, setDeletedProjectAssignments] = useState<string[]>([]);
  const [snapshotAddedProjectAssignments, setSnapshotAddedProjectAssignments] = useState<string[]>([]);
  const [snapshotDeletedProjectAssignments, setSnapshotDeletedProjectAssignments] = useState<string[]>([]);
  const [bankAccounts, setBankAccounts] = useState<
    CreateCompanyBankAccountPayload[] | UpdateCompanyBankAccountPayload[]
  >([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [showEditContact, setShowEditContact] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<CompanyPersonReadModel | undefined>();

  const projects = (data?.projects ?? []).slice();
  const groups = (data?.projectGroups ?? []).slice();

  useEffect(() => {
    if (branch?.bankAccounts) {
      setBankAccounts(branch.bankAccounts);
    }
  }, [branch]);

  const getAllProjects = () => {
    const projectsPayloads = projects.map((project) => project);
    const projectArray: ShortProjectReadModel[] = [...projectsPayloads];
    groups.forEach((group) => {
      group.projects.forEach((project) => {
        projectArray.push(project);
      });
    });
    return projectArray.sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'case'}));
  };

  const addProjectAssignment = (projectId: string) => {
    if (
      !addedProjectAssignments.includes(projectId) &&
      !branch?.projectAssignments.some((assignment) => assignment.project.projectId === projectId)
    ) {
      setAddedProjectAssignments([...addedProjectAssignments, projectId]);
    }
    if (deletedProjectAssignments.includes(projectId)) {
      setDeletedProjectAssignments(deletedProjectAssignments.filter((assignment) => assignment !== projectId));
    }
  };

  const deleteProjectAssignment = (projectId: string) => {
    if (addedProjectAssignments.includes(projectId)) {
      setAddedProjectAssignments(addedProjectAssignments.filter((assignment) => assignment !== projectId));
    } else if (branch?.projectAssignments.some((assignment) => assignment.project.projectId === projectId)) {
      setDeletedProjectAssignments([...deletedProjectAssignments, projectId]);
    }
  };

  const [getNextCode] = useApiPostGenerateNextTenantObjectCodeMutation();

  useEffect(() => {
    const getNextContractCode = async () => {
      const isGettingNextCode = false;
      const nextCodeResponse = await safeMutation(
        getNextCode,
        {
          body: { 
            ownerObjectId: company?.id ?? '',
            tenantObjectType: 'Company'
          }
        },
        isGettingNextCode,
      );
      if (typeof nextCodeResponse !== 'undefined') {
        formRef.current?.setValue('code', nextCodeResponse.code);
      }
    }

    if (isEmpty(branch) && !isEmpty(company) && isEmpty(formRef.current?.getValues().code)){
      getNextContractCode();
    }
  }, [getNextCode, branch, company])

  const CompanyBranchFormValidationSchema = yup.object({
    code: yup.string().required('validation.required'),
    name: yup.string().required('validation.required'),
    isMainBranch: yup.boolean().optional(),
    email: yup.string().optional().email('validation.email'),
    phone: yup.string().optional(),
    mobile: yup.string().optional(),
    link: yup.string().optional(),
    creditorId: yup.string().optional(),
    debitorId: yup.string().optional(),
    spvId: yup.string().optional(),
    street: yup.string().optional(),
    number: yup.string().optional(),
    supplement: yup.string().optional(),
    postalCode: yup.string().optional(),
    city: yup.string().optional(),
    country: yup.string().optional(),
  });

  type CompanyBranchFormValidationValues = InferType<typeof CompanyBranchFormValidationSchema>;

  const handleSubmit = async (data: CompanyBranchFormValidationValues) => {
    if (isAddMode) {
      try {
        await safeMutation(
          postCreateCompany,
          {
            companyId: company?.id ?? '',
            body: {
              isMainBranch: data.isMainBranch ?? false,
              code: data.code,
              name: data.name ?? '',
              email: data.email ?? '',
              phone: data.phone ?? '',
              mobile: data.mobile ?? '',
              link: data.link ?? '',
              creditorId: data.creditorId,
              debitorId: data.debitorId,
              spvId: data.spvId,
              address: {
                street: data.street ?? '',
                number: data.number ?? '',
                supplement: data.supplement ?? '',
                postalCode: data.postalCode ?? '',
                city: data.city ?? '',
                country: data.country ?? '',
              },
            },
          },
          isCreating,
        );
        onClose();
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await safeMutation(
          postUpdateCompany,
          {
            companyId: company?.id ?? '',
            companyBranchId: branch?.id ?? '',
            body: {
              isMainBranch: data.isMainBranch ?? false,
              code: data.code,              
              name: data.name ?? '',
              email: data.email ?? '',
              phone: data.phone ?? '',
              mobile: data.mobile ?? '',
              link: data.link ?? '',
              creditorId: data.creditorId,
              debitorId: data.debitorId,
              spvId: data.spvId,
              address: {
                street: data.street ?? '',
                number: data.number ?? '',
                supplement: data.supplement ?? '',
                postalCode: data.postalCode ?? '',
                city: data.city ?? '',
                country: data.country ?? '',
              },
              addedProjectAssignments: addedProjectAssignments,
              deletedProjectAssignments: deletedProjectAssignments,
              addedBankAccounts: bankAccounts.filter((bankAccount) => !('id' in bankAccount)),
              updatedBankAccounts: bankAccounts.filter(
                (bankAccount) => 'id' in bankAccount,
              ) as UpdateCompanyBankAccountPayload[],
              deletedBankAccounts:
                branch?.bankAccounts
                  .filter(
                    (bankAccount) => !bankAccounts.some((account) => 'id' in account && account.id === bankAccount.id),
                  )
                  .map((bankAccount) => bankAccount.id) ?? [],
            },
          },
          isUpdating,
        );
        onClose();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const removeBranchFromContact = useCallback(
    async (personId: string) => {
      if (company?.id && personId && branch?.id) {
        try {
          await safeMutation(
            removeBranchFromPerson,
            {
              companyId: company.id,
              companyPersonId: personId,
              body: {
                branchId: branch.id,
              },
            },
            isRemoving,
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    [company?.id, branch?.id, removeBranchFromPerson, isRemoving],
  );

  const emloyeesListItems: SlideOverListItemValueProps[] = useMemo(() => {
    if (!branch?.persons.length) return [];
    const persons = [...branch.persons].sort((a, b) => a.personName.localeCompare(b.personName, undefined, {sensitivity: 'case'}));
    return persons.map((person) => {
      const foundPerson = company?.persons.find((companyPerson) => companyPerson.id === person.personId);
      const contextMenuItems: ContextMenuItem[] = [
        {
          label: t('common.edit'),
          subtitle: t('app.companyContactEdit'),
          icon: <PencilIcon />,
          onClick: () => {
            setSelectedContact(foundPerson);
            setShowEditContact(true);
          },
        },
        {
          label: t('app.companyContactRemoveAssignedBranch'),
          subtitle: t('app.companyContactRemoveBranch'),
          icon: <TrashIcon />,
          onClick: () => {
            removeBranchFromContact(person.personId);
          },
        },
      ];
      return {
        icon: <UserIcon />,
        id: person.id,
        leftCenter: person.personName,
        leftBottom: person.personEmail ?? '-',
        onClick: () => {
          setSelectedContact(foundPerson);
          setShowEditContact(true);
        },
        contextMenuItems: contextMenuItems,
        borderColor: '',
      };
    });
  }, [branch?.persons, company?.persons, t, removeBranchFromContact]);

  const defaultValues = {
    isMainBranch: branch?.isMainBranch ?? false,
    code: branch?.code ?? '',
    name: branch?.name ?? '',
    email: branch?.email ?? '',
    phone: branch?.phone ?? '',
    mobile: branch?.mobile ?? '',
    link: branch?.link ?? '',
    creditorId: branch?.creditorId ?? '',
    debitorId: branch?.debitorId ?? '',
    spvId: branch?.spvId ?? '',
    street: branch?.address?.street ?? '',
    number: branch?.address?.number ?? '',
    supplement: branch?.address?.supplement ?? '',
    postalCode: branch?.address?.postalCode ?? '',
    city: branch?.address?.city ?? '',
    country: branch?.address?.country ?? '',
  };

  const deletedAssignmentsUsedInProject = useMemo(() => {
    return (
      branch?.projectAssignments.filter(
        (assignment) => deletedProjectAssignments.includes(assignment.project.projectId) && assignment.usedInProject,
      ) ?? []
    );
  }, [branch?.projectAssignments, deletedProjectAssignments]);

  return (
    <>
      {(isFetching || isCreating || isRemoving || isUpdating) && (
        <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} mode="overlay" />
      )}
      <Form<CompanyBranchFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={CompanyBranchFormValidationSchema}
        defaultValues={defaultValues}
        ref={formRef}
        className="flex flex-col flex-grow min-h-0"
      >
        <SlideOver.Header
          title={isAddMode ? t('app.masterDataCompaniesNewBranch') : branch?.name ?? ''}
          subTitle={isAddMode ? '' : t('app.masterDataBranch')}
          backgroundClassName="bg-slate-800"
          onClose={onClose}
          truncateTitle={false}
        />
        <SlideOver.Content className="p-8">
          <SlideOverTitle title={t('app.companiesContact')} marginTop={false} />
          <FormField name="code">
            {(control) => <TextInput icon={<ContractNumberIcon />} label={t('common.code')} {...control} />}
          </FormField>
          <FormField name="name">
            {(control) => <TextInput icon={<OrganizationIcon />} label={t('common.name')} {...control} />}
          </FormField>
          <FormField name="isMainBranch">
            {(control) => (
              <BooleanInput
                label={t('app.masterDataMainBranch')}
                className="mb-2"
                value={control.value}
                onChange={control.onChange}
              />
            )}
          </FormField>

          <CreditDataFormFields />
          <ContactDetailsFormFields fields={['email', 'phone', 'mobile']} />
          <AddressFormFields />

          {!isAddMode && (
            <>
              <div>
                <SlideOverTitle title={t('app.companiesBankAccounts')} />
                <div className="z-1">
                  {bankAccounts.map((bankAccount, index) => (
                    <div key={'bankAccount-' + index} className="mt-6">
                      <div className="flex justify-between items-center text-sm p-1">
                        <span className="text-gray-500"> {t('app.companiesBankAccount')}</span>
                        <div className="flex justify-end">
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700"
                            onClick={() => {
                              const newBankAccounts = [...bankAccounts];
                              newBankAccounts.splice(index, 1);
                              setBankAccounts(newBankAccounts);
                            }}
                          >
                            {t('common.delete')}
                          </button>
                        </div>
                      </div>
                      <TextInput
                        icon={<BankAccountIcon />}
                        label={t('app.companiesBankAccountOwner')}
                        value={bankAccount.accountOwner ?? ''}
                        onChange={(value) => {
                          setBankAccounts((prev) => {
                            const newBankAccounts = [...prev];
                            newBankAccounts[index] = {
                              ...newBankAccounts[index],
                              accountOwner: value,
                            };
                            return newBankAccounts;
                          });
                        }}
                      />
                      <TextInput
                        icon={<MuseumIcon />}
                        label={t('app.companiesBank')}
                        value={bankAccount.bankName ?? ''}
                        onChange={(value) => {
                          setBankAccounts((prev) => {
                            const newBankAccounts = [...prev];
                            newBankAccounts[index] = {
                              ...newBankAccounts[index],
                              bankName: value,
                            };
                            return newBankAccounts;
                          });
                        }}
                      />
                      <TextInput
                        icon={<ChaseBankIcon />}
                        label={t('app.companiesSwift')}
                        value={bankAccount.swift ?? ''}
                        showValidation={!!bankAccount.swift && !isValidSWIFT(bankAccount.swift)}
                        isValidationValid={isValidSWIFT(bankAccount.swift)}
                        helperText={
                          !!bankAccount.swift && !isValidSWIFT(bankAccount.swift)
                            ? t('app.companiesInvalidSwift')
                            : undefined
                        }
                        onChange={(value) => {
                          setBankAccounts((prev) => {
                            const newBankAccounts = [...prev];
                            newBankAccounts[index] = {
                              ...newBankAccounts[index],
                              swift: value,
                            };
                            return newBankAccounts;
                          });
                        }}
                      />

                      <TextInput
                        icon={<BankCardEuroIcon />}
                        label={t('app.companiesIban')}
                        value={bankAccount.iban ?? ''}
                        showValidation={!!bankAccount.iban && !isValidIBAN(bankAccount.iban)}
                        isValidationValid={isValidIBAN(bankAccount.iban)}
                        helperText={
                          !!bankAccount.iban && !isValidIBAN(bankAccount.iban)
                            ? t('app.companiesInvalidIban')
                            : undefined
                        }
                        onChange={(value) => {
                          setBankAccounts((prev) => {
                            const newBankAccounts = [...prev];
                            newBankAccounts[index] = {
                              ...newBankAccounts[index],
                              iban: value,
                            };
                            return newBankAccounts;
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex w-full justify-end items-center z-50">
                <AddButton
                  className="-mt-6"
                  onClick={() =>
                    setBankAccounts([...bankAccounts, { bankName: '', accountOwner: '', iban: '', swift: '' }])
                  }
                />
              </div>

              <SlideOverTitle title={t('app.masterDataEmployees')} />
              <SlideOverList
                className="gap-1 mt-0"
                items={emloyeesListItems}
                noItemsMessage={t('app.companiesNoEmployeesMessage')}
                rounded={false}
                showShadow={false}
                margin={false}
                onAdd={() => {
                  setShowEditContact(true);
                }}
              />

              <div>
                <SlideOverTitle title={t('app.masterDataAssignedProjects')} />
                {deletedAssignmentsUsedInProject.length > 0 && (
                  <HintBox hintType="warning">
                    {t('app.masterDataAssignedRemovedHint')}
                    <div className="mt-4">
                      {deletedAssignmentsUsedInProject.map((assignment, index) => (
                        <div className="font-bold mt-1" key={'deletedAssignment-' + index}>
                          - {assignment.project.name}
                        </div>
                      ))}
                    </div>
                  </HintBox>
                )}
                <div className="flex flex-col h-full pb-3 justify-center items-center">
                  {getAllProjects()
                    .filter(
                      (project) =>
                        (addedProjectAssignments.includes(project.id) ||
                          branch?.projectAssignments.some(
                            (assignment) => assignment.project.projectId === project.id,
                          )) &&
                        !deletedProjectAssignments.includes(project.id),
                    )
                    .map((project) => (
                      <div
                        className="relative z-1 w-full h-full my-2 py-6 flex flex-col justify-center items-center shadow-lg [text-shadow:_1_1px_0_rgb(0_0_0_/_90%)] text-white"
                        style={{
                          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(${getSettingsProjectThumbnail(
                            project.id,
                            projects,
                            groups,
                          )})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                        key={'project' + project.id}
                      >
                        <div
                          className="absolute top-0 right-0 p-2 cursor-pointer"
                          onClick={() => deleteProjectAssignment(project.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 hover:text-red-400 transition-colors duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                        <div className="font-bold">{project.name}</div>
                        <div className="text-xs font-bold">{project.city}</div>
                      </div>
                    ))}
                  <div className="flex w-full justify-end items-center z-50">
                    <AddButton
                      className="-mt-8"
                      onClick={() => {
                        setIsProjectsModalOpen(true);
                        setSnapshotAddedProjectAssignments(addedProjectAssignments);
                        setSnapshotDeletedProjectAssignments(deletedProjectAssignments);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </SlideOver.Content>

        <SlideOver.Controls>
          {!isAddMode && (
            <Button variant="danger" className="mr-2" onClick={() => setIsDeleteModalOpen(true)}>
              {t('common.delete')}
            </Button>
          )}
          <div className="w-full flex justify-end">
            <Button variant="secondary" className="mr-2" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button variant="primary" formSubmit={true} innerRef={submitRef}>
              {isAddMode ? t('common.create') : t('common.save')}
            </Button>
          </div>
        </SlideOver.Controls>
      </Form>
      <Modal
        isOpen={isProjectsModalOpen}
        onClose={() => {
          setIsProjectsModalOpen(false);
          setAddedProjectAssignments(snapshotAddedProjectAssignments);
          setDeletedProjectAssignments(snapshotDeletedProjectAssignments);
        }}
      >
        <Modal.Header title={t('app.masterDataAddProjectAssignments')} />
        <Modal.Content className="w-full h-full mb-2">
          <div className="w-full flex justify-center mb-2">
            <span
              className="mr-2 cursor-pointer hover:opacity-75 font-bold"
              onClick={() => setShowProjectThumbnails(!showProjectThumbnails)}
            >
              {showProjectThumbnails
                ? t('app.masterDataAssignedProjectsShowList')
                : t('app.masterDataAssignedProjectsShowThumbnails')}
            </span>
          </div>
          {showProjectThumbnails ? (
            <div className="w-full h-full pb-3 grid grid-cols-3 gap-3">
              {getAllProjects().map((project) => (
                <div
                  className={classNames(
                    'w-full h-full flex flex-col justify-center items-center shadow-lg [text-shadow:_1_1px_0_rgb(0_0_0_/_90%)] text-white hover:scale-95 transition-transform duration-200 cursor-pointer text-center',
                    {
                      'border-2 border-slate-500 opacity-80':
                        (addedProjectAssignments.includes(project.id) ||
                          branch?.projectAssignments.some(
                            (assignment) => assignment.project.projectId === project.id,
                          )) &&
                        !deletedProjectAssignments.includes(project.id),
                      'm-[2px]':
                        !(
                          addedProjectAssignments.includes(project.id) ||
                          !branch?.projectAssignments.some((assignment) => assignment.project.projectId === project.id)
                        ) && deletedProjectAssignments.includes(project.id),
                    },
                  )}
                  key={'addProject-' + project.id}
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(${getSettingsProjectThumbnail(
                      project.id,
                      projects,
                      groups,
                    )})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                  onClick={() =>
                    (addedProjectAssignments.includes(project.id) ||
                      branch?.projectAssignments.some((assignment) => assignment.project.projectId === project.id)) &&
                    !deletedProjectAssignments.includes(project.id)
                      ? deleteProjectAssignment(project.id)
                      : addProjectAssignment(project.id)
                  }
                >
                  <div className="font-bold">{project.name}</div>
                  <div className="text-xs font-bold">{project.city}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full h-full flex flex-col mb-2">
              {getAllProjects().map((project) => (
                <div
                  key={'addProject-' + project.id}
                  className="w-full flex justify-between items-center bg-white hover:bg-slate-50 my-1 p-2"
                >
                  <div
                    className={classNames('w-full h-full flex flex-col cursor-pointer')}
                    onClick={() =>
                      (addedProjectAssignments.includes(project.id) ||
                        branch?.projectAssignments.some((assignment) => assignment.project.projectId === project.id)) &&
                      !deletedProjectAssignments.includes(project.id)
                        ? deleteProjectAssignment(project.id)
                        : addProjectAssignment(project.id)
                    }
                  >
                    <div className="font-bold">{project.name}</div>
                    <div className="text-xs font-bold">{project.city}</div>
                  </div>

                  <CheckBox
                    checked={
                      (addedProjectAssignments.includes(project.id) ||
                        branch?.projectAssignments.some((assignment) => assignment.project.projectId === project.id)) &&
                      !deletedProjectAssignments.includes(project.id)
                    }
                    onChange={() =>
                      (addedProjectAssignments.includes(project.id) ||
                        branch?.projectAssignments.some((assignment) => assignment.project.projectId === project.id)) &&
                      !deletedProjectAssignments.includes(project.id)
                        ? deleteProjectAssignment(project.id)
                        : addProjectAssignment(project.id)
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </Modal.Content>
        <Modal.Controls className="bg-white w-full flex justify-between">
          <Button
            variant="danger"
            onClick={() => {
              setIsProjectsModalOpen(false);
              setAddedProjectAssignments(snapshotAddedProjectAssignments);
              setDeletedProjectAssignments(snapshotDeletedProjectAssignments);
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button variant="primary" onClick={() => setIsProjectsModalOpen(false)}>
            {t('common.adopt')}
          </Button>
        </Modal.Controls>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
      >
        <BranchDeleteModal
          branch={branch}
          outerClose={() => onClose()}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      </Modal>
      <SlideOver
        isOpen={showEditContact}
        onClose={() => setShowEditContact(false)}
        onAfterLeave={() => setSelectedContact(undefined)}
      >
        <ContactDetailsSlideOver
          isAdd={!selectedContact}
          company={company}
          contact={selectedContact}
          branch={branch}
          onClose={() => setShowEditContact(false)}
        />
      </SlideOver>
    </>
  );
};
