import { useLoadedProjectVariants } from '@client/project/store';
import { CalculationModelType } from '@client/shared/api';
import { SelectedCustomIcon, UnselectedCustomIcon } from '@client/shared/toolkit';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { versionTextFromType } from '@client/project/shared';
import { sortVersionStrings } from '@client/shared/utilities';

interface VariantDropDownData {
  id: string;
  name: string;
  type: CalculationModelType | null | undefined;
  version: string | null | undefined;
  versionRaw: string | null | undefined;
}

interface CalculationModelSelectProps {
  className?: string;
  selectedCalculationModelId?: string;
  onChange?: (variantId: string) => void;
}

export const CalculationModelSelect = ({
  className,
  selectedCalculationModelId,
  onChange,
}: CalculationModelSelectProps) => {
  const { t } = useTranslation();

  const { data } = useLoadedProjectVariants();

  const selectedCalculationModel = data?.find((x) => x.id === selectedCalculationModelId);

  const variants = useMemo(() => {
    return data
      .filter(
        (x) =>
          x.type === 'Version' || x.type === 'Variant' || x.type === 'ArchivedVariant' || x.type === 'ArchivedVersion'
      )
      .map<VariantDropDownData>((v) => {
        return {
          id: v.id,
          name: v.name,
          type: v.type,
          versionRaw: v.version,
          version: versionTextFromType(t, v.type, v.version),
        };
      })
      .sort((a, b) => sortVersionStrings(a.versionRaw ?? 'zzz', b.versionRaw ?? 'zzz'));
  }, [t, data]);

  const handleSelectVariant = (id: string) => {
    onChange?.(id);
  };

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  return (
    <Listbox
      as="div"
      className={classNames(
        'w-full h-12 flex items-center text-sm font-medium text-gray-600 bg-gray-400 bg-opacity-10 rounded-full hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        className
      )}
    >
      <div ref={setTargetElement} className="w-full px-6">
        <ListboxButton className="flex w-full">
          <div className="flex-grow truncate">
            <div className="text-left text-xs text-gray-600 -mb-1 font-normal">{t('project.variantSelectorLabel')}</div>
            <div className="text-left font-bold pr-3 text-[15px] truncate">
              {selectedCalculationModel != null
                ? selectedCalculationModel?.name
                : t('project.variantSelectorSelectVariant')}
            </div>
          </div>
          <ChevronDownIcon className="w-5 h-5 my-auto text-gray-600 flex-shrink-0" />
        </ListboxButton>
      </div>
      <ListboxOptions
        portal
        ref={setPopperElement}
        style={{ ...styles.popper }}
        {...attributes.popper}
        className="absolute z-50 right-0 mt-4 w-96 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div className="divide-y divide-gray-100">
          {variants.map((variant) => (
            <ListboxOption key={variant.id} value={variant.id}>
              {({ focus }) => (
                <div
                  onClick={() => handleSelectVariant(variant.id)}
                  className={classNames('text-gray-900 group flex rounded-none w-full py-3 px-4 text-sm', {
                    'bg-gray-100': focus,
                  })}
                >
                  <div className="grow text-left w-7/12 pr-3 my-auto">
                    <div className="text-[15px] font-bold">
                      {variant.name}
                      <span className="font-normal text-gray-600 text-[15px]">
                        {variant.version ? ` \u2022 ${variant.version}` : ''}
                      </span>{' '}
                    </div>
                  </div>
                  <div className="w-12 text-right my-auto">
                    {selectedCalculationModel?.id === variant.id ? (
                      <SelectedCustomIcon className="w-5 h-5 ml-auto" />
                    ) : (
                      <UnselectedCustomIcon className="w-5 h-5 ml-auto" />
                    )}
                  </div>
                </div>
              )}
            </ListboxOption>
          ))}
        </div>
      </ListboxOptions>
    </Listbox>
  );
};
