import { useDocumentTitle, TitleBar } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useProjects } from '@client/project/store';
import { settings, useUi } from '@client/shared/store';

export const MultiReportingRoute = () => {
  const { t } = useTranslation();

  useDocumentTitle({ title: t('reporting.routeTitle') });

  const { id } = useParams<{ id: string }>();
  const { groups } = useProjects();
  const group = groups.find((g) => g.id === id);

  const ui = useUi();
  const user = ui.appUser;

  return (
    <>
      <TitleBar
        title={group?.name ?? t('reporting.multiReportingTitle')}
        subTitle={t('reporting.multiReportingSubTitle')}
      />
      <div className="flex-grow flex flex-col overflow-y-auto p-8">
        {/* TODO Only for Axa presentation, remove later again */}
        {((user.tenant?.tenantId === '3239f945-4fce-4ebb-931f-89700f4c7631') || settings.mainDomain === 'probis.qa' || settings.mainDomain === 'probis.test' || settings.mainDomain === 'probis.dev') && (
          <img className="w-full h-auto" src="/assets/multireport-axa.svg" alt="Multi-Reporting" />
        )}
      </div>
    </>
  );
};
