import React, { Fragment, PropsWithChildren, ReactElement, ReactNode, useRef } from 'react';
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import classNames from 'classnames';
import {XMarkIcon} from '@heroicons/react/24/solid';
import { MaximizeIcon, MinimizeWindowIcon } from '../icons';

export interface ModalOnCloseProps {
    onClose: (confirm: boolean) => void;
}

interface ModalDialogProps extends ModalOnCloseProps {
  isOpen: boolean;
  className?: string;
  showCloseButton?: boolean;
  showMaximizeButton?: boolean;
  isMaximized?: boolean;
  onClickMaximizeButton?: () => void;
  variant?: 'default' | 'small' | 'medium' | 'large' | 'custom' | 'x-large' | 'full' | 'full-height' | 'max';
  position?: 'top' | 'bottom' | 'center';
  rounded?: boolean;
  defaultBg?: boolean;
  zIndex?: string;
  onAfterLeave?: () => void;
  initialFocus?: React.RefObject<HTMLElement>
  outerContent?: ReactNode | string
}

export const ModalDialog = ({
  children,
  className,
  isOpen,
  onClose,
  showCloseButton = true,
  showMaximizeButton = false,
  isMaximized = false,
  onClickMaximizeButton,
  variant = 'default',
  position = 'center',
  rounded = true,
  defaultBg = true,
  zIndex = 'z-50',
  onAfterLeave,
  initialFocus,
  outerContent
}: PropsWithChildren<ModalDialogProps>) => {
  const closeRef = useRef<HTMLDivElement>(null)
  return (
    <Transition show={isOpen} as={Fragment} unmount={true} appear={true}>
      <Dialog
        as="div"
        className={classNames('relative', zIndex)}
        onClose={() => onClose(false)}
        initialFocus={initialFocus ? initialFocus : (closeRef ?? undefined)}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div
          className={classNames('fixed z-10 overflow-y-auto', {
            'top-0 left-0 right-0': position === 'top',
            'bottom-0 left-0 right-0': position === 'bottom',
            'inset-0': position === 'center',
          })}
        >
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterLeave={onAfterLeave}
            >
              <DialogPanel className="relative">
                {outerContent}
                <div
                  className={classNames('relative text-left transform transition-all flex flex-col', className, {
                    'w-[740px] h-full': variant === 'full-height',
                    'w-[1024px] h-[720px]': variant === 'max',
                    'w-[740px] h-[560px]': variant === 'default',
                    'w-[1024px] h-[560px]': variant === 'large',
                    'w-[1280px] h-[560px]': variant === 'x-large',
                    'w-[1280px] h-[720px]': variant === 'full',
                    'w-[848px] h-[560px]': variant === 'medium',
                    'w-[320px] h-[420px]': variant === 'small',
                    'rounded-lg overflow-hidden': rounded,
                    'bg-gray-100 shadow-xl': defaultBg,
                  })}
                >
                  {showMaximizeButton && isMaximized && (
                    <div
                      className="absolute p-2 right-10 z-50 flex items-center justify-center"
                      onClick={onClickMaximizeButton}
                    >
                      <MinimizeWindowIcon className="w-5 h-5 text-gray-500 hover:text-gray-600 transition-color duration-200 cursor-pointer mt-1.5" />
                    </div>
                  )}
                  {showMaximizeButton && !isMaximized && (
                    <div
                      className="absolute p-2 right-10 z-50 flex items-center justify-center"
                      onClick={onClickMaximizeButton}
                    >
                      <MaximizeIcon className="w-5 h-5 text-gray-500 hover:text-gray-600 transition-color duration-200 cursor-pointer mt-1.5" />
                    </div>
                  )}

                  {showCloseButton && (
                    <div className="absolute p-2 right-0 z-50" onClick={() => onClose(false)} tabIndex={0} ref={closeRef}>
                      <XMarkIcon className="w-8 h-8 text-gray-500 hover:text-gray-600 transition-color duration-200 cursor-pointer" />
                    </div>
                  )}
                  <div className={classNames('flex-grow flex flex-col min-h-0', className)}>{children}</div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};

interface ModalDialogControlsProps extends PropsWithChildren {
    className?: string;
    hasPadding?: boolean
}

export const ModalDialogControls = ({children, className, hasPadding = true}: ModalDialogControlsProps) => (
    <div className={classNames('flex justify-end space-x-2 transition-colors duration-200 mt-auto', hasPadding? 'py-6 px-12' : '', className)}>
        {children}
    </div>
);

interface ModalDialogHeaderProps extends PropsWithChildren {
    className?: string;
    title?: string;
    description?: string;
    icon?: ReactNode
}

export const ModalDialogHeader = ({children, className, description, title, icon}: ModalDialogHeaderProps) => (
    <div className={classNames('pt-12 pb-6 text-center my-auto', className)}>
        {icon && (
          <div className="h-36 w-36 mb-8 mx-auto">
            {React.cloneElement(icon as ReactElement, { className: 'w-full h-full' })}
          </div>
        )}
        {title && (
            <DialogTitle as="h1" className="text-3xl font-bold text-gray-900 px-12">
              {title}
            </DialogTitle>
        )}
        {description && <Description className="pt-3 text-sm text-gray-500 px-12">{description}</Description>}
        {children}
    </div>
);

interface ModalDialogContentProps extends PropsWithChildren {
    className?: string;
    hasPadding?: boolean
}

export const ModalDialogContent = ({children, className, hasPadding = true}: ModalDialogContentProps) => (
    <div className={classNames('w-full flex flex-col flex-grow min-h-0 overflow-y-auto', { 'px-12': hasPadding }, className)}>
        {children}
    </div>
);

export const Modal = Object.assign(ModalDialog, {
    Header: ModalDialogHeader,
    Content: ModalDialogContent,
    Controls: ModalDialogControls,
});
