import { ElementUserDefinedFieldDefinitionReadModel, UserDefinedFieldDefinitionReadModel, UserDefinedFieldType } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';
import { SingleChoiceIcon, TextInputFormIcon } from '@client/shared/toolkit';

const iconComponents = {
  Text: TextInputFormIcon,
  Number: TextInputFormIcon,
  List: SingleChoiceIcon
};

export function getCustomFieldTypeIcon(type: UserDefinedFieldType) {
  const DynamicComponent = iconComponents[type];
  return DynamicComponent ?? ''
}

export function getCustomFieldTypeSubtitle(field: UserDefinedFieldDefinitionReadModel) {
  switch(field.fieldType) {
    case 'Text':
      return i18n.t('app.settingsCustomFieldFieldTypeTextField')
    case 'Number':
      return i18n.t('app.settingsCustomFieldFieldTypeNumberField')
    case 'List': {
      const result = field.listItems?.map(a => a.label);
      return `${i18n.t('app.settingsCustomFieldFieldTypeSelectField')}: ${result?.join(', ')}`
    }
    default:
      return '';
  }
}

export function  checkIfFieldIsNotValid(item: ElementUserDefinedFieldDefinitionReadModel) {
  let text = item.userDefinedField?.text?.trim() ? item.userDefinedField.text?.trim() : null
  let number = typeof (item.userDefinedField?.number) !== 'undefined' ? item.userDefinedField.number : null
  let listItemId = item.userDefinedField?.listSelectedItem ? item.userDefinedField?.listSelectedItem.listItemId : null

  // user has has not entered anything yet, so we check the default value, if there is one
  if (!item.userDefinedField) {
    text = item.defaultText ?? null
    number = typeof (item.defaultNumber) !== 'undefined' ? item.defaultNumber : null
    listItemId = item.defaultListItem?.listItemId ?? null
  }

  return item.isRequired && !text && (typeof (number) === 'undefined' || number === null) && !listItemId
}
