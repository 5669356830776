import { Button, LoadingIndicator, TitleBar, useDocumentTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { DashboardContainer } from '../components';
import { getProjectThumbnailByCoverId } from '@client/project/shared';
import { useParams } from 'react-router-dom';
import { useState, useEffect, ReactNode } from 'react';
import { ProjectReadModel, useApiGetProjectGroupQuery } from '@client/shared/api';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { setProjectId, useLoadedProjectId } from '@client/project/store';
import { useDispatch } from 'react-redux';

interface StackedCardsProps {
  children?: ReactNode;
  previous?: ProjectReadModel;
  next?: ProjectReadModel;
}

const StackedCards = (props: StackedCardsProps) => {
  return (
    <>
      {!!props.previous && (
        <div className="absolute top-8 left-2 h-full w-full p-6 pr-24">
          <div className="h-full w-full border-8 border-white bg-gray-100 shadow-lg">
            <div
              style={{ backgroundImage: `url(${getProjectThumbnailByCoverId(props.previous?.payload.id ?? '', props.previous?.payload.name ?? '', props.previous?.payload.coverPictureId, false)})` }}
              className="aspect-video bg-cover bg-center rounded-none w-full h-1/3 bg-white"
            />
          </div>
        </div>
      )}
      {!!props.next && (
        <div className="absolute top-8 right-4 h-full w-full p-6 pl-24">
          <div className="h-full w-full border-8 border-white bg-gray-100 shadow-lg">
            <div
              style={{ backgroundImage: `url(${getProjectThumbnailByCoverId(props.next?.payload.id ?? '', props.next?.payload.name ?? '', props.next?.payload.coverPictureId, false)})` }}
              className="aspect-video bg-cover bg-center rounded-none w-full h-1/3 bg-white"
            />
          </div>
        </div>
      )}
      <div className="absolute w-full pl-6 pr-24 pb-6">
        <div className="border-8 border-white bg-slate-100 shadow-lg pb-4">{props.children}</div>
      </div>
    </>
  );
};

export const MultiDashboardRoute = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [project, setProject] = useState<ProjectReadModel | undefined>(undefined);
  const loadedProjectId = useLoadedProjectId();

  const { id } = useParams<{ id: string }>();
  
  const { data : groupData, isFetching : isLoading } = useApiGetProjectGroupQuery(
    { groupId: id ?? '' },
    { skip: !id, },
  );

  useEffect(() => {
    if (!loadedProjectId && groupData?.projectGroup.projects && groupData?.projectGroup.projects.length > 0) {
      setProject(groupData?.projectGroup.projects[0]);
    }
  }, [groupData, loadedProjectId]);

  const variant = project?.calculationModels?.find((calculationModel) => calculationModel.type === 'Version');

  useEffect(() => {
    if (loadedProjectId !== project?.payload.id) {
      dispatch(
        setProjectId({
          projectId: project?.payload.id,
          variantId: variant?.id,
        })
      );
    }
  }, [dispatch, project, variant, loadedProjectId]);

  useDocumentTitle({ title: t('project.menuDashboard') });

  const onLeftArrowClick = () => {
    setProject(groupData?.projectGroup.projects[currentProjectIndex - 1]);
  };

  const onRightArrowClick = () => {
    setProject(groupData?.projectGroup.projects[currentProjectIndex + 1]);
  };

  const hasProjects = groupData ? groupData?.projectGroup.projects?.length > 0 : false;
  const currentProjectIndex = groupData?.projectGroup.projects.findIndex((p) => p.payload.id === project?.payload.id) ?? 0;
  const previousProject = groupData?.projectGroup.projects[currentProjectIndex - 1];
  const nextProject = groupData?.projectGroup.projects[currentProjectIndex + 1];

  return (
    <>
      {isLoading ?(
        <LoadingIndicator text="Loading projects..." mode="overlay"/>
      ) : (
        <>
          <TitleBar title={groupData?.projectGroup.name ?? t('projectCalculate.routeTitle')} subTitle={t('project.menuDashboard')} />
          {hasProjects && (
            <div className="flex-grow flex flex-col overflow-hidden">
              <div className="relative h-full w-full p-8 overflow-auto">
                <StackedCards previous={previousProject} next={nextProject}>
                  <DashboardContainer multiProject={true} />
                </StackedCards>

                <Button
                  variant="primary"
                  className="absolute top-1/2 left-4 aspect-square w-14 shadow-lg"
                  disabled={!previousProject}
                  onClick={onLeftArrowClick}
                >
                  <ChevronLeftIcon className="-mx-1 w-8 text-white" />
                </Button>
                <Button
                  variant="primary"
                  className="absolute top-1/2 right-6 aspect-square w-14 shadow-lg"
                  disabled={!nextProject}
                  onClick={onRightArrowClick}
                >
                  <ChevronRightIcon className=" w-8 text-white" />
                </Button>
              </div>
            </div>
          )}
        </>
      )}        
    </>
  );
};
