import {
  ApiDeleteCompanyApiArg,
  ApiDeleteCompanyApiResponse,
  ApiDeleteCompanyBranchApiArg,
  ApiDeleteCompanyBranchApiResponse,
  ApiDeleteCompanyPersonApiArg,
  ApiDeleteCompanyPersonApiResponse,
  ApiGetTenantCompanyApiArg,
  ApiGetTenantCompanyApiResponse,
  ApiPostCompanyImportDataApiArg,
  ApiPostCompanyImportDataApiResponse,
  ApiPostCreateCompanyBranchApiArg,
  ApiPostCreateCompanyBranchApiResponse,
  ApiPostCreateCompanyPersonApiArg,
  ApiPostCreateCompanyPersonApiResponse,
  ApiPostCreatePrivatePersonApiArg,
  ApiPostCreatePrivatePersonApiResponse,
  ApiPostQuickCreateCompanyApiArg,
  ApiPostQuickCreateCompanyApiResponse,
  ApiPostUpdateCompanyApiArg,
  ApiPostUpdateCompanyApiResponse,
  ApiPostUpdateCompanyBranchApiArg,
  ApiPostUpdateCompanyBranchApiResponse,
  ApiPostUpdateCompanyPersonApiArg,
  ApiPostUpdateCompanyPersonApiResponse,
  ApiPostUpdatePrivatePersonApiArg,
  ApiPostUpdatePrivatePersonApiResponse,
  ApiRemoveBranchFormCompanyPersonApiArg,
  ApiRemoveBranchFormCompanyPersonApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiCompanyEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetTenantCompanies: {
    providesTags: [{ type: ApiTagTypes.Company, id: 'LIST' }, ApiTagTypes.Companies],
  },
  apiGetTenantCompany: {
    providesTags: (
      result: ApiGetTenantCompanyApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetTenantCompanyApiArg,
    ) => {
      const tags = [{ type: ApiTagTypes.Company, id: args.companyId }];
      if (result?.branches?.length) {
        result.branches.forEach((branch) => {
          if (branch.projectAssignments.length) {
            branch.projectAssignments.forEach((pa) => {
              if (pa.tenantTrust?.trustId) {
                tags.push({
                  type: ApiTagTypes.CompanyTenantTrust,
                  id: pa.tenantTrust.trustId,
                });
              }
              tags.push({
                type: ApiTagTypes.CompanyProjectImage,
                id: pa.project.projectId
              });
            });
          }
        });
      }
      return tags;
    },
  },
  apiGetProjectCompanyBranches: {
    providesTags: [ApiTagTypes.CompanyBranches],
  },
  apiGetProjectCompanies: {
    providesTags: [ApiTagTypes.Companies],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateCompany: {
    invalidatesTags: (
      _result: ApiPostCreateCompanyBranchApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCreateCompanyBranchApiArg,
    ) => (error == null ? [ApiTagTypes.Companies] : []),
  },
  apiPostCreatePrivatePerson: {
    invalidatesTags: (
      _result: ApiPostCreatePrivatePersonApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCreatePrivatePersonApiArg,
    ) => (error == null ? [ApiTagTypes.Companies, ApiTagTypes.CompanyBranches] : []),
  },
  apiPostUpdateCompany: {
    invalidatesTags: (
      _result: ApiPostUpdateCompanyApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCompanyApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.Company,
              id: args.companyId,
            },
            ApiTagTypes.Companies,
            {
              type: ApiTagTypes.CompanyContract,
              id: args.companyId,
            },
            ApiTagTypes.Contracts,
            {
              type: ApiTagTypes.CompanyInvoice,
              id: args.companyId,
            },
          ]
        : [],
  },
  apiPostUpdatePrivatePerson: {
    invalidatesTags: (
      _result: ApiPostUpdatePrivatePersonApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdatePrivatePersonApiArg,
    ) =>
      error == null
        ? [
            {
              type: ApiTagTypes.Company,
              id: args.personId,
            },
            ApiTagTypes.Companies,
            {
              type: ApiTagTypes.CompanyContract,
              id: args.personId,
            },
            {
              type: ApiTagTypes.CompanyInvoice,
              id: args.personId,
            },
          ]
        : [],
  },
  apiDeleteCompany: {
    invalidatesTags: (
      _result: ApiDeleteCompanyApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCompanyApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Company, id: args.companyId }, ApiTagTypes.Companies] : []),
  },
  apiPostCreateCompanyBranch: {
    invalidatesTags: (
      _result: ApiPostCreateCompanyBranchApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCompanyBranchApiArg,
    ) =>
      error == null
        ? [{ type: ApiTagTypes.Company, id: args.companyId }, ApiTagTypes.Companies, ApiTagTypes.CompanyBranches]
        : [],
  },
  apiPostUpdateCompanyBranch: {
    invalidatesTags: (
      _result: ApiPostUpdateCompanyBranchApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCompanyBranchApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Company, id: args.companyId },
            ApiTagTypes.Companies,
            ApiTagTypes.CompanyBranches,
            { type: ApiTagTypes.CompanyContract, id: args.companyId },
            ApiTagTypes.Contracts,
            { type: ApiTagTypes.CompanyInvoice, id: args.companyId },
            ApiTagTypes.Invoices,
          ]
        : [],
  },
  apiDeleteCompanyBranch: {
    invalidatesTags: (
      _result: ApiDeleteCompanyBranchApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCompanyBranchApiArg,
    ) =>
      error == null
        ? [{ type: ApiTagTypes.Company, id: args.companyId }, ApiTagTypes.Companies, ApiTagTypes.CompanyBranches]
        : [],
  },
  apiPostCompanyImportData: {
    invalidatesTags: (
      _result: ApiPostCompanyImportDataApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostCompanyImportDataApiArg,
    ) =>
      error == null
        ? [
            ApiTagTypes.Companies,
            ApiTagTypes.CompanyBranches,
            ApiTagTypes.Contract,
            ApiTagTypes.Contracts,
            ApiTagTypes.Invoice,
            ApiTagTypes.Invoices,
          ]
        : [],
  },
  apiPostCreateCompanyPerson: {
    invalidatesTags: (
      _result: ApiPostCreateCompanyPersonApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCompanyPersonApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Company, id: 'LIST' },
            {
              type: ApiTagTypes.Company,
              id: args.companyId,
            },
            ApiTagTypes.Companies,
          ]
        : [],
  },
  apiPostUpdateCompanyPerson: {
    invalidatesTags: (
      _result: ApiPostUpdateCompanyPersonApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCompanyPersonApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Company, id: 'LIST' },
            {
              type: ApiTagTypes.Company,
              id: args.companyId,
            },
            ApiTagTypes.Companies,
          ]
        : [],
  },
  apiRemoveBranchFormCompanyPerson: {
    invalidatesTags: (
      _result: ApiRemoveBranchFormCompanyPersonApiResponse,
      error: FetchBaseQueryError,
      args: ApiRemoveBranchFormCompanyPersonApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Company, id: 'LIST' },
            {
              type: ApiTagTypes.Company,
              id: args.companyId,
            },
            ApiTagTypes.Companies,
          ]
        : [],
  },
  apiDeleteCompanyPerson: {
    invalidatesTags: (
      _result: ApiDeleteCompanyPersonApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCompanyPersonApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Company, id: 'LIST' },
            {
              type: ApiTagTypes.Company,
              id: args.companyId,
            },
            ApiTagTypes.Companies,
          ]
        : [],
  },
  apiPostQuickCreateCompany: {
    invalidatesTags: (
      _result: ApiPostQuickCreateCompanyApiResponse,
      error: FetchBaseQueryError,
      _args: ApiPostQuickCreateCompanyApiArg,
    ) =>
      error == null
        ? [{ type: ApiTagTypes.Company, id: 'LIST' }, ApiTagTypes.Companies, ApiTagTypes.CompanyBranches]
        : [],
  },
};
