import { Modal, Button, ModalOnCloseProps, BooleanInput, TaxiLocationIcon, BillIcon, TagsIcon, UsDollarCircledIcon, LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  ElementSettingReadModel,
  useApiPostUpdateCostElementExtensionSettingsMutation,
  UserDefinedFieldDefinitionReadModel
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId } from '@client/project/store';
import {
  SelectUserDefinedFieldsList
} from '@client/project/shared';

interface CostElementSettingModalProps extends ModalOnCloseProps {
  costElementId: string;
  calculationModelId: string;
  fieldsToShow: ElementSettingReadModel;
  onChange: (value: ElementSettingReadModel) => void;
  isReadOnly?: boolean;
  updateSelectedCustomFields: (fields: UserDefinedFieldDefinitionReadModel[]) => void
  customFields: UserDefinedFieldDefinitionReadModel[]
}

export const CostElementSettingModal = ({
  costElementId,
  calculationModelId,
  fieldsToShow,
  onClose,
  onChange,
  isReadOnly,
  updateSelectedCustomFields,
  customFields
}: CostElementSettingModalProps) => {
  const { t } = useTranslation();

  const projectId = useLoadedProjectId();

  const [updateSetting, { isLoading: isUpdating }] = useApiPostUpdateCostElementExtensionSettingsMutation();

  const [elements, setElements] = useState(fieldsToShow);
  const [selectedCustomFields, setSelectedCustomFields] = useState<UserDefinedFieldDefinitionReadModel[] | null>(null);

  const handleOnCancel = () => {
    onClose(false);
  };

  const handleClose = async () => {
    onChange(elements);

    if (selectedCustomFields?.length) {
      const visibleSelectedFields = [...selectedCustomFields]
      updateSelectedCustomFields(visibleSelectedFields)
    } else {
      updateSelectedCustomFields([])
    }
    await handleUpdate();
    onClose(true);
  };

  const handleUpdate = async () => {
    if (costElementId) {
      try {
        await safeMutation(
          updateSetting,
          {
            id: costElementId,
            calculationModelId: calculationModelId,
            projectId: projectId ?? 'unset',
            body: {
              hasStatus: elements.hasStatus,
              hasVat: elements.hasVat,
              hasConstructionPhase: elements.hasConstructionPhase,
              hasTags: elements.hasTags,
            },
          },
          isUpdating
        );
        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    setElements(fieldsToShow);
  }, [fieldsToShow]);

  useEffect(() => {
    setSelectedCustomFields(customFields)
  }, [customFields]);

  return (
    <>
      <Modal.Header title={t('extensionElements.title')} description={t('extensionElements.description')} />
      <Modal.Content className="pb-3 divide-y">
        {isUpdating && <LoadingIndicator text={t('projectCalculate.elementSettingsUpdating') ?? ''} mode="overlay" />}
        <BooleanInput
          icon={<UsDollarCircledIcon />}
          label={t('extensionElements.vatLabel')}
          name="VAT"
          subLabel={t('extensionElements.vatSubLabel')}
          value={elements.hasVat}
          onChange={() => setElements({ ...elements, hasVat: !elements.hasVat })}
        />

        <BooleanInput
          icon={<TagsIcon />}
          label={t('extensionElements.tagLabel')}
          name="#-Tags"
          subLabel={t('extensionElements.tagSubLabel')}
          value={elements.hasTags}
          onChange={() => setElements({ ...elements, hasTags: !elements.hasTags })}
        />

        <BooleanInput
          icon={<BillIcon />}
          label={t('extensionElements.statusLabel')}
          name="status"
          subLabel={t('extensionElements.statusSubLabel')}
          value={elements.hasStatus}
          onChange={() => setElements({ ...elements, hasStatus: !elements.hasStatus })}
        />

        <BooleanInput
          icon={<TaxiLocationIcon />}
          label={t('extensionElements.constructionPhaseLabel')}
          name="constructionPhase"
          subLabel={t('extensionElements.constructionPhaseSubLabel')}
          value={elements.hasConstructionPhase}
          onChange={() => setElements({ ...elements, hasConstructionPhase: !elements.hasConstructionPhase })}
        />

        <SelectUserDefinedFieldsList
          updateSelectedCustomFields={setSelectedCustomFields}
          selectedCustomFields={selectedCustomFields}
        />

      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={handleOnCancel} variant="secondary">
          {t('common.cancel')}
        </Button>
        {!isReadOnly && <Button onClick={handleClose}>{t('common.save')}</Button>}
      </Modal.Controls>
    </>
  );
};
