import { FormRefHandle, LoadingIndicator } from '@client/shared/toolkit';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AiEvalValuesReadModel,
  CompanyBranchReadModel,
  ShortContractReadModel,
  useApiGetProjectCompanyBranchesQuery,
} from '@client/shared/api';
import { useLoadedProjectId } from '@client/project/store';
import { InvoiceCreateFormValidationValues } from '../InvoiceCreateFormValidationValues';
import { InvoiceContractorAndClientEdit } from '../InvoiceTab';
import { useValidateProjectPermission } from '@client/shared/permissions';

export const findBranchByIbanOrName = (branches: CompanyBranchReadModel[], name?: string, iban?: string) => {
  return branches?.find((branch) => {
    if (name && branch.name.toLowerCase().split(' ').join('') === name.toLowerCase().split(' ').join('')) {
      return true;
    }
    if (branch.bankAccounts && iban) {
      const foundBankAccount = branch.bankAccounts.find((bankAccount) => {
        return bankAccount.iban?.toLowerCase().split(' ').join('') === iban.toLowerCase().split(' ').join('');
      });
      if (foundBankAccount) {
        return true;
      }
    }
    return false;
  });
};

interface InvoiceDocumentReviewContractorProps {
  fileData?: AiEvalValuesReadModel | null;
  formRef?: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>>;
  selectedContract?: ShortContractReadModel | null;
}

export const InvoiceDocumentReviewContractorAndClient = (props: InvoiceDocumentReviewContractorProps) => {
  const { fileData, formRef, selectedContract } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  const { data: branches, isFetching: isLoadingBranches } = useApiGetProjectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  // original mapped contractor from file data an branches list
  const [mappedContractor, setMappedContractor] = useState<CompanyBranchReadModel | null>(null);
  // original mapped client from file data an branches list
  const [mappedClient, setMappedClient] = useState<CompanyBranchReadModel | null>(null);

  const companiesOptions = useMemo(() => {
    return (
      branches?.map((branch) => {
        return {
          label: branch.name,
          value: branch.id,
        };
      }) ?? []
    );
  }, [branches]);

  useEffect(() => {
    if (companiesOptions.length && fileData) {
      const currentValues = formRef?.current?.getValues();
      if (!currentValues?.invoicingPartyId && fileData?.contractorName && branches) {
        // search for the name or iban in branch
        const foundBranch = findBranchByIbanOrName(
          branches,
          fileData?.contractorName,
          fileData?.contractorBankDetails?.iban,
        );

        if (foundBranch) {
          setMappedContractor(foundBranch);
          if (!selectedContract) {
            const foundCompany = companiesOptions.find((option) => option.value === foundBranch.id);
            if (foundCompany) {
              formRef?.current?.setValue('invoicingPartyId', foundCompany.value);
            }
          }
        }
      }
      if (!currentValues?.invoiceRecipientId && fileData?.customerName && branches) {
        const foundBranch = findBranchByIbanOrName(branches, fileData?.customerName);
        if (foundBranch) {
          setMappedClient(foundBranch);
          if (!selectedContract) {
            const foundCompany = companiesOptions.find((option) => option.value === foundBranch.id);
            if (foundCompany) {
              formRef?.current?.setValue('invoiceRecipientId', foundCompany.value);
            }
          }
        }
      }
    }
  }, [formRef, companiesOptions, fileData, branches, selectedContract]);

  return isLoadingBranches ? (
    <LoadingIndicator text={t('common.loading')} />
  ) : (
    <InvoiceContractorAndClientEdit
      formRef={formRef}
      labelContractor={t('projectControl.auditInvoiceContractor')}
      labelClient={t('projectControl.auditInvoiceCustomer')}
      originalContractor={mappedContractor}
      originalClient={mappedClient}
      branches={branches}
      selectedContract={selectedContract}
      canEdit={canWrite}
      invoiceDocumentFileData={fileData}
    />
  );
};
