import { DealType } from '@client/shared/api';
import { DealModule } from '../components';
import { TitleBar, useDocumentTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useLoadedProject } from '@client/project/store';

interface DealRouteProps {
  type: DealType;
}

export const DealRoute = ({ type }: DealRouteProps) => {
  const { t } = useTranslation();

  const documentTitle = type === 'Rent' ? t('projectRent.rentalRouteTitle') : t('projectRent.sellRouteTitle');
  const documentSubTitle =
    type === 'Rent' ? t('projectRent.rentalRouteSubTitle') : t('projectRent.sellRouteSubTitle');

  const { data: loadedProject } = useLoadedProject();

  useDocumentTitle({ title: documentTitle });

  return (
    <>
      <TitleBar title={loadedProject?.project?.payload?.name ?? documentTitle} subTitle={documentSubTitle} />
      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="p-8 flex flex-col flex-grow overflow-y-auto">
          <DealModule type={type} />
        </div>
      </div>
    </>
  );
};
