import { CostIcon, HintBox, NumberInput, TrashIcon } from "@client/shared/toolkit";
import { formatPercentage } from "@client/shared/utilities";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface BudgetAssignmentInputProps {
  name: string;
  code: string;
  budgetSum: number;
  budgetRest: number,
  value: number;
  hasFormula: boolean;
  disabled: boolean;
  onChange: (value: number) => void;
  showValidation?: boolean;
  onDelete: () => void;
  active?: boolean;
}

export const BudgetAssignmentInput = ({
  name,
  code,
  budgetSum,
  budgetRest,
  value,
  hasFormula,
  disabled,
  onChange,
  showValidation,
  onDelete,
  active = false,
}: BudgetAssignmentInputProps) => {
  
  
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [budgetValue, setBudgetValue] = useState<number>(value);

  const budgetPercentage = budgetSum !== 0
    ? budgetValue / budgetSum
    : 0;
  const valueTooLongForInput = value.toString().length > 6 || budgetPercentage.toString().length > 6;

  useEffect(() => {
    if (active && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      const input = ref.current.querySelector('input');
      if (input !== null) {
        setTimeout(() => {
          input.focus();
        }, 0);
      }
    }
  }, [active]);

  return (
    <div ref={ref}>
      <div
        className={classNames(
          'flex justify-between px-3 divide-x-2 relative before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10',
          {
            'h-14': !valueTooLongForInput,
            'h-20': valueTooLongForInput,
            'before:opacity-1': active,
            'before:opacity-0': !active,
          },
        )}
      >
        <div className="w-1/2 flex items-center truncate text-ellipsis gap-3 pr-2">
          <div className="h-5 w-5 flex items-center justify-center">
            <CostIcon className="w-5" />
          </div>

          <div className="w-5/6 h-full flex flex-col justify-center">
            <div className="text-lg font-bold truncate text-ellipsis">{name}</div>
            <div className="text-xs text-gray-500 leading-none">
              {t('projectContract.costGroupShort')} {code}
            </div>
          </div>
        </div>

        <div className="w-1/2 pl-2 flex items-end truncate text-ellipsis text-xs text-slate-600">
          <div
            className={classNames('w-full flex justify-end', {
              'flex-col': valueTooLongForInput,
              'flex-row items-end': !valueTooLongForInput,
            })}
          >
            <div
              className={classNames('h-full flex flex-none items-end z-50', {
                'pb-3 justify-center w-[50px]': !valueTooLongForInput,
                'pr-3 justify-end': valueTooLongForInput,
              })}
            >
              {formatPercentage(budgetPercentage)}
            </div>
            <div className="h-full flex items-center justify-start">
              <NumberInput
                label={t('projectContract.budget')}
                value={budgetValue}
                onChange={(value) => {
                  setBudgetValue(value ?? 0)
                }}
                onBlur={() => {
                  setBudgetValue(value ?? 0)
                  onChange(budgetValue)
                }}
                textRight={true}
                isValidationValid={budgetRest >= 0}
                showValidation={showValidation}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="h-full flex justify-center items-center hover:opacity-70 cursor-pointer">
            {!disabled && <TrashIcon className="w-4" onClick={() => onDelete()} />}
          </div>
        </div>
      </div>
      {hasFormula && (
        <div className="p-2 ">
          <HintBox hintType="warning">{t('projectContract.formulaWarning')}</HintBox>
        </div>
      )}
    </div>
  );
};