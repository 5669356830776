import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toggleExpandedIds } from './utils';

export interface IdListState {
  Taxonomy: {
    project: string;
    ids: string[];
  }[];
  Calculate: {
    Cost: string[];
    Risk: string[];
    Earning: string[];
    Financing: string[];
  };
  Company: string[] | null;
  Reporting: {
    Budget: string[];
    Comparison: string[];
    Forecast: string[];
    Detail: string[];
    CostBreakdown: string[];
  }
}

const initialState: IdListState = {
  Taxonomy: [],
  Calculate: {
    Cost: [],
    Risk: [],
    Earning: [],
    Financing: [],
  },
  Company: null,
  Reporting: {
    Budget: [],
    Comparison: [],
    Forecast: [],
    Detail: [],
    CostBreakdown: [],
  }
};

export const idListSlice = createSlice({
  name: 'idList',
  initialState,
  reducers: {
    setExpandedTaxonomyIds: (state, action: PayloadAction<IdListState['Taxonomy']>) => {
      state.Taxonomy = action.payload
      toggleExpandedIds(action.payload, 'Taxonomy')
    },
    setExpandedCostCatalogIds: (state, action: PayloadAction<string[]>) => {
      state.Calculate.Cost = action.payload
      toggleExpandedIds(action.payload, 'Cost')
    },
    setExpandedRiskCatalogIds: (state, action: PayloadAction<string[]>) => {
      state.Calculate.Risk = action.payload
      toggleExpandedIds(action.payload, 'Risk')
    },
    setExpandedEarningCatalogIds: (state, action: PayloadAction<string[]>) => {
      state.Calculate.Earning = action.payload
      toggleExpandedIds(action.payload, 'Earning')
    },
    setExpandedFinancingCatalogIds: (state, action: PayloadAction<string[]>) => {
      state.Calculate.Financing = action.payload
      toggleExpandedIds(action.payload, 'Financing')
    },
    setExpandedCompanyIds: (state, action: PayloadAction<IdListState['Company']>) => {
      state.Company = action.payload
      toggleExpandedIds(action.payload, 'Company')
    },
    setExpandedDetailReportIds: (state, action: PayloadAction<string[]>) => {
      state.Reporting.Detail = action.payload
      toggleExpandedIds(action.payload, 'Detail')
    },
    setExpandedBudgetReportIds: (state, action: PayloadAction<string[]>) => {
      state.Reporting.Budget = action.payload
      toggleExpandedIds(action.payload, 'Budget')
    },
    setExpandedComparisonReportIds: (state, action: PayloadAction<string[]>) => {
      state.Reporting.Comparison = action.payload
      toggleExpandedIds(action.payload, 'Comparison')
    },
    setExpandedForecastReportIds: (state, action: PayloadAction<string[]>) => {
      state.Reporting.Forecast = action.payload
      toggleExpandedIds(action.payload, 'Forecast')
    },
    setExpandedCostBreakdownReportIds: (state, action: PayloadAction<string[]>) => {
      state.Reporting.CostBreakdown = action.payload
      toggleExpandedIds(action.payload, 'CostBreakdown')
    }
  },
});

export const {
  setExpandedTaxonomyIds,
  setExpandedCostCatalogIds,
  setExpandedRiskCatalogIds,
  setExpandedEarningCatalogIds,
  setExpandedFinancingCatalogIds,
  setExpandedCompanyIds,
  setExpandedDetailReportIds,
  setExpandedBudgetReportIds,
  setExpandedComparisonReportIds,
  setExpandedForecastReportIds,
  setExpandedCostBreakdownReportIds,
} = idListSlice.actions;
