import { fork, put } from 'redux-saga/effects';
import { setExpandedBudgetReportIds, setExpandedComparisonReportIds, setExpandedCostBreakdownReportIds, setExpandedDetailReportIds, setExpandedForecastReportIds, TOGGLED_EXPANDED_IDS } from '@client/project/store';

function* loadReportingExpandedIds() {
  const serializedExpandedIds = localStorage.getItem(TOGGLED_EXPANDED_IDS);
  if (serializedExpandedIds) {
    const preselectedExpandedIds = JSON.parse(serializedExpandedIds)
    if (preselectedExpandedIds.Reporting && typeof preselectedExpandedIds.Reporting !== 'undefined') {
      if (preselectedExpandedIds.Reporting.Detail) {
        yield put(setExpandedDetailReportIds(preselectedExpandedIds.Reporting.Detail))
      }
      if (preselectedExpandedIds.Reporting.Budget) {
        yield put(setExpandedBudgetReportIds(preselectedExpandedIds.Reporting.Budget))
      }
      if (preselectedExpandedIds.Reporting.Comparison) {
        yield put(setExpandedComparisonReportIds(preselectedExpandedIds.Reporting.Comparison))
      }
      if (preselectedExpandedIds.Reporting.Forecast) {
        yield put(setExpandedForecastReportIds(preselectedExpandedIds.Reporting.Forecast))
      }
      if (preselectedExpandedIds.Reporting.CostBreakdown) {
        yield put(setExpandedCostBreakdownReportIds(preselectedExpandedIds.Reporting.CostBreakdown))
      }
    }
  }
}

export const reportingSaga = function* () {
  yield fork(loadReportingExpandedIds);
};
