import { useTranslation } from 'react-i18next';
import { Button, LoadingIndicator, Modal } from '@client/shared/toolkit';
import React, { useMemo, useState } from 'react';
import { BudgetAssignment } from './BudgetAssignment';
import {
  CommitmentReadModel,
  ContractReadModel,
  CreateBudgetAssignmentPayload,
  UpdateBudgetAssignmentPayload,
  useApiPostUpdateCommitmentMutation,
  useApiPostUpdateContractMutation,
} from '@client/shared/api';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';
import { timelineReadModelToPayload } from '../../utils';

interface BudgetModalProps {
  setIsOpen: (open: boolean) => void;
  contract?: ContractReadModel | null;
  commitment?: CommitmentReadModel | null;
  selectedBudget?: string;
}
export const BudgetModal = (props: BudgetModalProps) => {
  const { setIsOpen, contract, commitment, selectedBudget } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '');

  const [postUpdateContract, { isLoading }] = useApiPostUpdateContractMutation();
  const [postUpdateCommitment, { isLoading: isUpdating }] = useApiPostUpdateCommitmentMutation();

  const [addedBudgetAssignments, setAddedBudgetAssignments] = useState<CreateBudgetAssignmentPayload[]>([]);
  const [updatedBudgetAssignments, setUpdatedBudgetAssignments] = useState<UpdateBudgetAssignmentPayload[]>([]);
  const [deletedBudgetAssignments, setDeletedBudgetAssignments] = useState<string[]>([]);

  const [validBudget, setValidBudget] = useState<boolean>(true);

  const [isBudgetAssignmentPopoverOpen, setIsBudgetAssignmentPopoverOpen] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (loadedVariantId && loadedProjectId && validBudget) {
      if (contract) {
        const singleInvoiceCalculationScheme = contract.invoiceCalculationSchemes.find(
          (scheme) => scheme.invoiceCalculationScheme.type === 'Single',
        );
        const cumulatedInvoiceCalculationScheme = contract.invoiceCalculationSchemes.find(
          (scheme) => scheme.invoiceCalculationScheme.type === 'Cumulated',
        );
        const advancePaymentInvoiceCalculationScheme = contract.invoiceCalculationSchemes.find(
          (scheme) => scheme.invoiceCalculationScheme.type === 'AdvancePayment',
        );

        try {
          await safeMutation(
            postUpdateContract,
            {
              contractId: contract.id,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                code: contract.code,
                name: contract.name,
                description: contract.description,
                vat: contract.vat,
                discount: contract.discount,
                dueDateDeadline: contract.dueDateDeadline,
                cashDiscountDeadline: contract.cashDiscountDeadline,
                contractorId: contract.contractor?.id,
                clientId: contract.client?.id,
                commitmentId: contract.commitmentId,
                elementTimeline: contract?.elementTimeline
                  ? timelineReadModelToPayload(contract.elementTimeline)
                  : null,
                addedBudgetAssignments: addedBudgetAssignments,
                updatedBudgetAssignments: updatedBudgetAssignments,
                deletedBudgetAssignments: deletedBudgetAssignments,
                cumulatedInvoiceCalculationScheme: cumulatedInvoiceCalculationScheme
                  ? {
                      invoiceCalculationSchemeId:
                        cumulatedInvoiceCalculationScheme.invoiceCalculationScheme.invoiceCalculationSchemeId,
                      valueOverrides: [],
                    }
                  : null,
                singleInvoiceCalculationScheme: singleInvoiceCalculationScheme
                  ? {
                      invoiceCalculationSchemeId:
                        singleInvoiceCalculationScheme.invoiceCalculationScheme.invoiceCalculationSchemeId,
                      valueOverrides: [],
                    }
                  : null,
                advancePaymentInvoiceCalculationScheme: advancePaymentInvoiceCalculationScheme
                  ? {
                      invoiceCalculationSchemeId:
                        advancePaymentInvoiceCalculationScheme.invoiceCalculationScheme.invoiceCalculationSchemeId,
                      valueOverrides: [],
                    }
                  : null,
              },
            },
            isLoading,
          );
          setIsOpen(false);
        } catch (e) {
          console.error(e);
        }
      }
      if (commitment) {
        try {
          await safeMutation(
            postUpdateCommitment,
            {
              commitmentId: commitment.id,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                code: commitment.code,
                name: commitment.name,
                description: commitment.description,
                addedContracts: [],
                deletedContracts: [],
                addedBudgetAssignments: addedBudgetAssignments,
                updatedBudgetAssignments: updatedBudgetAssignments,
                deletedBudgetAssignments: deletedBudgetAssignments,
              },
            },
            isUpdating,
          );
          setIsOpen(false);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const commitmentHasBudgeting = useMemo(() => {
    if (contract?.commitmentId && !contract.canBeBudgeted && !contract.budgetAssignments.length) {
      if (contract.titles.length) {
        const foundTitlesWithBudget = contract.titles.find((title) => title.budgetAssignments.length > 0);
        if (foundTitlesWithBudget) {
          return false;
        }
        // contract does not have assignments and titles don't have assignments, so the commitment does
        return true;
      }
    }
    return false;
  }, [contract]);

  return (
    <>
      <Modal.Header title={t('projectContract.budgeting')} description={t('projectContract.budgetingDescription')} />
      <Modal.Content>
        {(isLoading || isUpdating) && (
          <LoadingIndicator text={t('projectContract.budgetingSaving') ?? ''} mode="overlay" />
        )}
        <BudgetAssignment
          showTitle={false}
          budgetAssignments={contract ? contract.budgetAssignments : commitment?.budgetAssignments ?? []}
          id={contract?.id ?? commitment?.id ?? ''}
          disabled={!canWrite || (!!contract && !contract.canBeBudgeted) || (!!commitment && !commitment.canBeBudgeted)}
          canBeBudgeted={contract?.canBeBudgeted || commitment?.canBeBudgeted}
          updateBudgetAssignments={({ added, updated, deleted }) => {
            setAddedBudgetAssignments(added);
            setUpdatedBudgetAssignments(updated);
            setDeletedBudgetAssignments(deleted);
          }}
          validBudget={(valid) => setValidBudget(valid)}
          setIsBudgetAssignmentPopoverOpen={(open) => setIsBudgetAssignmentPopoverOpen(open)}
          cannotBeBudgetedMessage={
            commitmentHasBudgeting
              ? t('projectContract.contractBudgetedByCommitmentMessage')
              : t('projectContract.contractCannotBeBudgetedMessage')
          }
          selectedBudget={selectedBudget}
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => setIsOpen(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        {canWrite && (
          <Button variant="primary" onClick={isBudgetAssignmentPopoverOpen ? undefined : () => handleSubmit()}>
            {t('common.save')}
          </Button>
        )}
      </Modal.Controls>
    </>
  );
};
