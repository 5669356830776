import {
  ComboSelect,
  ComboSelectOption,
  ContractIcon,
  DocumentPositionBoundingBoxReadModel,
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  Form,
  FormField,
  FormWatch,
  FormRefHandle,
  InvoiceDocumentPositionReadModel,
  LoadingIndicator,
  ComboSelectAdditionalOption, Modal,
} from '@client/shared/toolkit';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AiEvalResultReadModel,
  AiEvalValuesReadModel,
  InvoiceState,
  InvoiceType,
  ProbisErrorDataType,
  ShortContractReadModel,
  useApiGetContractsQuery,
  useApiPostCreateInvoiceByContractMutation,
  useApiPostCreateInvoiceMutation, useApiPostGenerateNextProjectObjectCodeMutation,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import { addDays} from 'date-fns';
import {
  InvoiceCreateFormValidationSchema,
  InvoiceCreateFormValidationValues,
} from '../InvoiceCreateFormValidationValues';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import cn from 'classnames';
import { InvoiceDocumentReviewContractorAndClient } from './InvoiceDocumentReviewContractorAndClient';
import { InvoiceDocumentReviewValues } from './InvoiceDocumentReviewValues';
import { ContractNewWizard } from '../../Contract';
import { InvoiceDataEdit } from '../InvoiceTab';

interface InvoiceDocumentFileDataProps {
  invoiceDocument?: AiEvalResultReadModel;
  fileData?: AiEvalValuesReadModel | null;
  allBoundingBoxes?: {
    boxes: number[][][];
    pages: number[];
    texts: string[][];
  } | null;
  hoveredBox: number | null;
  showAllBoxes: boolean;
  boxes?: (DocumentPositionBoundingBoxReadModel | null)[];
  setShowAllBoxes: () => void;
  setHoveredBox: (index: number | null) => void;
  setIsFormValid?: (isValid: boolean) => void;
  onClose: () => void
}

export type InvoiceDocumentFileDataRef = {
  createInvoice: () => void;
};

export const InvoiceDocumentFileData = forwardRef<InvoiceDocumentFileDataRef, InvoiceDocumentFileDataProps>((props, ref) => {
  const {
    invoiceDocument,
    fileData,
    hoveredBox,
    boxes,
    setHoveredBox,
    setIsFormValid,
    onClose
  } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  const [createInvoice, { isLoading: isCreating }] = useApiPostCreateInvoiceMutation();
  const [createInvoiceByContract, { isLoading: isCreatingByContract }] = useApiPostCreateInvoiceByContractMutation();
  const [createdContract, setCreatedContract] = useState<string | undefined>(undefined)
  const [getNextCode, { isLoading: isGettingCode }] = useApiPostGenerateNextProjectObjectCodeMutation();

  const { data: fetchedContracts, isFetching: isFetchingContracts } = useApiGetContractsQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  const formRef = useRef<FormRefHandle<InvoiceCreateFormValidationValues>>(null);

  useImperativeHandle(ref, () => ({
    createInvoice: () => {
      if (formRef.current) {
        formRef.current?.validateForm()
        const { isValid } = formRef.current.getState()
        if (setIsFormValid) {
          setIsFormValid(isValid)
        }
        formRef?.current?.submitForm()
      }
    }
  }));

  const [contractOptions, setContractOptions] = useState<ComboSelectOption[]>([]);
  const [codeError, setCodeError] = useState(false);
  const [loadedContracts, setLoadedContracts] = useState<ShortContractReadModel[]>([]);
  const [selectedContract, setSelectedContract] = useState<ShortContractReadModel | null>(null);
  const [allowChangeMode, setAllowChangeMode] = useState(true);
  const [isCreateContractWizardOpen, setIsCreateContractWizardOpen] = useState(false);

  useEffect(() => {
    const getNextInvoiceCode = async () => {
      if (loadedProjectId && loadedVariantId && !formRef.current?.getValues().code) {
        const isGettingNextCode = false;
        const nextCodeResponse = await safeMutation(
          getNextCode,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: { projectObjectType: 'Invoice' }
          },
          isGettingNextCode,
        );
        if (typeof nextCodeResponse !== 'undefined') {
          formRef.current?.setValue('code', nextCodeResponse.code);
        }
      }
    }

    if (formRef.current && !formRef.current.getValues().code && !isGettingCode && invoiceDocument) {
      getNextInvoiceCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDocument])

  const defaultFormValues = useMemo(() => {

    let dateOfReceipt = '';
    if (invoiceDocument?.document.created) {
      dateOfReceipt = formatDateOnly(new Date(invoiceDocument.document.created));
    }

    const contractId = invoiceDocument?.document?.uploadedByContract?.id ?? invoiceDocument?.result?.contractId ?? null;

    return {
      invoicingPartyId: null,
      invoiceRecipientId: null,
      contractId: contractId,
      code: '',
      externalCode: invoiceDocument?.result?.invoiceDetails.number ?? '',
      state: 'Pending' as InvoiceState,
      type: invoiceDocument?.result?.invoiceDetails.type ?? 'Single' as InvoiceType,
      dateOfReceipt: dateOfReceipt,
      invoiceDate: invoiceDocument?.result?.invoiceDetails.invoiceDate ?? null,
      dateOfAudit: null,
      dateOfApproval: null,
      paymentDate: null,
      vat: invoiceDocument?.result?.invoiceDetails.vat ?? 0,
      net: invoiceDocument?.result?.invoiceDetails.net ?? 0,
      claim: invoiceDocument?.result?.invoiceDetails.gross ?? 0,
      dueDate: invoiceDocument?.result?.invoiceDetails.dueDate ?? null,
      cashDiscountDate: invoiceDocument?.result?.invoiceDetails.cashDiscountDate ?? null,
    };
  }, [invoiceDocument]);

  useEffect(() => {
    if (typeof fetchedContracts !== 'undefined') {
      let allContracts: ShortContractReadModel[] = fetchedContracts.contracts ?? [];
      let contractOptions =
        fetchedContracts.contracts?.map((contract) => {
          return {
            value: contract.id,
            label: `${contract.code} - ${contract.name}`,
          };
        }) ?? [];

      fetchedContracts.commitments?.forEach((commitment) => {
        allContracts = [...allContracts, ...(commitment.contracts ?? [])];
        contractOptions = contractOptions.concat(
          commitment.contracts?.map((contract) => {
            return {
              value: contract.id,
              label: `${contract.code} - ${contract.name}`,
            };
          }) ?? [],
        );
      });
      setLoadedContracts(allContracts);
      setContractOptions(contractOptions);
    }
  }, [fetchedContracts]);

  useEffect(() => {
    if (createdContract && loadedContracts.length && formRef.current) {
      const foundContract = loadedContracts.find((contract) => contract.id === createdContract);
      if (foundContract) {
        setSelectedContract(foundContract ?? null)
        formRef.current.setValue('invoicingPartyId', null)
        formRef.current.setValue('invoiceRecipientId', null)
        formRef.current.setValue('contractId', foundContract.id)
      }
    }
  }, [loadedContracts, createdContract]);

  useEffect(() => {
    if ((invoiceDocument?.document?.uploadedByContract?.id || invoiceDocument?.result?.contractId) && loadedContracts.length && formRef.current && !createdContract) {
      const contractId = invoiceDocument?.document?.uploadedByContract?.id ?? invoiceDocument?.result?.contractId;
      const foundContract = loadedContracts.find((contract) => contract.id === contractId);
      if (foundContract) {
        setSelectedContract(foundContract ?? null)
        formRef.current.setValue('invoicingPartyId', null)
        formRef.current.setValue('invoiceRecipientId', null)
        formRef.current.setValue('contractId', foundContract.id)
      }
    }
  }, [createdContract, loadedContracts, invoiceDocument?.document?.uploadedByContract?.id, invoiceDocument?.result?.contractId])

  const positions: InvoiceDocumentPositionReadModel[] = []; // TODO

  const handleError = (e: unknown) => {
    const error = e as FetchBaseQueryError;
    const data = error.data as ProbisErrorDataType;
    if (data?.code === 'error.invoice.code_already_exists') {
      setCodeError(true);
    }
  };

  const handleSubmit = async (data: InvoiceCreateFormValidationValues) => {
    if (loadedProjectId && loadedVariantId && invoiceDocument?.document.id) {
      if (data.contractId) {
        try {
          await safeMutation(
            createInvoiceByContract,
            {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              contractId: data.contractId,
              body: {
                invoiceDocumentId: invoiceDocument.document.id,
                code: data.code,
                externalCode: data.externalCode,
                type: data.type,
                vat: data.vat,
                claim: data.claim,
                invoiceDate: data.invoiceDate ? formatDateOnly(new Date(data.invoiceDate)) : formatDateOnly(new Date()),
                dateOfReceipt: data.dateOfReceipt
                  ? formatDateOnly(new Date(data.dateOfReceipt))
                  : formatDateOnly(new Date()),
                dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                comment: data.comment
              },
            },
            isCreatingByContract,
          );
          onClose();
        } catch (e) {
          handleError(e);
        }
      } else {
        try {
          await safeMutation(
            createInvoice,
            {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                invoiceDocumentId: invoiceDocument.document.id,
                invoicingPartyId: data.invoicingPartyId,
                invoiceRecipientId: data.invoiceRecipientId,
                code: data.code,
                externalCode: data.externalCode,
                type: data.type,
                vat: data.vat,
                claim: data.claim,
                invoiceDate: data.invoiceDate ? formatDateOnly(new Date(data.invoiceDate)) : formatDateOnly(new Date()),
                dateOfReceipt: data.dateOfReceipt
                  ? formatDateOnly(new Date(data.dateOfReceipt))
                  : formatDateOnly(new Date()),
                dueDate: data.dueDate ? formatDateOnly(new Date(data.dueDate)) : null,
                cashDiscountDate: data.cashDiscountDate ? formatDateOnly(new Date(data.cashDiscountDate)) : null,
                comment: data.comment
              },
            },
            isCreating,
          );
          onClose();
        } catch (e) {
          handleError(e);
        }
      }
    }
  };

  // render form with default values only if invoice document was loaded already
  if (!invoiceDocument) return null;

  return (
    <Form<InvoiceCreateFormValidationValues>
      onSubmit={handleSubmit}
      validationSchema={InvoiceCreateFormValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col gap-7"
      ref={formRef}
    >
      {isFetchingContracts && (
        <LoadingIndicator text={t('projectControl.loadingInvoiceDocument')} mode="overlay-window" />
      )}
      {(isCreating || isCreatingByContract) && (
        <LoadingIndicator text={t('projectControl.creatingInvoiceDocument')} mode="overlay-window" />
      )}

      <FormWatch<InvoiceCreateFormValidationValues>
        onChange={({ invoicingPartyId, invoiceRecipientId, invoiceDate }) => {
          if (invoicingPartyId || invoiceRecipientId) {
            setSelectedContract(null);
          }
        }}
        fieldNames={[
          'externalCode',
          'code',
          'invoicingPartyId',
          'invoiceRecipientId',
          'type',
          'invoiceDate',
          'dateOfReceipt',
          'dueDate',
          'cashDiscountDate',
          'claim',
          'net',
          'vat'
        ]}
      >
        {({
          dateOfReceipt,
          claim,
          net,
          vat
        }) => (
          <>
            {/* CONTRACT */}
            <DocumentViewerFileDataGroup className="relative overflow-hidden" divider={false}>
              <DocumentViewerFileDataInlineEdit
                allowChangeMode={allowChangeMode}
                toggleContent={
                  <FormField name="contractId">
                    {(control) => (
                      <ComboSelect
                        nullable
                        icon={<ContractIcon className="h-6 w-6" />}
                        label={t('projectContract.contract')}
                        options={contractOptions}
                        disabled={!canWrite}
                        handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                        {...control}
                        onChange={(contractId) => {
                          const foundContract = loadedContracts.find((contract) => contract.id === contractId);
                          setSelectedContract(foundContract ?? null);
                          control.onChange(contractId);
                          formRef.current?.setValue('invoicingPartyId', null);
                          formRef.current?.setValue('invoiceRecipientId', null);
                          /*
                           * For the due date the due date deadline days of contract are added to the invoice receipt date
                           * For the cash discount date the ash discount deadline days of contract are added to the invoice receipt date
                           */
                          if (formRef.current && dateOfReceipt && foundContract) {
                            if (foundContract?.dueDateDeadline) {
                              formRef.current.setValue('dueDate', addDays(dateOfReceipt, foundContract.dueDateDeadline))
                            }
                            if (foundContract?.cashDiscountDeadline) {
                              formRef.current.setValue('cashDiscountDate', addDays(dateOfReceipt, foundContract.cashDiscountDeadline))
                            }
                          }
                        }}
                        additionalOptionOnClick={() => setIsCreateContractWizardOpen(true)}
                        additionalOption={<ComboSelectAdditionalOption label={t('projectContract.createContract')} />}
                      />
                    )}
                  </FormField>
                }
              >
                {selectedContract ? (
                  <DocumentViewerFileDataSet
                    className={selectedContract.code !== defaultFormValues.code ? 'text-secondary' : undefined}
                    label={t('projectContract.contract')}
                    title={`${selectedContract.code} - ${selectedContract.name}`}
                    text={selectedContract?.description}
                  />
                ) : (
                  <DocumentViewerFileDataSet label={t('projectContract.contract')} subtitle="-" />
                )}
              </DocumentViewerFileDataInlineEdit>
            </DocumentViewerFileDataGroup>

            {/* CONTRACTOR & CLIENT */}
            <InvoiceDocumentReviewContractorAndClient
              fileData={fileData}
              formRef={formRef}
              selectedContract={selectedContract}
            />

            {/* INVOICE DATA */}
            <DocumentViewerFileDataGroup className="relative">
              <InvoiceDataEdit
                codeError={codeError}
                defaultFormValues={defaultFormValues}
                allowChangeMode={allowChangeMode}
                setAllowChangeMode={setAllowChangeMode}
                canEdit={canWrite}
                first
              />
            </DocumentViewerFileDataGroup>

            {/* INVOICE TITLES AND VALUES */}
            <DocumentViewerFileDataGroup className="relative" divider={false}>
              {/* INVOICE TITLES */}
              {positions && positions?.length > 0 && (
                <div className="flex flex-col gap-4 text-[15px] truncate">
                  {positions.map((position, i) => (
                    <div
                      key={`invoice-position-${i}`}
                      className={cn('flex flex-nowrap gap-4 justify-between transition-colors duration-300', {
                        'bg-red-500/20': hoveredBox === i,
                        'cursor-pointer': boxes?.length && boxes[i],
                      })}
                      onMouseEnter={boxes?.length && boxes[i] ? () => setHoveredBox(i) : undefined}
                      onMouseLeave={boxes?.length && boxes[i] ? () => setHoveredBox(null) : undefined}
                    >
                      <span className="truncate">{position.value}</span>
                      <span className="text-right whitespace-nowrap">
                      {position.correctedValue ? position.correctedValue : position.originalValue}
                    </span>
                    </div>
                  ))}
                </div>
              )}
              {/* INVOICE VALUES */}
              <InvoiceDocumentReviewValues
                claim={claim}
                net={net}
                vat={vat}
                formRef={formRef}
                defaultFormValues={defaultFormValues}
              />
            </DocumentViewerFileDataGroup>
            {/*
          {allBoundingBoxes && (
            <Button variant="text" onClick={setShowAllBoxes} className="text-left text-xs">
              {showAllBoxes ? 'Hide' : 'Show'} all extracted texts
            </Button>
          )}
          */}
          </>
        )}
      </FormWatch>

      <Modal
        isOpen={isCreateContractWizardOpen}
        onClose={() => setIsCreateContractWizardOpen(false)}
      >
        <ContractNewWizard
          onClose={(createdContract) => {
            setIsCreateContractWizardOpen(false)
            // setCreatedContract(createdContract)
          }}
          setNewContractId={setCreatedContract}
        />
      </Modal>
    </Form>
  );
});
