import { ProjectReadModel, TaxonomyReadModel } from '@client/shared/api';

// TODO: this randomly takes a random stock photo image from the assets folder if no cover picture is set
export const getThumbnail = (item?: TaxonomyReadModel, project?: ProjectReadModel) => {
  if (project == null) return '/assets/project-placeholder/project-placeholder-empty.svg';

  const thumbnail = item?.files?.find((file) => file.fileType === 'Thumbnail');

  if (thumbnail) {
    return `/api/projects/${project.payload.id}/calculation-models/${item?.calculationModelId}/taxonomy/${item?.itemId}/file/${thumbnail.id}`;
  }

  if (!thumbnail && item?.itemType === 'Parking') return '/assets/parking-placeholder.jpg';

  const number = (project.payload.name.length % 6) + 1;

  return project.payload.coverPictureId
    ? `/api/projects/${project.payload.id}/cover/${project.payload.coverPictureId}`
    : `/assets/project-placeholder/project-placeholder-${number}.jpg`;
};
