import * as yup from 'yup';
import { InferType } from 'yup';

export const ProjectSettingElementFormValidationSchema = yup.object({
  groupType: yup.string().required('validation.required'),
  projectId: yup.string().required('validation.required'),
  elementType: yup.string().required('validation.required'),
  name: yup.string().when('groupType', ([groupType], schema) => {
    if (groupType === 'Vat') {
      return yup.string().optional()
    }
    return yup.string().required('validation.required')
  }),
  vat: yup.number().when('groupType', ([groupType], schema) => {
    if (groupType === 'Vat') {
      return yup.number().required('validation.required').typeError('validation.number').min(0).max(100)
    }
    return schema
  })
});

export type ProjectSettingElementFormValidationValues = InferType<typeof ProjectSettingElementFormValidationSchema>;
