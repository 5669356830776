import { useEffect, useState } from 'react';
import {
  CashFlowSettingsReadModel,
  useApiPostUpdateCalculationModelCashFlowSettingsMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariant, useLoadedVariantId } from '@client/project/store';
import {
  BaseSelect,
  BaseSelectOption,
  BooleanInput,
  Button,
  DatePicker,
  Form,
  FormField, HeaderButton,
  LoadingIndicator,
  TimeLimitIcon,
} from '@client/shared/toolkit';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import { CashFlowSettingsValidationSchema, CashFlowSettingsValidationValues } from './CashFlowSettingsValidationValues';

export const CashFlowSettings = () => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data, isLoading: isLoadingCalculationModel } = useLoadedVariant();
  const [updateCashFlowSettings, { isLoading: isUpdating }] =
    useApiPostUpdateCalculationModelCashFlowSettingsMutation();

  const [cashFlowSettings, setCashFlowSettings] = useState<CashFlowSettingsReadModel | undefined>(undefined);

  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });
  const handleSubmit = async (data: CashFlowSettingsValidationValues) => {
    if (loadedProjectId && loadedVariantId) {
      try {
        targetElement?.click();
        await safeMutation(
          updateCashFlowSettings,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              date: data.date ? formatDateOnly(data.date) : null,
              dateRef: data.dateRef ?? 'ReceivedDate',
              valueRef: data.valueRef ?? 'Invoice',
              overridePlan: data.overridePlan ?? false,
            },
          },
          isUpdating,
        );
      } catch (e) {
        // left blank intentionally
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (data?.calculationModel?.modelMetadata?.cashFlowSettings) {
      setCashFlowSettings(data.calculationModel.modelMetadata.cashFlowSettings);
    }
  }, [data?.calculationModel?.modelMetadata?.cashFlowSettings]);

  const referenceDateOptions: BaseSelectOption[] = [
    {
      label: t('projectCalculate.cashFlow.settingsReferenceDateReceivedDate'),
      value: 'ReceivedDate',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceDateInvoiceDate'),
      value: 'InvoiceDate',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceDateApprovalDate'),
      value: 'ApprovalDate',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceDatePaymentDate'),
      value: 'PaymentDate',
    },
  ];

  const referenceValueOptions: BaseSelectOption[] = [
    {
      label: t('projectCalculate.cashFlow.settingsReferenceValueInvoice'),
      value: 'Invoice',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceValuePayment'),
      value: 'Payment',
    },
    {
      label: t('projectCalculate.cashFlow.settingsReferenceValueTotalPayment'),
      value: 'AbsolutePayment',
    },
  ];

  const defaultFormValues = {
    date: cashFlowSettings?.statusMonth ? new Date(cashFlowSettings?.statusMonth) : null,
    dateRef: cashFlowSettings?.referenceDate ?? 'ReceivedDate',
    valueRef: cashFlowSettings?.referenceValue ?? 'Invoice',
    overridePlan: cashFlowSettings?.overridePlan ?? false,
  };

  return (
    <Popover as="div" className="flex items-center">
      <PopoverButton ref={setTargetElement} className="h-10">
        <HeaderButton
          title={t('projectCalculate.finance')}
          subtitle={t('projectCalculate.cashFlow.settings')}
          showToggle
          className="cursor-pointer min-w-[130px]"
        />
      </PopoverButton>
      <PopoverPanel
        portal
        as="div"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="z-30 w-96 flex items-center justify-center bg-gray-100 border border-gray-200 shadow-lg outline-none border-t-0 rounded-lg"
      >
        {({ close }) => (
          <>
            {isLoadingCalculationModel ? (
              <LoadingIndicator text={t('common.loading')} />
            ) : (
              <Form<CashFlowSettingsValidationValues>
                onSubmit={handleSubmit}
                validationSchema={CashFlowSettingsValidationSchema}
                defaultValues={defaultFormValues}
                className="w-full pt-6 px-6 pb-4 flex flex-col gap-1"
              >
                <FormField name="date">
                  {(control) => (
                    <DatePicker
                      label={t('projectCalculate.cashFlow.settingsStatusMonth')}
                      icon={<TimeLimitIcon />}
                      customZIndex="z-40"
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="dateRef">
                  {(control) => (
                    <BaseSelect
                      label={t('projectCalculate.cashFlow.settingsReferenceDate')}
                      options={referenceDateOptions}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="valueRef">
                  {(control) => (
                    <BaseSelect
                      label={t('projectCalculate.cashFlow.settingsReferenceValue')}
                      options={referenceValueOptions}
                      {...control}
                    />
                  )}
                </FormField>
                <FormField name="overridePlan">
                  {(control) => (
                    <BooleanInput
                      label={t('projectCalculate.cashFlow.settingsOverridePlan')}
                      {...control}
                    />
                  )}
                </FormField>
                <div className="text-base bg-gray-100 bottom-0 flex gap-2 pt-4 sticky w-full justify-end">
                  <Button
                    hasPadding={false}
                    className="bg-gray-300 text-white py-1.5 px-5"
                    variant="custom"
                    onClick={() => {
                      close();
                    }}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    hasPadding={false}
                    className="py-1.5 px-5"
                    variant="primary"
                    formSubmit
                    disabled={isLoadingCalculationModel || isUpdating}
                  >
                    {t('common.apply')}
                  </Button>
                </div>
              </Form>
            )}
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
};
