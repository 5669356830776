import { useState, useCallback, useEffect } from 'react';
import { useDocumentTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { CalculateContainer } from '../components';
import { VariantDropDown } from '@client/project/shared';
import { useLoadedProject } from '@client/project/store';
import { CalculateContextProvider, CalculateSearchFilter } from '../components';
import { CostsFilterOption } from '../utils';
import { TitleBar, ToggleSlider } from '@client/shared/toolkit';
import { useSearchParams } from 'react-router-dom';
import { CashFlowSettings } from '../components/CashFlow/CashFlowSettings';
import { settings } from '@client/shared/store';

export const CalculateRoute = () => {
  const [showFinance, setShowFinance] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCostsFilters, setSelectedCostsFilters] = useState<CostsFilterOption[]>([]);
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation();

  const isDevMode = settings.devMode;

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const searchParamsName = searchParams.get('view')
    if (searchParamsName && searchParamsName === 'cashFlow') {
      setTimeout(() => {
        setShowFinance(true);
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const { data: loadedProject } = useLoadedProject();
  useDocumentTitle({ title: t('project.menuCalculate') });

  const disableFinanceToggle = false;

  const toggleFinance = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      setShowFinance((prev) => !prev);
      searchParams.set('view', showFinance ? 'timeline' : 'cashFlow')
      setSearchParams(searchParams, { replace: true });
    }, 10);
  }, [searchParams, showFinance, setSearchParams]);

  return (
    <CalculateContextProvider loading={isLoading}>
      <TitleBar
        title={loadedProject?.project?.payload?.name ?? t('projectCalculate.routeTitle')}
        subTitle={t('projectCalculate.routeSubTitle')}
      >
        <div className="flex flex-wrap lg:flex-nowrap justify-between items-center w-full gap-2 xl:gap-5 ">
          <div className="flex flex-wrap lg:flex-nowrap gap-2 xl:gap-5 items-center">
            <CalculateSearchFilter
              handleSearchValueChange={setSearchValue}
              handleSelectedCostFiltersChange={setSelectedCostsFilters}
              updateSearchResults={setSearchResult}
              showFinance={showFinance}
            />
            {!disableFinanceToggle && (
              <ToggleSlider
                left={!showFinance}
                onClick={toggleFinance}
                headerLabel={t('projectCalculate.display')}
                labelLeft={t('projectCalculate.timeline')}
                labelRight={t('projectCalculate.finance')}
              />
            )}
            {showFinance && isDevMode && (
              <CashFlowSettings />
            )}
          </div>
          <VariantDropDown />
        </div>
      </TitleBar>
      <div className="flex-grow flex flex-col overflow-hidden">
        <CalculateContainer
          showFinance={showFinance}
          costsFilters={selectedCostsFilters}
          searchValue={searchValue ?? ''}
          searchResults={searchResult}
        />
      </div>
    </CalculateContextProvider>
  );
};
