import {
    ContractTitleReadModel,
    useApiPostCreateContractTitleDocumentMutation,
    useApiGetContractTitleDocumentsQuery
} from '@client/shared/api';
import { useState } from 'react';
import { FileInput, Button, LoadingIndicator, SlideOverTitle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { ContractTitleDocumentRow } from './ContractTitleDocumentRow';
import { safeMutation } from '@client/shared/utilities';

interface CalculateElementDocumentsProps {
  title: ContractTitleReadModel;
  canWrite: boolean;
  canDelete: boolean;
}

export const ContractTitleDocuments = ({ title, canWrite, canDelete }: CalculateElementDocumentsProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedCalculationModelId = useLoadedVariantId();
  const [files, setFiles] = useState<FileList | null>(null);

  const [postContractTitleDocuments, { isLoading : isLoadingContractTitleDocuments }] = useApiPostCreateContractTitleDocumentMutation();
  const { data: documents, isFetching } =  useApiGetContractTitleDocumentsQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedCalculationModelId ?? '',
      contractId: title.contractId ?? '',
      contractTitleId: title.id ?? ''
    }
  );

  const handleFileChange = (fileList: FileList | null) => {
    setFiles(fileList);
  };

  const handleSaveDocuments = async () => {
    if (files && files.length > 0) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      try {
        await safeMutation(
          postContractTitleDocuments,
          {
            projectId: loadedProjectId ?? 'unset',
            calculationModelId: loadedCalculationModelId ?? 'unset',
            contractId: title.contractId,
            contractTitleId: title.id,
            body: formData as unknown as { files: Blob[] },
          },
          isLoadingContractTitleDocuments
        );

        setFiles(null);
      } catch (e) {
        console.log(e, 'error');
      }
    }
  };

  return (
    <>
      {
        isFetching === true ? (<LoadingIndicator text={t('app.loadingDocuments') ?? ''} />
      ) : (
        <div>
            <SlideOverTitle title={t('projectCalculate.addElementDocument')} marginTop={false} />
            <div className="bg-white p-3 rounded-sm">
                <FileInput acceptedFileTypes={['*']} multiple={true} selectedFiles={files} onChange={(files) => handleFileChange(files)} />
            </div>

            <div className='flex justify-end mt-4 mb-10'>
                <Button
                  variant="primary"
                  onClick={handleSaveDocuments}
                  disabled={!canWrite}>
                    {t('common.upload')}
                </Button>
            </div>
            <SlideOverTitle title={t('projectCalculate.elementDocument')} />
            {documents?.map((document) => (
                <ContractTitleDocumentRow title={title} elementDocument={document} key={document.id} canDelete={canDelete}/>
            ))}
            {!documents?.length && (
              <div className="flex flex-col items-center justify-center p-5 bg-white text-gray-500">
                {t('projectCalculate.elementDocumentsEmpty')}
              </div>
            )}
        </div>
      )}
  </>);
}
