import React, {
  useCallback,
  useContext,
  useEffect, useMemo,
  useRef,
  useState
} from 'react';
import {
  CalculationCommitmentDto, CalculationInvoicingPartyDto,
  CalculationModelMilestoneReadModel,
  ElementDeliveryPeriod
}
  from '@client/shared/api';
import {
  getMilestonesForYear,
  getPhasesForYear, TimeLineDataContext
} from './TimeLineDataContext';
import {
  CalculationModelDeliveryPhaseReadModelExt,
  TimeLineView
} from '../index';
import {
  ContractDto,
  FinanceTimeline,
} from '@client/shared/api';
import {TimeLineBodyYear} from './TimeLineBodyYear';

export interface TimeLineElementsProps {
  id?: string;
  parentId?: string | null;
  elementId?: string | null;
  timelineElement?: ElementDeliveryPeriod | null;
  type: 'group' | 'element' | 'summary';
  contracts?: (ContractDto | null | undefined)[] | undefined;
  financeTimeline?: FinanceTimeline | null;
  restBudget?: boolean;
  level?: number;
  description: string | null | undefined;
  elementType: string;
  matchSearch?: boolean
  hasChildren?: boolean
  commitments?: CalculationCommitmentDto[]
  invoicingParties?: CalculationInvoicingPartyDto[]
}

export interface TimeLineBarChartDataProps {
  date: string
  isValue: number
  shouldValue: number
}

export interface TimeLineBodyGroupProps {
  elements?: TimeLineElementsProps[]
  name?: string
  open?: boolean
  expandedIds?: string[]
  year?: number
  rows?: number
  barChartData?: FinanceTimeline | null
}

export interface TimeLineBodyProps {
  className?: string;
  monthsGroupedByYear?: Date[][]
  phases?: CalculationModelDeliveryPhaseReadModelExt[];
  milestones?: CalculationModelMilestoneReadModel[];
  view: TimeLineView
  groups?: TimeLineBodyGroupProps[]
  containerOpen: { [key: string]: boolean }
  expandedIds?: string[][]
  dueDate?: string | null
  isDragging?: boolean
}

export const TimeLineBody = (props: TimeLineBodyProps) => {
  const {
    phases = [],
    milestones = [],
    view,
    monthsGroupedByYear = [],
    groups = [],
    expandedIds = [],
    containerOpen,
    dueDate
  } = props
  const {
    containerRef
  } = useContext(TimeLineDataContext)
  const bodyRef = useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined)
  const milestonesPerYear = useCallback((year: number) => {
    return getMilestonesForYear(year, milestones)
  }, [milestones])
  const phasesPerYear = useCallback((year: number) => {
    return getPhasesForYear(year, phases)
  }, [phases])

  // reduce the height, to show not all divs at once (to avoid laggy scroll container for fully expanded ids)
  useEffect(() => {
    if (!bodyRef.current || !containerRef?.current) {
      return
    }
    const containerRefCurrent = containerRef.current
    const handleScroll = ({ target }: Event) => {
      if (target && bodyRef.current) {
        const elem = target as HTMLElement
        if (elem.scrollTop) {
          const newHeight = window.innerHeight + elem.scrollTop
          // bodyRef.current.style.maxHeight = `${newHeight}px`
          setMaxHeight(newHeight)
        }
      }
    }
    if (containerRefCurrent.scrollTop) {
      setMaxHeight(window.innerHeight + containerRefCurrent.scrollTop)
    } else {
      setMaxHeight(window.innerHeight)
      /* const body = bodyRef.current
      if (body.scrollHeight > window.innerHeight) {
        setMaxHeight(window.innerHeight)
      } */
    }
    containerRefCurrent.addEventListener('scroll', handleScroll)
    return () => {
      containerRefCurrent?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyRef.current, expandedIds, containerRef, view, containerOpen, setMaxHeight]);

  return useMemo(() => (
    <div className="h-full z-[5] pointer-events-none min-w-full flex w-fit-content will-change-contents" ref={bodyRef} style={{ maxHeight: maxHeight }}>
      {monthsGroupedByYear?.map((months, index) => (
        <TimeLineBodyYear
          key={`timeline-body-${index}`}
          months={months}
          view={view}
          milestones={months.length ? milestonesPerYear(months[0].getFullYear()) : []}
          phases={phases.length ? phasesPerYear(months[0].getFullYear()) : []}
          groups={groups}
          expandedIds={expandedIds}
          containerOpen={containerOpen}
          dueDate={dueDate}
          maxHeight={maxHeight}
          firstYear={!index}
        />
      ))}
    </div>
  ), [maxHeight, monthsGroupedByYear, view, milestonesPerYear, phases.length, phasesPerYear, groups, expandedIds, containerOpen, dueDate]);
};
