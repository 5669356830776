import {
  ApprovedIcon,
  ContextMenu,
  ContextMenuItem,
  DocumentIcon,
  FloatingActionButton,
  InvoiceNotAuditedIcon,
  ListItemProps,
  LoadingIndicator,
  Modal,
  ToggleSwitch, TrashIcon,
  EyeIcon, AddIcon, SubmitDocumentIcon, CircledPlayIcon, UnavailableIcon,
  SlideOver, BillIcon,
} from '@client/shared/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  InvoiceListItemSum,
  InvoiceListSortHeader
} from './Invoice';
import { useTranslation } from 'react-i18next';
import { List } from '@client/shared/toolkit';
import { InvoiceListItem } from './Invoice';
import {
  AiEvalDocumentReadModel,
  api,
  apiBase,
  ApiTagTypes,
  ExternalApiReadModel,
  ShortInvoiceReadModel, useApiCancelAiEvalProcessMutation,
  useApiGetAiEvalDocumentsQuery,
  useApiGetExternalApisQuery,
  useApiGetInvoicesQuery,
  useApiPostReprocessAiEvalDocumentMutation,
  useApiStartInvoiceWorkflowMutation
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId, useToggledInvoiceFilters } from '@client/project/store';
import {
  InvoiceDeleteModal,
  InvoiceDocumentUploadModal,
  InvoiceEditContextProvider,
  StartWorkflowDisabledMessage,
  InvoiceCreateWizard,
  InvoiceTakeoverModal
} from '@client/project/shared';
import { InvoiceDocumentListItem } from './Invoice';
import { ProtectedRoute, ROUTES_CONFIG, useValidateProjectPermission } from '@client/shared/permissions';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { InvoiceView, InvoiceDocumentView, ImportInvoicesSlideOver, InvoiceCoverSheetView } from '.';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { DeleteInvoiceAiEvalDocumentModal } from './Invoice';
import { safeMutation } from '@client/shared/utilities';
import toast from 'react-hot-toast';
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';

export interface ControlContainerProps {
  searchValue?: string
  searchResults?: string[]
}

export const ControlContainer = ({ searchValue = '', searchResults = [] }: ControlContainerProps) => {
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, AnyAction>>();

  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');
  const canDelete = useValidateProjectPermission(['INVOICE_DELETE'], loadedProjectId ?? '');
  const canApprove = useValidateProjectPermission(['INVOICE_APPROVE'], loadedProjectId ?? '');

  const [openOverlay, setOpenOverlay] = useState<null | 'upload' | 'create' | 'import' | 'delete' | 'documentDelete' | 'takeOver'>(null);

  const [invoices, setInvoices] = useState<ShortInvoiceReadModel[]>([])
  const [aiEvalDocuments, setAiEvalDocuments] = useState<AiEvalDocumentReadModel[]>([])
  const [selectedInvoice, setSelectedInvoice] = useState<ShortInvoiceReadModel | null>(null)
  const [selectedInvoiceDocument, setSelectedInvoiceDocument] = useState<AiEvalDocumentReadModel | null>(null)
  const [showNet, setShowNet] = useState(true)
  const [sortValues, setSortValues] = useState<(boolean | null)[]>([true, null, null, null, null, null])

  const [isLoadedList1, setIsLoadedList1] = useState(false)
  const [isLoadedList2, setIsLoadedList2] = useState(false)
  const [isLoadedList3, setIsLoadedList3] = useState(false)
  const [isLoadedList4, setIsLoadedList4] = useState(false)

  const navigate = useNavigate();

  const toggledInvoiceFilters = useToggledInvoiceFilters();

  const [reprocessInvoiceDocument, { isLoading: isLoadingReprocessing }] = useApiPostReprocessAiEvalDocumentMutation();
  const [startWorkflow, { isLoading: isStartingWorkflow }] = useApiStartInvoiceWorkflowMutation();
  const [cancelProcessingInvoiceDocument, { isLoading: isCancellingProcessing }] = useApiCancelAiEvalProcessMutation();

  const { data: externalApis, isFetching: isLoadingExternalApis } = useApiGetExternalApisQuery();

  // don't show loading indicator for this one, as this is running the background
  const { data: fetchedInvoiceDocuments} = useApiGetAiEvalDocumentsQuery({
    projectId: loadedProjectId ?? 'unset'
  }, {
    skip: !loadedProjectId,
    refetchOnMountOrArgChange: true
  });

  const { data: fetchedInvoices, isFetching: isFetchingInvoices } = useApiGetInvoicesQuery({
    projectId: loadedProjectId ?? 'unset',
    calculationModelId: loadedVariantId ?? ''
  }, {
    skip: !loadedProjectId || !loadedVariantId
  });

  const invoiceAiActive = useMemo(() => {
    return !!externalApis?.find((x: ExternalApiReadModel) => x.api.name === 'Probis Invoice AI')?.api.isActive
  }, [externalApis])

  const loadData = async () => {
    if (loadedProjectId) {
      await dispatch(api.endpoints.apiGetAiEvalDocuments.initiate(
        {
          projectId: loadedProjectId
        }, {
          subscribe: false,
          forceRefetch: true
        }));
    }
  }

  useEffect(() => {
    if (loadedProjectId) {
      const interval = setInterval(() => {
        loadData();
      }, 5000)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchedInvoiceDocuments) {
      if (aiEvalDocuments.length) {
        fetchedInvoiceDocuments.forEach((document) => {
          const foundDocument = aiEvalDocuments.find((x) => x.id === document.id)
          if (foundDocument && foundDocument !== document) {
            // @ts-expect-error tmp fix for missing callback from BE
            dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.InvoiceDocumentResult, id: document.id }]));
          }
        })
      }
      setAiEvalDocuments(fetchedInvoiceDocuments)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedInvoiceDocuments]);

  useEffect(() => {
    if (fetchedInvoices && fetchedInvoices.invoices?.length) {
      const data = [...fetchedInvoices.invoices]
      setInvoices(data)

      // if in create / edit slide over the selected invoice got updated, we need to update the selected invoice, too
      if (selectedInvoice) {
        const foundInvoice = data.find((invoice) => invoice.id === selectedInvoice.id)
        if (foundInvoice) {
          setSelectedInvoice(foundInvoice)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedInvoices]);

  const closeOverlay = () => {
    setOpenOverlay(null);
  }

  const handleCloseInvoiceUploadSlideOver = () => {
    setSelectedInvoiceDocument(null);
    closeOverlay();
  };

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectControl.uploadInvoiceTitle'),
      subtitle: t('projectControl.uploadInvoiceSubTitle'),
      icon: <AddIcon />,
      onClick: () => {
        setSelectedInvoice(null)
        setSelectedInvoiceDocument(null)
        setOpenOverlay('upload');
      },
      isDisabled: !invoiceAiActive || !canWrite
    },
    {
      label: t('projectContract.newInvoice'),
      subtitle: t('projectContract.createNewInvoice'),
      icon: <AddIcon />,
      onClick: () => {
        setSelectedInvoice(null)
        setSelectedInvoiceDocument(null)
        setOpenOverlay('create');
      },
      isDisabled: !canWrite
    },
    {
      label: t('projectControl.invoiceImport'),
      subtitle: t('projectControl.invoiceImportDescription'),
      icon: <AddIcon />,
      onClick: () => {
        setSelectedInvoice(null)
        setSelectedInvoiceDocument(null)
        setOpenOverlay('import');
      },
      isDisabled: !canWrite || !canApprove
    }
  ];

  const handleStartWorkflow = async (invoiceId: string) => {
    if (loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          startWorkflow,
          {
            body: {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              invoiceId: invoiceId,
            }
          },
          isStartingWorkflow
        );
      } catch (e) {
        console.error(e)
      }
    }
  }

  const openInvoice = useCallback((invoice: ShortInvoiceReadModel) => {
    if (selectedInvoice !== invoice) {
      setSelectedInvoice(invoice)
    }
    navigate(ROUTES_CONFIG.PROJECT_INVOICE_VIEW.path.replace(':id', loadedProjectId ?? '').replace(':invoiceId', invoice.id));
  }, [selectedInvoice, navigate, loadedProjectId])

  const openInvoiceDocument = useCallback((invoiceDocument: AiEvalDocumentReadModel) => {
    if (selectedInvoiceDocument !== invoiceDocument) {
      setSelectedInvoiceDocument(invoiceDocument)
    }
    navigate(ROUTES_CONFIG.PROJECT_INVOICE_DOCUMENT_VIEW.path.replace(':id', loadedProjectId ?? '').replace(':documentId', invoiceDocument.id));
  }, [loadedProjectId, navigate, selectedInvoiceDocument])

  const invoiceListItems = useMemo(() => {
    let totalNetNotApproved = 0
    let totalNetApproved = 0
    let totalNetPayed = 0

    let totalGrossNotApproved = 0
    let totalGrossApproved = 0
    let totalGrossPayed = 0

    let retentionNetNotApproved = 0
    let retentionNetApproved = 0
    let retentionNetPayed = 0

    let retentionGrossNotApproved = 0
    let retentionGrossApproved = 0
    let retentionGrossPayed = 0

    let paymentNetNotApproved = 0
    let paymentNetApproved = 0
    let paymentNetPayed = 0

    let paymentGrossNotApproved = 0
    let paymentGrossApproved = 0
    let paymentGrossPayed =  0

    const notApproved: ListItemProps[] = []
    const approved: ListItemProps[] = []
    const payed: ListItemProps[] = []

    let currentInvoices = [...invoices]

    if (currentInvoices.length) {
      // apply search value
      if (searchValue) {
        const searchFor = searchValue.toLowerCase()
        currentInvoices = currentInvoices.filter((invoice) => {
          return toggledInvoiceFilters && toggledInvoiceFilters.length > 0  ? searchResults && searchResults?.includes(invoice.id) :
          (invoice.code.toLowerCase().includes(searchFor)
          || invoice.contractName?.toLowerCase().includes(searchFor)
          || invoice.contractCode?.toLowerCase().includes(searchFor)
          || invoice.state?.toLowerCase().includes(searchFor)
          || invoice.externalCode?.toLowerCase().includes(searchFor))
        })
      }

      // TODO global sorting
      currentInvoices.sort((a, b) => {
        if (sortValues[0] !== null) {
          const valA = a.code ?? ''
          const valB = b.code ?? ''
          if (sortValues[0]) {
            return valA.localeCompare(valB)
          } else {
            return valB.localeCompare(valA)
          }
        } else if (sortValues[1] !== null) {
          const valA = a.dateOfReceipt ? new Date(a.dateOfReceipt).getTime() : null
          const valB = b.dateOfReceipt ? new Date(b.dateOfReceipt).getTime() : null
          if (valA && valB) {
            if (sortValues[1]) {
              return valA - valB
            } else {
              return valB - valA
            }
          }
        } else if (sortValues[2] !== null) {
          const valA = a.contractName ?? ''
          const valB = b.contractName ?? ''
          if (sortValues[2]) {
            return valA.localeCompare(valB)
          } else {
            return valB.localeCompare(valA)
          }
        } else if (sortValues[3] !== null) {
          const valA = a.claim ?? 0
          const valB = b.claim ?? 0
          if (sortValues[3]) {
            return valA - valB
          } else {
            return valB - valA
          }
        } else if (sortValues[4] !== null) {
          const valA = a.invoiceValueNet ?? 0
          const valB = b.invoiceValueNet ?? 0
          if (sortValues[4]) {
            return valA - valB
          } else {
            return valB - valA
          }
        } else if (sortValues[5] !== null) {
          const valA = a.retentionNet ?? 0
          const valB = b.retentionNet ?? 0
          if (sortValues[5]) {
            return valA - valB
          } else {
            return valB - valA
          }
        } else if (sortValues[6] !== null) {
          const valA = a.paymentValueNet ?? 0
          const valB = b.paymentValueNet ?? 0
          if (sortValues[6]) {
            return valA - valB
          } else {
            return valB - valA
          }
        }
        return 0
      })
      currentInvoices.forEach((invoice) => {
        const contextMenuItems: ContextMenuItem[] = [{
            label: t('common.view'),
            subtitle: t('projectControl.viewInvoiceDetails'),
            icon: <EyeIcon />,
            onClick: () => {
              openInvoice(invoice);
            }
          }];

        contextMenuItems.push({
          label: t('projectControl.viewIcs'),
          subtitle: t('projectControl.viewIcsSubtitle'),
          icon: <BillIcon />,
          onClick: () => {
            navigate(`${ROUTES_CONFIG.PROJECT_INVOICE_VIEW.path.replace(':id', loadedProjectId ?? '').replace(':invoiceId', invoice.id)}?tab=ics`);
          }
        })

        if (!invoice.isPxInvoice) {
          if (invoice.contractId && invoice.state === 'Pending'){
            contextMenuItems.push({
              label: t('projectControl.takeoverInvoice'),
              subtitle: t('projectControl.takeoverInvoiceDetails'),
              icon: <ArrowsRightLeftIcon/>,
              onClick: () => {
                setSelectedInvoice(invoice)
                setOpenOverlay('takeOver');
              }
            })
          }

          if (canDelete) {
            contextMenuItems.push({
              label: t('common.delete'),
              subtitle: t('projectControl.deleteInvoice'),
              icon: <TrashIcon/>,
              // disabled: invoice.
              onClick: () => {
                setSelectedInvoice(invoice)
                setOpenOverlay('delete');
              }
            })
          }
          contextMenuItems.push({
            label: invoice.startWorkflowStatus.canRestartWorkflow
              ? invoice.startWorkflowStatus.workflowName ? t('projectControl.restartWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.restartWorkflow')
              : invoice.startWorkflowStatus.workflowName ? t('projectControl.startWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.startWorkflow'),
            subtitle: t('projectControl.startWorkflowForInvoice'),
            icon: <CircledPlayIcon />,
            onClick: () => {
              handleStartWorkflow(invoice.id);
            },
            isDisabled: !invoice.startWorkflowStatus.canStartWorkflow && !invoice.startWorkflowStatus.canRestartWorkflow,
            children: !invoice.startWorkflowStatus.canStartWorkflow && !invoice.startWorkflowStatus.canRestartWorkflow ? (
              <StartWorkflowDisabledMessage invoice={invoice} projectId={loadedProjectId ?? ''} />
            ) : null,
            truncateText: false
          });
        }

        const item = {
          onClick: () => openInvoice(invoice),
          borderColor: 'bg-slate-400',
          children: (
            <InvoiceListItem
              invoice={invoice}
              showNet={showNet}
              searchValue={searchValue}
            />
          ),
          additionalContent: (
            <div className="hidden md:flex flex-col justify-center px-4">
              <ContextMenu items={contextMenuItems}/>
            </div>
          )
        }

        if (invoice.state !== 'Approved' && invoice.state !== 'Paid') {
          totalNetNotApproved += invoice.invoiceValueNet
          totalGrossNotApproved += invoice.invoiceValueGross
          retentionNetNotApproved += invoice.retentionNet
          retentionGrossNotApproved += invoice.retentionGross
          paymentNetNotApproved += invoice.paymentValueNet
          paymentGrossNotApproved += invoice.paymentValueGross
          item.borderColor = 'bg-slate-400';
          notApproved.push(item)
        } else if (invoice.state === 'Approved') {
          totalNetApproved += invoice.invoiceValueNet
          totalGrossApproved += invoice.invoiceValueGross
          retentionNetApproved += invoice.retentionNet
          retentionGrossApproved += invoice.retentionGross
          paymentNetApproved += invoice.paymentValueNet
          paymentGrossApproved += invoice.paymentValueGross
          item.borderColor = 'bg-green-400'
          approved.push(item)
        } else {
          totalNetPayed += invoice.invoiceValueNet
          totalGrossPayed += invoice.invoiceValueGross
          retentionNetPayed += invoice.retentionNet
          retentionGrossPayed += invoice.retentionGross
          paymentNetPayed += invoice.paymentValueNet
          paymentGrossPayed += invoice.paymentValueGross
          item.borderColor = 'bg-gray-400'
          payed.push(item)
        }
      });
    }

    return {
      notApproved: {
        items: notApproved,
        totalGross: totalGrossNotApproved,
        totalNet: totalNetNotApproved,
        retentionGross: retentionGrossNotApproved,
        retentionNet: retentionNetNotApproved,
        paymentGross: paymentGrossNotApproved,
        paymentNet: paymentNetNotApproved
      },
      approved: {
        items: approved,
        totalGross: totalGrossApproved,
        totalNet: totalNetApproved,
        retentionGross: retentionGrossApproved,
        retentionNet: retentionNetApproved,
        paymentGross: paymentGrossApproved,
        paymentNet: paymentNetApproved
      },
      payed: {
        items: payed,
        totalGross: totalGrossPayed,
        totalNet: totalNetPayed,
        retentionGross: retentionGrossPayed,
        retentionNet: retentionNetPayed,
        paymentGross: paymentGrossPayed,
        paymentNet: paymentNetPayed
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, sortValues, t, openInvoice, showNet, searchValue, searchResults, toggledInvoiceFilters, canDelete])

  const triggerProcessingDocument = useCallback(async (document: AiEvalDocumentReadModel) => {
    if (loadedProjectId) {
      try {
        await safeMutation(
          reprocessInvoiceDocument,
          {
            documentId: document.id,
            projectId: loadedProjectId
          },
          isLoadingReprocessing,
        );
        toast.success(
          t('projectControl.notificationUploadingInvoiceDocument', { name: document.filename }),
          { duration: 5000 }
        )
      } catch (e) {
        console.error(e)
      }
    }
  }, [loadedProjectId, isLoadingReprocessing, t, reprocessInvoiceDocument]);

  const cancelProcessingDocument = useCallback(async (document: AiEvalDocumentReadModel) => {
    if (loadedProjectId) {
      try {
        await safeMutation(
          cancelProcessingInvoiceDocument,
          {
            documentId: document.id,
            projectId: loadedProjectId
          },
          isCancellingProcessing,
        );
        toast.success(
          t('projectControl.notificationCanceledInvoiceDocument', { name: document.filename }),
          { duration: 5000 }
        )
      } catch (e) {
        console.error(e)
      }
    }
  }, [loadedProjectId, cancelProcessingInvoiceDocument, isCancellingProcessing, t])

  const invoiceDocumentListItems = useMemo(() => {
    const currentDocuments = [...aiEvalDocuments]
    const listItems: ListItemProps[] = []
    if (currentDocuments.length) {
      currentDocuments.sort((a, b) => {
        if (sortValues[0] !== null) {
          const valA = a.filename ?? ''
          const valB = b.filename ?? ''
          if (sortValues[0]) {
            return valA.localeCompare(valB)
          } else {
            return valB.localeCompare(valA)
          }
        }
        return 0
      })
      currentDocuments.forEach((document) => {
        const contextMenuItems: ContextMenuItem[] = []
        if (document.state === 'Succeeded') {
          contextMenuItems.push( {
            label: t('projectControl.reviewInvoiceDocument'),
            subtitle: t('projectControl.editInvoiceDocument'),
            icon: <EyeIcon />,
            onClick: () => openInvoiceDocument(document)
          })
        }
        if (invoiceAiActive && document.state !== 'Succeeded' && document.state !== 'Processing') {
          contextMenuItems.push({
            label: t('projectControl.extractValues'),
            subtitle: t('projectControl.extractValuesFromInvoiceDocument'),
            icon: <SubmitDocumentIcon />,
            onClick: () => {
              triggerProcessingDocument(document);
            },
            isDisabled: !canDelete
          });
        }
        if (document.state === 'Processing') {
          contextMenuItems.push({
            label: t('projectControl.cancelExtractingValues'),
            subtitle: t('projectControl.cancelExtractValuesFromInvoiceDocument'),
            icon: <UnavailableIcon />,
            onClick: () => {
              cancelProcessingDocument(document);
            },
            isDisabled: !canDelete || !invoiceAiActive
          });
        }
        if (document.state !== 'Processing') {
          contextMenuItems.push({
            label: t('common.delete'),
            subtitle: t('projectControl.deleteInvoiceDocument'),
            icon: <TrashIcon/>,
            onClick: () => {
              setSelectedInvoiceDocument(document);
              setOpenOverlay('documentDelete');
            },
            isDisabled: !canDelete
          });
        }
        listItems.push({
          onClick: document.state === 'Succeeded' || document.state === 'Unprocessed' ? () => openInvoiceDocument(document) : undefined,
          borderColor: 'bg-orange-400',
          children: (
            <InvoiceDocumentListItem
              invoiceDocument={document}
              showNet={showNet}
            />
          ),
          additionalContent: (
            <div className="hidden md:flex flex-col justify-center px-4 w-[56px] flex-none">
              {contextMenuItems.length > 0 && <ContextMenu items={contextMenuItems}/>}
            </div>
          )
        })
      });
    }
    return listItems
  }, [aiEvalDocuments, sortValues, t, openInvoiceDocument, canDelete, showNet, invoiceAiActive, triggerProcessingDocument, cancelProcessingDocument])

  const onHandleSort = useCallback((index: number) => {
    const currentSortValues = [...sortValues]
    currentSortValues.forEach((val, i) => {
      if (i !== index) {
        currentSortValues[i] = null
      }
    })
    const oldValue = currentSortValues[index]
    currentSortValues[index] = !oldValue
    setSortValues(currentSortValues)
  }, [sortValues])

  const sortHeader = useMemo(() => {
    if (invoices.length) {
      return (
        <InvoiceListSortHeader
          onHandleSort={onHandleSort}
          sortValues={sortValues}
        />
      )
    }
    return null
  }, [invoices.length, onHandleSort, sortValues])

  return (
    <div className="relative mb-20">
      {(isFetchingInvoices || !isLoadedList1 || !isLoadedList2 || !isLoadedList3 || !isLoadedList4 || isStartingWorkflow || isLoadingExternalApis || isCancellingProcessing) && (
        <LoadingIndicator text={t('projectControl.fetchingInvoicesLoadingIndicator')} mode="overlay-window" />
      )}
      {invoices.length > 0 && (
        <div className="md:absolute md:right-0 md:top-0 mb-2 md:mb-0">
          <ToggleSwitch
            id="netGrossToggle"
            checked={showNet}
            name="netGrossToggle"
            onChange={() => setShowNet(prev => !prev)}
            offLabel={t('projectControl.gross')}
            onLabel={t('projectControl.net')}
          />
        </div>
      )}
      {/* Invoice documents (have no invoice, yet) */}
      <List
        icon={<DocumentIcon className="w-5 h-5" />}
        listTitle={t('projectControl.notAuditedInvoiceDocuments')}
        items={invoiceDocumentListItems}
        emptyMessage={t('projectControl.noInvoiceDocuments')}
        updateLoading={() => setIsLoadedList1(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      />
      {/* Invoices */}
      <List
        className="mt-20"
        icon={<InvoiceNotAuditedIcon />}
        listTitle={t('projectControl.notApprovedInvoices')}
        items={invoiceListItems.notApproved.items}
        emptyMessage={t('projectControl.noInvoices')}
        sortHeader={invoiceListItems.notApproved.items.length > 0 ? sortHeader : []}
        updateLoading={() => setIsLoadedList2(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      >
        {invoiceListItems.notApproved.items.length > 0 && (
          <InvoiceListItemSum
            showNet={showNet}
            grossValue={invoiceListItems.notApproved.totalGross}
            netValue={invoiceListItems.notApproved.totalNet}
            retentionGross={invoiceListItems.notApproved.retentionGross}
            retentionNet={invoiceListItems.notApproved.retentionNet}
            paymentGross={invoiceListItems.notApproved.paymentGross}
            paymentNet={invoiceListItems.notApproved.paymentNet}
          />
        )}
      </List>
      <List
        className="mt-10"
        icon={<ApprovedIcon />}
        listTitle={t('projectControl.approvedInvoices')}
        items={invoiceListItems.approved.items}
        emptyMessage={t('projectControl.noInvoices')}
        sortHeader={invoiceListItems.approved.items.length > 0 ? sortHeader : []}
        updateLoading={() => setIsLoadedList3(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      >
        {invoiceListItems.approved.items.length > 0 && (
          <InvoiceListItemSum
            showNet={showNet}
            grossValue={invoiceListItems.approved.totalGross}
            netValue={invoiceListItems.approved.totalNet}
            retentionGross={invoiceListItems.approved.retentionGross}
            retentionNet={invoiceListItems.approved.retentionNet}
            paymentGross={invoiceListItems.approved.paymentGross}
            paymentNet={invoiceListItems.approved.paymentNet}
          />
        )}
      </List>
      <List
        className="mt-10"
        icon={<ApprovedIcon />}
        listTitle={t('projectControl.payedInvoices')}
        items={invoiceListItems.payed.items}
        emptyMessage={t('projectControl.noInvoices')}
        sortHeader={invoiceListItems.payed.items.length > 0 ? sortHeader : []}
        updateLoading={() => setIsLoadedList4(true)}
        collapsible
        showPagination={true}
        amountPerPage={5}
      >
        {invoiceListItems.payed.items.length > 0 && (
          <InvoiceListItemSum
            showNet={showNet}
            grossValue={invoiceListItems.payed.totalGross}
            netValue={invoiceListItems.payed.totalNet}
            retentionGross={invoiceListItems.payed.retentionGross}
            retentionNet={invoiceListItems.payed.retentionNet}
            paymentGross={invoiceListItems.payed.paymentGross}
            paymentNet={invoiceListItems.payed.paymentNet}
          />
        )}
      </List>
      <FloatingActionButton menuItems={contextItems} />


      {/* ------------ OVERLAYS (MODALS & SLIDE OVERS) -------------*/}

      {/* INVOICE DOCUMENT UPLOAD */}
      <InvoiceDocumentUploadModal
        isOpen={openOverlay === 'upload'}
        onClose={handleCloseInvoiceUploadSlideOver}
      />

      {/* CREATE INVOICE */}
      <InvoiceCreateWizard
        isOpen={openOverlay === 'create'}
        onClose={closeOverlay}
      />


      {/* IMPORT INVOICE */}
      <SlideOver variant='large' isOpen={openOverlay === 'import'} onClose={closeOverlay}>
        <ImportInvoicesSlideOver
          onClose={closeOverlay}
        />
      </SlideOver>

      {/* INVOICE EDIT replaced by invoice create wizard */}
      {/*
      <SlideOver isOpen={openOverlay === 'create'} onClose={closeOverlay}>
        <InvoiceEditSlideOver
          onClose={closeOverlay}
        />
      </SlideOver>
      */}

      {/* DELETE INVOICE */}
      <Modal isOpen={openOverlay === 'delete'} onClose={closeOverlay}>
        {selectedInvoice && (
          <InvoiceDeleteModal
            invoice={selectedInvoice}
            onClose={closeOverlay}
          />
        )}
      </Modal>

      <Modal isOpen={openOverlay === 'takeOver'} onClose={closeOverlay}>
        {selectedInvoice && (
          <InvoiceTakeoverModal
            invoiceId={selectedInvoice.id}
            contractId={selectedInvoice.contractId ?? ''}
            onClose={closeOverlay}
          />
        )}
      </Modal>

      {/* DELETE INVOICE DOCUMENT */}
      <Modal
        isOpen={openOverlay === 'documentDelete'}
        onClose={closeOverlay}
      >
        {selectedInvoiceDocument && (
          <DeleteInvoiceAiEvalDocumentModal
            document={selectedInvoiceDocument}
            onClose={closeOverlay}
          />
        )}
      </Modal>

      {/* ------------ CHILD ROUTES -------------*/}
      <Routes>
        <Route
          path={ROUTES_CONFIG.PROJECT_INVOICE_VIEW.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_INVOICE_VIEW}
              projectId={loadedProjectId}
            >
              <InvoiceEditContextProvider>
                <InvoiceView isLoading={isFetchingInvoices || !isLoadedList1 || !isLoadedList2 || !isLoadedList3 || !isLoadedList4 || isStartingWorkflow || isLoadingExternalApis || isCancellingProcessing} />
              </InvoiceEditContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECT_INVOICE_DOCUMENT_VIEW.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_INVOICE_DOCUMENT_VIEW}
              projectId={loadedProjectId}
            >
              <InvoiceDocumentView />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECT_INVOICE_ICS_VIEW.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_INVOICE_ICS_VIEW}
              projectId={loadedProjectId}
            >
              <InvoiceCoverSheetView />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to='' />} />
      </Routes>
    </div>
  )
}
