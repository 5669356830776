import {
  LoadingIndicator,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle
} from '@client/shared/toolkit';
import {
  Chart as ChartJS,
  Filler,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  ChartOptions
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useMemo } from 'react';
import { getLanguageAndLocale } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useProjectDashboardData } from '../../hooks';
import { ChartDataset } from 'chart.js/dist/types';

const DATASET_1_BG_COLOR_GREEN = '#17A085';
const DATASET_2_BG_COLOR_GREEN = '#81C6B8';
const DATASET_1_BG_COLOR_RED = '#D18386';
const DATASET_2_BG_COLOR_RED = '#B71E23';

export const DashboardWidgetIsShouldChart = () => {
  ChartJS.register(
    Filler,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Title,
  );
  const { t } = useTranslation();
  const { data: projectData, isFetching } = useProjectDashboardData();
  const locale = getLanguageAndLocale().locale;
  const options: ChartOptions = useMemo(() => {
    return {
      responsive: true,
      // devicePixelRatio: 4,
      categoryPercentage: 0.8,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          labels: {
            font: {
              size: 8
            }
          }
        },
        tooltip: {
          filter: (data) => {
            return data.datasetIndex > 0;
          }
        },
      },
      scales: {
        x: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Roboto',
              size: 10,
            },
            color: '#64748B',
            padding: 10,
            showLabelBackdrop: false
          },
          grid: {
            drawOnChartArea: false,
            drawTicks: false
          },
          border: {
            width: 2,
            dash: [5, 15]
          }
        },
        y: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            font: {
              family: 'Roboto',
              size: 10
            },
            color: '#64748B',
            callback: (value, index, ticks) => {
              return new Intl.NumberFormat(locale, {
                maximumFractionDigits: 2,
                signDisplay: 'auto',
                notation: 'compact',
                compactDisplay: 'short'
              }).format(Number(value));
            },
            mirror: true,
            padding: 0,
            labelOffset: -10,
            z: 10
          },
          position: 'right',
          grid: {
            drawTicks: false
          },
          border: {
            display: false
          }
        }
      },
      layout: {
        padding: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        },
      },
    };
  }, [locale]);

  const data = useMemo(() => {
    let datasets: ChartDataset[] = [];
    if (projectData?.project?.calculationModel?.projectCalculationValues) {
      const { planValues, isValues } = projectData.project.calculationModel.projectCalculationValues;
      const plan = planValues; // [61240479.68, 2865637.20, 500000.00, -44321130.73, -5695364.61, -3005739.31];
      const is = isValues; //[10000000.00, 1825231.53, 0, -17435632.23, 0, -1151795.98];

      const dataset0: number[] = [0];
      //-PLAN*(PLAN<0)+IST
      const dataset1: number[] = [];
      //=PLAN*(PLAN>0)-IST
      const dataset2: number[] = [];
      let sumPlan = 0;
      let sumIs: number | null = null;
      let prevDataset0 = 0;
      const dataset1BgColors = [DATASET_1_BG_COLOR_GREEN];
      const dataset2BgColors = [DATASET_2_BG_COLOR_GREEN];
      plan.forEach((value, index) => {
        const planValue = value ?? 0;
        const isValue = is[index] ?? 0;
        const dataset1Val = -planValue*(planValue<0 ? 1 : 0) + isValue;
        dataset1.push(dataset1Val);
        sumPlan += planValue;
        dataset2.push(planValue*(planValue>0 ? 1 : 0) - isValue);
        if (is[index]) {
          sumIs = sumIs === null ? isValue : sumIs + isValue;
        }
        if (index > 0) {
          // R6=R5+U5*(U5>0)+U6*(U6<0)
          const prevPlanValue = plan[index-1] ?? 0;
          prevDataset0 = dataset0[index-1] + ((prevPlanValue*(prevPlanValue > 0 ? 1 : 0)) + (planValue*(planValue < 0 ? 1 : 0)));
          dataset0.push(prevDataset0);

          if (planValue < 0) {
            dataset1BgColors.push(DATASET_1_BG_COLOR_RED);
            dataset2BgColors.push(DATASET_2_BG_COLOR_RED);
          } else {
            dataset1BgColors.push(DATASET_1_BG_COLOR_GREEN);
            dataset2BgColors.push(DATASET_2_BG_COLOR_GREEN);
          }
        }
      });
      // add marge
      dataset0.push(0);
      if (sumIs != null) {
        dataset1.push(sumIs);
        dataset2.push(sumPlan-sumIs);
      }
      dataset1BgColors.push(DATASET_1_BG_COLOR_GREEN);
      dataset2BgColors.push(DATASET_2_BG_COLOR_GREEN);

      datasets = [
        {
          label: '',
          data: dataset0,
          backgroundColor: 'transparent'
        },
        {
          label: '',
          data: dataset1,
          backgroundColor: dataset1BgColors,
          barPercentage: 0.5
        },
        {
          label: '',
          data: dataset2,
          backgroundColor: dataset2BgColors,
          barPercentage: 0.5
        }
      ] as ChartDataset[];
    }

    return {
      labels: [
        t('project.dashboard.widget.cashflow.chart.label.salesVolume'),
        t('project.dashboard.widget.cashflow.chart.label.rentalIncome'),
        t('project.dashboard.widget.cashflow.chart.label.kfw'),
        [
          t('project.dashboard.widget.cashflow.chart.label.propertyCosts'),
          t('project.dashboard.widget.cashflow.chart.label.includingReimbursements')
        ],
        [
          t('project.dashboard.widget.cashflow.chart.label.distributionCosts'),
          t('project.dashboard.widget.cashflow.chart.label.includingSalesIncentives')
        ],
        [
          t('project.dashboard.widget.cashflow.chart.label.financing'),
          t('project.dashboard.widget.cashflow.chart.label.financingCosts'),
        ],
        t('project.dashboard.widget.cashflow.chart.label.projectMargin')
      ],
      datasets: datasets,
    };
  }, [projectData?.project?.calculationModel?.projectCalculationValues, t]);
  return (
    <WidgetContainer>
      {isFetching ? <LoadingIndicator className="p-2" /> : (
        <>
          <WidgetContainerTitle>{t('project.dashboard.widget.isShould.title')}</WidgetContainerTitle>
          <WidgetContainerContent>
            <div className="w-full h-full p-2">
              <Chart options={options} type="bar" data={data} />
            </div>
          </WidgetContainerContent>
        </>
      )}
    </WidgetContainer>
  );
};
