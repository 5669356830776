import {
  api,
  ApiPostUploadAiEvalDocumentApiResponse
} from '@client/shared/api';
import { fork, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { PayloadAction } from '@reduxjs/toolkit';
import { i18n } from '@client/shared/utilities';

function* onInvoiceDocumentUploaded() {
  yield takeEvery(
    [api.endpoints.apiPostUploadAiEvalDocument.matchFulfilled],
    function* (action: PayloadAction<ApiPostUploadAiEvalDocumentApiResponse>) {
      toast.success(i18n.t('app.notificationInvoiceDocumentUpdated', { name: action.payload.fileName }));
      yield;
    }
  );
}

export const controlSaga = function* () {
  yield fork(onInvoiceDocumentUploaded);
};
