import {
  useApiGetCalculationModelDeliveryPhasesQuery,
  PositionType,
  TimelineTypes,
  TimelineDurationUnit,
  useApiGetCalculationModelQuery,
  DeliveryPhaseOverviewReadModel,
  CalculationModelElementTimelineReadModel,
  useApiGetCalculationModelMilestonesQuery,
  CalculationModelMilestoneReadModel,
} from '@client/shared/api';
import {
  RadioGroup,
  Tab,
  Popover,
  PopoverButton,
  PopoverPanel,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Radio
} from '@headlessui/react';
import { TimeLimitIcon, DatePicker, NumberInput, FormHelperText, LoadingIndicator } from '@client/shared/toolkit';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useState, useEffect, useId, useMemo } from 'react';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import { isEmpty, formatDate, formatDateOnly, formatNullableDateOnly } from '@client/shared/utilities';
import { addMonths } from 'date-fns';
import { useLoadedProjectId } from '@client/project/store';
import { groupBy } from '../../utils';

export interface TimeInput {
  variantDeliveryPhaseId?: string | null;
  variantMileStoneId?: string | null;
  offset?: number | null;
  offsetPosition?: PositionType | null;
  offsetUnit?: TimelineDurationUnit | null;
  fixedDate?: string | null;
  elementTimelineId?: string | null;
  type: TimelineTypes;
  duration?: number | null;
  durationUnit?: TimelineDurationUnit | null;
  effectiveDate?: string | null;
  durationStartDate?: string | null;
}

interface ControlState {
  timeElement: string | null;
  timeCustomDate: string | null;
  duration?: number | null;
  offsetBegin?: string;
  offsetAmount?: string;
  offsetCustomAmount?: number;
  offsetCustomUnit?: TimelineDurationUnit;
  durationCustomUnit?: TimelineDurationUnit;
}

interface TimeLineElementInputProps {
  label: string;
  icon?: React.ReactNode;
  timeLineData?: TimeInput;
  variantId: string;
  onChange?: (result: TimeInput) => void;
  showDuration?: boolean;
  placeHolder?: string;
  className?: string;
  disabled?: boolean;
  showValidation?: boolean;
  isValidationValid?: boolean;
  helperText?: string;
  showChevronDown?: boolean;
  onHidePopover?: () => void;
  showProjectEnd?: boolean;
  showProjectStart?: boolean;
  showVariableDates?: boolean;
  showFixedDates?: boolean;
  showValue?: boolean; // e.g. if input shall be rendered, but actually cannot be edited
  optionalNode?: React.ReactNode;
  minDate?: Date;
  maxDate?: Date;
  inModal?: boolean;
}

export const addMonthsToDate = (date: string, months: number) => {
  if (isEmpty(date)) {
    return undefined;
  }

  const parsedDate = new Date(date);
  return formatDateOnly(addMonths(parsedDate, months));
};

export const TimeLineElementInput = ({
  icon,
  label,
  onChange,
  showDuration,
  timeLineData,
  variantId,
  className,
  showValidation,
  isValidationValid,
  helperText,
  disabled,
  placeHolder,
  showChevronDown = true,
  onHidePopover,
  showProjectEnd = false,
  showVariableDates = true,
  showFixedDates = true,
  showValue = true,
  optionalNode,
  minDate,
  maxDate,
  inModal = false,
}: TimeLineElementInputProps) => {
  const { t } = useTranslation();
  const inputId = useId();

  const loadedProjectId = useLoadedProjectId();

  const { data: deliveryPhases, isFetching: isLoadingDeliveryPhases } = useApiGetCalculationModelDeliveryPhasesQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: variantId ?? '',
    },
    { skip: variantId == null },
  );

  const { data: milestones, isFetching: isLoadingMilestones } = useApiGetCalculationModelMilestonesQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: variantId ?? '',
    },
    { skip: variantId == null },
  );

  const { data: variantData, isFetching: isLoadingVariantData } = useApiGetCalculationModelQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: variantId ?? '',
    },
    { skip: variantId == null },
  );

  const panelsRef = React.useRef<HTMLDivElement>(null);

  const timeFrameElements = variantData?.calculationModel?.payload?.timeframeElements ?? [];

  const monthOptions = Array.from({ length: 7 }, (v, k) => k);

  const defaultControlState: ControlState = {
    timeElement: 'time-custom',
    timeCustomDate: null,
    duration: null,
    offsetBegin: 'offset-begin-start',
    offsetAmount: '',
    offsetCustomAmount: 0,
    offsetCustomUnit: 'Months',
    durationCustomUnit: 'Months',
  };
  const [controlState, setControlState] = useState<ControlState>(defaultControlState);

  const [calculationModelEndDate, setCalculationModelEndDate] = useState<Date>(new Date());

  useEffect(() => {
    if (!deliveryPhases || !showProjectEnd) {
      return;
    }
    setCalculationModelEndDate(
      deliveryPhases?.calculationModelEndDate ? new Date(deliveryPhases?.calculationModelEndDate) : new Date(),
    );
  }, [deliveryPhases, showProjectEnd]);

  // if a reference is selected, we show offset, otherwise not
  const tabs = useMemo(() => {
    if (controlState?.timeElement === 'time-custom') {
      return [
        {
          name: 'Time',
          label: t('projectCalculate.timeLineElementTabTime'),
        },
      ];
    }
    return [
      {
        name: 'Time',
        label: t('projectCalculate.timeLineElementTabTime'),
      },
      {
        name: 'Offset',
        label: t('projectCalculate.timeLineElementTabOffset'),
      },
    ];
  }, [controlState?.timeElement, t]);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: inModal ? 'bottom-start': 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: [(inModal ? 'top-start': 'top-end'), 'auto'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const [showPopover, setShowPopover] = useState(false);
  // detect closing popover
  useEffect(() => {
    if (!popperElement) {
      if (showPopover && onHidePopover) {
        onHidePopover();
      }
      setShowPopover(false);
    } else {
      setShowPopover(true);
      if (!timeLineData && optionalNode) {
        setControlState({ timeCustomDate: null, timeElement: 'time-custom' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  const handleTimeElementChange = (value: string) => {
    const newState = { ...controlState, timeElement: value };

    if (value !== 'time-custom') {
      newState.duration = null;
    }

    setControlState(newState);
    triggerOnChange(newState);
  };

  const handleControlStateChange = (state: Partial<ControlState>) => {
    const newState = { ...controlState, ...state };

    setControlState(newState);
    triggerOnChange(newState);
  };

  function handleTabChange() {
    panelsRef.current?.scrollTo(0, 0);
  }

  useEffect(() => {
    if (!timeLineData) {
      setControlState(defaultControlState);
      return;
    }

    const newState = { ...controlState };

    if (timeLineData?.type === 'FixedDates') {
      newState.timeElement = 'time-custom';

      if (timeLineData.fixedDate) {
        newState.timeCustomDate = formatDateOnly(new Date(timeLineData.fixedDate));
      }
      if (timeLineData?.duration && timeLineData.duration > 0) {
        newState.duration = timeLineData.duration;
      }
    }

    if (timeLineData?.type === 'ProjectEnd') {
      newState.timeElement = 'project-end';
    }

    if (timeLineData?.type === 'TimelineReference') {
      newState.timeElement = `tf-${timeLineData.elementTimelineId}`;
    }

    if (timeLineData?.type === 'DeliveryPhaseReference') {
      newState.timeElement = `dp-${timeLineData.variantDeliveryPhaseId}`;
    }

    if (timeLineData?.type === 'MileStoneReference') {
      newState.timeElement = `ms-${timeLineData.variantMileStoneId}`;
    }

    if (timeLineData?.offsetPosition === 'End') {
      newState.offsetBegin = 'offset-begin-end';
    }

    if (timeLineData?.offset && timeLineData.offset > 0 && timeLineData.offset < 7) {
      newState.offsetAmount = `option-${timeLineData.offset}`;
    }

    if (timeLineData?.offset && timeLineData.offset > 6) {
      newState.offsetAmount = 'option-custom';
      newState.offsetCustomAmount = timeLineData.offset;
    }

    if (timeLineData?.offsetUnit) {
      newState.offsetCustomUnit = timeLineData?.offsetUnit;
    }

    if (timeLineData?.durationUnit) {
      newState.durationCustomUnit = timeLineData?.durationUnit;
    }

    setControlState(newState);
  }, [timeLineData]); // eslint-disable-line react-hooks/exhaustive-deps
  // FP: we only want to run this effect when the timeLineData changes

  const triggerOnChange = (currentState: ControlState) => {
    const result: TimeInput = {
      durationUnit: currentState.durationCustomUnit,
      type: 'FixedDates',
      offsetUnit: currentState.offsetCustomUnit,
    };

    if (onChange) {
      // Fixed date
      if (currentState.timeElement === 'time-custom') {
        result.type = 'FixedDates';

        // either we have a custom date ( = fixed date)
        if (currentState.timeCustomDate) {
          result.fixedDate = currentState.timeCustomDate;
          result.effectiveDate = currentState.timeCustomDate;
        }
        // or we have a duration, then we add the months to the start date
        if (currentState.duration && currentState.duration > 0 && timeLineData?.durationStartDate) {
          result.duration = currentState.duration;

          result.fixedDate = addMonthsToDate(timeLineData.durationStartDate, result.duration ?? 0);
          result.effectiveDate = result.fixedDate;
        }
      } // Project end
      else if (currentState.timeElement === 'project-end') {
        result.type = 'ProjectEnd';
        result.fixedDate = formatDateOnly(calculationModelEndDate);
        result.effectiveDate = formatDateOnly(calculationModelEndDate);
      } // Referenced
      else {
        if (currentState.timeElement?.startsWith('tf-')) {
          const id = currentState.timeElement.substring(3);
          const selectedElement = timeFrameElements.find((e) => e.id === id);

          if (selectedElement) {
            result.elementTimelineId = selectedElement?.id;
            result.type = 'TimelineReference';
            result.effectiveDate = selectedElement.effectiveStart;
          }
        } else if (currentState.timeElement?.startsWith('dp-')) {
          const id = currentState.timeElement.substring(3);
          const selectedElement = deliveryPhases?.deliveryPhases.find((e) => e.id === id);

          if (selectedElement) {
            result.variantDeliveryPhaseId = selectedElement?.id;
            result.type = 'DeliveryPhaseReference';
            if (currentState.offsetBegin && currentState.offsetBegin === 'offset-begin-end') {
              result.effectiveDate = selectedElement.timeLine.effectiveEndDate;
            } else {
              result.effectiveDate = selectedElement.timeLine.startFixedStartDate;
            }
          }
        } else if (currentState.timeElement?.startsWith('ms-')) {
          const id = currentState.timeElement.substring(3);
          const selectedElement = milestones?.milestones.find((e) => e.id === id);

          if (selectedElement) {
            result.variantMileStoneId = selectedElement?.id;
            result.type = 'MileStoneReference';
            result.effectiveDate = selectedElement.date;
          }
        }

        // for references we can set an offset
        if (currentState.offsetAmount || (currentState.offsetCustomAmount && currentState.offsetCustomAmount > 0)) {
          result.offsetPosition = currentState.offsetBegin === 'offset-begin-start' ? 'Start' : 'End';

          if (currentState.offsetAmount === 'option-custom') {
            result.offset = currentState.offsetCustomAmount ? currentState.offsetCustomAmount : 0;
          } else {
            const offset = currentState.offsetAmount ? Number(currentState.offsetAmount.substring(7)) : 0;
            const offsetPrefix = offset && offset >= 0 ? '+' : '-';

            result.offset = currentState.offsetAmount ? parseInt(`${offsetPrefix}${offset}`) : 0;
          }
        }
        if (currentState.offsetBegin && !currentState.offsetAmount) {
          if (!result.durationUnit) result.durationUnit = 'Months';
          result.offsetPosition = currentState.offsetBegin === 'offset-begin-start' ? 'Start' : 'End';
          result.offset = -0;
        }

        if (result.effectiveDate && result.offset) {
          result.effectiveDate = addMonthsToDate(result.effectiveDate, result.offset);
        }
      }

      onChange(result);
    }
  };

  useEffect(() => {
    if (optionalNode && timeLineData?.type === 'ProjectEnd') {
      triggerOnChange(controlState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculationModelEndDate]);

  const selectedOffsetText = useMemo(() => {
    if (controlState.timeElement !== 'time-custom') {
      let offsetPrefix = '+';
      const offsetSuffix = 'months'.substring(0, 1).toUpperCase();
      let offsetAmountValue: number;

      if (controlState.offsetBegin === 'offset-begin-end') {
        offsetPrefix = '-';
      }

      if (controlState.offsetAmount === 'option-custom') {
        offsetAmountValue = controlState.offsetCustomAmount ? controlState.offsetCustomAmount : 0;
      } else {
        offsetAmountValue = controlState.offsetAmount ? parseInt(controlState.offsetAmount.substring(7) ?? '0') : 0;
      }

      return offsetAmountValue > 0 ? `${offsetPrefix}${offsetAmountValue}${offsetSuffix}` : undefined;
    }
    return '';
  }, [controlState]);

  let selectedElement:
    | DeliveryPhaseOverviewReadModel
    | CalculationModelElementTimelineReadModel
    | CalculationModelMilestoneReadModel
    | undefined;
  let selectedElementCode: string | undefined;
  let selectedElementDescription: string | undefined;

  if (controlState.timeElement?.startsWith('tf-')) {
    const id = controlState.timeElement.substring(3);

    selectedElement = timeFrameElements.find((e) => e.id === id);
    selectedElementCode = undefined;
    selectedElementDescription = selectedElement?.description;
  } else if (controlState.timeElement?.startsWith('dp-')) {
    const id = controlState.timeElement.substring(3);

    selectedElement = deliveryPhases?.deliveryPhases.find((e) => e.id === id);
    selectedElementCode = selectedElement?.code;
    selectedElementDescription = selectedElement?.name;
  } else if (controlState.timeElement?.startsWith('ms-')) {
    const id = controlState.timeElement.substring(3);

    selectedElement = milestones?.milestones.find((e) => e.id === id);
    selectedElementCode = selectedElement?.code;
    selectedElementDescription = selectedElement?.name;
  }

  let selectedTimeText: string | undefined;

  if (controlState.timeElement === 'time-custom' && controlState.timeCustomDate) {
    selectedTimeText = formatDate(new Date(controlState.timeCustomDate));
  }

  const durationPrefix = controlState.duration && controlState.duration >= 0 ? '+' : '-';

  const groupedTimeframeElements = groupBy(timeFrameElements, ['type']);

  const noValue =
    isEmpty(controlState.offsetAmount) &&
    isEmpty(controlState.offsetCustomAmount) &&
    isEmpty(controlState.duration) &&
    isEmpty(controlState.timeElement) &&
    isEmpty(controlState.timeCustomDate);

  return (
    <div className={className}>
      {(isLoadingDeliveryPhases || isLoadingMilestones || isLoadingVariantData) && (
        <LoadingIndicator text={t('common.loading') ?? ''} mode="overlay" />
      )}
      <div className="w-full relative h-14 bg-white">
        <Popover as="div">
          <>
            <PopoverButton
              as="div"
              ref={setReferenceElement}
              className={classNames('w-full relative h-14 px-3 flex flex-row bg-white outline-none peer', className, {
                'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
                  showValidation && isValidationValid != null && !isValidationValid,
                'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
                'pointer-events-none': disabled,
              })}
            >
              {icon && (
                <div className="flex items-center h-full">
                  <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
                </div>
              )}
              <div
                className={classNames('relative flex-grow min-w-0', {
                  'ml-2': icon,
                  'mx-1': !icon,
                })}
              >
                <div
                  className={classNames(
                    'fake-mt block pt-5 w-full text-lg appearance-none focus:outline-none bg-transparent font-medium text-left truncate',
                    {
                      'text-gray-800': !disabled,
                      'text-gray-500 cursor-not-allowed': disabled,
                      'pr-4': showChevronDown,
                    },
                  )}
                >
                  {!showValue ? (
                    '-'
                  ) : (
                    <>
                      {selectedTimeText && <span className="font-semibold">{selectedTimeText}&nbsp;</span>}
                      {selectedElement && (
                        <span className="font-semibold">{`${
                          selectedElementCode ? selectedElementCode + ' - ' : ''
                        }${selectedElementDescription}`}</span>
                      )}
                      {showDuration && controlState.duration && controlState.duration > 0 && (
                        <span className="font-semibold">
                          {durationPrefix}
                          {controlState.duration}M
                        </span>
                      )}
                      {selectedOffsetText && (
                        <span className="font-light">
                          {selectedTimeText || (selectedElement && <>,</>)} {selectedOffsetText}
                        </span>
                      )}
                      {!selectedTimeText && !showDuration && !selectedElement && timeLineData?.effectiveDate && (
                        <span className="font-semibold">{formatDate(new Date(timeLineData.effectiveDate))}&nbsp;</span>
                      )}
                    </>
                  )}
                </div>
                <label
                  htmlFor={inputId}
                  className={classNames(
                    'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate text-left',
                    {
                      'pt-3 mt-[3px]': noValue && isEmpty(placeHolder),
                      'pt-5 -mt-px text-xs -translate-y-3': !noValue || !isEmpty(placeHolder),
                    },
                  )}
                >
                  {label}
                </label>
              </div>
              {showChevronDown && (
                <div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon className="w-5 h-5 text-gray-800" />
                  </div>
                </div>
              )}
            </PopoverButton>
            <PopoverPanel
              portal
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-20 h-96"
            >
              <div
                className={classNames('rounded rounded-tr-none bg-slate-100 w-[32rem] z-20 h-96 shadow absolute', {
                  'right-0': !inModal,
                  'left-0': inModal,
                })}
              >
                <TabGroup defaultIndex={0}>
                  <TabList className="shadow px-6 z-10 relative">
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.name}
                        className={({ selected }) =>
                          classNames(
                            'px-4 pt-6 border-b-4 border-transparent text-sm font-medium outline-none focus:outline-none transition-colors',
                            selected ? 'border-b-blue-500 bg-zinc-200' : '',
                          )
                        }
                        onClick={() => handleTabChange()}
                      >
                        {tab.label}
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanels className="overflow-y-auto max-h-80 pb-12 t-5" ref={panelsRef}>
                    <TabPanel className="px-6">
                      {optionalNode && <RadioGroup>{optionalNode}</RadioGroup>}
                      <RadioGroup
                        value={controlState.timeElement}
                        onChange={handleTimeElementChange}
                        name="time-options"
                      >
                        {showProjectEnd ? (
                          <Radio value="project-end" as={Fragment} key="time-radio">
                            {({ checked }) => (
                              <label key="project-end" className="flex bg-white border-b py-3 px-3">
                                <div className="font-bold flex-shrink-0">
                                  {t('projectCalculate.timeLineElementProjectEnd')}
                                </div>
                                <div className="px-2">&bull;</div>
                                <div className="flex-grow truncate">{formatDate(calculationModelEndDate)}</div>
                                <div className="flex flex-col items-center justify-center">
                                  <CustomRadioCircle checked={checked} />
                                </div>
                              </label>
                            )}
                          </Radio>
                        ) : (
                          <>
                            {showFixedDates && (
                              <Radio value={`time-custom`} as={Fragment} key="time-radio">
                                {({ checked }) => (
                                  <div className="bg-white border-b flex">
                                    {showDuration && (
                                      <NumberInput
                                        label={t('projectCalculate.deliveryPhaseDurationInMonths')}
                                        value={controlState.duration}
                                        onChange={(value) =>
                                          handleControlStateChange({
                                            duration: Number(value),
                                            timeCustomDate: null,
                                            timeElement: 'time-custom',
                                          })
                                        }
                                        className="w-full"
                                      />
                                    )}
                                    <DatePicker
                                      label={t('common.date')}
                                      value={controlState.timeCustomDate}
                                      onChange={(value) =>
                                        handleControlStateChange({
                                          ...defaultControlState,
                                          ...{
                                            duration: undefined,
                                            timeCustomDate: formatNullableDateOnly(value) ?? null,
                                            timeElement: 'time-custom',
                                          },
                                        })
                                      }
                                      className="w-full"
                                      icon={<TimeLimitIcon />}
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      onTogglePopover={() =>
                                        setControlState({
                                          timeCustomDate: controlState.timeCustomDate,
                                          timeElement: 'time-custom',
                                        })
                                      }
                                    />
                                    <div className="flex flex-col items-center justify-center pr-3">
                                      <CustomRadioCircle checked={checked} />
                                    </div>
                                  </div>
                                )}
                              </Radio>
                            )}
                            {showVariableDates && (
                              <>
                                <div>
                                  <div className="font-light text-xl pb-2 mt-4 ml-1">
                                    {t('projectCalculate.timeLineElementPhases')}
                                  </div>
                                  {deliveryPhases?.deliveryPhases.map((phase) => (
                                    <Radio value={`dp-${phase.id}`} as={Fragment} key={phase.id}>
                                      {({ checked }) => (
                                        <label key={phase.id} className="flex bg-white border-b py-3 px-3">
                                          <div className="font-bold flex-shrink-0">{phase.code}</div>
                                          <div className="px-2">&bull;</div>
                                          <div className="flex-grow truncate">{phase.name}</div>
                                          <div className="flex flex-col items-center justify-center">
                                            <CustomRadioCircle checked={checked} />
                                          </div>
                                        </label>
                                      )}
                                    </Radio>
                                  ))}
                                </div>
                                {milestones && milestones.milestones.length > 0 && (
                                  <div>
                                    <div className="font-light text-xl pb-2 mt-4 ml-1">Milestones</div>
                                    {milestones?.milestones.map((milestone) => (
                                      <Radio value={`ms-${milestone.id}`} as={Fragment} key={milestone.id}>
                                        {({ checked }) => (
                                          <label key={milestone.id} className="flex bg-white border-b py-3 px-3">
                                            <div className="font-bold flex-shrink-0">{milestone.code}</div>
                                            <div className="px-2">&bull;</div>
                                            <div className="flex-grow truncate">{milestone.name}</div>
                                            <div className="flex flex-col items-center justify-center">
                                              <CustomRadioCircle checked={checked} />
                                            </div>
                                          </label>
                                        )}
                                      </Radio>
                                    ))}
                                  </div>
                                )}
                              </>
                            )}
                            {!optionalNode &&
                              Object.keys(groupedTimeframeElements).map((key, index) => (
                                <div key={index}>
                                  <div className="font-light text-xl pb-2 mt-4 ml-1">{key}</div>
                                  {groupedTimeframeElements[key].map((timeFrame) => (
                                    <Radio value={`tf-${timeFrame.id}`} as={Fragment} key={timeFrame.id}>
                                      {({ checked }) => (
                                        <label className="flex bg-white border-b py-3 px-3">
                                          <div className="flex-grow truncate">{timeFrame.description}</div>
                                          <div className="flex flex-col items-center justify-center">
                                            <CustomRadioCircle checked={checked} />
                                          </div>
                                        </label>
                                      )}
                                    </Radio>
                                  ))}
                                </div>
                              ))}
                          </>
                        )}
                      </RadioGroup>
                    </TabPanel>
                    <TabPanel className="px-6">
                      {!showProjectEnd && (
                        <div>
                          <RadioGroup
                            value={controlState.offsetBegin}
                            onChange={(value) => handleControlStateChange({ offsetBegin: value })}
                            name="offset-begin"
                          >
                            <Radio value="offset-begin-start" as={Fragment}>
                              {({ checked }) => (
                                <label className="flex bg-white border-b py-3 px-3">
                                  <div className="font-bold flex-shrink-0 flex-grow">
                                    {t('projectCalculate.timeLineElementAtBeginning')}
                                  </div>
                                  <div className="flex flex-col items-center justify-center">
                                    <CustomRadioCircle checked={checked} />
                                  </div>
                                </label>
                              )}
                            </Radio>
                            <Radio value="offset-begin-end" as={Fragment}>
                              {({ checked }) => (
                                <label className="flex bg-white border-b py-3 px-3">
                                  <div className="font-bold flex-shrink-0 flex-grow">
                                    {t('projectCalculate.timeLineElementAtEnd')}
                                  </div>
                                  <div className="flex flex-col items-center justify-center">
                                    <CustomRadioCircle checked={checked} />
                                  </div>
                                </label>
                              )}
                            </Radio>
                          </RadioGroup>
                        </div>
                      )}
                      <div className="mt-2">
                        <RadioGroup
                          value={controlState.offsetAmount}
                          onChange={(value) => handleControlStateChange({ offsetAmount: value })}
                          name="offset-options"
                        >
                          {monthOptions.map((option) => (
                            <Radio value={`option-${option}`} as={Fragment} key={option}>
                              {({ checked }) => (
                                <label key={option} className="flex bg-white border-b py-3 px-3">
                                  <div className="font-bold flex-shrink-0 flex-grow">
                                    + {option} {t('common.month', { count: option })}
                                  </div>
                                  <div className="flex flex-col items-center justify-center">
                                    <CustomRadioCircle checked={checked} />
                                  </div>
                                </label>
                              )}
                            </Radio>
                          ))}
                          <div>
                            <Radio value={`option-custom`} as={Fragment} key={'option-custom'}>
                              {({ checked }) => (
                                <div className="bg-white border-b relative">
                                  <NumberInput
                                    label={t('common.month', { count: 3 })}
                                    value={
                                      controlState.offsetCustomAmount && controlState.offsetCustomAmount > 0
                                        ? controlState.offsetCustomAmount
                                        : null
                                    }
                                    onChange={(value) => {
                                      handleControlStateChange({
                                        offsetAmount: 'option-custom',
                                        offsetCustomAmount: Number(value),
                                      });
                                    }}
                                    className="w-full"
                                  />
                                  <div className="absolute right-0 top-0 bottom-0 flex flex-col items-center justify-center px-3">
                                    <CustomRadioCircle checked={checked} />
                                  </div>
                                </div>
                              )}
                            </Radio>
                          </div>
                        </RadioGroup>
                      </div>
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            </PopoverPanel>
          </>
        </Popover>
      </div>
      {helperText && (
        <div className="w-full bg-white">
          <FormHelperText text={helperText} error={!isValidationValid} />
        </div>
      )}
    </div>
  );
};

const CustomRadioCircle = ({ ...props }) => (
  <div className="h-4 w-4 border-2 rounded-full border-neutral-500 focus:ring-2 focus:ring-blue-300 flex items-center justify-center">
    <div className={classNames('w-2 h-2 rounded-full', { 'bg-black': props.checked })}></div>
  </div>
);
