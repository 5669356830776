import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { Button } from '.';
import { useTranslation } from 'react-i18next';

interface MonthPickerProps {
  label: string;
  value?: Date | string | null;
  onChange: (value?: Date | null) => void;
  className?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  onTogglePopover?: () => void;
  disablePortal?: boolean;
}

export const MonthPicker = ({
  label,
  value,
  className,
  icon,
  disabled,
  onTogglePopover,
  disablePortal,
  onChange,
}: MonthPickerProps) => {
  const { t } = useTranslation();
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  const [year, setYear] = useState(new Date().getFullYear());
  const [viewYear, setViewYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const handleTogglePopover = () => {
    if (onTogglePopover) {
      onTogglePopover();
    }
  };

  const viewPreviousYear = () => {
    setViewYear(viewYear - 1);
  };

  const viewNextYear = () => {
    setViewYear(viewYear + 1);
  };

  const handleOnChange = (month: number, year: number) => {
    const date = new Date(year, month, 1);
    onChange(date);
  };

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setYear(date.getFullYear());
      setMonth(date.getMonth() + 1);
    }
  }, [value]);

  const months = [
    { label: t('common.monthJanuary'), value: 1 },
    { label: t('common.monthFebruary'), value: 2 },
    { label: t('common.monthMarch'), value: 3 },
    { label: t('common.monthApril'), value: 4 },
    { label: t('common.monthMay'), value: 5 },
    { label: t('common.monthJune'), value: 6 },
    { label: t('common.monthJuly'), value: 7 },
    { label: t('common.monthAugust'), value: 8 },
    { label: t('common.monthSeptember'), value: 9 },
    { label: t('common.monthOctober'), value: 10 },
    { label: t('common.monthNovember'), value: 11 },
    { label: t('common.monthDecember'), value: 12 },
  ];

  return (
    <div className={className}>
      <div className="w-full relative h-14 bg-white">
        <Popover>
          {({ open }) => (
            <>
              <div
                ref={setTargetElement}
                className={classNames('w-full relative h-14 flex flex-row bg-white outline-none peer')}
              >
                <div className="w-full px-3 flex">
                  {icon && (
                    <div className="flex items-center h-full">
                      <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
                    </div>
                  )}
                  <div
                    className={classNames('relative flex-grow', {
                      'ml-2': icon,
                      'mx-1': !icon,
                    })}
                  >
                    <div className="fake-mt block pt-5 w-full text-lg appearance-none focus:outline-none bg-transparent font-medium text-gray-700">
                      {months.find((m) => m.value === month)?.label} {year}
                    </div>
                    <label className="absolute top-0 left-0 right-0 text-xs duration-200 origin-0 text-gray-600 select-none transform truncate text-left cursor-pointer mt-[8px]">
                      {label}
                    </label>
                  </div>

                  <PopoverButton
                    className={classNames('flex items-center pl-1 pr-3 -mr-3 z-20 ', {
                      'text-gray-800 cursor-pointer': !disabled,
                      'text-gray-500 cursor-not-allowed': disabled,
                    })}
                    disabled={disabled}
                  >
                    <CalendarDaysIcon className="w-5 h-5" onClick={handleTogglePopover} />
                  </PopoverButton>
                </div>
                <div
                  className={classNames(
                    'absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-200 transition-opacity peer-focus:opacity-100',
                    {
                      'opacity-0': !open,
                    }
                  )}
                />
              </div>
              <PopoverPanel
                portal={disablePortal === false}
                as="div"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className="date-picker-portal-wrapper z-50 w-full flex items-center justify-center bg-white border border-gray-200 shadow-lg outline-none border-t-0 rounded-bl-lg rounded-br-lg"
              >
                {({ close }) => (
                  <div className="flex flex-col h-full w-full">
                    <div className="p-3 flex flex-row items-center justify-between">
                      <Button
                        aria-label="Previous Year"
                        variant="secondary"
                        className="aspect-square"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          viewPreviousYear();
                        }}
                      >
                        <ChevronLeftIcon className="w-5 h-5 text-black" />
                      </Button>

                      <div className="font-bold text-lg text-center">{viewYear}</div>

                      <Button
                        aria-label="Next Year"
                        variant="secondary"
                        className="aspect-square"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          viewNextYear();
                        }}
                      >
                        <ChevronRightIcon className="w-5 h-5 text-gray-800" />
                      </Button>
                    </div>

                    <div className="p-3 pt-0 flex justify-center">
                      <div className="grid grid-cols-2 w-fit text-sm font-medium text-gray-500">
                        {months.map((m) => (
                          <div
                            key={m.value}
                            className={classNames('p-2 cursor-pointer hover:bg-gray-100', {
                              'bg-gray-100': m.value === month && viewYear === year,
                            })}
                            onClick={() => {
                              setMonth(m.value);
                              setYear(viewYear);
                              handleOnChange(m.value, viewYear);
                              close();
                            }}
                          >
                            {m.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </PopoverPanel>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};
