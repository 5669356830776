import {
  ElementReadModel,
  VatReadModel,
  useApiDeleteProjectConstructionPhaseMutation,
  useApiDeleteProjectStatusMutation,
  useApiDeleteProjectVatMutation,
} from '@client/shared/api';
import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import {ProjectSettingGroupType} from '.';

interface ProjectSettingElementDeleteModalProps extends ModalOnCloseProps {
  groupType: ProjectSettingGroupType;
  projectId: string;
  element?: ElementReadModel;
  vatElement?: VatReadModel;
  getSettingElementGroupTranslation: (groupType: ProjectSettingGroupType) => string;
}

export const ProjectSettingElementDeleteModal = ({
  onClose,
  groupType,
  projectId,
  element,
  vatElement,
  getSettingElementGroupTranslation,
}: ProjectSettingElementDeleteModalProps) => {
  const { t } = useTranslation();

  const [deleteVat, { isLoading: isDeletingVat }] = useApiDeleteProjectVatMutation();
  const [deleteStatus, { isLoading: isDeletingStatus }] = useApiDeleteProjectStatusMutation();
  const [deleteConstructionPhase, { isLoading: isDeletingPhase }] = useApiDeleteProjectConstructionPhaseMutation();

  const handleOnClose = () => {
    onClose(false);
  };

  const handleVatDelete = async () => {
    try {
      await safeMutation(
        deleteVat,
        {
          projectId: projectId,
          id: vatElement?.id ?? '',
        },
        isDeletingVat
      );

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  const handleElementDelete = async () => {
    try {
      await safeMutation(
        groupType === 'Status' ? deleteStatus : deleteConstructionPhase,
        {
          projectId: projectId,
          id: element?.id ?? '',
        },
        groupType === 'Status' ? isDeletingStatus : isDeletingPhase
      );

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  const handleDelete = async () => {
    switch (groupType) {
      case 'Vat':
        await handleVatDelete();
        break;
      default:
        await handleElementDelete();
        break;
    }
  };

  const translatedGrouptype = getSettingElementGroupTranslation(groupType);

  return (
    <ConfirmModal
      title={t('projectSetting.deleteTitle', { element: translatedGrouptype })}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isDeletingVat || isDeletingStatus || isDeletingPhase}
    >
      {(isDeletingVat || isDeletingStatus || isDeletingPhase) && <LoadingIndicator text={t('project.updatingProjectLoadingIndicator')} mode="overlay" />}
      <HintBox hintType="warning">{t('projectSetting.deleteHint', { element: translatedGrouptype })}</HintBox>
    </ConfirmModal>
  );
};
