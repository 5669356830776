export function isValidSWIFT(swift: string | undefined | null): boolean {
    const regex = new RegExp(/^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/);
    if (swift == null || swift === undefined) return false;
    else return regex.test(swift);
  }

export function isValidIBAN(iban: string | undefined | null): boolean {
    const regex = new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/);
    if (iban == null || iban === undefined) return false;
    else return regex.test(iban);
  } 