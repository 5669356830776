import { Avatar, CardDataSet } from '@client/shared/toolkit';
import { CompanyBranchReadModel } from '@client/shared/api';
import cn from 'classnames';

interface BranchDataProps {
  branch: CompanyBranchReadModel;
  label: string;
  branchNameClassName?: string
}

export const BranchData = (props: BranchDataProps) => {
  const { branch, label, branchNameClassName } = props;
  return (
    <div className="flex gap-5">
      <Avatar
        name={branch.name ?? ''}
        className="w-12 h-12 flex-none"
        rounded={false}
        randomBgColor={false}
        imagePath={branch.logoImageId ? `/api/companies/${branch.companyId}/logo/${branch.logoImageId}` : undefined}
      />
      <CardDataSet label={label}>
        <span className={cn('font-bold text-lg leading-tight mb-1.5', branchNameClassName)}>{branch.name ?? '-'}</span>
        {branch.address && (
          <>
            <span className="text-gray-500 text-[15px] leading-tight">
              {branch.address.street} {branch.address.number}
            </span>
            <span className="text-gray-500 text-[15px] leading-tight">
              {branch.address.postalCode} {branch.address.city}
            </span>
            <span className="text-gray-500 text-[15px] leading-tight">{branch.address.country}</span>
          </>
        )}
      </CardDataSet>
    </div>
  );
};
