import { Modal } from './Modal';
import { ButtonVariants, Button } from './Button';
import { PropsWithChildren, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps extends PropsWithChildren {
  title: string;
  description?: string;
  onConfirm: () => void;
  onClose: () => void;
  cancelText?: string;
  confirmText?: string;
  cancelVariant?: ButtonVariants;
  confirmVariant?: ButtonVariants;
  isLoading?: boolean;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation();
  const {
    title,
    description,
    cancelVariant = 'text',
    confirmVariant = 'primary',
    onConfirm,
    onClose,
    children,
    confirmText = t('common.ok'),
    cancelText = t('common.cancel'),
    isLoading = false,
  } = props
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  return (
    <>
      <Modal.Header title={title} description={description} />
      <Modal.Content className="text-center">{children}</Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant={cancelVariant} onClick={onClose} className="mr-2">
          {cancelText}
        </Button>
        <Button variant={confirmVariant} disabled={isLoading} onClick={onConfirm} innerRef={buttonRef}>
          {confirmText}
        </Button>
      </Modal.Controls>
    </>
  );
};
