import { BaseSelect, Button, LoadingIndicator, Modal, ModalOnCloseProps } from '@client/shared/toolkit';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiCreateDatevAuthenticationUrlQuery,
  useApiGetDatevActiveClientsQuery,
  useApiGetDatevClientsQuery
} from '@client/shared/api';
import { DatevAuthState, updateDatevAuthData } from '@client/project/store';

interface DatevClientUpdateModalProps extends ModalOnCloseProps {
  projectId?: string;
  apiAccessId?: string;
  datevClientId?: string;
  isOpen: boolean;
}

export const DatevClientUpdateModal = ({
  projectId,
  apiAccessId,
  datevClientId,
  onClose,
  isOpen,
}: DatevClientUpdateModalProps) => {
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<string>(datevClientId ?? '');
  const [selectedDatevClientId, setSelectedDatevClientId] = useState<string>('');

  const { data: tenantClients, isFetching } = useApiGetDatevClientsQuery(
    {
      datevApiAccessId: apiAccessId ?? '',
    },
    {
      skip: !apiAccessId || !!projectId,
    },
  );

  const { data: projectClients, isFetching: isFetchingClients } = useApiGetDatevActiveClientsQuery(undefined, {
    skip: !projectId,
  });

  const { data: createDatevAuthUrlResponse, isFetching: isFetchingDatevAuthUrl } = useApiCreateDatevAuthenticationUrlQuery(
    {
      datevClientId: selectedDatevClientId,
    },
    {
      skip: selectedClient === datevClientId,
      refetchOnMountOrArgChange: true
    },
  );

  const handleSubmit = async () => {
    if (createDatevAuthUrlResponse?.url && selectedClient && selectedDatevClientId) {
      updateDatevAuthData({
        datevClientId: selectedClient,
        datevUpdateData: {
          projectId: projectId,
          apiAccessId: apiAccessId
        }
      } as DatevAuthState);
      window.open(createDatevAuthUrlResponse.url, '_self');
    }
  };

  const clientOptions = useMemo(() => {
    const data = projectId ? projectClients : tenantClients;
    return (
      data?.clients.map((client) => {
        return {
          label: client.name,
          value: client.id,
        };
      }) ?? []
    );
  }, [projectClients, projectId, tenantClients]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header
        title={t('app.settingsApiDatevChooseClient')}
        className="mb-0"
        description={t('app.settingsApiDatevChooseClientDescription')}
      />
      <Modal.Content className="flex items-center justify-center !flex-none">
        {(isFetching || isFetchingClients || isFetchingDatevAuthUrl) && (
          <LoadingIndicator text={t('app.settingsApiDatevLoadingClients') ?? ''} mode="overlay" />
        )}

        {clientOptions.length === 0 ? (
          t('app.settingsApiDatevNoClientsAvailable')
        ) : (
          <BaseSelect
            className="w-full"
            label={t('app.settingsApiDatevClient')}
            options={clientOptions}
            value={selectedClient}
            onChange={(value) => {
              setSelectedClient(value);
              let foundClientDatevId = '';
              if (projectId) {
                const foundClient = projectClients?.clients.find((client) => client.id === value);
                foundClientDatevId = foundClient?.clientId ?? '';
              } else {
                const foundClient = tenantClients?.clients.find((client) => client.id === value);
                foundClientDatevId = foundClient?.datevClientId ?? '';
              }
              setSelectedDatevClientId(foundClientDatevId);
            }}
          />
        )}
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={() => onClose(false)} variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={selectedClient === datevClientId}>
          {t('common.next')}
        </Button>
      </Modal.Controls>
    </Modal>
  );
};
