import { ElementSettingReadModel, ElementType, ProjectReadModel } from '@client/shared/api';
import {
  BaseSelect,
  BaseSelectOption,
  BillIcon,
  SlideOverTitle,
  TaxiLocationIcon,
  UsDollarCircledIcon
} from '@client/shared/toolkit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type ExtensionElement = {
  statusElementId: string | undefined;
  constructionPhaseElementId: string | undefined;
  vatElementId: string | undefined;
};

interface ExtensionElementComponentProps {
  project: ProjectReadModel;
  setting: ElementSettingReadModel;
  extensionElements: ExtensionElement;
  elementType: ElementType;
  disabled?: boolean;
  onChange: (extensionElements: ExtensionElement) => void;
}

export const ExtensionElementComponent = ({
  setting,
  project,
  extensionElements,
  elementType,
  disabled,
  onChange,
}: ExtensionElementComponentProps) => {
  const { t } = useTranslation();

  const statusList = project.payload.projectSetting?.statuses?.filter((x) => x.elementType === elementType);
  const constructionPhaseList = project.payload.projectSetting?.constructionPhases?.filter(
    (x) => x.elementType === elementType
  );
  const vatList = project.payload.projectSetting?.vats?.filter((x) => x.elementType === elementType);

  const vatOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    for (const vatOption of vatList ?? []) {
      options.push({
        label: `${vatOption.vat}` ?? 'no-vat',
        value: vatOption.id ?? 'no-id',
      });
    }

    return options;
  }, [vatList]);

  const statusOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    for (const statusOption of statusList ?? []) {
      options.push({
        label: `${statusOption.name}` ?? 'no-status',
        value: statusOption.id ?? 'no-id',
      });
    }

    return options;
  }, [statusList]);

  const constructionPhaseOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = [];
    for (const constructionPhase of constructionPhaseList ?? []) {
      options.push({
        label: `${constructionPhase.name}` ?? 'no-construction-phase',
        value: constructionPhase.id ?? 'no-id',
      });
    }

    return options;
  }, [constructionPhaseList]);

  return (
    <>
      <SlideOverTitle title={t('projectCalculate.extensionElements')} className="px-8" />
      {setting.hasVat && (
        <div className="mx-8 bg-white">
          <BaseSelect
            label={t('projectSetting.vatElement')}
            options={vatOptions}
            value={extensionElements.vatElementId ?? ''}
            disabled={disabled}
            onChange={(selected) => onChange({ ...extensionElements, vatElementId: selected })}
            icon={<UsDollarCircledIcon />}
          />
        </div>
      )}

      {setting.hasStatus && (
        <div className="mx-8 bg-white">
          <BaseSelect
            label={t('projectSetting.statusElement')}
            options={statusOptions}
            value={extensionElements.statusElementId ?? ''}
            disabled={disabled}
            onChange={(selected) => onChange({ ...extensionElements, statusElementId: selected })}
            icon={<BillIcon />}
          />
        </div>
      )}

      {setting.hasConstructionPhase && (
        <div className="mx-8 bg-white">
          <BaseSelect
            label={t('projectSetting.constructionPhaseElement')}
            options={constructionPhaseOptions}
            value={extensionElements.constructionPhaseElementId ?? ''}
            disabled={disabled}
            onChange={(selected) => onChange({ ...extensionElements, constructionPhaseElementId: selected })}
            icon={<TaxiLocationIcon />}
          />
        </div>
      )}
    </>
  );
};
