import {
  Wizard,
  Button,
  Form,
  FormField,
  TextInput,
  Modal,
  FormRefHandle,
  FormWatch,
  ContractNumberIcon,
  RenameIcon,
  CommitmentCustomIcon,
  TaskCompletedDottedIcon,
  WizardSlideHeader,
  WizardSlideContent,
  wizardHandleOnSlideChangeFormValidation,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ContractFormValidationSchema, ContractFormValidationValues } from '@client/project/shared';
import { useLoadedProject, useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';
import { ProbisErrorDataType, useApiPostCreateCommitmentMutation, useApiPostGenerateNextProjectObjectCodeMutation } from '@client/shared/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';

interface CommitmentNewWizardProps {
  onClose: () => void;
}

export const CommitmentNewWizard = ({ onClose }: CommitmentNewWizardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: loadedProject } = useLoadedProject();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const formRef = useRef<FormRefHandle<ContractFormValidationValues>>();
  const [currentStep, setCurrentStep] = useState(0);
  const [postCreateCommitment, { isLoading: isCreating }] = useApiPostCreateCommitmentMutation();
  const [getNextCode] = useApiPostGenerateNextProjectObjectCodeMutation();

  useEffect(() => {
    const getNextCommitmentCode = async () => {
      if (loadedProjectId && loadedVariantId) {
        const isGettingNextCode = false;
        const nextCodeResponse = await safeMutation(
          getNextCode,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: { projectObjectType: 'Commitment' }
          },
          isGettingNextCode,
        );
        if (typeof nextCodeResponse !== 'undefined') {
          formRef.current?.setValue('code', nextCodeResponse.code);
        }
      }
    }

    getNextCommitmentCode();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedProjectId])

  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [codeError, setCodeError] = useState(false);
  const [createdContract, setCreatedContract] = useState<string | undefined>(undefined);

  const vat = useMemo(() => {
    return loadedProject?.project?.payload?.vat;
  }, [loadedProject?.project?.payload?.vat]);

  const handleCloseModal = () => {
    onClose();
  };

  const handleCreateContract = async (data: ContractFormValidationValues) => {
    setCodeError(false);
    if (loadedProjectId && loadedVariantId) {
      try {
        const response = await safeMutation(
          postCreateCommitment,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              code: data.code,
              name: data.name,
              addedContracts: [],
              budgetAssignments: [],
            },
          },
          isCreating,
        );

        setCreatedContract(response?.commitmentId);

        // move to last page in wizard
        setCurrentStep(currentStep + 1);
      } catch (e) {
        handleError(e);
      }
    }
  };

  const handleError = (e: unknown) => {
    const error = e as FetchBaseQueryError;
    const data = error.data as ProbisErrorDataType;
    if (data?.code === 'error.commitment.code_already_exists') {
      setCodeError(true);
    }
  };

  const defaultFormValues = {
    name: '',
    code: '',
    vat: vat,
    files: undefined,
  };

  return (
    <Form<ContractFormValidationValues>
      validationSchema={ContractFormValidationSchema}
      defaultValues={defaultFormValues}
      onSubmit={handleCreateContract}
      ref={formRef}
      className="h-full"
    >
      <Wizard
        currentStep={currentStep}
        onSlideChange={(step) => wizardHandleOnSlideChangeFormValidation(step, formRef, setCurrentStep)}
        className="w-[740px] h-[560px]"
      >
        {isCreating && <LoadingIndicator text={t('projectContract.wizardNewCommitmentLoading') ?? ''} mode="overlay" />}
        <Wizard.Slides>
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<CommitmentCustomIcon />}
              title={t('projectContract.wizardNewCommitment')}
              subTitle={t('projectContract.wizardNewCommitmentSubtitle')}
            />
            <WizardSlideContent className="flex">
              <FormField name="selectedType">
                {(control) => <TextInput className="hidden" disabled label="" {...control} />}
              </FormField>
              <FormWatch<ContractFormValidationValues>
                onChange={({ code, name }) => {
                  if (code && name) {
                    setIsCreateDisabled(false);
                  } else {
                    setIsCreateDisabled(true);
                  }
                }}
                fieldNames={['code', 'name']}
              >
                {() => (
                  <>
                    <FormField name="code">
                      {(control) => (
                        <TextInput
                          className="w-1/4"
                          label={t('projectContract.contractCode')}
                          icon={<ContractNumberIcon />}
                          helperTextClassName="bg-transparent"
                          helperTextTruncate={false}
                          {...control}
                          isValidationValid={!codeError && control.isValidationValid}
                          showValidation={codeError || control.showValidation}
                          helperText={codeError ? t('error.contract.code_already_exists') : control.helperText}
                        />
                      )}
                    </FormField>
                    <FormField name="name">
                      {(control) => (
                        <TextInput
                          className="flex-1 ml-1"
                          label={t('projectContract.contractName')}
                          icon={<RenameIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                  </>
                )}
              </FormWatch>
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<TaskCompletedDottedIcon />}
              title={t('projectContract.wizardNewContractComplete')}
              subTitle={t('projectContract.wizardNewContractCompleteDescription')}
              fullHeight
            />
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ count, isFirst, isLast, canGoPrevious, canGoNext, previous, next }) => (
            <Modal.Controls
              className="bg-white"
            >
              {(() => {
                if (isFirst) {
                  return (
                    <Button variant="text" onClick={handleCloseModal}>
                      {t('common.cancel')}
                    </Button>
                  );
                } else if (isLast) {
                  return (
                    <Button variant="text" onClick={handleCloseModal}>
                      {t('common.close')}
                    </Button>
                  );
                } else {
                  return (
                    <Button variant="text" disabled={!canGoPrevious} onClick={previous}>
                      {t('common.back')}
                    </Button>
                  );
                }
              })()}
              {(() => {
                if (isLast) {
                  return (
                    <Button
                      variant="primary"
                      onClick={() => {
                        onClose();
                        if (createdContract) {
                          navigate(
                            ROUTES_CONFIG.PROJECT_COMMITMENT_VIEW.path
                              .replace(':id', loadedProjectId ?? '')
                              .replace(':commitmentId', createdContract),
                          );
                        }
                      }}
                    >
                      {t('projectContract.createWizard.goToCreatedCommitmentButton')}
                    </Button>
                  );
                } else if (currentStep === count - 2) {
                  return (
                    <Button variant="primary" formSubmit disabled={isCreateDisabled}>
                      {t('common.create')}
                    </Button>
                  );
                } else {
                  return (
                    <Button variant="primary" disabled={!canGoNext} onClick={next}>
                      {t('common.next')}
                    </Button>
                  );
                }
              })()}
            </Modal.Controls>
          )}
        </Wizard.Navigation>
      </Wizard>
    </Form>
  );
};
