import { Avatar } from '@client/shared/toolkit';

interface IProps {
  tenantId: string;
  tenantName: string;
  hasAvatar: boolean;
  classNames?: string;
}

export const CompanyAvatar = ({ tenantId, tenantName, hasAvatar, classNames }: IProps) => {
  const avatarPath = hasAvatar ? '/api/tenant/avatar' : undefined;

  const classes = classNames ? classNames : 'w-12 h-12';

  return <Avatar name={tenantName} className={classes} rounded={false} randomBgColor={false} imagePath={avatarPath} />;
};
