import { CatalogElementRestBudget, CostCatalogElementDto, CostElementDto, CostElementRestBudget } from '@client/shared/api';

export const findCostElement = (costElementId: string, flattenedCostElements: CostCatalogElementDto[]): CostCatalogElementDto | CostElementDto | undefined => {
  const foundCostGroupElement = flattenedCostElements.find((x) => x.costElementId === costElementId || x.id === costElementId);
  if (foundCostGroupElement) return foundCostGroupElement;
  else {
    let foundCostElement: CostElementDto | undefined = undefined;
    flattenedCostElements.forEach((costGroupElement) => {
      if (costGroupElement.costElements.length > 0) {
        costGroupElement.costElements.forEach((costElement) => {
          if (costElement.modelCostElement && costElement.modelCostElement.elementId === costElementId) {
            foundCostElement = costElement.modelCostElement;
          }
        });
      }
    });
    return foundCostElement;
  }
}

export const findCostElementRestBudget = (costElementId: string, flattenedCostElements: CatalogElementRestBudget[]): CatalogElementRestBudget | CostElementRestBudget | undefined => {
  const foundCostGroupElement = flattenedCostElements.find((x) => x.costElementId === costElementId || x.catalogElementId === costElementId);
  if (foundCostGroupElement) return foundCostGroupElement;
  else {
    let foundCostElement: CostElementRestBudget | undefined = undefined;
    flattenedCostElements.forEach((costGroupElement) => {
      if (costGroupElement.costElements.length > 0) {
        costGroupElement.costElements.forEach((costElement) => {
          if (costElement.id === costElementId) {
            foundCostElement = costElement;
          }
        });
      }
    });
    return foundCostElement;
  }
}
