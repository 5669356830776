import * as yup from 'yup';
import { InferType } from 'yup';

export const EditProjectFormValidationSchema = yup.object({
  projectId: yup.string().required('validation.required'),
  dwhProjectId: yup.string().optional(),
  name: yup.string().required('validation.required'),
  street: yup.string().optional(),
  houseNumber: yup.string().optional(),
  postalCode: yup.string().optional(),
  city: yup.string().optional(),
  countryCode: yup.string().optional(),
  vat: yup.number().optional(),
  currency: yup.string().required(),
});

export type EditProjectFormValidationValues = InferType<typeof EditProjectFormValidationSchema>;
