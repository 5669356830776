import {
  CatalogElementData
} from '@client/shared/api';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import { ToggleButton } from '@client/shared/toolkit';
import { t } from 'i18next';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { PropsWithChildren } from 'react';

interface ImportRecordProps {
  element: CatalogElementData;
  level?: number
}

export const ImportRecord = ({ element, level = 0 }: ImportRecordProps) => {
  if (element.children?.length) {
    return (
      <Disclosure as="div" className="w-full" defaultOpen={false}>
        {({ open }) => (
          <>
            <DisclosureButton as="div">
              <div
                className={cn('flex items-center hover:text-black hover:bg-gray-50 relative', {
                  'border-b font-bold text-sm text-gray-900': level === 0,
                  'text-xs text-gray-500': level > 0,
                })}
              >
                <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
                <div className='flex flex-row items-center justify-between w-full my-1'>
                  <div className="pl-2 w-full h-full flex flex-row cursor-pointer items-center">
                    <div className="mr-5">{element.data.code}</div>
                    <div style={level > 1 ? { marginLeft: `${level * 0.6}rem` } : undefined}>
                      {element.data.name}
                    </div>
                  </div>

                  {element.errors && element.errors.length > 0 && (
                    <div className="mr-2 flex justify-end text-red-600">
                      <ExclamationCircleIcon className="w-6" />
                    </div>
                  )}
                </div>                  
              </div>
            </DisclosureButton>
            <DisclosurePanel>
              <div className='flex flex-col'>
                <CatalogImportRecord element={element}/>
                {element.children?.length > 0 && (
                  <>
                    {element.children.map((child, i) => (
                      <ImportRecord
                        key={`catalog-element-child-${child.data.code}-${i}`}
                        element={child}
                        level={level + 1}
                      />
                    ))}
                  </>
                )}                
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <Disclosure as="div" className="w-full" defaultOpen={false}>
    {({ open }) => (
      <>
        <DisclosureButton as="div">
          <div
            className={cn('flex items-center hover:text-black hover:bg-gray-50 relative', {
              'border-b font-bold text-sm text-gray-900': level === 0,
              'text-xs text-gray-500': level > 0,
            })}
          >
            <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
            <div className='flex flex-row items-center justify-between w-full my-1'>
              <div className="pl-2 w-full h-full flex flex-row cursor-pointer items-center">
                <div className="mr-5">{element.data.code}</div>
                <div style={level > 1 ? { marginLeft: `${level * 0.6}rem` } : undefined}>
                  {element.data.name}
                </div>
              </div>

              {element.errors && element.errors.length > 0 && (
                <div className="mr-2 flex justify-end text-red-600">
                  <ExclamationCircleIcon className="w-6" />
                </div>
              )}
            </div>                  
          </div>
        </DisclosureButton>
        <DisclosurePanel>
          <CatalogImportRecord element={element}/>
        </DisclosurePanel>
      </>
    )}
  </Disclosure>
  );
};

interface CatalogImportRecordProps {
  element: CatalogElementData;
}

const CatalogImportRecord = ({ element }: CatalogImportRecordProps) => {
  return (
    <div className='flex flex-col text-xs'>
      <div className="w-full text-center my-1">
        {element.errors.map((err, index) => (
          <div key={index} className="text-red-500">
            {t(err)}
          </div>
        ))}
      </div>
      <div className='ml-2'>
        <div className="flex flex-row">
          <div className="w-1/3">
            <LabeledValue value={element.data.parentCode ?? '-'}>{t('common.parentCode')}</LabeledValue>
          </div>                
          <div className="w-1/3">
            <LabeledValue value={element.data.code ?? '-'}>{t('common.code')}</LabeledValue>
          </div>
          <div className="w-1/3">
            <LabeledValue value={element.data.name ?? '-'}>{t('common.name')}</LabeledValue>
          </div>
        </div>
      </div>
    </div>
  );
};

interface LabelProps extends PropsWithChildren {
  value?: string;
}

const LabeledValue = ({ children, value }: LabelProps) => {
  return (
    <div className="flex flex-row text-slate-400">
      <div className="flex flex-row text-slate-400">
        <div className="">{children}</div>
        <div>:</div>
      </div>
      <div className="ml-2 text-primary">{value}</div>
    </div>
  );
};