type TSettings = {
  mainDomain: string;
  enableSentry: boolean;
  sentryEnvironment: string;
  clarityId?: string;
  buildId: string;
  devMode: boolean;
  queryDelay?: string;
};

export const settings: TSettings = {
  mainDomain: 'probis.test',
  enableSentry: false,
  sentryEnvironment: 'local.development',
  buildId: '(?)',
  devMode: false,
};
