import {
  excludeDeleteDeductionByType,
  IcsDeductionNewFormValidationSchema,
  IcsDeductionNewFormValidationValues,
  IcsType,
} from '.';
import {
  getIcsDeductionTypeLabel,
  getInvoiceCoverSheetDeductionGroupLabel,
  getReferenceOptionsByType,
} from '../../utils';
import { useTranslation } from 'react-i18next';
import {
  BaseSelect,
  BaseSelectOption,
  Button,
  Form,
  FormField,
  FormRefHandle,
  FormWatch, LoadingIndicator,
  NumberInput,
  PercentageIcon,
  RenameIcon,
  SlideOver,
  StaticViewLevel2Icon,
  TaskListIcon,
  TextInput,
} from '@client/shared/toolkit';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { safeMutation } from '@client/shared/utilities';
import {
  CalculationRuleGroupReadModel,
  CalculationRuleReadModel,
  CalculationRuleType, CreateCalculationRulePayload,
  InvoiceCalculationSchemeReadModel,
  ReferenceType,
  UpdateCalculationRulePayload,
  useApiPostCreateProjectCalculationRuleMutation,
  useApiPostCreateTenantCalculationRuleMutation,
  useApiPostUpdateProjectCalculationRuleMutation,
  useApiPostUpdateTenantCalculationRuleMutation,
} from '@client/shared/api';
import { ProjectTaxPickerInput } from '../ProjectTaxPickerInput';
import { getInvoiceCoverSheetDeductionNames } from '../../utils';
import { IcsDeductionValueFormField } from './IcsDeductionValueFormField';
import { IcsDeductionCumulatedValueFormFields } from './IcsDeductionCumulatedValueFormFields';

type IcsDeductionCreateSlideOverProps = {
  isOpen: boolean
  type: IcsType
  onClose: () => void
  sheet: InvoiceCalculationSchemeReadModel | null
  onAfterLeave: () => void
  group: CalculationRuleGroupReadModel | null
  calculationRule?: CalculationRuleReadModel | null
  projectId?: string
}

export const IcsDeductionCreateSlideOver = (props: IcsDeductionCreateSlideOverProps) => {
  const {
    isOpen,
    onClose,
    type,
    sheet,
    onAfterLeave,
    group,
    calculationRule,
    projectId
  } = props
  const { t } = useTranslation()

  const formRef = useRef<FormRefHandle<IcsDeductionNewFormValidationValues>>(null);

  const [createTenantIcsDeduction, { isLoading: isCreatingTenantIcsDeduction }] =
    useApiPostCreateTenantCalculationRuleMutation()
  const [createProjectIcsDeduction, { isLoading: isCreatingProjectIcsDeduction }] =
    useApiPostCreateProjectCalculationRuleMutation()

  const [updateTenantIcsDeduction, { isLoading: isUpdatingTenantIcsDeduction }] =
    useApiPostUpdateTenantCalculationRuleMutation()
  const [updateProjectIcsDeduction, { isLoading: isUpdatingProjectIcsDeduction }] =
    useApiPostUpdateProjectCalculationRuleMutation()

  const [submitted, setSubmitted] = useState(false)
  const [currentDeductionType, setCurrentDeductionType] = useState(calculationRule?.type ?? 'Deduction' as CalculationRuleType)

  const [customAmount, setCustomAmount] = useState<number | undefined | null>(calculationRule?.value)

  useEffect(() => {
    setCustomAmount(calculationRule?.value)
  }, [calculationRule?.value]);

  const handleSubmit = async (data: IcsDeductionNewFormValidationValues) => {
    if (sheet?.invoiceCalculationSchemeId && group) {
      setSubmitted(true);
      if (!nameIsNotUnique) {
        let finalValue = customAmount;
        if (sheet.type === 'Cumulated' && data.referenceType !== 'None' && data.finalInvoiceReferenceType !== 'None' && data.partialFinalInvoiceReferenceType !== 'None' && data.partialInvoiceReferenceType !== 'None') {
          finalValue = null;
        } else if (sheet.type !== 'Cumulated' && data.referenceType !== 'None') {
          finalValue = null;
        }

        const body: CreateCalculationRulePayload | UpdateCalculationRulePayload = {
          calculationRuleGroupId: data.group ?? group.calculationRuleGroupId,
          name: data.name,
          type: data.type as CalculationRuleType,
          vat: data.vat,
          percentage: data.percentage,
          value: finalValue,
          // not cumulated only:
          referenceType: sheet.type === 'Cumulated' ? null : data.referenceType as ReferenceType,
          calculationRuleGroupReferenceId: sheet.type === 'Single' && data.referenceType === 'CalculationRuleGroup' ? data.calculationRuleGroupReference : null,
          calculationRuleReferenceId: sheet.type === 'Single' && data.referenceType === 'CalculationRule' ? data.calculationRuleReference : null,
          // cumulated only:
          partialInvoiceReferenceType: sheet.type === 'Cumulated' ? data.partialInvoiceReferenceType as ReferenceType : null,
          partialInvoiceCalculationRuleReferenceId: sheet.type === 'Cumulated' ? data.partialInvoiceCalculationRuleReference : null,
          partialInvoiceCalculationRuleGroupReferenceId: sheet.type === 'Cumulated' ? data.partialInvoiceCalculationRuleGroupReference : null,
          partialFinalInvoiceReferenceType: sheet.type === 'Cumulated' ? data.partialFinalInvoiceReferenceType as ReferenceType : null,
          partialFinalInvoiceCalculationRuleReferenceId: sheet.type === 'Cumulated' ? data.partialFinalInvoiceCalculationRuleReference : null,
          partialFinalInvoiceCalculationRuleGroupReferenceId: sheet.type === 'Cumulated' ? data.partialFinalInvoiceCalculationRuleGroupReference : null,
          finalInvoiceReferenceType: sheet.type === 'Cumulated' ? data.finalInvoiceReferenceType as ReferenceType : null,
          finalInvoiceCalculationRuleReferenceId: sheet.type === 'Cumulated' ? data.finalInvoiceCalculationRuleReference : null,
          finalInvoiceCalculationRuleGroupReferenceId: sheet.type === 'Cumulated' ? data.finalInvoiceCalculationRuleGroupReference : null,
        }

        if (type === 'tenant') {
          if (!calculationRule) {
            try {
              await safeMutation(
                createTenantIcsDeduction,
                {
                  invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
                  calculationRuleGroupId: group.calculationRuleGroupId,
                  body
                },
                isCreatingTenantIcsDeduction,
              );
              setSubmitted(false);
              onClose();
            } catch (e) {
              console.log(e);
            }
          } else {
            try {
              await safeMutation(
                updateTenantIcsDeduction,
                {
                  invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
                  calculationRuleGroupId: group.calculationRuleGroupId,
                  calculationRuleId: calculationRule.calculationRuleId,
                  body: body as UpdateCalculationRulePayload
                },
                isUpdatingTenantIcsDeduction,
              );
              setSubmitted(false);
              onClose();
            } catch (e) {
              console.log(e);
            }
          }

        } else if (projectId) {
          if (!calculationRule) {
            try {
              await safeMutation(
                createProjectIcsDeduction,
                {
                  projectId: projectId,
                  invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
                  calculationRuleGroupId: group.calculationRuleGroupId,
                  body: body as CreateCalculationRulePayload
                },
                isCreatingProjectIcsDeduction,
              );
              setSubmitted(false)
              onClose();
            } catch (e) {
              console.log(e);
            }
          } else {
            try {
              await safeMutation(
                updateProjectIcsDeduction,
                {
                  projectId: projectId,
                  invoiceCalculationSchemeId: sheet.invoiceCalculationSchemeId,
                  calculationRuleGroupId: group.calculationRuleGroupId,
                  calculationRuleId: calculationRule.calculationRuleId,
                  body: body as UpdateCalculationRulePayload
                },
                isUpdatingProjectIcsDeduction,
              );
              setSubmitted(false)
              onClose();
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
    }
  }

  const allTypes = useMemo(() => {
    return [
      {
        label: getIcsDeductionTypeLabel('Deduction'),
        value: 'Deduction'
      },
      {
        label: getIcsDeductionTypeLabel('Retention'),
        value: 'Retention'
      },
      {
        label: getIcsDeductionTypeLabel('Subtotal'),
        value: 'Subtotal'
      },
      {
        label: getIcsDeductionTypeLabel('Vat'),
        value: 'Vat'
      },
      {
        label: getIcsDeductionTypeLabel('CashDiscount'),
        value: 'CashDiscount'
      },
      {
        label: getIcsDeductionTypeLabel('Discount'),
        value: 'Discount'
      },
      {
        label: getIcsDeductionTypeLabel('Repayment'),
        value: 'Repayment'
      },
      {
        label: getIcsDeductionTypeLabel('PartialPayment'),
        value: 'PartialPayment'
      },
    ]
  }, [])

  const allExistingDeductions = useMemo(() => {
    return sheet?.calculationRuleGroups.reduce((result, currentObject) => {
      result.calculationRules = [...result.calculationRules, ...currentObject.calculationRules]
      return result;
    }, { calculationRules: []} as { calculationRules: CalculationRuleReadModel[] });
  }, [sheet?.calculationRuleGroups])

  const allExistingNames = useMemo(() => {
    if (sheet) {
      return getInvoiceCoverSheetDeductionNames(sheet, [], calculationRule?.calculationRuleId ? [calculationRule?.calculationRuleId] : [])
    }
    return []
  }, [sheet, calculationRule?.calculationRuleId])

  /**
   * The following calculationRules cannot be used for group type:
   *
   * Cumulated: -
   * Not Cumulated: Partial payment, Repayment
   * Advanced Payment: Partial payment, Repayment, Cash discount, Discount
   *
   * All calculationRules, except "Deduction/Retention" and "Subtotal" can only be added once to a sheet
   */
  const calculationRuleTypeOptions = useMemo(() => {
    if (sheet?.type) {
      let types = [...allTypes]

      if (sheet.type === 'Single') {
        types = types.slice(0, -2);
      } else if (sheet.type === 'AdvancePayment') {
        types = types.slice(0, -3);
      }
      // remove VAT, Discount, CashDiscount, Repayment and PartialPayment if already added as calculationRule to sheet
      if (allExistingDeductions?.calculationRules.length) {
        allExistingDeductions?.calculationRules.forEach((existingRule) => {
          if (existingRule.type !== 'Deduction' && existingRule.type !== 'Retention' && existingRule.type !== 'Subtotal'
            // on edit, we need the current selected type in the dropdown
            && (!calculationRule || (calculationRule && calculationRule.type !== existingRule.type))) {
            types = types.filter((type) => type.value !== existingRule.type)
          }
        })
      }
      return types
    }
    return []
  }, [sheet?.type, allTypes, allExistingDeductions?.calculationRules, calculationRule])

  const [nameIsNotUnique, setNameIsNotUnique] = useState(false)

  const checkIfNameIsUnique = useCallback((name?: string) => {
    if (name && allExistingNames?.length) {
      setNameIsNotUnique(allExistingNames.includes(name))
    } else {
      setNameIsNotUnique(false)
    }
  }, [allExistingNames])

  const calculationRuleGroupReferenceOptions = useMemo(() => {
    return getReferenceOptionsByType('CalculationRuleGroup', currentDeductionType as CalculationRuleType, sheet, group, calculationRule)
  }, [currentDeductionType, sheet, group, calculationRule])

  const calculationRuleReferenceOptions = useMemo(() => {
    return getReferenceOptionsByType('CalculationRule', currentDeductionType as CalculationRuleType, sheet, group, calculationRule)
  }, [currentDeductionType, sheet, group, calculationRule])

  const groupOptions = useMemo(() => {
    const allGroupOptions: BaseSelectOption[] = []
    sheet?.calculationRuleGroups.forEach((g) => {
      allGroupOptions.push({
        label: getInvoiceCoverSheetDeductionGroupLabel(g.name),
        value: g.calculationRuleGroupId
      })
    })
    return allGroupOptions
  }, [sheet?.calculationRuleGroups])

  const paymentGroup = useMemo(() => {
    if (sheet?.calculationRuleGroups) {
      return sheet.calculationRuleGroups.find((group) => {
        return group.type === 'Payment'
      })
    }
    return null
  }, [sheet?.calculationRuleGroups])

  const getReferenceTypeByReferenceId = useCallback((id: string) => {
    if (calculationRuleGroupReferenceOptions.length) {
      const foundOption = calculationRuleGroupReferenceOptions.find((option) => option.value === id)
      if (foundOption) {
        return 'CalculationRuleGroup'
      }
    }
    if (calculationRuleReferenceOptions.length) {
      const foundOption = calculationRuleReferenceOptions.find((option) => option.value === id)
      if (foundOption) {
        return 'CalculationRule'
      }
    }
    return id;
  }, [calculationRuleGroupReferenceOptions, calculationRuleReferenceOptions])

  if (!sheet || !group) return null

  const currentSelectedValue = (
    referenceTypeName:
      | 'referenceType'
      | 'partialInvoiceReferenceType'
      | 'partialFinalInvoiceReferenceType'
      | 'finalInvoiceReferenceType',
    calculationRuleReferenceName:
      | 'calculationRuleReference'
      | 'partialInvoiceCalculationRuleReference'
      | 'partialFinalInvoiceCalculationRuleReference'
      | 'finalInvoiceCalculationRuleReference',
    calculationRuleGroupReferenceName:
      | 'calculationRuleGroupReference'
      | 'partialInvoiceCalculationRuleGroupReference'
      | 'partialFinalInvoiceCalculationRuleGroupReference'
      | 'finalInvoiceCalculationRuleGroupReference',
  ) => {
    if (calculationRule) {
      if (calculationRule[referenceTypeName] === 'CalculationRule') {
        return calculationRule[calculationRuleReferenceName]?.calculationRuleId;
      } else if (calculationRule[referenceTypeName] === 'CalculationRuleGroup') {
        return calculationRule[calculationRuleGroupReferenceName]?.calculationRuleGroupId;
      } else {
        return calculationRule[referenceTypeName];
      }
    }
    return 'None';
  };

  const defaultFormValues = {
    name: calculationRule?.name ?? '',
    type: calculationRule?.type ?? ('Deduction' as CalculationRuleType),
    group: group.calculationRuleGroupId,
    percentage:
      typeof calculationRule?.percentage !== 'undefined'
        ? calculationRule?.percentage
        : calculationRule?.type === 'CashDiscount'
          ? 0
          : null,
    vat: calculationRule?.vat ?? null,

    // not cumulated
    referenceType: calculationRule?.referenceType ?? ('None' as ReferenceType),
    selectedValue: currentSelectedValue('referenceType', 'calculationRuleReference', 'calculationRuleGroupReference'),
    calculationRuleReference: calculationRule?.calculationRuleReference?.calculationRuleId ?? null,
    calculationRuleGroupReference: calculationRule?.calculationRuleGroupReference?.calculationRuleGroupId ?? null,

    // cumulated only

    // partialInvoice
    selectedPartialInvoiceValue: currentSelectedValue(
      'partialInvoiceReferenceType',
      'partialInvoiceCalculationRuleReference',
      'partialInvoiceCalculationRuleGroupReference',
    ),
    partialInvoiceReferenceType: calculationRule?.partialInvoiceReferenceType ?? ('None' as ReferenceType),
    partialInvoiceCalculationRuleReference: calculationRule?.partialInvoiceCalculationRuleReference?.calculationRuleId ?? null,
    partialInvoiceCalculationRuleGroupReference: calculationRule?.partialInvoiceCalculationRuleGroupReference?.calculationRuleGroupId ?? null,

    // partialFinalInvoice
    selectedPartialFinalInvoiceValue: currentSelectedValue(
      'partialFinalInvoiceReferenceType',
      'partialFinalInvoiceCalculationRuleReference',
      'partialFinalInvoiceCalculationRuleGroupReference',
    ),
    partialFinalInvoiceReferenceType: calculationRule?.partialFinalInvoiceReferenceType ?? ('None' as ReferenceType),
    partialFinalInvoiceCalculationRuleReference: calculationRule?.partialFinalInvoiceCalculationRuleReference?.calculationRuleId ?? null,
    partialFinalInvoiceCalculationRuleGroupReference: calculationRule?.partialFinalInvoiceCalculationRuleGroupReference?.calculationRuleGroupId ?? null,

    // finalInvoice
    selectedFinalInvoiceValue: currentSelectedValue(
      'finalInvoiceReferenceType',
      'finalInvoiceCalculationRuleReference',
      'finalInvoiceCalculationRuleGroupReference',
    ),
    finalInvoiceReferenceType: calculationRule?.finalInvoiceReferenceType ?? ('None' as ReferenceType),
    finalInvoiceCalculationRuleReference: calculationRule?.finalInvoiceCalculationRuleReference?.calculationRuleId ?? null,
    finalInvoiceCalculationRuleGroupReference: calculationRule?.finalInvoiceCalculationRuleGroupReference?.calculationRuleGroupId ?? null,
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} onAfterLeave={onAfterLeave}>
      {(isCreatingTenantIcsDeduction ||
        isCreatingProjectIcsDeduction ||
        isUpdatingTenantIcsDeduction ||
        isUpdatingProjectIcsDeduction) && (
        <LoadingIndicator text={t('app.settingsSavingInvoiceCoverSheet')} mode="overlay-window" />
      )}
      <SlideOver.Header
        title={t('ics.invoiceCoverSheetDeduction')}
        subTitle={t('ics.newInvoiceCoverSheetDeduction')}
        backgroundClassName="bg-slate-900"
        onClose={onClose}
      />
      <Form<IcsDeductionNewFormValidationValues>
        validationSchema={IcsDeductionNewFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <SlideOver.Content className="p-8">
          <FormWatch<IcsDeductionNewFormValidationValues>
            onChange={({ name }) => {
              checkIfNameIsUnique(name);
            }}
            fieldNames={['name']}
          >
            {() => (
              <FormField name="name">
                {(control) => (
                  <TextInput
                    label={t('app.settingsInvoiceCoverSheetName')}
                    icon={<RenameIcon />}
                    {...control}
                    showValidation={nameIsNotUnique || control.showValidation}
                    helperText={
                      submitted && nameIsNotUnique ? t('ics.deductionWithNameAlreadyExists') : control.helperText
                    }
                    isValidationValid={(!submitted || (submitted && !nameIsNotUnique)) && control.isValidationValid}
                    helperTextTruncate={false}
                  />
                )}
              </FormField>
            )}
          </FormWatch>
          <FormField name="type">
            {(control) => (
              <BaseSelect
                label={t('ics.deductionTypeLabel')}
                options={calculationRuleTypeOptions}
                icon={<TaskListIcon />}
                disabled={
                  calculationRule ? excludeDeleteDeductionByType[sheet.type].includes(calculationRule.type) : false
                }
                {...control}
                onChange={(type) => {
                  if (type === 'CashDiscount') {
                    // Cash discount: default percentage is 0...
                    if (typeof calculationRule?.percentage === 'undefined') {
                      formRef.current?.setValue('percentage', 0);
                    }
                    // ... and reference group is set to "Payment" group
                    if (paymentGroup?.calculationRuleGroupId && calculationRule?.type !== 'CashDiscount') {
                      formRef.current?.setValue('referenceType', 'CalculationRuleGroup');
                      formRef.current?.setValue('calculationRuleGroupReference', paymentGroup.calculationRuleGroupId);
                      formRef.current?.setValue('selectedValue', paymentGroup.calculationRuleGroupId);

                      // we set those values anyways, but they will be ignored for not cumulated type in submit
                      formRef.current?.setValue('partialInvoiceReferenceType', 'CalculationRuleGroup');
                      formRef.current?.setValue(
                        'partialInvoiceCalculationRuleGroupReference',
                        paymentGroup.calculationRuleGroupId,
                      );
                      formRef.current?.setValue('selectedPartialInvoiceValue', paymentGroup.calculationRuleGroupId);

                      formRef.current?.setValue('partialFinalInvoiceReferenceType', 'CalculationRuleGroup');
                      formRef.current?.setValue(
                        'partialFinalInvoiceCalculationRuleGroupReference',
                        paymentGroup.calculationRuleGroupId,
                      );
                      formRef.current?.setValue(
                        'selectedPartialFinalInvoiceValue',
                        paymentGroup.calculationRuleGroupId,
                      );

                      formRef.current?.setValue('finalInvoiceReferenceType', 'CalculationRuleGroup');
                      formRef.current?.setValue(
                        'finalInvoiceCalculationRuleGroupReference',
                        paymentGroup.calculationRuleGroupId,
                      );
                      formRef.current?.setValue('selectedFinalInvoiceValue', paymentGroup.calculationRuleGroupId);
                    }
                  }
                  // percentage and value is only possible for "Deduction/Retention" and "CashDiscount"
                  if (type !== 'Deduction' && type !== 'Retention' && type !== 'CashDiscount') {
                    formRef.current?.setValue('percentage', null);
                    setCustomAmount(undefined);
                  }
                  // Setting a reference is only possible for "Deduction/Retention", "CashDiscount" and "Discount"
                  if (type !== 'Deduction' && type !== 'Retention' && type !== 'CashDiscount' && type !== 'Discount') {
                    // remove references
                    formRef.current?.setValue('referenceType', 'None');
                    formRef.current?.setValue('partialInvoiceReferenceType', 'None');
                    formRef.current?.setValue('partialFinalInvoiceReferenceType', 'None');
                    formRef.current?.setValue('finalInvoiceReferenceType', 'None');
                  }

                  // Setting vat is not allowed for subtotal
                  if (type === 'Subtotal') {
                    formRef.current?.setValue('vat', undefined);
                  }

                  setCurrentDeductionType(type as CalculationRuleType);
                  control.onChange(type);
                }}
              />
            )}
          </FormField>
          <FormField name="group">
            {(control) => (
              <BaseSelect
                label={t('ics.deductionGroup')}
                options={groupOptions}
                icon={<StaticViewLevel2Icon />}
                {...control}
                onChange={(value) => {
                  if (value) {
                    formRef.current?.setValue('selectedValue', 'None');
                    formRef.current?.setValue('calculationRuleReference', null);
                    formRef.current?.setValue('calculationRuleGroupReference', null);
                    formRef.current?.setValue('selectedPartialInvoiceValue', 'None');
                    formRef.current?.setValue('partialInvoiceCalculationRuleReference', null);
                    formRef.current?.setValue('partialInvoiceCalculationRuleGroupReference', null);
                    formRef.current?.setValue('selectedPartialFinalInvoiceValue', 'None');
                    formRef.current?.setValue('partialFinalInvoiceCalculationRuleReference', null);
                    formRef.current?.setValue('partialFinalInvoiceCalculationRuleGroupReference', null);
                    formRef.current?.setValue('selectedFinalInvoiceValue', 'None');
                    formRef.current?.setValue('finalInvoiceCalculationRuleReference', null);
                    formRef.current?.setValue('finalInvoiceCalculationRuleGroupReference', null);
                  }
                  control.onChange(value);
                }}
              />
            )}
          </FormField>
          <FormWatch<IcsDeductionNewFormValidationValues>
            fieldNames={[
              'type',
              'selectedValue',
              'selectedPartialFinalInvoiceValue',
              'selectedPartialInvoiceValue',
              'selectedFinalInvoiceValue',
            ]}
          >
            {({
                type,
                selectedValue,
                selectedPartialFinalInvoiceValue,
                selectedPartialInvoiceValue,
                selectedFinalInvoiceValue,
              }) => (
              <>
                {/*
                    All reference types, reference groups and references are set all in one dropdown:
                    Setting a reference is only possible for "Deduction/Retention", "Cash Discount" and "Discount"
                    Cash discount: default percentage is 0, default reference to the group "Payment"
                    Deduction/Retention: no default, everything is possible
                  */}
                {(type === 'Deduction' || type === 'Retention' || type === 'CashDiscount' || type === 'Discount') && (
                  <div className="mt-4">
                    {type !== 'Discount' && (
                      <>
                        <FormField name="percentage">
                          {(control) => (
                            <NumberInput
                              label={t('ics.deductionFactor')}
                              icon={<PercentageIcon className="h-6 w-6" />}
                              decimalScale={4}
                              {...control}
                            />
                          )}
                        </FormField>
                        {/* Reference or custom value for not cumulated invoices
                            For not cumulated use:
                            * ReferenceType
                            * CalculationRuleGroup
                            * CalculationRule
                         */}
                        {sheet?.type !== 'Cumulated' && (
                          <IcsDeductionValueFormField
                            getReferenceTypeByReferenceId={getReferenceTypeByReferenceId}
                            selectedValue={selectedValue}
                            calculationRuleReferenceOptions={calculationRuleReferenceOptions}
                            calculationRuleGroupReferenceOptions={calculationRuleGroupReferenceOptions}
                            sheet={sheet}
                            setCustomAmount={setCustomAmount}
                            customAmount={customAmount}
                            formRef={formRef}
                            name="selectedValue"
                            label={t('ics.deductionValueAmount')}
                          />
                        )}
                        {/*
                           References or custom values for cumulated invoices
                           For cumulated use:
                            * partialInvoiceReferenceType
                            * partialInvoiceCalculationRuleReference
                            * partialInvoiceCalculationRuleGroupReference
                            * partialFinalInvoiceReferenceType
                            * partialFinalInvoiceCalculationRuleReference
                            * partialFinalInvoiceCalculationRuleGroupReference
                            * finalInvoiceReferenceType
                            * finalInvoiceCalculationRuleReference
                            * finalInvoiceCalculationRuleGroupReference
                        */}
                        {sheet?.type === 'Cumulated' && (
                          <IcsDeductionCumulatedValueFormFields
                            getReferenceTypeByReferenceId={getReferenceTypeByReferenceId}
                            selectedValues={[
                              selectedPartialInvoiceValue,
                              selectedPartialFinalInvoiceValue,
                              selectedFinalInvoiceValue
                            ]}
                            calculationRuleReferenceOptions={calculationRuleReferenceOptions}
                            calculationRuleGroupReferenceOptions={calculationRuleGroupReferenceOptions}
                            sheet={sheet}
                            setCustomAmount={setCustomAmount}
                            customAmount={customAmount}
                            formRef={formRef}
                          />
                        )}
                      </>
                    )}
                    <FormField name="vat">
                      {(control) => (
                        <ProjectTaxPickerInput
                          isNullable
                          nullLabel={t('ics.useTaxRateFromInvoice')}
                          {...control}
                        />
                      )}
                    </FormField>
                  </div>
                )}
              </>
            )}
          </FormWatch>
        </SlideOver.Content>
        <SlideOver.Controls>
          <Button variant="secondary" className="mr-2" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit>
            {t('common.save')}
          </Button>
        </SlideOver.Controls>
      </Form>
    </SlideOver>
  );
}
