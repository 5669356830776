import { useUi } from '@client/shared/store';
import { Button, Modal, SlideOver } from '@client/shared/toolkit';
import { useState } from 'react';
import { CompanyAvatar } from './CompanyAvatar';
import { RemoveImageModal } from './RemoveImageModal';
import { UploadImageSlideOver } from './UploadImageSlideOver';
import { AddressEditSlideOver } from './AddressEditSlideOver';
import classNames from 'classnames';
import { NamesEditSlideOver } from './NamesEditSlideOver';
import { TenantOwner } from './TenantOwner';
import { useTranslation } from 'react-i18next';
import { SettingsHeader } from '../SettingsHeader';

export const CompanyInfo = () => {
  const { t } = useTranslation();

  const [showUploadImage, setShowUploadImage] = useState<boolean>(false);
  const [showRemoveImage, setShowRemoveImage] = useState<boolean>(false);
  const [showEditNames, setShowEditNames] = useState<boolean>(false);
  const [showEditAddress, setShowEditAddress] = useState<boolean>(false);

  const ui = useUi();
  const tenant = ui.appUser.tenant?.isOwner === true ? ui.appUser.tenant : undefined;

  return (
    <div>
      <SettingsHeader>
        {t('projectRent.dealContactLabelCompany')}
      </SettingsHeader>
      <div className="mt-3">
        <div className="border-b flex pb-5">
          <div className="grow text-sm">
            <p className="text-gray-600 mb-3">{t('app.settingsYourAccountProfilePicture')}</p>
            <div className="flex">
                <CompanyAvatar
                  tenantName={tenant?.name ?? ''}
                  tenantId={tenant?.tenantId ?? ''}
                  hasAvatar={tenant?.hasAvatar ?? false}
                  classNames="w-20 h-20"
                />
            </div>
          </div>
          {tenant?.hasAvatar && (
            <Button onClick={() => setShowRemoveImage(true)} className="my-auto mr-3">
              {t('common.delete')}
            </Button>
          )}
          <Button onClick={() => setShowUploadImage(true)} className="my-auto">
            {t('app.editUploadImage')}
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <div className="border-b flex pb-5">
          <div className="grow text-sm">
            <p className="text-gray-600 mb-3">{t('common.name')}</p>
            <div className="flex flex-col">
              <div className="font-bold text-gray-800 grow">{tenant?.name}</div>
              <div className="text-gray-800 grow">{tenant?.name2}</div>
            </div>
          </div>
          <Button onClick={() => setShowEditNames(true)} className="my-auto">
            {t('common.edit')}
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <div className="border-b flex pb-5">
          <TenantOwner />
        </div>
      </div>
      <div className="mt-3">
        <div className="border-b flex pb-5">
          <div className="grow text-sm">
            <p className="text-gray-600 mb-3">{t('app.settingsBillingAddress')}</p>
            <div className="flex flex-col">
              <div className="font-bold">{tenant?.billingAddress.name}</div>
              <div className="text-sm text-gray-600">{tenant?.billingAddress.email}</div>
              <div className="flex flex-row mt-2 text-gray-800">
                <div className="mr-1">{tenant?.billingAddress.street}</div>
                <div className="mr-1">{tenant?.billingAddress.number}</div>
                <div className="mr-3">{tenant?.billingAddress.suffix}</div>
                <div className="mr-1">{tenant?.billingAddress.postalCode}</div>
                <div className="">{tenant?.billingAddress.city}</div>
              </div>
              <div className="flex flex-row">
                <div className={classNames({ 'mr-1': tenant?.billingAddress.state })}>
                  {tenant?.billingAddress.state}
                </div>
                <div className="">{tenant?.billingAddress.country}</div>
              </div>
            </div>
          </div>
          <Button onClick={() => setShowEditAddress(true)} className="my-auto">
            {t('common.edit')}
          </Button>
        </div>
      </div>
      <SlideOver isOpen={showUploadImage} onClose={() => setShowUploadImage(false)}>
        <UploadImageSlideOver onClose={() => setShowUploadImage(false)} />
      </SlideOver>
      <Modal isOpen={showRemoveImage} onClose={() => setShowRemoveImage(false)}>
        <RemoveImageModal onClose={() => setShowRemoveImage(false)} />
      </Modal>
      <SlideOver isOpen={showEditNames} onClose={() => setShowEditNames(false)}>
        <NamesEditSlideOver onClose={() => setShowEditNames(false)} />
      </SlideOver>
      <SlideOver isOpen={showEditAddress} onClose={() => setShowEditAddress(false)}>
        <AddressEditSlideOver onClose={() => setShowEditAddress(false)} />
      </SlideOver>
    </div>
  );
};
