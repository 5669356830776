import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticateDatevClientResult, AuthenticateTenantDatevResponse } from '@client/shared/api';
import { LOCAL_STORAGE_KEY_DATEV_AUTH_DATA } from './utils/updateDatevAuthData';

export interface DatevAuthUpdateData {
  projectId?: string;
  apiAccessId: string;
}

export interface DatevAuthState {
  datevTenantAuthData: AuthenticateTenantDatevResponse | undefined;
  datevClientId: string | undefined;
  datevClientAuthData: AuthenticateDatevClientResult | undefined;
  datevUpdateData: DatevAuthUpdateData | undefined;
}

export const initialDatevAuthState: DatevAuthState = {
  datevTenantAuthData: undefined,
  datevClientId: undefined,
  datevClientAuthData: undefined,
  datevUpdateData: undefined,
};

export const datevAuthSlice = createSlice({
  name: 'datev',
  initialState: initialDatevAuthState,
  reducers: {
    setDatevAuth: (state, action: PayloadAction<DatevAuthState>) => {
      state.datevTenantAuthData = action.payload.datevTenantAuthData;
      state.datevClientId = action.payload.datevClientId;
      state.datevClientAuthData = action.payload.datevClientAuthData;
      state.datevUpdateData = action.payload.datevUpdateData;
    },
    setDatevTenantAuthData: (state, action: PayloadAction<AuthenticateTenantDatevResponse>) => {
      state.datevTenantAuthData = action.payload;
    },
    setDatevClientId: (state, action: PayloadAction<string>) => {
      state.datevClientId = action.payload;
    },
    setDatevClientAuthData: (state, action: PayloadAction<AuthenticateDatevClientResult>) => {
      state.datevClientAuthData = action.payload;
    },
    setDatevUpdateData: (state, action: PayloadAction<DatevAuthUpdateData>) => {
      state.datevUpdateData = action.payload;
    },
    removeDatevData: (state, action: PayloadAction) => {
      localStorage.removeItem(LOCAL_STORAGE_KEY_DATEV_AUTH_DATA);
      state = initialDatevAuthState;
    },
  },
});

export const {
  setDatevAuth,
  setDatevTenantAuthData,
  setDatevClientId,
  setDatevClientAuthData,
  setDatevUpdateData,
  removeDatevData,
} = datevAuthSlice.actions;
