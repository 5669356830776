import { formatNumber, formatPercentage, isCurrencySymbol } from '@client/shared/utilities';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CostCatalogElement, DecoratedElement } from '../hooks';
import { getDisplayAndChangeValue, getUnitLabel } from '../utils';
import { CalculateElementGroupSum } from './CalculateElementGroupSum';
import {
  ContextMenuItem,
  TransactionIcon,
  DistributeAllIcon,
  AddSubnodeIcon,
  MoveGrabberIcon,
  UncheckedCheckbox,
  TakeOverAllIcon, PencilIcon, TrashIcon,
  AddIcon
} from '@client/shared/toolkit';
import { ElementRowMenu } from './ElementRowMenu';
import { useLoadedProjectId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { TimeLineView } from './Timeline';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateColumns } from './CalculateColumns';
import {
  CalculateGroupElementInformationColumn,
  CalculateInformationColumnContent,
} from './CalculateGroupElementInformationColumn';
import { CostsFinanceDetailsPopover } from './Costs';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn, getUnderBudget } from './CalculateSelectableColumn';

interface CostGroupElementProps {
  item: DecoratedElement<CostCatalogElement>;
  selectedVersionId: string;
  expanded?: boolean;
  onClick?: () => void;
  onRemoveBudget?: () => void;
  onNewElement?: (type: 'costElement' | 'regulatoryElement') => void;
  onCodeClick?: () => void;
  onTakeOver?: () => void;
  onTakeOverAll?: () => void;
  onMove?: () => void;
  onDistribute?: () => void;
  onDistributeAll?: () => void;
  isSelected?: boolean;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  column?: Column;
  view: TimeLineView;
  isReadOnly: boolean;
  searchValue?: string
}

export const CostGroupElement = (props: CostGroupElementProps) => {
  const {
    isReadOnly = false,
    expanded = false,
    item,
    onClick,
    onNewElement,
    onRemoveBudget,
    onCodeClick,
    onTakeOver,
    onTakeOverAll,
    onMove,
    onDistribute,
    onDistributeAll,
    optionalColumn,
    obligoColumn,
    view,
    searchValue = ''
  } = props;
  const {
    //children,
    element: { group, showError, costElements },
    level,
  } = item;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canDeleteCosts = useValidateProjectPermission(['COSTS_DELETE'], loadedProjectId ?? '');
  const canWriteCosts = useValidateProjectPermission(['COSTS_WRITE'], loadedProjectId ?? '');

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onClick?.();
  };

  const handleOnCodeClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    onCodeClick?.();
  };

  // const childrenErrors = (children: DecoratedElement<CostCatalogElement>[]): boolean => {
  //   return children.some((child) => {
  //     if (
  //       child.element.costElement &&
  //       (child.element.costElement?.timelineErrors?.length > 0 ||
  //         child.element.costElement?.contractBudgetErrors?.length > 0)
  //     ) {
  //       return true;
  //     } else if (
  //       child.element.group &&
  //       (child.element.group.modelValues.calculationState === 'Overflow' ||
  //         child.element.group.timelineErrors.length > 0)
  //     ) {
  //       return true;
  //     } else if (child.children.length > 0) {
  //       return childrenErrors(child.children);
  //     } else {
  //       return false;
  //     }
  //   });
  // };

  const showExpanded = useMemo(() => {
    return (
      expanded &&
      ((costElements && costElements.length > 0) ||
        (view === TimeLineView.FINANCE &&
          ((group?.contracts && group?.contracts?.length > 0) ||
            !!(
              group?.modelValues.restBudget && group?.modelValues?.restBudget !== group?.modelValues?.effectiveValue
            ) ||
            !!(group?.refValues?.restBudget && group?.refValues?.restBudget !== group?.refValues?.effectiveValue))))
    );
  }, [expanded, costElements, view, group]);

  const groupMenuItemsTimelineView = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];

    if (onNewElement) {
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.rowMenu.newCostElementTitle'),
        subtitle: t('projectCalculate.rowMenu.newCostElementSubTitle'),
        onClick: () => onNewElement('costElement'),
        icon: <AddIcon />,
      });
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.featureElement'),
        subtitle: t('projectCalculate.featureElementSubtitle'),
        onClick: () => onNewElement('regulatoryElement'),
        icon: <AddIcon />,
      });
    }
    if (onClick) {
      items.push({
        label: t('projectCalculate.rowMenu.updateGroupElementTitle'),
        subtitle: t('projectCalculate.rowMenu.updateGroupElementSubTitle'),
        onClick: onClick,
        icon: <PencilIcon />,
      });
    }
    if (onRemoveBudget) {
      items.push({
        isDisabled: !canDeleteCosts,
        label: t('projectCalculate.rowMenu.removeGroupBudgetTitle'),
        subtitle: t('projectCalculate.rowMenu.removeGroupBudgetSubTitle'),
        onClick: onRemoveBudget,
        icon: <TrashIcon />,
      });
    }
    return items;
  }, [t, onNewElement, onClick, onRemoveBudget, canDeleteCosts, canWriteCosts]);

  const groupMenuItemsFinanceView = useMemo<ContextMenuItem[]>(() => {
    const items: ContextMenuItem[] = [];
    if (onNewElement) {
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.rowMenu.newChildCostElementTitle'),
        subtitle: t('projectCalculate.rowMenu.newChildCostElementSubTitle'),
        onClick: () => onNewElement('costElement'),
        icon: <AddSubnodeIcon />,
      });
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.rowMenu.newFeatureElementTitle'),
        subtitle: t('projectCalculate.rowMenu.newRegulatoryElementSubTitle'),
        onClick: () => onNewElement('regulatoryElement'),
        icon: <AddSubnodeIcon />,
      });
    }
    if (onMove) {
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.rowMenu.moveElementTitle'),
        subtitle: t('projectCalculate.rowMenu.moveElementSubTitle'),
        onClick: onMove,
        icon: <MoveGrabberIcon />,
      });
    }
    if (onRemoveBudget) {
      items.push({
        isDisabled: !canDeleteCosts,
        label: t('projectCalculate.rowMenu.removeGroupBudgetTitle'),
        subtitle: t('projectCalculate.rowMenu.removeGroupBudgetSubTitle'),
        onClick: onRemoveBudget,
        icon: <TrashIcon />,
      });
    }
    if (onTakeOver) {
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.rowMenu.takeoverForecastTitle'),
        subtitle: t('projectCalculate.rowMenu.takeoverForecastSubTitle'),
        onClick: onTakeOver,
        icon: <UncheckedCheckbox />,
      });
    }
    if (onTakeOverAll) {
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.rowMenu.takeoverForecastTitle'),
        subtitle: t('projectCalculate.rowMenu.forAllChildrenSubTitle'),
        onClick: onTakeOverAll,
        icon: <TakeOverAllIcon />,
      });
    }
    if (onDistribute) {
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.rowMenu.distributeRestObligoTitle'),
        subtitle: t('projectCalculate.rowMenu.takeoverForecastSubTitle'),
        onClick: onDistribute,
        icon: <TransactionIcon />,
      });
    }
    if (onDistributeAll) {
      items.push({
        isDisabled: !canWriteCosts,
        label: t('projectCalculate.rowMenu.distributeRestObligoTitle'),
        subtitle: t('projectCalculate.rowMenu.forAllChildrenSubTitle'),
        onClick: onDistributeAll,
        icon: <DistributeAllIcon />,
      });
    }
    return items;
  }, [
    onNewElement,
    canWriteCosts,
    t,
    onMove,
    onRemoveBudget,
    canDeleteCosts,
    onTakeOver,
    onDistribute,
    onDistributeAll,
    onTakeOverAll,
  ]);

  const detailValues = useMemo(() => {
    if (group?.modelValues) {
      const { displayValue } = getDisplayAndChangeValue(group.modelValues);
      return {
        // Vorheriges Budget // TODO
        previousBudget: null,
        // Hauptauftrag
        mainContract: group.modelValues?.totalContract,
        // Nachtrag
        totalSupplement: group.modelValues?.totalSupplement,
        // Gesamtauftrag
        totalContract:
          group.modelValues.totalContract && group.modelValues.totalSupplement
            ? group.modelValues.totalContract + group.modelValues.totalSupplement
            : null,
        // Externe Prognose
        effectiveForecast: group.modelValues?.effectiveForecast,
        // Aktuelles Budget
        totalBudget: displayValue,
      };
    }
    return null;
  }, [group?.modelValues]);

  const totalValue = useMemo(() => {
    if (group?.modelValues.calculationState === 'JustTotal') {
      return group?.modelValues.total
    } else if (group?.modelValues.calculationState === 'JustCalculated') {
      return group?.modelValues.calculatedValue
    } else {
      return group?.modelValues.effectiveValue
    }
  }, [group?.modelValues.calculationState, group?.modelValues.total, group?.modelValues.calculatedValue, group?.modelValues.effectiveValue])

  const underBudgetInPercent = useMemo(() => {
    return getUnderBudget(group?.modelValues.effectiveForecast, group?.modelValues.total)
  }, [group?.modelValues.effectiveForecast, group?.modelValues.total])

  return (
    <CalculateColumns
      view={view}
      informationColumnContent={
        <CalculateGroupElementInformationColumn
          level={level}
          view={view}
          data-cy={`costgroup-${item.element?.group?.code}`}
          handleOnClick={onClick ? handleOnClick : undefined}
          codeWidth={group?.code?.length}
          leftColumn={
            <CalculateInformationColumnContent
              showCode={!!group?.code}
              code={group?.code}
              level={level}
              handleOnCodeClick={handleOnCodeClick}
              showExpanded={showExpanded}
              showError={showError}
              title={
                group?.isUnassignedContainer
                  ? t('projectCalculate.calculateCostsElementGroupUnassigned')
                  : group?.description
              }
              searchValue={searchValue}
            >
              {group?.modelValues.vat && <span> ({group?.modelValues.vat}%)</span>}
              {group?.hasFormula && <sup className="font-normal italic text-gray-400">&nbsp;fx</sup>}
            </CalculateInformationColumnContent>
          }
          rightColumn={
            <>
              <div className="pl-2 hidden md:flex w-2/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  {isCurrencySymbol(group?.modelValues.unit) ? (
                    <FormattedCurrency amount={group?.modelValues.amount} />
                  ) : (
                    formatNumber(group?.modelValues.amount)
                  )}
                  {group?.modelValues.unit && !isCurrencySymbol(group.modelValues.unit) && (
                    <div className="text-xs font-light">{getUnitLabel(group.modelValues.unit)}</div>
                  )}
                </div>
              </div>
              <div className="hidden w-4/12 md:flex xl:w-5/12 text-sm text-right flex-row justify-end items-center leading-tight">
                <div>
                  <FormattedCurrency amount={group?.modelValues.unitPrice} />
                  {group?.modelValues.unit && !isCurrencySymbol(group.modelValues.unit) && group.modelValues.unit !== '%' && (
                    <div className="text-xs font-light">
                      {t('projectCalculate.earningsElementLabelPer')} {getUnitLabel(group?.modelValues.unit)}
                    </div>
                  )}
                </div>
              </div>
              {group && (
                <CalculateElementGroupSum
                  classNames={`w-5/12`}
                  calculationState={group?.modelValues.calculationState}
                  calculatedValue={group?.modelValues.calculatedValue}
                  effectiveValue={group?.modelValues.effectiveValue}
                  difference={group?.modelValues.difference}
                  total={group?.modelValues.total}
                  overflow={group?.modelValues.overflow}
                  remainder={group?.modelValues.remainder}
                  forecast={group?.modelValues.effectiveForecast}
                  column={Column.INFORMATION}
                />
              )}
            </>
          }
          menu={
            view === TimeLineView.FINANCE ? (
              <ElementRowMenu
                items={groupMenuItemsFinanceView}
                details={<CostsFinanceDetailsPopover detailValues={detailValues} />}
              />
            ) : (
              !isReadOnly && <ElementRowMenu items={groupMenuItemsTimelineView} />
            )
          }
        />
      }
      currentColumnContent={
        <CalculateElementRowFinanceColumn
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.CURRENT}
          view={view}
        >
          {group?.modelValues.calculationState === 'Empty' ? (
            <div>&nbsp;</div>
          ) : (
            <>
              {!!totalValue && (
                <>
                  {underBudgetInPercent ? (
                    <div className="flex gap-1 items-center">
                      <span className="text-[9px] text-red-800">
                        {formatPercentage(
                          underBudgetInPercent,
                          { maxDigits: 0 }
                        )}
                      </span>
                      <FormattedCurrency amount={totalValue} />
                    </div>
                  ) : (
                    <FormattedCurrency amount={totalValue} />
                  )}
                </>
              )}
            </>
          )}
        </CalculateElementRowFinanceColumn>
      }
      obligoColumnContent={
        <CalculateSelectableColumn
          column={obligoColumn}
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.OBLIGO}
          view={view}
          values={group}
          type="costCatalogElement"
        />
      }
      optionalColumnContent={
        <CalculateSelectableColumn
          column={optionalColumn}
          level={level}
          handleOnClick={handleOnClick}
          columnType={Column.OPTIONAL_COLUMN}
          view={view}
          values={group}
          type="costCatalogElement"
        />
      }
    />
  );
};
