import classNames from 'classnames';
import React, { Fragment, PropsWithChildren, ReactNode, useEffect, useId, useMemo } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Listbox, Transition, Portal, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { isEmpty } from '@client/shared/utilities';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FormHelperText } from './FormHelperText';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './Tooltip';
import { InfoIcon } from '../icons';

export interface BaseSelectOption {
  label: string;
  value: string;
  order?: number;
  disabled?: boolean;
  icon?: ReactNode;
  options?: BaseSelectOption[];
  children?: ReactNode;
  customLabel?: string | null;
  info?: ReactNode
}

export interface BaseSelectProps {
  label: string;
  value: string;
  options: BaseSelectOption[];
  additionalOption?: ReactNode;
  onChange: (selected: string) => void;
  onBlur?: () => void;
  icon?: React.ReactNode;
  placeHolder?: string;
  className?: string;
  disabled?: boolean;
  showValidation?: boolean;
  isValidationValid?: boolean;
  helperText?: string;
  children?: ReactNode;
  nullable?: boolean;
  handlePopoverVisibility?: (isOpen: boolean) => void
}

export const BaseSelect = ({
  className,
  disabled,
  helperText,
  icon,
  isValidationValid,
  label,
  onChange,
  onBlur,
  options,
  additionalOption,
  placeHolder,
  showValidation,
  value,
  children,
  nullable,
  handlePopoverVisibility
}: BaseSelectProps) => {
  const { t } = useTranslation();
  const inputId = useId();
  const selectedOption = useMemo(() => {
    let foundOption: BaseSelectOption | undefined;
    options.forEach((option) => {
      if (!foundOption) {
        if (option.value === value) {
          foundOption = option;
        } else if (option.options?.length) {
          foundOption = option.options.find((childOption) => childOption.value === value);
        }
      }
    });
    return foundOption;
  }, [options, value]);
  const popperElRef = React.useRef(null);
  const [targetElement, setTargetElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
    ],
  });

  useEffect(() => {
    if (handlePopoverVisibility) {
      handlePopoverVisibility(!!popperElement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  const listOptionsWidth = targetElement?.scrollWidth;

  return (
    <div className={className}>
      <div className="w-full relative h-14 bg-white">
        <Listbox value={value} onChange={(item) => onChange(item)} disabled={disabled} as="div" onBlur={onBlur}>
          {({ open }) => (
            <>
              <div ref={setTargetElement}>
                <ListboxButton
                  className={classNames('w-full relative h-14 px-3 flex flex-row bg-white outline-none peer', {
                    'shadow-[inset_0px_0px_0px_1px] shadow-red-500':
                      showValidation && isValidationValid != null && !isValidationValid,
                    'shadow-[inset_0px_0px_0px_1px] shadow-green-500': showValidation && isValidationValid,
                  })}
                >
                  {icon && (
                    <div className="flex items-center h-full">
                      <div className="h-5 w-5 flex items-center justify-center">{icon}</div>
                    </div>
                  )}
                  <div
                    className={classNames('relative flex-grow truncate', {
                      'ml-2': icon,
                      'mx-1': !icon,
                      'h-full flex items-center': !label,
                    })}
                  >
                    <div
                      className={classNames(
                        'fake-mt block w-full text-lg appearance-none focus:outline-none bg-transparent font-medium text-left truncate pr-4',
                        {
                          // 'text-gray-800': !disabled,
                          'text-gray-500 cursor-not-allowed': disabled,
                          'pt-5': label,
                        },
                      )}
                      title={selectedOption?.label}
                    >
                      {selectedOption?.customLabel ?? selectedOption?.label ?? <>&nbsp;</>}
                    </div>
                    {label && (
                      <label
                        htmlFor={inputId}
                        className={classNames(
                          'absolute top-0 left-0 right-0 text-lg duration-200 origin-0 text-gray-600 select-none transform truncate pr-4 text-left',
                          {
                            'pt-3 mt-[3px]': isEmpty(value) && isEmpty(placeHolder),
                            'pt-5 -mt-px text-xs -translate-y-3': !isEmpty(value) || !isEmpty(placeHolder),
                          },
                        )}
                      >
                        {label}
                      </label>
                    )}
                  </div>
                  <div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon className="w-5 h-5 text-gray-800" />
                    </div>
                  </div>
                </ListboxButton>
                <div
                  className={classNames(
                    'absolute bottom-0 h-0.5 bg-black left-0 right-0 duration-200 transition-opacity peer-focus:opacity-100',
                    {
                      'opacity-0': !open,
                    },
                  )}
                />
              </div>

              <Portal>
                <div
                  ref={popperElRef}
                  style={{ ...styles.popper, width: listOptionsWidth }}
                  {...attributes.popper}
                  className="z-50"
                >
                  <Transition
                    show={open}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    beforeEnter={() => setPopperElement(popperElRef.current)}
                    afterLeave={() => setPopperElement(null)}
                  >
                    <ListboxOptions className="origin-top-right bg-white border border-t-0 border-gray-200 divide-y divide-gray-100 rounded-bl-lg rounded-br-lg shadow-lg outline-none max-h-72 overflow-y-auto">
                      {nullable && selectedOption && (
                        <Fragment key={`select-option-clear`}>
                          <ListboxOption
                            className={({ focus }) =>
                              classNames(
                                'select-none relative py-2 px-3 text-sm  truncate text-center',
                                { 'cursor-pointer text-gray-900': !focus },
                                { 'cursor-pointer text-gray-900 bg-sky-100': focus },
                              )
                            }
                            value={null}
                            title={t('app.clearSelection')}
                          >
                            {t('app.clearSelection')}
                          </ListboxOption>
                        </Fragment>
                      )}

                      {options.map((option, index) => (
                        <Fragment key={`select-option-${index}`}>
                          <ListboxOption
                            className={({ focus }) =>
                              classNames(
                                'select-none relative py-3 px-3 text-lg',
                                { 'cursor-pointer text-gray-900': !focus && !option.disabled },
                                { 'cursor-pointer text-gray-900 bg-sky-100': focus && !option.disabled },
                                { 'cursor-not-allowed text-gray-400 bg-gray-100/50': option.disabled },
                              )
                            }
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {({ selected }) => (
                              <div className="flex gap-2 items-center justify-between">
                                <div className="flex gap-2 items-center relative">
                                  {option.icon}
                                  <span
                                    className={cn('block truncate', {
                                      'font-medium pr-5': selected,
                                    })}
                                    title={option.label}
                                  >
                                    {option.label}
                                  </span>
                                </div>
                                <div className="flex gap-2 items-center">
                                  {selected ? <CheckIcon className="w-5 h-5 text-primary" /> : undefined}
                                  {option.info}
                                </div>
                              </div>
                            )}
                          </ListboxOption>
                          {option.children}
                          {option.options && option.options.length > 0 && (
                            <div className="ml-6">
                              {option.options.map((childOption, childIndex) => (
                                <ListboxOption
                                  key={`select-option-${index}-${childIndex}`}
                                  className={({ focus }) =>
                                    classNames(
                                      'select-none relative py-3 px-3 text-lg',
                                      { 'cursor-pointer text-gray-900': !focus && !childOption.disabled },
                                      { 'cursor-pointer text-gray-900 bg-sky-100': focus && !childOption.disabled },
                                      { 'cursor-not-allowed text-gray-400 bg-gray-100/50': childOption.disabled },
                                    )
                                  }
                                  value={childOption.value}
                                  disabled={childOption.disabled}
                                >
                                  {({ selected }) => (
                                    <div className="flex gap-2 items-center justify-between">
                                      <div className="flex gap-2 items-center">
                                        {childOption.icon}
                                        <span
                                          className={cn('block truncate', {
                                            'font-medium pr-5': selected,
                                          })}
                                          title={childOption.label}
                                        >
                                          {childOption.label}
                                        </span>
                                      </div>
                                      <div className="flex gap-2 items-center">
                                        {selected ? <CheckIcon className="w-5 h-5 text-primary pr-3" /> : undefined}
                                        {option.info}
                                      </div>
                                    </div>
                                  )}
                                </ListboxOption>
                              ))}
                            </div>
                          )}
                        </Fragment>
                      ))}
                      {additionalOption && (
                        <ListboxButton
                          as="div"
                          className={classNames('py-3 px-3 text-lg cursor-pointer text-gray-900', {
                            'hover:bg-sky-100 cursor-pointer': !disabled,
                          })}
                        >
                          {additionalOption}
                        </ListboxButton>
                      )}
                    </ListboxOptions>
                  </Transition>
                </div>
              </Portal>
            </>
          )}
        </Listbox>
      </div>
      {helperText && (
        <div className="w-full bg-white">
          <FormHelperText text={helperText} error={!isValidationValid} />
        </div>
      )}
      {children}
    </div>
  );
};

export const BaseSelectOptionInfoTooltip = ({ children }: PropsWithChildren) => {
  return (
    <Tooltip
      label={<InfoIcon className="w-6 cursor-pointer" />}
      tooltipPanelClasses="z-50 max-w-[308px] bg-secondary rounded-xl"
    >
      <>{children}</>
    </Tooltip>
  )
}
