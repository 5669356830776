import {
  BaseSelect,
  Button,
  Form,
  FormField,
  EditNodeIcon,
  Modal,
  ModalOnCloseProps,
  NumberInput, PercentageIcon, TagWindowIcon,
  TextInput, LoadingIndicator,
} from '@client/shared/toolkit';
import { ProjectSettingGroupType } from '.';
import { useTranslation } from 'react-i18next';
import {
  ElementReadModel,
  ElementType,
  VatReadModel,
  useApiPostCreateProjectConstructionPhaseMutation,
  useApiPostUpdateProjectConstrutionPhaseMutation,
  useApiPostCreateProjectStatusMutation,
  useApiPostUpdateProjectStatusMutation,
  useApiPostCreateProjectVatMutation,
  useApiPostUpdateProjectVatMutation,
} from '@client/shared/api';
import { i18n, safeMutation } from '@client/shared/utilities';
import {
  ProjectSettingElementFormValidationSchema,
  ProjectSettingElementFormValidationValues,
} from './ProjectSettingElementFormValidationSchema';
import { useState } from 'react';
import { ProjectSettingElementDeleteModal } from './ProjectSettingElementDeleteModal';
import classNames from 'classnames';
import { useValidateProjectPermission } from '@client/shared/permissions';

interface ProjectSettingElementModalProps extends ModalOnCloseProps {
  isAdd: boolean;
  projectId: string;
  readOnly: boolean;
  settingElement?: ElementReadModel;
  vatElement?: VatReadModel;
  groupType: ProjectSettingGroupType;
  getSettingElementGroupTranslation: (groupType: ProjectSettingGroupType) => string;
}

export const getElementTypeValueLabel = (elementType: ElementType): string => {
  switch (elementType) {
    case 'CostElement':
      return i18n.t('Cost Element');
    case 'RiskElement':
      return i18n.t('Risk Element');
    case 'Earnings':
      return i18n.t('Earnings');
    case 'Taxonomy':
      return i18n.t('Taxonomy');
    default:
      return 'None';
  }
};

export const ProjectSettingElementModal = ({
  isAdd,
  onClose,
  groupType,
  projectId,
  readOnly,
  settingElement,
  vatElement,
  getSettingElementGroupTranslation,
}: ProjectSettingElementModalProps) => {
  const { t } = useTranslation();

  const [editStatus, { isLoading: isUpdating }] = useApiPostUpdateProjectStatusMutation();
  const [addStatus, { isLoading: isAdding }] = useApiPostCreateProjectStatusMutation();

  const [editConstructionPhase, { isLoading: isPhaseUpdating }] = useApiPostUpdateProjectConstrutionPhaseMutation();
  const [addConstructionPhase, { isLoading: isAddingPhase }] = useApiPostCreateProjectConstructionPhaseMutation();

  const [editVat, { isLoading: isVatUpdating }] = useApiPostUpdateProjectVatMutation();
  const [addVat, { isLoading: isAddingVat }] = useApiPostCreateProjectVatMutation();

  const canDelete = useValidateProjectPermission(['PROJECT_DELETE'], projectId);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const availableElementTypes: { label: string; value: ElementType }[] = [
    { label: getElementTypeValueLabel('CostElement'), value: 'CostElement' },
    { label: getElementTypeValueLabel('RiskElement'), value: 'RiskElement' },
    { label: getElementTypeValueLabel('Earnings'), value: 'Earnings' },
    { label: getElementTypeValueLabel('Taxonomy'), value: 'Taxonomy' },
  ];

  const handleSubmit = async (data: ProjectSettingElementFormValidationValues) => {
    switch (groupType) {
      case 'Status':
        await handleStatusSubmit(data);
        break;
      case 'Construction Phase':
        await handleConstructionPhaseSubmit(data);
        break;
      case 'Vat':
        await handleVatSubmit(data);
        break;
    }
  };

  const handleStatusSubmit = async (data: ProjectSettingElementFormValidationValues) => {
    try {
      if (isAdd) {
        await safeMutation(
          addStatus,
          {
            projectId: projectId,
            body: {
              name: data.name ?? '',
              elementType: data.elementType as ElementType,
            },
          },
          isAdding
        );
      } else {
        await safeMutation(
          editStatus,
          {
            projectId: projectId,
            id: settingElement?.id ?? '',
            body: {
              elementType: data.elementType as ElementType,
              name: data.name ?? '',
            },
          },
          isUpdating
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleConstructionPhaseSubmit = async (data: ProjectSettingElementFormValidationValues) => {
    try {
      if (isAdd) {
        await safeMutation(
          addConstructionPhase,
          {
            projectId: projectId,
            body: {
              name: data.name ?? '',
              elementType: data.elementType as ElementType,
            },
          },
          isAddingPhase
        );
      } else {
        await safeMutation(
          editConstructionPhase,
          {
            projectId: projectId,
            id: settingElement?.id ?? '',
            body: {
              name: data.name ?? '',
              elementType: data.elementType as ElementType,
            },
          },
          isPhaseUpdating
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleVatSubmit = async (data: ProjectSettingElementFormValidationValues) => {
    try {
      if (isAdd) {
        await safeMutation(
          addVat,
          {
            projectId: projectId,
            body: {
              vat: data.vat ?? 0,
              elementType: data.elementType as ElementType,
            },
          },
          isAddingVat
        );
      } else {
        await safeMutation(
          editVat,
          {
            projectId: projectId,
            id: vatElement?.id ?? '',
            body: {
              vat: data.vat ?? 0,
              elementType: data.elementType as ElementType,
            },
          },
          isVatUpdating
        );
      }

      onClose(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseDelete = () => {
    setIsOpenDeleteModal(false);
    onClose(true);
  };
  const defaultValues = {
    projectId: projectId,
    groupType: groupType,
    name: settingElement?.name ?? '',
    vat: vatElement?.vat,
    elementType: settingElement?.elementType ?? vatElement?.elementType ?? 'CostElement',
  };

  return (
    <>
      {(isUpdating || isAdding || isPhaseUpdating || isAddingPhase || isVatUpdating || isAddingVat) && (
        <LoadingIndicator text={t('project.updatingProjectLoadingIndicator')} mode="overlay" />
      )}
      <Form<ProjectSettingElementFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={ProjectSettingElementFormValidationSchema}
        defaultValues={defaultValues}
        className="flex flex-col flex-grow min-h-0"
      >
        <Modal.Header
          title={
            isAdd
              ? t('common.add').concat(' ', getSettingElementGroupTranslation(groupType))
              : getSettingElementGroupTranslation(groupType).concat(' ', t('common.update'))
          }
        />
        <Modal.Content className="h-full space-y-6 flex-grow ">
          {groupType !== 'Vat' && (
            <FormField name="name">
              {(control) => (
                <TextInput
                  disabled={readOnly}
                  label={getSettingElementGroupTranslation(groupType)}
                  icon={<TagWindowIcon />}
                  {...control}
                />
              )}
            </FormField>
          )}
          {groupType === 'Vat' && (
            <FormField name="vat">
              {(control) => (
                <NumberInput
                  disabled={readOnly}
                  label={getSettingElementGroupTranslation(groupType)}
                  decimalScale={2}
                  icon={<PercentageIcon />}
                  {...control}
                />
              )}
            </FormField>
          )}
          <FormField name="elementType">
            {(control) => (
              <BaseSelect
                disabled={readOnly}
                label={t('app.settingsColType')}
                options={availableElementTypes}
                icon={<EditNodeIcon />}
                {...control}
              />
            )}
          </FormField>
        </Modal.Content>
        <Modal.Controls className={classNames('bg-white', !isAdd ? 'justify-between' : 'justify-end')}>
          {!isAdd && canDelete && (
            <Button variant="warning" onClick={() => setIsOpenDeleteModal(true)}>
              {t('common.delete')}
            </Button>
          )}
          <div className="flex">
            <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
              {t('common.cancel')}
            </Button>
            {!readOnly && (
              <Button variant="primary" formSubmit={true}>
                {t('common.save')}
              </Button>
            )}
          </div>
        </Modal.Controls>
      </Form>

      {!isAdd && (settingElement || vatElement) && (
        <Modal isOpen={isOpenDeleteModal} onClose={handleCloseDelete} variant="small">
          <ProjectSettingElementDeleteModal
            groupType={groupType}
            projectId={projectId}
            element={settingElement}
            vatElement={vatElement}
            onClose={handleCloseDelete}
            getSettingElementGroupTranslation={getSettingElementGroupTranslation}
          />
        </Modal>
      )}
    </>
  );
};
