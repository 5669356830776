import * as yup from 'yup';
import { InferType } from 'yup';
import {
  UserDefinedFieldElementType,
  UserDefinedFieldCalculateElementType,
  UserDefinedFieldType,
} from '@client/shared/api';

export const CreateCustomFieldFormValidationSchema = yup.object({
  id: yup.string().optional(),
  name: yup.string().required('validation.required'),
  description: yup.string().nullable().optional(),
  fieldType: yup.mixed<UserDefinedFieldType>().oneOf(['Text', 'Number', 'List']).required('validation.required'),
  listItems: yup.array().when('fieldType', {
    is: 'List',
    then: () =>
      yup
        .array()
        .of(
          yup.object().shape({
            label: yup.string().required('validation.required'),
          }),
        )
        .min(1),
  }),
  elementType: yup
    .mixed<UserDefinedFieldElementType>()
    .oneOf(['Cost', 'Risk', 'Earning', 'Project', 'Taxonomy', 'Contract', 'ContractTitle', 'Invoice'])
    .required('validation.required'),
  visibilityType: yup
    .mixed()
    .oneOf([
      'AlwaysVisibleAndRequired',
      'AlwaysVisible',
      'VisibleOnComponentAfterManualSelection',
      'VisibleOnAllComponentsAfterManualSelection',
    ])
    .required('validation.required'),
  calculateElementType: yup
    .mixed<UserDefinedFieldCalculateElementType>()
    .oneOf(['Group', 'Element'])
    .nullable()
    .when('elementType', ([elementType]) => {
      return elementType === 'Cost' || elementType === 'Risk' || elementType === 'Earning'
        ? yup
            .mixed<UserDefinedFieldCalculateElementType>()
            .oneOf(['Group', 'Element'])
            .nullable()
            .required('validation.required')
        : yup.mixed<UserDefinedFieldCalculateElementType>().oneOf(['Group', 'Element']).nullable().optional();
    }),
  isRequired: yup.boolean().optional(),
  defaultText: yup.string().nullable().optional(),
  defaultNumber: yup.number().nullable().optional(),
  defaultListItem: yup.string().nullable().optional(),
});

export type CreateCustomFieldFormValidationValues = InferType<typeof CreateCustomFieldFormValidationSchema>;
