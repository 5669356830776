import React, { useEffect, useState } from 'react';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline';

export interface PaginationProps {
  totalItems: number;
  setShownItems: (startIndex: number, endIndex: number) => void;
  amountPerPage: number;
}

export const Pagination = ({ totalItems, setShownItems, amountPerPage }: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const startIndex = (currentPage - 1) * amountPerPage;
    const endIndex = Math.min(startIndex + amountPerPage, totalItems);
    setShownItems(startIndex, endIndex);
  }, [currentPage, totalItems, amountPerPage, setShownItems]);

  const getPageButtons = () => {
    const maxVisibleButtons = 8;
    const totalPages = Math.ceil(totalItems / amountPerPage);
    const displayStart = Math.max(1, Math.min(currentPage - 3, totalPages - maxVisibleButtons + 1));
    const displayEnd = Math.min(totalPages, displayStart + maxVisibleButtons - 1);
    const buttons = [];

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={`page-${i}`}
            className={`px-2 py-1 rounded ${
              currentPage === i ? 'bg-primary text-white' : 'bg-gray-200 hover:opacity-80 text-gray-800'
            }`}
            disabled={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>,
        );
      }
    } else {
      if (displayStart > 1) {
        buttons.push(
          <button
            key={`page-1`}
            className={`px-2 py-1 rounded ${
              currentPage === 1 ? 'bg-primary text-white' : 'bg-gray-200 hover:opacity-80 text-gray-800'
            }`}
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(1)}
          >
            1
          </button>,
          <span key="placeholder1">...</span>,
        );
      }

      for (let i = displayStart; i <= displayEnd; i++) {
        buttons.push(
          <button
            key={`page-${i}`}
            className={`px-2 py-1 rounded ${
              currentPage === i ? 'bg-primary text-white' : 'bg-gray-200 hover:opacity-80 text-gray-800'
            }`}
            disabled={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>,
        );
      }

      if (displayEnd < totalPages) {
        buttons.push(
          <span key="placeholder2">...</span>,
          <button
            key={`page-${totalPages}`}
            className={`px-2 py-1 rounded ${
              currentPage === totalPages ? 'bg-primary text-white' : 'bg-gray-200 hover:opacity-80 text-gray-800'
            }`}
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(totalPages)}
          >
            {totalPages}
          </button>,
        );
      }
    }

    return buttons;
  };

  return (
    totalItems > amountPerPage && (
      <div className="w-full flex justify-center gap-2 transition-all duration-200 mt-4">
        <button
          className={`px-2 py-1 rounded bg-gray-200 text-gray-800 ${
            currentPage === 1 ? 'opacity-60' : 'hover:opacity-80'
          }`}
          disabled={currentPage === 1}
          onClick={() => currentPage !== 1 && setCurrentPage(currentPage - 1)}
        >
          <ArrowLongLeftIcon className="w-4 h-4 fill-gray-800" />
        </button>
        {getPageButtons()}
        <button
          className={`px-2 py-1 rounded bg-gray-200 text-gray-800 ${
            currentPage === Math.ceil(totalItems / amountPerPage) ? 'opacity-60' : 'hover:opacity-80'
          }`}
          disabled={currentPage === Math.ceil(totalItems / amountPerPage)}
          onClick={() => currentPage !== Math.ceil(totalItems / amountPerPage) && setCurrentPage(currentPage + 1)}
        >
          <ArrowLongRightIcon className="w-4 h-4 fill-gray-800" />
        </button>
      </div>
    )
  );
};
