import { Modal, Button, FileInput, ModalOnCloseProps, LoadingIndicator } from '@client/shared/toolkit';
import {useApiPostProjectGroupCoverMutation, useApiPostUploadProjectCoverMutation} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ProjectImageUploadModalProps extends ModalOnCloseProps {
  projectId: string;
  group?: boolean
}

export const ProjectImageUploadModal = ({ onClose, projectId, group = false }: ProjectImageUploadModalProps) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const [postImage, { isLoading }] = useApiPostUploadProjectCoverMutation();
  const [postGroupImage, { isLoading: groupIsLoading }] = useApiPostProjectGroupCoverMutation();

  const handleSaveProject = async () => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        if (group) {
          await safeMutation(
            postGroupImage,
            {
              groupId: projectId,
              body: formData as unknown as { file: Blob },
            },
            groupIsLoading
          );
        } else {
          await safeMutation(
            postImage,
            {
              projectId: projectId,
              body: formData as unknown as { file: Blob },
            },
            isLoading
          );
        }

        onClose(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleFileChange = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    }
  };

  return (
    <>
      {(isLoading || groupIsLoading) && <LoadingIndicator text={t('common.uploading')} mode="overlay" />}
      <Modal.Header title={t('app.projectImageUploadTitle')} />
      <Modal.Content>
        <FileInput
          className="bg-white"
          acceptedFileTypes={['image/*']}
          multiple={false}
          onChange={(files) => handleFileChange(files)}
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSaveProject} disabled={!file}>
          {t('app.projectImageUploadImage')}
        </Button>
      </Modal.Controls>
    </>
  );
};
