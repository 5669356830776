import { FormattedCurrency } from '../../FormattedCurrency';
import React, { PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import {
  InvoiceCalculationRuleReadModel,
  InvoiceReadModel, useApiPostUpdateInvoiceCalculationRuleMutation
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import {
  getIcsDeductionName,
  getIcsDeductionTypeLabel,
  getInvoiceCoverSheetDeductionGroupLabel,
  getIcsVatRenderedInfo,
} from '../../../utils';
import cn from 'classnames';
import { formatDistanceToNow, parseISO } from 'date-fns'
import { DocumentViewerFileDataInlineEdit, LoadingIndicator, NumberInput, PercentageIcon } from '@client/shared/toolkit';
import { ProjectTaxPickerInput } from '../../ProjectTaxPickerInput';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';

export interface InvoiceCalculationProps {
  invoice: InvoiceReadModel
  isLastInvoice?: boolean
}
export const InvoiceCalculation = (props: InvoiceCalculationProps) => {
  const {
    invoice,
    // isLastInvoice
  } = props
  const { t } = useTranslation()

  let vatRendered = false;
  let prevSum = invoice.valueNet;

  /* const paymentGroup = useMemo(() => {
    return invoice.invoiceCalculationRuleGroups.find((group) => group.type === 'Payment')
  }, [invoice.invoiceCalculationRuleGroups]) */

  const vatRenderedInfo = useMemo(() => {
    return getIcsVatRenderedInfo(invoice.invoiceCalculationRuleGroups);
  }, [invoice.invoiceCalculationRuleGroups]);

  /* const getGroupCalculationRulesSum = useCallback((vatRendered: boolean, calculationRules: InvoiceCalculationRuleReadModel[], groupIndex: number) => {
    let sum = 0;
    const groupVatRendered = groupIndex > vatRenderedInfo.groupIndex;
    calculationRules.forEach((rule, ruleIndex) => {
      if (rule.type !== 'Subtotal') {
        sum += ruleIndex < vatRenderedInfo.ruleIndex && !groupVatRendered ? rule.calculationResultGross : rule.calculationResultNet;
      }
    });
    return sum;
  }, [vatRenderedInfo]); */

  return (
    <>
      <div className="flex justify-between items-center mt-4">
        <span className="font-bold text-[13px]">{t('projectControl.sumOfPayments')}:</span>
        <span className="font-bold text-[15px]"><FormattedCurrency amount={invoice.valueNet} /></span>
      </div>
      <div className="flex flex-col gap-5 mt-6">
        {invoice.invoiceCalculationRuleGroups.map((group, groupIndex) => {
          // whether we need to show the group sum or not
          // let showSum = true
          // tmp store the old prevSum
          const previous = prevSum;
          // override the prevSum with the current group sum
          prevSum = groupIndex >= vatRenderedInfo.groupIndex ? group.calculationResultGross : group.calculationResultNet;

          return (
            <div
              className="w-full flex flex-col divide-y-2"
              key={`invoice-calculation-rule-group-${group.calculationRuleGroupId}`}
            >
              <div className="flex justify-between items-center pb-2 w-full">
                <span className="text-lg">{getInvoiceCoverSheetDeductionGroupLabel(group.name)}</span>
                {/* Use the tmp stored prev sum */}
                <span className="font-bold text-[15px]"><FormattedCurrency amount={previous} /></span>
              </div>

              {group.calculationRules.length > 0 ? (
                <div className="pt-2 w-full flex flex-col gap-2">
                  {group.calculationRules.map((rule, ruleIndex) => {
                    if (rule.type === 'Vat') {
                      vatRendered = true
                    }
                    // TODO after working correctly, reinsert this again
                    /* if (rule.type === 'CashDiscount') {
                      const isLast = groupIndex === invoice.invoiceCalculationRuleGroups.length - 1 && ruleIndex === group.calculationRules.length - 1
                      /* if (isLast) {
                        showSum = false
                      }
                      return (
                        <InvoiceCalculationCashDiscountSection
                          key={`invoice-calculation-rule-${rule.calculationRuleId}`}
                          rule={rule}
                          isLast={isLast}
                          invoice={invoice}
                          vatRendered={vatRendered}
                          paymentGroupValue={vatRendered ? paymentGroup?.calculationResultGross ?? 0 : paymentGroup?.calculationResultNet ?? 0 }
                          isLastInvoice={isLastInvoice}
                        />
                      )
                    } */
                    return (
                      <InvoiceCalculationRow
                        key={`invoice-calculation-rule-${rule.calculationRuleId}`}
                        name={getIcsDeductionName(rule.name)}
                        /* everything before VAT is net, below it's the gross value (for vat those values are the same) */
                        value={vatRendered ? rule.calculationResultGross : rule.calculationResultNet}
                        rule={rule}
                        invoiceId={invoice.id}
                        calculationRuleGroupId={group.calculationRuleGroupId}
                        editable={invoice.state === 'Pending'}
                      />
                    )
                  })}
                </div>
              ) : <span className="text-gray-500 pt-2">-</span>}
              <div className="flex justify-between items-center pt-2 mt-2">
                <span className="font-bold text-[13px]">{t('ics.deductionGroupLabelSum')}</span>
                <span className="font-bold text-[15px]"><FormattedCurrency amount={vatRendered ? group.calculationResultGross : group.calculationResultNet} /></span>
                {/* <span className="font-bold text-[15px]"><FormattedCurrency amount={getGroupCalculationRulesSum(vatRendered, group.calculationRules, groupIndex)} /></span> */}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

interface InvoiceCalculationRowProps extends PropsWithChildren {
  name: string | ReactNode
  value?: number
  className?: string
  centered?: boolean
  editable?: boolean
  rule: InvoiceCalculationRuleReadModel
  invoiceId: string
  calculationRuleGroupId: string
}

export const InvoiceCalculationRow = (props: InvoiceCalculationRowProps) => {
  const {
    name,
    value,
    className,
    centered = true,
    children,
    editable = true,
    rule,
    invoiceId,
    calculationRuleGroupId,
  } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [update, { isLoading }] = useApiPostUpdateInvoiceCalculationRuleMutation();

  const [allowChangeMode, setAllowChangeMode] = useState(true);

  const [overridePercentage, setOverridePercentage] = useState<undefined | null | number>(undefined);
  const [overrideValue, setOverrideValue] = useState<undefined | null | number>(undefined);
  const [overrideVat, setOverrideVat] = useState<undefined | null | number>(undefined);

  /**
   * Can edit Percentage:
   *
   * Type == Deduction
   * Type == Retention
   * Type == CashDiscount
   */
  const factorIsDisabled = useMemo(() => {
    return rule.type !== 'Deduction' && rule.type !== 'Retention' && rule.type !== 'CashDiscount';
  }, [rule.type]);

  /**
   * Can edit Value:
   *
   * calculation rule doesn't have a reference
   * Type == Repayment
   * Type == Deduction
   * Type == Retention
   * Type == CashDiscount
   */
  const valueIsDisabled = useMemo(() => {
    if (
      rule.referenceType !== 'None' ||
      rule.partialInvoiceReferenceType !== 'None' ||
      rule.partialFinalInvoiceReferenceType !== 'None' ||
      rule.finalInvoiceReferenceType !== 'None'
    ) {
      return true;
    }
    return rule.type !== 'Repayment' && rule.type !== 'Deduction' && rule.type !== 'Retention' && rule.type !== 'CashDiscount';
  }, [rule.finalInvoiceReferenceType, rule.partialFinalInvoiceReferenceType, rule.partialInvoiceReferenceType, rule.referenceType, rule.type]);

  /**
   * Can edit Vat:
   *
   * Type == Deduction
   * Type == Retention
   * Type == CashDiscount
   * Type == Discount
   */
  const vatIsDisabled = useMemo(() => {
    return rule.type !== 'Deduction' && rule.type !== 'Retention' && rule.type !== 'CashDiscount' && rule.type !== 'Discount';
  }, [rule.type]);

  const typeLabel = useMemo(() => {
    return getIcsDeductionTypeLabel(rule.type);
  }, [rule.type]);

  const isEditable = useMemo(() => {
    return editable && rule.type !== 'Vat';
  }, [editable, rule.type])

  const handleUpdate = async () => {
    if (loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          update,
          {
            invoiceId: invoiceId,
            calculationRuleGroupId: calculationRuleGroupId,
            calculationRuleId: rule.calculationRuleId,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              calculationRuleGroupId: calculationRuleGroupId,
              name: rule.name,
              type: rule.type,
              vat: typeof overrideVat !== 'undefined' && overrideVat !== rule.vat ? overrideVat : rule.vat,
              percentage:
                typeof overridePercentage !== 'undefined' && overridePercentage !== rule.percentage ? overridePercentage : rule.percentage,
              value: typeof overrideValue !== 'undefined' && overrideValue !== rule.value ? overrideValue : rule.value,
              referenceType: rule.referenceType,
              calculationRuleGroupReferenceId: rule.calculationRuleGroupReference?.calculationRuleGroupId,
              calculationRuleReferenceId: rule.calculationRuleReference?.calculationRuleId,
              finalInvoiceReferenceType: rule.finalInvoiceReferenceType,
              finalInvoiceCalculationRuleGroupReferenceId: rule.finalInvoiceCalculationRuleGroupReference?.calculationRuleGroupId,
              finalInvoiceCalculationRuleReferenceId: rule.finalInvoiceCalculationRuleReference?.calculationRuleId,
              partialFinalInvoiceReferenceType: rule.partialFinalInvoiceReferenceType,
              partialFinalInvoiceCalculationRuleGroupReferenceId: rule.partialFinalInvoiceCalculationRuleGroupReference?.calculationRuleGroupId,
              partialFinalInvoiceCalculationRuleReferenceId: rule.partialFinalInvoiceCalculationRuleReference?.calculationRuleId,
              partialInvoiceReferenceType: rule.partialInvoiceReferenceType,
              partialInvoiceCalculationRuleGroupReferenceId: rule.partialInvoiceCalculationRuleGroupReference?.calculationRuleGroupId,
              partialInvoiceCalculationRuleReferenceId: rule.partialInvoiceCalculationRuleReference?.calculationRuleId
            },
          },
          isLoading,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  if (isEditable) {
    return (
      <>
        {isLoading && <LoadingIndicator mode="overlay-window" />}
        <DocumentViewerFileDataInlineEdit
          marginX=""
          marginY="my-0"
          allowChangeMode={allowChangeMode}
          closeOnBlur={false}
          updateEditMode={async (editMode) => {
            // for debugging
            /* if (editMode) {
              console.log(rule, 'rule');
            } */
            if (
              !editMode &&
              ((typeof overridePercentage !== 'undefined' && overridePercentage !== null && overridePercentage !== rule.percentage) ||
                (typeof overrideVat !== 'undefined' && overrideVat !== null && overrideVat !== rule.vat) ||
                (typeof overrideValue !== 'undefined' && overrideValue !== null && overrideValue !== rule.value))
            ) {
              await handleUpdate();
              setOverridePercentage(undefined);
              setOverrideValue(undefined);
              setOverrideVat(undefined);
            }
          }}
          toggleContent={
            <>
              <span className="font-medium">
                {name} {typeLabel !== name && <>({typeLabel})</>}
              </span>
              {rule.type !== 'Discount' && (
                <NumberInput
                  className="mt-2"
                  label={t('ics.deductionFactor')}
                  icon={<PercentageIcon className="h-6 w-6" />}
                  value={overridePercentage ?? rule.percentage}
                  onChange={setOverridePercentage}
                  disabled={factorIsDisabled}
                  decimalScale={4}
                />
              )}
              <NumberInput
                className="mt-0.5"
                label={t('ics.deductionValueAmount')}
                value={overrideValue ?? rule.value}
                onChange={setOverrideValue}
                disabled={valueIsDisabled}
                decimalScale={2}
              />
              <ProjectTaxPickerInput
                isNullable
                nullLabel={t('ics.useTaxRateFromInvoice')}
                value={overrideVat ?? rule.vat ?? undefined}
                onChange={setOverrideVat}
                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                disabled={vatIsDisabled}
                className="mt-0.5"
              />
            </>
          }
        >
          <div className={cn('flex justify-between text-[15px]', centered ? 'items-center' : className)}>
            {name}
            {typeof value !== 'undefined' && (
              <span className="font-bold">
              <FormattedCurrency amount={value} options={{ maxDigits: 2 }} />
            </span>
            )}
            {children}
          </div>
        </DocumentViewerFileDataInlineEdit>
      </>
    );
  }
  return (
    <div className={cn('flex justify-between text-[15px]', centered ? 'items-center' : className)}>
      {name}
      {typeof value !== 'undefined' && (
        <span className="font-bold">
          <FormattedCurrency amount={value} options={{ maxDigits: 2 }} />
        </span>
      )}
      {children}
    </div>
  );
};


interface InvoiceCalculationCashDiscountSectionProps {
  rule: InvoiceCalculationRuleReadModel
  isLast: boolean
  invoice: InvoiceReadModel
  paymentGroupValue: number
  vatRendered: boolean
  isLastInvoice?: boolean
}

export const InvoiceCalculationCashDiscountSection = (props: InvoiceCalculationCashDiscountSectionProps) => {
  const {
    rule,
    isLast,
    invoice,
    paymentGroupValue,
    vatRendered,
    isLastInvoice = true
  } = props
  const { t } = useTranslation()

  const skontoInfo = useMemo(() => {
    return (
      <>
        <div className="flex justify-between items-center text-[13px] font-bold mb-2">
          <span>{t('ics.exampleOutputValue')}</span>
          <span className="font-bold"><FormattedCurrency amount={paymentGroupValue} /></span>
        </div>
        <InvoiceCalculationRow
          name={(
            <div className="flex flex-col gap-1 leading-none">
              {t('ics.minusCashDiscount')}
              {invoice.cashDiscountDate && (
                <span className="font-normal text-xs">
                  {parseISO(invoice.cashDiscountDate) > new Date() ? (
                    t('ics.cashDiscountUntil', { date: formatDistanceToNow(invoice.cashDiscountDate, { addSuffix: true, includeSeconds: false })})
                  ) : (
                    formatDistanceToNow(invoice.cashDiscountDate, { addSuffix: true, includeSeconds: false })
                  )}
                </span>
              )}
            </div>
          )}
          value={vatRendered ? rule.calculationResultGross : rule.calculationResultNet}
          className="font-bold leading-none"
          centered={false}
          rule={rule}
          invoiceId={invoice.id}
          calculationRuleGroupId="todo"
          editable={invoice.state === 'Pending'}
        />
      </>
    )
  }, [t, paymentGroupValue, invoice.cashDiscountDate, vatRendered, invoice.id, rule, invoice.state])

  if (isLast) {
    return (
      <div className={cn('bg-primary -ml-6 -mr-6 text-white p-6 mt-4', isLastInvoice ? '-mb-[40px]' : '')}>
        {skontoInfo}
      </div>
    )
  }

  return skontoInfo
}
