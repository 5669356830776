import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  ApiDeleteInvoiceApiArg,
  ApiDeleteInvoiceApiResponse,
  ApiDeleteInvoiceByContractApiArg,
  ApiDeleteInvoiceByContractApiResponse,
  ApiDeleteInvoiceDocumentApiArg,
  ApiDeleteInvoiceDocumentApiResponse,
  ApiDeleteInvoiceTitleApiArg,
  ApiDeleteInvoiceTitleApiResponse,
  ApiGetInvoiceApiArg,
  ApiGetInvoiceApiResponse,
  ApiGetInvoiceReportApiArg,
  ApiGetInvoiceReportApiResponse,
  ApiGetInvoicesApiArg,
  ApiGetInvoicesApiResponse,
  ApiPostApproveInvoiceApiArg,
  ApiPostApproveInvoiceApiResponse,
  ApiPostAuditInvoiceApiArg,
  ApiPostAuditInvoiceApiResponse,
  ApiPostCreateInvoiceApiArg,
  ApiPostCreateInvoiceApiResponse,
  ApiPostCreateInvoiceByContractApiArg,
  ApiPostCreateInvoiceByContractApiResponse,
  ApiPostCreateInvoiceByContractWithSourceDocumentApiArg,
  ApiPostCreateInvoiceByContractWithSourceDocumentApiResponse,
  ApiPostCreateInvoiceDocumentsApiArg,
  ApiPostCreateInvoiceDocumentsApiResponse,
  ApiPostCreateInvoiceSourceDocumentApiArg,
  ApiPostCreateInvoiceSourceDocumentApiResponse,
  ApiPostCreateInvoiceTitleApiArg,
  ApiPostCreateInvoiceTitleApiResponse,
  ApiPostCreateInvoiceWithSourceDocumentApiArg,
  ApiPostCreateInvoiceWithSourceDocumentApiResponse,
  ApiPostImportInvoiceFileApiArg,
  ApiPostImportInvoiceFileApiResponse,
  ApiPostPayInvoiceApiArg,
  ApiPostPayInvoiceApiResponse,
  ApiPostReopenInvoiceApiArg,
  ApiPostReopenInvoiceApiResponse,
  ApiPostTakeoverInvoiceByContractApiArg,
  ApiPostTakeoverInvoiceByContractApiResponse,
  ApiPostUpdateInvoiceApiArg,
  ApiPostUpdateInvoiceApiResponse,
  ApiPostUpdateInvoiceByContractApiArg,
  ApiPostUpdateInvoiceByContractApiResponse,
  ApiPostUpdateInvoiceCalculationRuleApiArg,
  ApiPostUpdateInvoiceCalculationRuleApiResponse,
  ApiPostUpdateInvoiceTitleApiArg,
  ApiPostUpdateInvoiceTitleApiResponse,
  ApiPostUpdateInvoiceTitleByContractTitleApiArg,
  ApiPostUpdateInvoiceTitleByContractTitleApiResponse,
} from '../apiGenerated';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiControlEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetInvoices: {
    providesTags: (_result: ApiGetInvoicesApiResponse, _error: FetchBaseQueryError, args: ApiGetInvoicesApiArg) => [
      {
        type: ApiTagTypes.Invoices,
        id: args.calculationModelId,
      },
      {
        type: ApiTagTypes.Invoices,
        id: args.projectId,
      },
      ApiTagTypes.Invoices,
    ],
  },
  apiGetInvoice: {
    providesTags: (result: ApiGetInvoiceApiResponse, _error: FetchBaseQueryError, args: ApiGetInvoiceApiArg) => {
      const tags = [
        {
          type: ApiTagTypes.Invoice,
          id: args.invoiceId,
        },
        {
          type: ApiTagTypes.Invoice,
          id: args.calculationModelId,
        },
      ];
      if (result?.invoice?.contractId) {
        tags.push({ type: ApiTagTypes.ContractInvoice, id: result.invoice.contractId });
      }
      if (result?.invoice?.calculationSchemeId) {
        tags.push({ type: ApiTagTypes.CalculationSchemeInvoice, id: result.invoice.calculationSchemeId });
      }
      if (result?.invoice?.invoiceRecipient?.companyId) {
        tags.push({ type: ApiTagTypes.CompanyInvoice, id: result?.invoice?.invoiceRecipient?.companyId });
      }
      if (result?.invoice?.invoicingParty?.companyId) {
        tags.push({ type: ApiTagTypes.CompanyInvoice, id: result?.invoice?.invoicingParty?.companyId });
      }
      return tags;
    },
  },
  apiGetInvoiceReport: {
    providesTags: (
      result: ApiGetInvoiceReportApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetInvoiceReportApiArg,
    ) => {
      const tags = [
        {
          type: ApiTagTypes.Invoice,
          id: args.invoiceId,
        },
        {
          type: ApiTagTypes.Invoice,
          id: args.calculationModelId,
        },
      ];
      if (result?.invoice?.contract?.id) {
        tags.push({ type: ApiTagTypes.ContractInvoice, id: result.invoice.contract.id });
      }
      if (result?.invoice?.calculationSchemeId) {
        tags.push({ type: ApiTagTypes.CalculationSchemeInvoice, id: result.invoice.calculationSchemeId });
      }
      if (result?.invoice?.invoiceRecipient?.companyId) {
        tags.push({ type: ApiTagTypes.CompanyInvoice, id: result?.invoice?.invoiceRecipient?.companyId });
      }
      if (result?.invoice?.invoicingParty?.companyId) {
        tags.push({ type: ApiTagTypes.CompanyInvoice, id: result?.invoice?.invoicingParty?.companyId });
      }
      return tags;
    },
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiPostCreateInvoice: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Tasks, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },        
          ]
        : [],
  },
  apiPostCreateInvoiceByContract: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceByContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceByContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.contractId ?? '' },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Tasks, id: args.calculationModelId },
            { type: ApiTagTypes.UserDefinedFields, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },            
          ]
        : [],
  },
  apiPostCreateInvoiceWithSourceDocument: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceWithSourceDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceWithSourceDocumentApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Tasks, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.UserDefinedFields, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostCreateInvoiceByContractWithSourceDocument: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceByContractWithSourceDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceByContractWithSourceDocumentApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.contractId ?? '' },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.Tasks, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },            
          ]
        : [],
  },
  apiPostAuditInvoice: {
    invalidatesTags: (
      _result: ApiPostAuditInvoiceApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostAuditInvoiceApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.InvoiceContract, id: args.invoiceId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostApproveInvoice: {
    invalidatesTags: (
      _result: ApiPostApproveInvoiceApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostApproveInvoiceApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.InvoiceContract, id: args.invoiceId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostPayInvoice: {
    invalidatesTags: (
      _result: ApiPostPayInvoiceApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostPayInvoiceApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.InvoiceContract, id: args.invoiceId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostReopenInvoice: {
    invalidatesTags: (
      _result: ApiPostReopenInvoiceApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostReopenInvoiceApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.InvoiceContract, id: args.invoiceId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostImportInvoiceFile: {
    invalidatesTags: (
      _result: ApiPostImportInvoiceFileApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostImportInvoiceFileApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.calculationModelId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.calculationModelId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostUpdateInvoice: {
    invalidatesTags: (
      _result: ApiPostUpdateInvoiceApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateInvoiceApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.UserDefinedFields, id: args.invoiceId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostUpdateInvoiceByContract: {
    invalidatesTags: (
      _result: ApiPostUpdateInvoiceByContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateInvoiceByContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.UserDefinedFields, id: args.invoiceId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostTakeoverInvoiceByContract: {
    invalidatesTags: (
      _result: ApiPostTakeoverInvoiceByContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostTakeoverInvoiceByContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contract, id: args.originalContractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiDeleteInvoice: {
    invalidatesTags: (
      _result: ApiDeleteInvoiceApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteInvoiceApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiDeleteInvoiceByContract: {
    invalidatesTags: (
      result: ApiDeleteInvoiceByContractApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteInvoiceByContractApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.DetailReport, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostCreateInvoiceTitle: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceTitleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceTitleApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.Invoice, id: args.invoiceId },
      { type: ApiTagTypes.AuditLogs, id: args.projectId, },
    ] : []),
  },
  apiPostUpdateInvoiceTitle: {
    invalidatesTags: (
      _result: ApiPostUpdateInvoiceTitleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateInvoiceTitleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },
          ]
        : [],
  },
  apiPostUpdateInvoiceTitleByContractTitle: {
    invalidatesTags: (
      _result: ApiPostUpdateInvoiceTitleByContractTitleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateInvoiceTitleByContractTitleApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.Invoice, id: args.invoiceId },
            { type: ApiTagTypes.Invoices, id: args.calculationModelId },
            { type: ApiTagTypes.Contract, id: args.contractId },
            { type: ApiTagTypes.Contracts, id: args.calculationModelId },
            { type: ApiTagTypes.AuditLogs, id: args.projectId, },            
          ]
        : [],
  },
  apiDeleteInvoiceTitle: {
    invalidatesTags: (
      _result: ApiDeleteInvoiceTitleApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteInvoiceTitleApiArg,
    ) => (error == null ? [
      { type: ApiTagTypes.Invoice, id: args.invoiceId },
      { type: ApiTagTypes.AuditLogs, id: args.projectId, },
    ] : []),
  },
  apiPostCreateInvoiceDocuments: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceDocumentsApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceDocumentsApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiDeleteInvoiceDocument: {
    invalidatesTags: (
      _result: ApiDeleteInvoiceDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteInvoiceDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiPostCreateInvoiceSourceDocument: {
    invalidatesTags: (
      _result: ApiPostCreateInvoiceSourceDocumentApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateInvoiceSourceDocumentApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
  apiPostUpdateInvoiceCalculationRule: {
    invalidatesTags: (
      _result: ApiPostUpdateInvoiceCalculationRuleApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateInvoiceCalculationRuleApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Invoice, id: args.invoiceId }] : []),
  },
};
